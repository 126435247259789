import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";

import { Divider } from "@mui/material";
import { Chip } from "@mui/material";

import { SupportedPlatforms } from "./FileUpload";

import styles from "./style.module.css";
import loadingGif from "./../../../assets/images/aws/loadingGif.gif";


import {
  Container, Button
} from "reactstrap";

import {
  getIaacLicenseRequest, 
  getIaacSubscriptionRequest,
  postIaacSubscriptionRequest,
  putIaacSubscriptionRequest,
  clearAllIaacSubscriptionMessages
} from "../../../store/actions";

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

export const subscriptionNames = ["Basic", "Standard", "Premium", "Enterprise"];

const LicenseSelection = (props) => {
  const { orgcode } = props;
  const { licenseSelectionMode, setPage, currentSubscription, setSubscriptionUpdateTime } = props;
  const [previousSubscription, setPreviousSubscription] = useState(currentSubscription);

  const [userSelectedLicense, setUserSelectedLicense] = useState(null);

  console.log("LicenseSelection props: ", props);

  useEffect(()=>{
    if(orgcode){
      props.getIaacLicense(orgcode);
    }
  }, [orgcode]);


  // Helper function to check if the selected subscription can be upgraded
  const canUpgradeSubscription = (selectedSubscription) => {
    console.log("canUpgradeSubscription, selectedSubscription: ", selectedSubscription);
    console.log("canUpgradeSubscription, currentSubscription: ", currentSubscription);
    const order = { Basic: 0, Standard: 1, Premium: 2, Enterprise: 3 };
    if(subscriptionNames.includes(selectedSubscription.subscription)){
      return order[selectedSubscription.subscription] > order[currentSubscription.subscription];
    } else {
      return order["Enterprise"] > order[currentSubscription.subscription];
    }
  }

  const shouldDisableButton = (selectedSubscription) => {
    if (licenseSelectionMode === "update" && currentSubscription) {
      const order = { Basic: 0, Standard: 1, Premium: 2, Enterprise: 3 };
      if(subscriptionNames.includes(selectedSubscription.subscription)) {
        return order[selectedSubscription.subscription] <= order[currentSubscription.subscription];
      } else {
        return order["Enterprise"] <= order[currentSubscription.subscription];
      }
    }
    return false; // Default to false for "create" mode or if no currentSubscription
  };

  const handleLicenseClick = (subscriptionRequest) => {
    console.log("handleLicenseClick subscriptionRequest = ", subscriptionRequest);
    if (licenseSelectionMode === "create" || canUpgradeSubscription(subscriptionRequest)) {
      setUserSelectedLicense(subscriptionRequest);
    } else {
      // Provide a user-friendly message or handle the case where downgrade is not allowed
      console.log("Downgrading subscription is not allowed.");
      enqueueSnackbar("Downgrading subscription is not allowed.", {variant: "error"});   
    }
    // setUserSelectedLicense(subscriptionRequest);
  }

  // const handleLicense = (licenseSelectionMode) => {
  //   const subscriptionRequest = {
  //     subscription: userSelectedLicense.subscription,
  //     price: userSelectedLicense.price,
  //     orgCode: orgcode,
  //     limit_value: userSelectedLicense.limit
  //   };

  //   if(licenseSelectionMode === "create") {
  //     console.log("~~~~~ subscriptionRequest = ", subscriptionRequest);
  //     props.postIaacSubscription(orgcode, subscriptionRequest);
  //   } else {
  //     props.putIaacSubscription(orgcode, subscriptionRequest);
  //   }
  // }
  const handleLicense = () => {
    if (userSelectedLicense) {
      const subscriptionRequest = {
        subscription: userSelectedLicense.subscription,
        price: userSelectedLicense.price,
        orgCode: orgcode,
        limit_value: userSelectedLicense.limit
      };
      console.log("userSelectedLicense: ", userSelectedLicense);
  
      if (licenseSelectionMode === "create") {
        console.log("~~~~~ create, subscriptionRequest = ", subscriptionRequest);
        props.postIaacSubscription(orgcode, subscriptionRequest);
      } else {
        // Check if upgrading the subscription is allowed
        if (canUpgradeSubscription(userSelectedLicense)) {
          console.log("~~~~~ update, subscriptionRequest = ", subscriptionRequest);
          props.putIaacSubscription(orgcode, subscriptionRequest);
        } else {
          // Provide a user-friendly message or handle the case where downgrade is not allowed
          console.log("Downgrading subscription is not allowed.");
          enqueueSnackbar("Downgrading subscription is not allowed.", {variant: "error"});   
        }
      }
    }
  }
  

  const postIaacSubscriptionMessage = props.postIaacSubscriptionMessage;
  const postIaacSubscriptionError = props.postIaacSubscriptionError;
  const putIaacSubscriptionMessage = props.putIaacSubscriptionMessage;
  const putIaacSubscriptionError = props.putIaacSubscriptionError;
  useEffect(()=>{
    if(postIaacSubscriptionMessage){
      console.log("postIaacSubscriptionMessage = ", postIaacSubscriptionMessage);
      enqueueSnackbar(postIaacSubscriptionMessage, {variant: "success"});      
      // props.getIaacSubscription(orgcode);
      setSubscriptionUpdateTime(new Date().getTime());
      setPage("dashboard");
    }
    if(putIaacSubscriptionMessage){
      console.log("putIaacSubscriptionMessage = ", putIaacSubscriptionMessage);
      enqueueSnackbar(putIaacSubscriptionMessage, {variant: "success"});      
      props.getIaacSubscription(orgcode);
      // setPage("dashboard");
    }    
    if(postIaacSubscriptionError) {
      console.log("postIaacSubscriptionError = ", postIaacSubscriptionError);
      enqueueSnackbar(postIaacSubscriptionError, {variant: "error"});   
    }
    if(putIaacSubscriptionError) {
      console.log("putIaacSubscriptionError = ", putIaacSubscriptionError);
      enqueueSnackbar(putIaacSubscriptionError, {variant: "error"});   
    }
    setTimeout(() => props.clearAllIaacSubscriptionMessages(), 10*1000);
  }, [
      orgcode, postIaacSubscriptionMessage, postIaacSubscriptionError, 
      putIaacSubscriptionMessage, putIaacSubscriptionError, 
      props, setPage, setSubscriptionUpdateTime
  ]);

  useEffect(()=>{
    if(props.subscription){
      // console.log("props.subscription = ", props.subscription);
      if(licenseSelectionMode==="update"){
        if((props.subscription.subscription!==previousSubscription.subscription)){
          setPreviousSubscription(props.subscription);
          setSubscriptionUpdateTime(new Date().getTime());
          setPage("dashboard");
        }
      }       
    }
  }, [
      props.subscription, previousSubscription, 
      setPreviousSubscription, licenseSelectionMode, setPage,
      setSubscriptionUpdateTime
    ]
  );

  
  const sortedSubscriptions = props.license
  ? props.license.subscriptions.slice().sort((a, b) => {
      const order = { Basic: 0, Standard: 1, Premium: 2, Enterprise: 3 };
      const aSubscriptionName = subscriptionNames.includes(a.subscription)?a.subscription:"Enterprise";
      const bSubscriptionName = subscriptionNames.includes(b.subscription)?b.subscription:"Enterprise";

      return order[aSubscriptionName] - order[bSubscriptionName];
    })
  : [];

  return (
    <>
      <SnackbarProvider />
      <div className="page-content" style={{ backgroundColor: "#fff" }}>
      <Container fluid>
        <div className={styles.headerContainer}>
          <h2 className={styles.header}>
            {licenseSelectionMode === "create" ? "License Selection": "Edit License "}
            {licenseSelectionMode === "update" && (
              <Chip 
                label={`Current License: ${currentSubscription["subscription"]}`}
                component="a"
                color="primary"
                variant="outlined"
              />
            )}
            {licenseSelectionMode === "update" && (
              <Button
              style={{
                textAlign: "right",
                backgroundColor: "#F98125",
                float: "right",
                borderColor: "#fff",
              }}
              size="sm"
              onClick={()=> setPage("dashboard")}
            >
              Show IaC Dashboard 
            </Button>
            )}
          </h2>
        </div>
        {props.isIaacLicenseGetting && (
          <div style={{display:"flex", justifyContent: "center", alignItems:"center"}}>
            <img src={loadingGif} height={40} alt="Loading Spinner" />
          </div>
        )}
        {props.license && (
          <div>
            <div style={{display:"flex", justifyContent: "center", alignItems:"center", flexDirection:"column"}}>
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  m: 1,
                  width: 250,
                  height: 250,
                  padding: "20px",

                },
              }}>
                {sortedSubscriptions.map(subscription =>  {
                    return (
                      <Paper key={subscription.subscription} elevation={3} 
                        style={{backgroundColor:  userSelectedLicense?.subscription===subscription.subscription?"#f7b688":""}}
                      >
                        <Stack spacing={2}>
                          <h3>{subscriptionNames.includes(subscription.subscription)?subscription.subscription: "Enterprise"}</h3>
                          <h4>${subscription.price}</h4>
                          <Divider light />
                          <div><CheckCircleIcon />Upload Limit {subscription.limit}</div>
                          <FormControl>
                            <Button variant="contained" color="primary" 
                              onClick={()=>handleLicenseClick(subscription)}
                              // disabled={props.isIaacSubscriptionPosting }
                              disabled={shouldDisableButton(subscription) || props.isIaacSubscriptionPosting }
                            >
                              Select
                            </Button>
                          </FormControl>
                        </Stack>
                      </Paper>
                      )
                  })}
                {/* <Paper elevation={3} >
                  <Stack  spacing={2}>
                    <h3>Enterprise</h3>
                    <Divider light />
                    <div><CheckCircleIcon />Upload Limit 12</div>                    
                    <FormControl>
                      <Button variant="contained" color="primary" 
                        disabled={props.isIaacSubscriptionPosting}
                      >
                        Select
                      </Button>
                    </FormControl>
                  </Stack>
                </Paper> */}
              </Box>

              <FormControl>
                <Button variant="contained" color="primary" 
                  onClick={()=>handleLicense()}
                  disabled={!userSelectedLicense || 
                            props.isIaacSubscriptionPosting ||
                            props.isIaacSubscriptionPutting }
                >
                  Submit
                </Button>
            </FormControl>

            </div>
          </div>
        )}
        {licenseSelectionMode === "create" && (
          <SupportedPlatforms />
        )}
      </Container>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    isIaacLicenseGetting,
    getIaacLicenseMessage,
    getIaacLicenseError,
    license,
  } = state.iaacLicense;

  const {
    isIaacSubscriptionPosting,
    postIaacSubscriptionMessage,
    postIaacSubscriptionError,
    isIaacSubscriptionPutting,
    putIaacSubscriptionMessage,
    putIaacSubscriptionError,    
    isIaacSubscriptionGetting,
    getIaacSubscriptionMessage,
    getIaacSubscriptionError,
    subscription,
  } = state.iaacSubscription;

  return {
    isIaacLicenseGetting,
    getIaacLicenseMessage,
    getIaacLicenseError,
    isIaacSubscriptionPosting,
    postIaacSubscriptionMessage,
    postIaacSubscriptionError,
    isIaacSubscriptionPutting,
    putIaacSubscriptionMessage,
    putIaacSubscriptionError,
    license,
    isIaacSubscriptionGetting,
    getIaacSubscriptionMessage,
    getIaacSubscriptionError,
    subscription,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIaacLicense: (orgcode) => {
      dispatch(getIaacLicenseRequest(orgcode));
    },
    postIaacSubscription: (orgcode, subscriptionRequest) => {
      dispatch(postIaacSubscriptionRequest(orgcode, subscriptionRequest));
    },
    putIaacSubscription: (orgcode, subscriptionRequest) => {
      dispatch(putIaacSubscriptionRequest(orgcode, subscriptionRequest));
    },    
    getIaacSubscription: (orgcode) => {
      dispatch(getIaacSubscriptionRequest(orgcode));
    },
    clearAllIaacSubscriptionMessages: () => {
      dispatch(clearAllIaacSubscriptionMessages());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseSelection);
