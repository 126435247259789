import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { BackgroundPattern } from "../../components/BackgroundPattern";
import logo from "./../../assets/images/Expanded_Colored_PoweredBy.png";
import { Grid, Box } from "@mui/material";

const apiBaseUrl = process.env.REACT_APP_AWS_API_URL;

const UserAuthentication = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const history = useHistory();
  const [authenticationMessage, setAuthenticationMessage] = useState("Wait till we validate your token!");
  const [isAuthSuccess, setIsAuthSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const response = await fetch(`${apiBaseUrl}/userauth/activate?token=${token}`);
          console.log("response:", response);
          if (response.ok) {
            const responseData = await response.text();
            setAuthenticationMessage("User authentication is successful. You will be redirected to login page.");
            setIsAuthSuccess(true);
            setTimeout(() => {
              history.push("/login");
            }, 20000);
          } else {
            const errorMessage = await response.text(); // Read the response body as text

            console.error("Error:", errorMessage);
            if (errorMessage === "User Already Activated") {
              history.push("/login"); // Redirect to login page immediately
              return; // Exit the function early
            }
            setAuthenticationMessage("There is error while authenticating: " + errorMessage);
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setAuthenticationMessage(error.message);
      }
    };

    fetchData();
  }, [history, token]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Box boxShadow={3} p={3} bgcolor="background.paper" borderRadius={4} textAlign="center">
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }} />
            <Box mt={2} mb={2}>
              <Typography variant="h5" component="h2" gutterBottom>
                {(token === null || token === undefined) ? 
                    "Please visit with the link that has appropriate token!"
                  : 
                  authenticationMessage
                }  
              </Typography>
              {isAuthSuccess && (
                <a
                  href={"/login"}
                  className="font-weight-medium text-primary"
                >
                  Click here to login
                </a>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <BackgroundPattern />
    </>
  );
};

export default UserAuthentication;
