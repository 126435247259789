import React, { useState } from 'react';

import {
  Button,
  TextField
} from "@material-ui/core";

const VerificationOtp = ({ onVerify, loading }) => {
  const [otp, setOtp] = useState('');

  const handleChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onVerify(otp); // Call the provided verification function
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <input
        type="text"
        placeholder="Enter verification otp"
        value={otp}
        onChange={handleChange}
      /> */}

      <TextField
        label="Verification Otp"
        variant="outlined"
        name="verificationOtp"
        value={otp}
        type="number"
        id="verificationOtp"
        placeholder="Enter Verification Otp"
        onChange={handleChange}
        required
        // fullWidth
        validate={{
          required: {
            value: true,
            errorMessage: "Please enter verification otp",
          },
        }}
      />
      <br/>
      <br/>
      <br/>
      <Button
        variant="contained"
        color="primary"
        // className={classes.submit}
        type="submit"
        disabled={loading}
      >
        Verify
      </Button>
    </form>
  );
};

export default VerificationOtp;
