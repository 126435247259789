import { takeEvery, fork, put, all, call, delay } from "redux-saga/effects";

import {
  GET_RULE_REMEDIATION,
  CLEAR_ALL_RULE_REMEDIATION_MESSAGES
} from './actionTypes'

import {
  getRuleRemediationSuccessful,
  getRuleRemediationFailed,
  clearAllRuleRemediationMessagesSuccessful as clearAllRuleRemediationMessagesSuccessfulAction,
} from "./actions";

import { getRuleRemediation as getRuleRemediationApi } from "../../helpers/auth_aws_helper";

function* getRuleRemediationSaga({payload: { resourceName }}) {
  try{
    console.log("--== getRuleRemediationSaga ", resourceName);
    const response = yield call(getRuleRemediationApi, resourceName);
    console.log("--==  * getRuleRemediationSaga response = ", response);
    if (response && response.status === 200) {
			yield put(getRuleRemediationSuccessful(({ ruleRemediation: response})));
		} else {
			yield put(getRuleRemediationFailed({ message: response.data}));
		}
  } catch (error){
    console.log("~~~ getRuleRemediationSaga error response : ", error);
		if (error.response) {
      yield put(
        getRuleRemediationFailed({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getRuleRemediationFailed({ message: "No response received" }));
    } else {
      yield put(getRuleRemediationFailed({ message: error?.message }));
    } 
  }
}

export function* clearAllRuleRemediationMessages() {
  console.log("ruleRemediationSaga: clear all messages");
  yield put(clearAllRuleRemediationMessagesSuccessfulAction());

}

export function* watchRuleRemediationSaga(){
  yield takeEvery(GET_RULE_REMEDIATION, getRuleRemediationSaga);
  yield takeEvery(CLEAR_ALL_RULE_REMEDIATION_MESSAGES, clearAllRuleRemediationMessages);
}

function* ruleRemediationSaga() {
  yield all([fork(watchRuleRemediationSaga)]);
}
export default ruleRemediationSaga;