export const ADD_RESOURCE_GROUP = "add_resource_group";
export const ADD_RESOURCE_GROUP_SUCCESSFUL = "add_resource_group_successful";
export const ADD_RESOURCE_GROUP_FAILURE = "add_resource_group_failure";

export const GET_RESOURCE_GROUPS = "get_resource_groups";
export const GET_RESOURCE_GROUPS_FAILURE = "get_resource_groups_failure";
export const GET_RESOURCE_GROUPS_SUCCESSFUL = "get_resource_groups_successful";

export const DELETE_RESOURCE_GROUP = "delete_resource_group";
export const DELETE_RESOURCE_GROUP_SUCCESSFUL = "delete_resource_group_successful";
export const DELETE_RESOURCE_GROUP_FAILURE = "delete_resource_group_failure";

export const EDIT_RESOURCE_GROUP = "edit_resource_group";
export const EDIT_RESOURCE_GROUP_SUCCESSFUL = "edit_resource_group_successful";
export const EDIT_RESOURCE_GROUP_FAILURE = "edit_resource_group_failure";

export const CLEAR_ALL_RESOURCE_GROUP_MESSAGES = "clear_all_resources_group_messages";
export const CLEAR_ALL_RESOURCE_GROUP_MESSAGES_SUCCESSFUL =
  "clear_all_resources_group_messages_successful";
