import { useEffect } from "react";

// Represents user's activity
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

// const ACTIVITY_TIMEOUT = 1 * 60 * 1000; // 1 minute in milliseconds, For quick testing
const ACTIVITY_TIMEOUT = 30 * 60 * 1000; // 15 minutes in milliseconds

const ACTIVITY_STATUS_CHECK_PERIOD = 1 * 30 * 1000; // Check every 30 seconds for activity

const AppLogout = ({ children }) => {
  let activityTimestamp;
  let timer;

  const updateActivityTimestamp = () => {
    activityTimestamp = Date.now();
    localStorage.setItem("activityTimestamp", activityTimestamp);
  };

  const handleLogoutTimer = () => {
    let lastActivity = localStorage.getItem("activityTimestamp");
    const currentTime = Date.now();
    if (!lastActivity) {
      lastActivity = "0";
    }
    if (
      lastActivity &&
      currentTime - parseInt(lastActivity, 10) > ACTIVITY_TIMEOUT
      // && !isTabVisible()
    ) {
      // The user has been inactive for more than 30 minutes, log them out.
      logoutAction();
    }
  };


  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setInterval(handleLogoutTimer, ACTIVITY_STATUS_CHECK_PERIOD); // Check status periodically
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("activeExp");
  };

  useEffect(() => {
    // Initialize the activity timestamp
    updateActivityTimestamp();


    // Add event listeners for user activity
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        updateActivityTimestamp();
        resetTimer();
      });
    });

    // Add an event listener for tab visibility changes
    document.addEventListener("visibilitychange", handleLogoutTimer);


    // Initialize the timer
    resetTimer();

    // Cleanup event listeners when the component unmounts
    return () => {
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, updateActivityTimestamp);
      });

      clearInterval(timer); // Clear the timer


      //  Remove the visibilitychange event listener
      document.removeEventListener("visibilitychange", handleLogoutTimer);
    };
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    // localStorage.clear();
    clearLocalStorage();
    window.location.pathname = "/sessionExpired";
  };

  return children;
};

export default AppLogout;