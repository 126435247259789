import React, { useEffect, useState } from "react";
import { Button, Row, Col, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { countryList } from "../../utilities/countries";
import { putBillingInfo, putMailingInfo, setMailingInfo } from "../../store/actions";

const UpdateOrgAddress = ({ closeModal, addressType }) => {
  const [isAlertOpen, setAlertOpen] = useState({status:  false})
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.orgList);
  const { updateOrgAddress } = useSelector((state) => state.account);
  const org = organizations[0] || {};

  useEffect(() => {
    // console.log('--== Alert Status updateOrgAddress ', updateOrgAddress);
    if (updateOrgAddress && updateOrgAddress.response) {
      const alertMsg = updateOrgAddress.response.data ? updateOrgAddress.response.data : 'Successfully  Updated'
      setAlertOpen({className: 'success', message: alertMsg,  status: true})
      setTimeout(() => {
        dispatch(setMailingInfo({}));
        closeModal();
      }, 500)
    } else if (updateOrgAddress && updateOrgAddress.error) {
      setAlertOpen({className: 'danger', message: "Unable to update",  status: true});
      dispatch(setMailingInfo({}));
    }
  }, [updateOrgAddress])

  const handleUpdateOrgAddress = (values) => {
    // console.log('--== handleUpdateOrgAddress ', org, values);
    if (addressType === 'mailing') {
      dispatch(putMailingInfo({
        id: org.id,
        orgcode: org.orgcode,
        orgname: org.orgname,
        orgmetadata: org.orgmetadata,
        orgdescription: org.orgdescription,
        orgprimaryname: org.orgprimaryname,

        orgprimaryemail: org.orgprimaryemail,
        orgaddress: values.address,
        orgcity: values.city,
        orgstate: values.state,
        orgzip: values.zipCode,
        orgcountry: values.country,
        orgbillingname: "",
        orgbillingemail: "",
        orgbillingaddress: "",
        orgbillingcity: "",
        orgbillingstate: "",
        orgbillingcountry: "",
        orgbillingzip: "",
        selltoken: "",
        resourceGroups: [],
        phoneNumber: "5656545654",
        user: {},
      }))
    } else {
      dispatch(putBillingInfo({
        id: org.id,
        orgcode: org.orgcode,
        orgname: org.orgname,
        orgmetadata: org.orgmetadata,
        orgdescription: org.orgdescription,
        orgprimaryname: org.orgprimaryname,
        orgprimaryemail: org.orgprimaryemail,
        orgaddress: "",
        orgcity: "",
        orgstate: "",
        orgcountry: "",
        orgzip: "",
        orgbillingname: org.orgbillingname,
        orgbillingemail: org.orgbillingemail,
        orgbillingaddress: values.address,
        orgbillingcity: values.city,
        orgbillingstate: values.state,
        orgbillingzip: values.zipCode,
        orgbillingcountry: values.country,
        selltoken: "",
        resourceGroups: [],
        phoneNumber: "5656545654",
        user: {},
      }))
    }
  }

  return (
    <React.Fragment>
      <Formik initialValues={addressType === 'mailing' ? {
        address: org.orgaddress,
        city: org.orgcity,
        state: org.orgstate,
        zipCode: org.orgzip,
        country: org.orgcountry
      } : {
        address: org.orgbillingaddress,
        city: org.orgbillingcity,
        state: org.orgbillingstate,
        zipCode: org.orgbillingzip,
        country: org.orgbillingcountry,
      }}>
        {
          ({ values, errors, isValid, touched, setFieldValue, handleChange, handleBlur }) => {
            return (
              <>
                <Alert
                  color={isAlertOpen.className}
                  isOpen={isAlertOpen.status}
                  toggle={() => setAlertOpen({status:  false})}
                >
                  {isAlertOpen.message}
                </Alert>
                <Row>
                  <Col className="col-md-8 col-sm-12">
                    <div class="form-group">
                      <label for="address">Address</label>
                      <input
                        type="text"
                        class="form-control"
                        id="address"
                        name="address"
                        aria-describedby="Address"
                        placeholder="Address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="col-md-9 col-sm-8">
                      <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            aria-describedby="City"
                            placeholder="City"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div class="form-group">
                      <label for="state">State</label>
                      <input
                        type="text"
                        class="form-control"
                        id="state"
                        name="state"
                        aria-describedby="State"
                        placeholder="State"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div class="form-group">
                      <label for="zipCode">Zip Code</label>
                      <input
                        type="text"
                        class="form-control"
                        id="zipCode"
                        name="zipCode"
                        aria-describedby="Zip Code"
                        placeholder="Zip Code"
                        maxLength={"5"}
                        value={values.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div class="form-group">
                      <label for="countryField">Country</label>
                      <select required class="custom-select" id="countryField" name="country" value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        <option selected>Choose...</option>
                        {
                          countryList.map((item, index) =>
                            <option value={item} key={`country_${item}_${index}`}>
                              {item}
                            </option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-md-8 col-md-4 col-sm-12">
                    <div className="d-flex justify-content-end">
                      <Button
                        type="button"
                        className="m-2"
                        color="light"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        className="m-2"
                        color="primary"
                        onClick={() => handleUpdateOrgAddress(values)}>
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        }
      </Formik>
    </React.Fragment>
  )

}

export default UpdateOrgAddress;
