import {
  GET_IAAC_LICENSE_REQUEST,
  GET_IAAC_LICENSE_SUCCESSFUL,
  GET_IAAC_LICENSE_FAILED,
  CLEAR_ALL_IAAC_LICENSE_MESSAGES,
  CLEAR_ALL_IAAC_LICENSE_MESSAGES_SUCCESSFUL

} from "./actionTypes";

export const getIaacLicenseRequest = (orgcode) => ({
  type: GET_IAAC_LICENSE_REQUEST,
  payload: { orgcode }
});

export const getIaacLicenseSuccessful = (license) => ({
  type: GET_IAAC_LICENSE_SUCCESSFUL,
  payload: { license }
});

export const getIaacLicenseFailed = (getIaacLicenseError) => ({
  type: GET_IAAC_LICENSE_FAILED,
  payload: { getIaacLicenseError }
});

export const clearAllIaacLicenseMessages = () => {
  return {
    type: CLEAR_ALL_IAAC_LICENSE_MESSAGES,
    payload: {},
  };
};

export const clearAllIaacLicenseMessagesSuccessful = () => {
  return {
    type: CLEAR_ALL_IAAC_LICENSE_MESSAGES_SUCCESSFUL,
    payload: {},
  };
};
