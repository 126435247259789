//ID Token
const accessToken = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));

  if (obj && obj.idToken) {
    return obj.idToken;
  } else {
    return "";
  }
};

export default accessToken;

//Access Token
export const fetchAccessToken = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));

  if (obj && obj.accessToken) {
    return obj.accessToken;
  } else {
    return "";
  }
};
