import React from 'react'

const BoxLabel = ({ label, count, color }) => {
  let shortLabel;
  if (label==="critical")
    shortLabel="C";
  else if (label==="high")
    shortLabel="H";
  else if (label==="low")
    shortLabel="L";
  else if (label==="medium")
    shortLabel="M";
  else if (label==="info")
    shortLabel="I";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginRight: 10,
        borderRadius: "2px",
        border: "1px solid black",
      }}
    >
      <div
        style={{
          width: 20,
          height: 20,
          // borderRadius: "2px",
          background: color,
          // border: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 5,
          color: "white"
        }}
      >
        {shortLabel}
      </div>
      <div
        style={{
          width: 20,
          height: 20,
          borderRadius: "2px",
          // background: color,
          // border: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginRight: 5,
        }}
      >
        {count}
      </div>
    </div>
  );
}

export default BoxLabel;