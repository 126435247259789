import React from "react";
import step1Icon from "../../../assets/images/cur_pairing_guide/1.png";
import step2Icon from "../../../assets/images/cur_pairing_guide/2.png";
import step3Icon from "../../../assets/images/cur_pairing_guide/3.png";
import step4Icon from "../../../assets/images/cur_pairing_guide/4.png";
import step5Icon from "../../../assets/images/cur_pairing_guide/5.png";
import step6Icon from "../../../assets/images/cur_pairing_guide/6.png";
import step7Icon from "../../../assets/images/cur_pairing_guide/7.png";
import step8Icon from "../../../assets/images/cur_pairing_guide/8.png";
import step9Icon from "../../../assets/images/cur_pairing_guide/9.png";
import step10Icon from "../../../assets/images/cur_pairing_guide/10.png";
import step11Icon from "../../../assets/images/cur_pairing_guide/11.png";
import step12Icon from "../../../assets/images/cur_pairing_guide/12.png";
import step13Icon from "../../../assets/images/cur_pairing_guide/13.png";

const CostAndUsageReports = () => {
  return (
    <div className="page-content">
      {/* Version 1 */}
      <h3>CONFIGURE COST AND USAGE REPORTS</h3>
      <ol>
        <li>
          Log in to your management account as an IAM user with the required
          permissions, and go to the <strong>Billing</strong> console: <br />
          <img src={step1Icon} alt="step 1" height="250rem" />
        </li>
        <li>
          Select <strong>Cost & Usage Reports</strong> from the left menu:{" "}
          <br />
          <img src={step2Icon} alt="step 2" height="250rem" />
        </li>
        <li>
          Click on Create report: <br />
          <img src={step3Icon} alt="step 2" height="250rem" />
        </li>
        <li>
          Enter a <strong>Report name</strong> (it can be any name, but we
          recommend including the account id in the name), ensure you have
          selected <strong>Include resource IDs</strong> and{" "}
          <strong>Data refresh settings</strong>, then click on{" "}
          <strong>Next</strong>: <br />
          <img src={step4Icon} alt="step 2" height="250rem" />
        </li>
        <li>
          Configure Set delivery options
          <ol type="a">
            <li>
              Click on Configure and enter a unique bucket name, and ensure the
              region is correct. Read and verify the policy, this will allow AWS
              to deliver billing reports to the bucket. Click on{" "}
              <strong>
                The following default policy will be applied to your bucket
              </strong>
              , then click <strong>Save</strong>. <br />
              <img src={step5Icon} alt="step 2" height="250rem" />
            </li>
            <li>
              Verify the settings:
              <ol type="i">
                <li>
                  Ensure your bucket is a <strong>Valid Bucket</strong> (if not,
                  verify the bucket policy)
                </li>
                <li>
                  Enter a <strong>S3 path prefix</strong> (it can be any word,
                  but we recommend “Your Account ID-cur”) without any ‘/’
                  characters. For example: <strong>129877208285-cur</strong>.
                  Make sure to record the S3 bucket “arn” as you will be
                  prompted to provide the S3 bucket’s arn back to us
                </li>
                <li>
                  Ensure the <strong>Time Granularity</strong> is{" "}
                  <strong>Daily</strong>
                </li>
                <li>
                  <strong>Report Versioning</strong> is set to{" "}
                  <strong>Overwrite existing report</strong>
                </li>
                <li>
                  <strong>Ensure Compression type</strong> is{" "}
                  <strong>ZIP</strong>
                </li>
                <li>
                  Click <strong>Next</strong> <br />
                  <img src={step6Icon} alt="step 2" height="250rem" />
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Review the configuration, scroll to the bottom and click on{" "}
          <strong>Create report:</strong>
          <br />
          <p>
            You have successfully configured a Cost and Usage Report to be
            delivered. It may take up to 24hrs for the first report to be
            delivered.
          </p>
          <br />
          <h5>
            <strong>
              Replicate your CUR Bucket to CloudNova Technologies account{" "}
            </strong>
          </h5>
          <ol>
            <li>Navigate to Amazon S3</li>
            <li>
              Select the Bucket you created in <strong>Step 5.a</strong>, then
              select <strong>Properties:</strong>
            </li>
            <li>
              Set Bucket versioning to <strong>Enabled</strong> <br />
              <img src={step7Icon} alt="step 2" height="250rem" />
            </li>
            <li>
              Select the Management tab, then click on{" "}
              <strong>Create replication rule</strong> under{" "}
              <strong>Replication rules</strong>
              <br />
              <img src={step8Icon} alt="step 2" height="250rem" />
            </li>
            <li>
              Create your replication rule by updating the{" "}
              <strong>following fields</strong> then click <strong>Save</strong>
              <ol type="a">
                <li>
                  Add a <strong>Replication rule name</strong> of
                  CUR-Bucket-Replication and select Enabled for Status <br />
                  <img src={step9Icon} alt="step 2" height="250rem" />
                </li>
                <li>
                  Select <strong>Apply to all objects in the bucket</strong> for{" "}
                  <strong>Choose a rule scope</strong> under Source Bucket
                  <br />
                  <img src={step10Icon} alt="step 2" height="250rem" />
                </li>
                <li>
                  Select <strong>Specify a bucket in another account</strong>{" "}
                  under Destination
                  <ol type="i">
                    <li>
                      Add <strong>CloudNova Technologies Account ID</strong> -
                      “829807204216”
                    </li>
                    <li>
                      Add <strong>CloudNova Technologies</strong> S3 Bucket name
                      - “devops01-cost-usage-bucket”
                    </li>
                    <li>
                      Select{" "}
                      <strong>
                        Change object ownership to destination bucket owner
                      </strong>
                      <br />
                      <img src={step11Icon} alt="step 2" height="250rem" />
                    </li>
                  </ol>
                  <li>
                    Select <strong>Create new role</strong> under the IAM role
                    section. Make sure to record the IAM role “arn” as you will
                    be prompted to provide the IAM role’s arn back to us.
                    <br />
                    <img src={step12Icon} alt="step 2" height="250rem" />
                  </li>
                  <li>Leave rest of the settings as default</li>
                  <li>
                    Scroll down and select <strong>Save</strong>
                  </li>
                </li>
              </ol>
            </li>
            <li>
              Replicate existing objects?
              <ol type="a">
                <li>
                  Select <strong>No, do not replicate existing objects</strong>{" "}
                  for <strong>Existing objects</strong>
                </li>
                <li>
                  Click on <strong>Submit</strong>
                  <br />
                  <img src={step13Icon} alt="step 2" height="250rem" />
                </li>
              </ol>
            </li>
            <li>
              Click on Replication IAM Role Name, record the IAM role “arn” as
              you will be prompted to provide the IAM role’s arn back to us.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default CostAndUsageReports;
