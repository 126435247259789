import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCostInsightMetric } from "../../../store/actions";

export const useCostInsightData = (accCodes, orgCode) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const costInsightMetric = useSelector(
    (state) => state.metricInfo.costInsightMetric
  );
  const costInsightMetricLoading = useSelector(
    (state) => state.metricInfo.costInsightMetricLoading
  );
  const costInsightMetricError = useSelector(
    (state) => state.metricInfo.costInsightMetricError
  );

  // To track if the API call has already been made
  const hasFetchedData = useRef(false);

  useEffect(() => {
    console.log("accCodes:", accCodes);
    console.log("orgCode:", orgCode);

    if (accCodes.length > 0 && orgCode && !hasFetchedData.current) {
      console.log("Making API call");
      dispatch(getCostInsightMetric(accCodes, orgCode));
      hasFetchedData.current = true; // Mark the call as done
    } else {
      console.log("Skipping API call");
    }
  }, [dispatch, accCodes, orgCode]);

  useEffect(() => {
    if (costInsightMetric) {
      const metricObject = apiDataToCostInsightMetricObject(costInsightMetric);
      setData(metricObject);
    }
    setLoading(costInsightMetricLoading);
    setError(costInsightMetricError ? "Error in Cost Insight API" : null);
  }, [costInsightMetric, costInsightMetricLoading, costInsightMetricError]);

  return { data, loading, error };
};

const apiDataToCostInsightMetricObject = (apiData) => {
  let metricObject = {};

  metricObject.costInsightYesterdayCost = apiData["Yesterday Cost"];
  metricObject.costInsightMonthToDateCost = apiData["Month to Date Cost"];
  metricObject.costInsightYearToDateCost = apiData["Year to Date Cost"];
  metricObject.costInsightMonthlySavingsCost = apiData["Monthly Savings"];

  return metricObject;
};
