import { all } from "redux-saga/effects";
import LayoutSaga from "./layout/saga";
import OrgList from "./orgList/saga";
import acctList from "./acctList/saga";
import userList from "./userList/saga";
import LoginSaga from "./auth/login/saga";
import RegisterSaga from "./auth/register/saga";
import ForgetPasswordSaga from "./auth/forgetPwd/saga";
import OrgAccountSaga from "./auth/orgAcct/saga";
import ProfileSaga from "./auth/profile/saga";
import licensing from "./licensing/saga";
import getRuleSaga from "./rulesList/saga";
import getAwsAccSaga from "./awsAcc/saga";
import getTableauSaga from "./tableau/saga";
import Header from "./header/saga";
//Added for AWS API
import getPricingInformationSaga from "./pricingInfo/saga";

//Added for AWS Metric API
import getMetricInformationSaga from "./metric/saga";
import resourceGroupSaga from "./resourceGroup/saga";
import ruleRemediationSaga from "./ruleRemediation/saga";

// IaaC
import iaacSaga from "./iaac/saga";
import iaacSubscriptionSaga from "./iaacSubscription/saga";
import iaacLicenseSaga from "./iaacLicense/saga";
import releaseNotesSaga from "./releaseNotes/saga";
// Support
import supportSaga from "./support/saga";

// AWS Marketplace
import awsMarketplaceSaga from "./awsMarketplace/saga";

// Feature Configuration
import featureConfigurationSaga from "./featureConfiguration/saga";

// Account Configuration
import accountConfigurationSaga from "./accountConfiguration/saga";

// Scan Service
import scanServiceSaga from "./scanService/saga";

// Kibana Dashboards
import kibanaDashboardsSaga from "./kibanaDashboards/saga";

// Billing Saga
import billingSaga from "./billing/saga.js";

// Tag Allocation
import tagsSaga from "./tagAllocation/saga.js";

export default function* rootSaga() {
  yield all([
    LayoutSaga(),
    OrgList(),
    acctList(),
    userList(),
    LoginSaga(),
    ForgetPasswordSaga(),
    RegisterSaga(),
    OrgAccountSaga(),
    ProfileSaga(),
    licensing(),
    getRuleSaga(),
    getAwsAccSaga(),
    getTableauSaga(),
    Header(),
    //Added for AWS API
    getPricingInformationSaga(),
    //Added for AWS Metric API
    getMetricInformationSaga(),
    resourceGroupSaga(),
    ruleRemediationSaga(), //Rule remediation saga
    // IaaC
    iaacSaga(),
    iaacSubscriptionSaga(),
    iaacLicenseSaga(),
    // Release Notes
    releaseNotesSaga(),
    // Support
    supportSaga(),
    // AWS Marketplace
    awsMarketplaceSaga(),
    // Feature Configuration
    featureConfigurationSaga(),
    // Account Configuration
    accountConfigurationSaga(),
    // Scan Service
    scanServiceSaga(),
    // Kibana Dashboards
    kibanaDashboardsSaga(),
    // Billing Saga
    billingSaga(),
    // Tag Allocation
    tagsSaga(),
  ]);
}
