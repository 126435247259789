import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESSFUL,
  UPLOAD_FILE_FAILED,

  SEND_FILE_COMMIT_REQUEST,
  SEND_FILE_COMMIT_SUCCESSFUL,
  SEND_FILE_COMMIT_FAILED,
  
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESSFUL,
  DOWNLOAD_FILE_FAILED,
  
  GET_UPLOAD_FILE_URL_REQUEST,
  GET_UPLOAD_FILE_URL_SUCCESSFUL,
  GET_UPLOAD_FILE_URL_FAILED,
  
  GET_IAAC_METRICS_REQUEST,
  GET_IAAC_METRICS_SUCCESSFUL,
  GET_IAAC_METRICS_FAILED,

  GET_IAAC_FILE_HISTORY_REQUEST,
  GET_IAAC_FILE_HISTORY_SUCCESSFUL,
  GET_IAAC_FILE_HISTORY_FAILED,

    CLEAR_ALL_IAAC_MESSAGES,
  CLEAR_ALL_IAAC_MESSAGES_SUCCESSFUL,  
} from "./actionsTypes";

export const uploadFileRequest = (fileData, uploadUrl) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: { fileData, uploadUrl },
});

export const uploadFileSuccessful = (fileUploadResponse) => ({
  type: UPLOAD_FILE_SUCCESSFUL,
  payload: { fileUploadResponse },
});

export const uploadFileFailed = (fileUploadError) => ({
  type: UPLOAD_FILE_FAILED,
  payload: { fileUploadError },
});

export const sendFileCommitRequest = (orgcode, filename, username, author, projectName, branch, version, comment, uuid) => ({
  type: SEND_FILE_COMMIT_REQUEST,
  payload: {
    orgcode, 
    filename, 
    username, 
    author, 
    projectName, 
    branch, 
    version, 
    comment,
    uuid
  }
});

export const sendFileCommitSuccessful = (fileCommitResponse) => ({
  type: SEND_FILE_COMMIT_SUCCESSFUL,
  payload: { fileCommitResponse },
});

export const sendFileCommitFailed = (fileCommitError) => ({
  type: SEND_FILE_COMMIT_FAILED,
  payload: { fileCommitError }
});

export const downloadFileRequest = (fileId, fileType) => ({
  type: DOWNLOAD_FILE_REQUEST,
  payload: { fileId, fileType },
});

export const downloadFileSuccessful = (fileDownloadUrl) => ({
  type: DOWNLOAD_FILE_SUCCESSFUL,
  payload: { fileDownloadUrl },
});

export const downloadFileFailed = (downloadFileError) => ({
  type: DOWNLOAD_FILE_FAILED,
  payload: { downloadFileError },
});

export const getUploadFileUrlRequest = (fileName, orgcode, username, uuid) => ({
  type: GET_UPLOAD_FILE_URL_REQUEST,
  payload: { fileName, orgcode, username, uuid },
});

export const getUploadFileUrlSuccessful = (uploadUrl) => ({
  type: GET_UPLOAD_FILE_URL_SUCCESSFUL,
  payload: { uploadUrl },
});

export const getUploadFileUrlFailed = (getUploadFileUrlError) => ({
  type: GET_UPLOAD_FILE_URL_FAILED,
  payload: { getUploadFileUrlError },
});

export const getIaacMetricsRequest = (orgcode) => ({
  type: GET_IAAC_METRICS_REQUEST,
  payload: { orgcode },
});

export const getIaacMetricsSuccessful = (metrics) => ({
  type: GET_IAAC_METRICS_SUCCESSFUL,
  payload: { metrics },
});

export const getIaacMetricsFailed = (getIaacMetricsError) => ({
  type: GET_IAAC_METRICS_FAILED,
  payload: { getIaacMetricsError },
});

export const getIaacFileHistoryRequest = (orgcode) => ({
  type: GET_IAAC_FILE_HISTORY_REQUEST,
  payload: { orgcode },
});

export const getIaacFileHistorySuccessful = (fileHistory) => ({
  type: GET_IAAC_FILE_HISTORY_SUCCESSFUL,
  payload: { fileHistory },
});

export const getIaacFileHistoryFailed = (getIaacFileHistoryError) => ({
  type: GET_IAAC_FILE_HISTORY_FAILED,
  payload: { getIaacFileHistoryError },
});

export const clearAllIaacMessages = () => {
  return {
    type: CLEAR_ALL_IAAC_MESSAGES,
    payload: {},
  };
}

export const clearAllIaacMessagesSuccessful = () => {
  return {
    type: CLEAR_ALL_IAAC_MESSAGES_SUCCESSFUL,
    payload: {},
  };
};

