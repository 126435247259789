import {
    GET_RULE_REMEDIATION,
    GET_RULE_REMEDIATION_SUCCESSFUL,
    GET_RULE_REMEDIATION_FAILED,
    CLEAR_ALL_RULE_REMEDIATION_MESSAGES_SUCCESSFUL,
  } from "./actionTypes";
  
  const initialState = {
    getRuleRemediationMessage: null,
    getRuleRemediationError: null,
    getRuleRemediationLoading: false,
  };
  
  const ruleRemediation = (state = initialState, action) => {
    switch (action.type) {
      case GET_RULE_REMEDIATION:
        state = {
          ...state,
          getRuleRemediationLoading: true,
          getRuleRemediationError: null,
        };
        break;
      case GET_RULE_REMEDIATION_SUCCESSFUL:
        state = {
          ...state,
          ruleRemediation: action.payload.ruleRemediation,
          getRuleRemediationLoading: false,
          getRuleRemediationError: null,
        };
        break;
      case GET_RULE_REMEDIATION_FAILED:
        state = {
          ...state,
          getRuleRemediationLoading: false,
          getRuleRemediationError: action.payload.error.message,
        };
        break;
      case CLEAR_ALL_RULE_REMEDIATION_MESSAGES_SUCCESSFUL:
        state = {
          ...state,
          getRuleRemediationLoading: false,
          getRuleRemediationError: null,
          getRuleRemediationMessage: null,
          ruleRemediation: null,
        };
        break;
      default:
        return state;
    }
    return state;
  
  }
  
  export default ruleRemediation;