import { FORGET_USER, FORGET_USER_SUCCESSFUL, FORGET_PASSWORD_API_FAILED, RESET_PASSWORD_STORE, VALIDATE_RESET_TOKEN, VALIDATE_RESET_TOKEN_FAILED, VALIDATE_RESET_TOKEN_SUCCESSFUL, RESET_PASSWORD_FORGOT, RESET_PASSWORD_FORGOT_FAILED, RESET_PASSWORD_FORGOT_SUCCESSFUL } from './actionTypes';

const initialState = {
    forgetError: null, message: null, loading: false, success: null, validateError: null,resetError: null, successMessage: null, resetSuccess: null,
}

const forgetpwd = (state = initialState, action) => {
    console.log("action", action)
    switch (action.type) {
        case FORGET_USER:
            state = {
                ...state,
                user: null,
                loading: true,
                forgetError: null
            }
            break;
        case FORGET_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
        case FORGET_PASSWORD_API_FAILED:
            state = {
                ...state,
                loading: false,
                forgetError: action.payload
            }
            break;
        case RESET_PASSWORD_STORE:
            state = initialState
            break;
        case VALIDATE_RESET_TOKEN:
            state = {
                ...state,
                user: null,
                loading: true,
                success: null
            }
            break;
        case VALIDATE_RESET_TOKEN_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                message: action.payload,
                success: true
            }
            break;
        case VALIDATE_RESET_TOKEN_FAILED:
            state = {
                ...state,
                loading: false,
                validateError: action.payload,
                success: false
            }
            break;
        case RESET_PASSWORD_FORGOT:
            state = {
                ...state,
                loading:true,
                resetSuccess: null,
                successMessage:null
            }
            break;
        case RESET_PASSWORD_FORGOT_SUCCESSFUL:
            state = {
                ...state,
                loading:false,
                successMessage: null,
                resetSuccess: true
            }
            break;
        case RESET_PASSWORD_FORGOT_FAILED:
            state = {
                ...state,
                loading:false,
                resetError: action.payload.response.data,
                // resetSuccess: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default forgetpwd;