import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import CloudWatch from "./CloudWatch";
import CloudWatchLogs from "./CloudWatchLogs";

const CloudWatchDashboard = () => {
    const [activeTab, setActiveTab] = useState("CloudWatch");
  
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };
  
    const renderTabContent = () => {
      switch (activeTab) {
        case "CloudWatch":
          return <CloudWatch />;
        case "CloudWatchLogs":
          return <CloudWatchLogs />;
        default:
          return null;
      }
    };
  
    return (
      <div style={{"marginTop": "5rem"}}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="CloudWatch" value="CloudWatch" />
          <Tab label="CloudWatch Logs" value="CloudWatchLogs" />
        </Tabs>
        {renderTabContent()}
      </div>
    );
  };
  
  export default CloudWatchDashboard;