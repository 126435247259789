import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import jwt_decode from "jwt-decode";
import useActivationNotification from "./AccountNotificationHook";
import { withNamespaces } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./CustomToast.module.css"; // Import the CSS module
import { Typography } from "@mui/material"; // MUI Typography

const ProfileMenu = ({ t }) => {
  const [menu, setMenu] = React.useState(false);

  useActivationNotification(2); // Use the custom hook

  const toggle = () => setMenu(!menu);

  let username;
  let isAdmin = false; // Default to false for role checking

  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const authUser = jwt_decode(obj.idToken);
    console.log("🚀 ~ ProfileMenu ~ authUser:", authUser);

    username = authUser.email;
    const [usernamePart, domain] = username.split("@");
    const [firstName, lastName] = usernamePart.split(/\.|\+/);
    username = firstName.charAt(0).toUpperCase() + firstName.slice(1);

    // Check if the user is part of the Administrator group
    if (
      authUser["cognito:groups"] &&
      authUser["cognito:groups"].includes("Administrator")
    ) {
      isAdmin = true;
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          {/* Use Typography for the username */}
          <Typography
            variant="body2"
            component="span"
            className="d-none d-xl-inline-block ml-1 text-transform"
          >
            {username}
          </Typography>
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <Link className="d-block dropdown-item d-block" to="/settings">
            <i className="ri-settings-2-line align-middle mr-1"></i>
            <Typography variant="body2" component="span">
              {t("Settings")}
            </Typography>
          </Link>

          {/* Conditionally render Billing if the user is an Administrator */}
          {isAdmin && (
            <Link className="d-block dropdown-item d-block" to="/billing">
              <i className="ri-money-dollar-circle-line align-middle mr-1"></i>
              <Typography variant="body2" component="span">
                {t("Billing")}
              </Typography>
            </Link>
          )}

          <Link
            className="d-block dropdown-item d-block text-danger"
            to="/logout"
          >
            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>
            <Typography
              variant="body2"
              component="span"
              className="text-danger"
            >
              {t("Logout")}
            </Typography>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {/* Use the style from the CSS module */}
      <ToastContainer
        className={styles.appBarToastContainer}
        containerId="appBarToastContainer"
        closeButton={false} // Optional: Remove close button to save space
      />
    </React.Fragment>
  );
};

export default withNamespaces()(ProfileMenu);
