import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { Button } from "reactstrap";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";

import styles from "./../style.module.css"; // Import your CSS Module

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getListOfAccts,
  sendSupportEmailRequest,
  clearAllSupportMessages,
} from "../../../store/actions";

import jwt_decode from "jwt-decode";

import { SnackbarProvider, enqueueSnackbar } from "notistack";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "align",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const EmailModal = (props) => {
  console.log("~~~~~~~~~~~~~EmailModal props:", props);

  const { open, onClose } = props;
  const [fullscreen, setFullscreen] = useState(false);
  const [ticketType, setTicketType] = useState("Feature Request");
  const [emailFrom, setEmailFrom] = useState("");
  // const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [orgcode, setOrgcode] = useState(null);
  const [orgname, setOrgname] = useState(null);
  // const [isEmailSending, setIsEmailSending] = useState(null);
  console.log(
    "~~~~~~~~~~~~~EmailModal emailFrom:",
    emailFrom,
    " emailBody:",
    emailBody,
    " ticketType:",
    ticketType
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const clearState = useCallback(() => {
    setFullscreen(false);
    setTicketType("Feature Request");
    // setEmailFrom("");
    // setEmailSubject("");
    setEmailBody("");
    setSelectedFile(null);
  });

  const handleSendClick = () => {
    console.log(
      "emailFrom: " +
        emailFrom +
        " emailBody: " +
        emailBody +
        " ticket: " +
        ticketType
    );
    // if (!emailFrom || !emailBody || !ticketType) {
    //   alert("Please fill in all fields.");
    //   return;
    // }

    const payload = {
      body: {
        email: emailFrom,
        message: emailBody,
        ticket_type: ticketType,
        org_code: orgcode,
        org_name: orgname,
      },
    };

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const base64EncodedFile = event.target.result.split(",")[1]; // Extracting base64 content

        payload.body.files = [
          {
            name: selectedFile.name,
            content: base64EncodedFile,
          },
        ];

        sendEmail(payload);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      sendEmail(payload);
    }
  };

  const sendEmail = async (payload) => {
    props.dispatchSendSupportEmail(payload);
    // try {
    //   setIsEmailSending(true);
    //   const response = await fetch("https://license.manager.api.dev.cntdev.link/cloudnova/support", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   });

    //   if (response.ok) {
    //     enqueueSnackbar("Email sent successfully!", { variant: "success" });
    //     clearState();
    //     onClose();
    //   } else {
    //     enqueueSnackbar("Email sending failed!", { variant: "error" });
    //   }
    // } catch (error) {
    //   console.error("Error sending email:", error);
    //   enqueueSnackbar("Error sending email.", { variant: "error" });
    // } finally {
    //   setIsEmailSending(false);
    // }
  };

  //For displaying send email success/error messages for short duration
  const sendSupportEmailMessage = props.sendSupportEmailMessage;
  const sendSupportEmailError = props.getSendSupportEmailError;
  const clearAllSupportMessages = props.dispatchClearAllSupportMessages;
  useEffect(() => {
    if (sendSupportEmailMessage) {
      enqueueSnackbar("Email sent successfully!", { variant: "success" });
      clearState();
      onClose();
    }
    if (sendSupportEmailError) {
      enqueueSnackbar("Email sending failed!", { variant: "error" });
    }
    if (sendSupportEmailMessage || sendSupportEmailError) {
      // setTimeout(() => {
      console.log("About to call clearAllSupportMessages");
      clearAllSupportMessages();
      // }, 10000); // Hide all messages after 10 seconds
    }
  }, [
    sendSupportEmailMessage,
    sendSupportEmailError,
    clearAllSupportMessages,
    clearState,
    onClose,
  ]);

  const handleExpandClick = () => {
    setFullscreen(!fullscreen);
  };

  useEffect(() => {
    props.dispatchGetListOfAccts();
  }, []);

  const organizations = props.organizations;
  useEffect(() => {
    if (organizations) {
      if (!orgcode) {
        setOrgcode(organizations[0]?.orgcode);
        // console.log("EmailModal, orgcode: ", organizations[0]?.orgcode);
      }
      if (!orgname) {
        setOrgname(organizations[0]?.orgname);
        // console.log("EmailModal, organizations: ", organizations[0]?.orgname);
      }
    }
  }, [organizations]);

  // get username from local storage
  useEffect(() => {
    let usernameFromLocalStorage;
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const authUser = jwt_decode(obj.idToken);
      usernameFromLocalStorage = authUser.email;
      console.log("usernameFromLocalStorage=", usernameFromLocalStorage);
    }
    if (!emailFrom) {
      if (usernameFromLocalStorage) {
        setEmailFrom(usernameFromLocalStorage);
      }
    }
  }, []);

  let height = fullscreen ? "100%" : "500px";

  return (
    <React.Fragment>
      <SnackbarProvider />
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={() => {
          clearState();
          onClose();
        }}
        TransitionComponent={Transition}
        // style={{width: "50%", position: "absolute", left: "50%", height: "100vh"}}
        style={{ height: height }}
      >
        <AppBar sx={{ position: "relative", background: "#f98125" }}>
          <Toolbar>
            Send request to support team
            <div className={styles.right}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleExpandClick}
              >
                {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  clearState();
                  onClose();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <div
          // style={{height: height}}
          className={styles.emailContainer}
        >
          Ticket Type:
          <RadioGroup
            row
            aria-label="ticket-type"
            name="ticket-type"
            value={ticketType}
            onChange={(e) => setTicketType(e.target.value)}
          >
            <FormControlLabel
              value="Feature Request"
              control={<Radio />}
              label="Feature Request"
            />
            <FormControlLabel
              value="Comment"
              control={<Radio />}
              label="Comment"
            />
            <FormControlLabel value="Bug" control={<Radio />} label="Bug" />
          </RadioGroup>
          <div className={styles.flexGrow}>
            {/* <div className={styles.fromField}>
              <TextField
                placeholder="My Email Address"
                value={emailFrom}
                disabled
                onChange={(e) => setEmailFrom(e.target.value)}
                fullWidth
                variant="outlined"
                size="small"
              />
            </div> */}
            {/* <TextField
              placeholder="Subject"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
            /> */}

            {/* <div className={styles.flexGrow} >
            </div> */}
            <ReactQuill
              theme="snow"
              style={{ display: "flex", flexDirection: "column" }}
              value={emailBody}
              onChange={setEmailBody}
              modules={modules}
              formats={formats}
            />
          </div>
          <div className={styles.bottomBar}>
            <div className={styles.fileUpload}>
              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <IconButton color="inherit" onClick={handleFileInputClick}>
                <AttachFileIcon />
              </IconButton>
              {selectedFile ? (
                <label htmlFor="fileInput" style={{ marginBottom: "0px" }}>
                  {selectedFile.name}
                </label>
              ) : (
                ""
              )}
            </div>

            <div className={styles.right}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSendClick}
                disabled={
                  props.isSupportEmailSending ||
                  !emailFrom ||
                  !emailBody ||
                  !ticketType
                }
              >
                Send <SendIcon />
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { organizations } = state.acctList;
  const {
    isSupportEmailSending,
    sendSupportEmailMessage,
    sendSupportEmailError,
  } = state.support;

  return {
    organizations,
    isSupportEmailSending,
    sendSupportEmailMessage,
    sendSupportEmailError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetListOfAccts: () => {
      dispatch(getListOfAccts());
    },
    dispatchSendSupportEmail: (emailBody) => {
      dispatch(sendSupportEmailRequest(emailBody));
    },
    dispatchClearAllSupportMessages: () => {
      dispatch(clearAllSupportMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailModal);
