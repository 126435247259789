import {
  ADD_RESOURCE_GROUP,
  ADD_RESOURCE_GROUP_SUCCESSFUL,
  ADD_RESOURCE_GROUP_FAILURE,
  GET_RESOURCE_GROUPS,
  GET_RESOURCE_GROUPS_SUCCESSFUL,
  GET_RESOURCE_GROUPS_FAILURE,
  DELETE_RESOURCE_GROUP,
  DELETE_RESOURCE_GROUP_SUCCESSFUL,
  DELETE_RESOURCE_GROUP_FAILURE,
  EDIT_RESOURCE_GROUP,
  EDIT_RESOURCE_GROUP_SUCCESSFUL,
  EDIT_RESOURCE_GROUP_FAILURE,
  CLEAR_ALL_RESOURCE_GROUP_MESSAGES,
  CLEAR_ALL_RESOURCE_GROUP_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

export const addResourceGroup = (resourceGroup) => {
  console.log("Actions, addResourceGroup :: ", resourceGroup);
  return {
    type: ADD_RESOURCE_GROUP,
    payload: { resourceGroup },
  };
};

export const addResourceGroupSuccessful = (resourceGroup) => {
  console.log("Actions, addResourceGroup Successful :: ", resourceGroup);
  return {
    type: ADD_RESOURCE_GROUP_SUCCESSFUL,
    payload: { resourceGroup },
  };
};

export const addResourceGroupFailure = (error) => {
  console.log("Actions, addResourceGroup Failure with error :: ", error);
  return {
    type: ADD_RESOURCE_GROUP_FAILURE,
    payload: { error },
  };
};

export const getResourceGroups = () => {
  console.log("Actions, getResourceGroups :: ");
  return {
    type: GET_RESOURCE_GROUPS,
  };
};

export const getResourceGroupsSuccessful = (resourceGroups) => {
  console.log("Actions, getResourceGroupsSuccessful :: ", resourceGroups);
  return {
    type: GET_RESOURCE_GROUPS_SUCCESSFUL,
    payload: { resourceGroups },
  };
};

export const getResourceGroupsFailure = (error) => {
  console.log("Actions, getResourceGroups Failure with error :: ", error);
  return {
    type: GET_RESOURCE_GROUPS_FAILURE,
    payload: { error },
  };
};

export const deleteResourceGroup = (resourceGroupName, orgCode) => {
  console.log(
    "deleteResourceGroup action, Resource Group Name:: ",
    resourceGroupName,
    "Organization Code:: ",
    orgCode
  );
  return {
    type: DELETE_RESOURCE_GROUP,
    payload: { resourceGroupName, orgCode },
  };
};

export const deleteResourceGroupSuccessful = (resourceGroup) => {
  console.log("deleteResourceGroupSuccess action");
  return {
    type: DELETE_RESOURCE_GROUP_SUCCESSFUL,
    payload: { resourceGroup },
  };
};

export const deleteResourceGroupFailure = (error) => {
  console.log("deleteResourceGroupFailed action, error:: ", error);
  return {
    type: DELETE_RESOURCE_GROUP_FAILURE,
    payload: { error },
  };
};

export const editResourceGroup = (resourceGroup) => {
  console.log("Actions, editResourceGroup :: ", resourceGroup);
  return {
    type: EDIT_RESOURCE_GROUP,
    payload: { resourceGroup },
  };
};

export const editResourceGroupSuccessful = (resourceGroup) => {
  console.log("Actions, editResourceGroup Successful :: ", resourceGroup);
  return {
    type: EDIT_RESOURCE_GROUP_SUCCESSFUL,
    payload: { resourceGroup },
  };
};

export const editResourceGroupFailure = (error) => {
  console.log("Actions, editResourceGroup Failure with error :: ", error);
  return {
    type: EDIT_RESOURCE_GROUP_FAILURE,
    payload: { error },
  };
};

export const clearAllResourceGroupMessages = () => {
  return {
    type: CLEAR_ALL_RESOURCE_GROUP_MESSAGES,
    payload: {},
  };
};

export const clearAllResourceGroupMessagesSuccessful = () => {
  return {
    type: CLEAR_ALL_RESOURCE_GROUP_MESSAGES_SUCCESSFUL,
    payload: {},
  };
};

