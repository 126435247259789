import React, { useState } from 'react'
import styles from "./style.module.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const FileIssueResults = ({files}) => {
  const [ resultOpen, setResultOpen ] =  useState(false);

  const toggleResults = () => {
    setResultOpen(!resultOpen);
  };

  return (
    <div>
      <div className={styles.result}>
        <button onClick={toggleResults} className={styles.iconButton}>
          {resultOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </button>
        Results ({files?.length})
      </div>
      {resultOpen && files?.map(
        (fileIssue, index) => (<FileIssue fileIssue={fileIssue} key={index} />)
      )}
    </div>
  );
}

export default FileIssueResults;

const FileIssue = ({fileIssue}) => {
  return (
    <div className={styles.fileIssueContainer}>
      <div className={styles.fileHeader}>
        <div className={styles.fileName}>
          File: {fileIssue?.file_name}
        </div>
        <div className={styles.lineNumber}>
          Line {fileIssue?.line} 
        </div>
      </div>
      <table className={styles.tableStyle}>
          <tbody>
            <tr>
              <td className={styles.cellHeaderStyle}>Expected:</td>
              <td className={styles.cellStyle}>{fileIssue?.expected_value}</td>
            </tr>
            <tr>
              <td className={styles.cellHeaderStyle}>Found:</td>
              <td className={styles.cellStyle}>{fileIssue?.actual_value}</td>
            </tr>
          </tbody>
      </table>
      {
        (fileIssue?.search_key && fileIssue?.search_key !=="" ) && (
          <div className={styles.codeContainer}>
            <SyntaxHighlighter
              // style={docco}
              className={styles.syntaxHighlighterContainer}
              language="yaml"
              wrapLines={true}
              showLineNumbers={true}
              startingLineNumber={fileIssue?.line}
              lineProps={(lineNumber) => {
                let style = { display: "block", backgroundColor : "#fc6e3a50", color: "black", width: "100%" };
                return { style };
              }}
            >
              {fileIssue?.search_key}
            </SyntaxHighlighter>
          </div>
        )
      }
    </div>
  )
}