import { 
	GET_LIST_OF_ACCTS,
  GET_LIST_OF_ACCTS_SUCCESSFUL,
  GET_LIST_OF_ACCTS_FAILED
} from './actionTypes';

const initialState = {
  error: null, message: null, loading: null, tabToken: null
}

const acctList = (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_OF_ACCTS:
			state = {
				...state,
				loading: true,
				error: null,	
			}
			break;
		case GET_LIST_OF_ACCTS_SUCCESSFUL:
			state = {
				...state,
				accounts: action.payload.acct.accounts,
				organizations: action.payload.acct.organizations,
				usersConfig: action.payload.acct.usersConfig,
				tabToken: action.payload.acct.tabToken,
				loading: false,
				error: null
			}
			break;
		case GET_LIST_OF_ACCTS_FAILED:
			state = {
				...state,
				loading: false,
				error: action.payload.acct
			}
			break;
		default:
			return state;
	}
	return {...state};
}

export default acctList;