import {
    PUSH_NOTIFICATION_DATA,
    POP_NOTIFICATION_DATA,
} from './actionTypes';

export const pushNotification = (options) => {
    return { type: PUSH_NOTIFICATION_DATA,
        payload: options }
}

export const popNotification = (id) => {
    return {
        type: POP_NOTIFICATION_DATA,
        payload: id
    }
}