export const CHECK_LOGIN = 'check_login';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';
export const LOGIN_USER_SUCCESSFUL_WITH_QR_CODE = "login_user_successfull_with_qr_code";
export const LOGIN_USER_SUCCESSFUL_WITH_VERIFICATION = "login_user_successfull_with_verification";

export const LOGOUT_USER = 'logout_user';
export const FORCE_LOGOUT = 'force_logout';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';

export const API_ERROR = 'api_failed';

export const VERIFY_TOTP_REQUEST = 'verify_totp_request';
export const VERIFY_TOTP_SUCCESS = 'verify_totp_success';
export const VERIFY_TOTP_FAILURE = 'verify_totp_failure';

export const CLEAR_ALL_LOGIN_MESSAGES = "clear_all_login_messages";
export const CLEAR_ALL_LOGIN_MESSAGES_SUCCESS = "clear_all_login_messages_success";
