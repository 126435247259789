import React from "react";
import { useOrganization } from "../../components/Common/HelperComponents/GetDataFromStore";
import accessToken from "../../helpers/jwt-token-access/accessToken";

export const Rules = () => {
  const organization = useOrganization();
  const token = accessToken();
  const VIEW_URL = process.env.REACT_APP_ELASTICSERACH;

  if (!organization || !organization.orgcode) {
    // Render a loading state while organization data is being fetched
    return <div>Loading...</div>;
  }

  const SPACE_URL = organization.orgcode.toLowerCase();
  const QUERY_PARAMS = `&authorization=${token}`;
  const dashboardURL = `${VIEW_URL}/s/${SPACE_URL}/app/management/insightsAndAlerting/triggersActions/rules?_a=(actionTypes:!(),lastResponse:!(),params:(),search:%27%27,status:!(),tags:!(),type:!())${QUERY_PARAMS}`;

  return (
    <div className="box" id="tableauDiv" style={{ marginTop: "-2rem" }}>
      <div className="iframe-container">
        <iframe
          src={dashboardURL}
          className="iframe-second-row"
          id="elasticsearchid"
          style={{ display: "block" }}
        ></iframe>
      </div>
    </div>
  );
};
