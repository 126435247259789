import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { validateResetToken} from "../../store/actions";
import PasswordResetPage from "./PasswordResetPage";
import { Row, Col, Alert, Container } from "reactstrap";
import { Link } from "react-router-dom";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";

const ResetPassWord = () => {

    const dispatch = useDispatch()
    const query = new URLSearchParams(useLocation().search);
    const token = query.get("token")
    // console.log("token is", token)
    const reset = useSelector((state) => {
    return state.forgetPassword});
    const {loading, success, validateError, resetSuccess} = reset;
    var showable = false;
    var successPage = false;
    useEffect(() => {
        // console.log("dispatch", token)
        dispatch(validateResetToken(token))
    }, [])
    useEffect(() => {
        showable = true;
        // console.log("token on page", token)
    }, [success])

    if (success) {
        return (
            <PasswordResetPage token={token} />
        )
    }
    else if (validateError && token === null) {
        return (<Redirect to='/login' />)
    }
    else if (validateError && token!= null) {
        return ( <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col lg={5}>
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <div>
                      <div className="text-center">
                        <div>
                          <Link to="/" className="logo">
                            <img src={logodark} height="60" alt="logo" />
                          </Link>
                        </div>
                        <h4 className="font-size-18 mt-5">
                        </h4>
                        <Alert color="danger" className="mt-4">
                                Link expired!
                              </Alert>

                      </div>
                      <div className="mt-5 text-center">
                        <p>
                           {" "}
                          <Link
                            to="/forgot-password"
                            className="font-weight-medium text-primary"
                          >
                            {" "}
                            Generate new link! {" "}
                          </Link>{" "}
                        </p>
                      </div>
                      <div className="mt-5 text-center">
                        <p>
                          Return to {" "}
                          <Link
                            to="/login"
                            className="font-weight-medium text-primary"
                          >
                            {" "}
                            Login Page {" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div className="authentication-bg">
              {/* bg-overlay */}
              <div className=""></div>
            </div>
          </Col>
        </Row>
      </Container>
        )
    }
    else {
        return (<div style={{ backgroundColor: "rgba(255,255,255,0.7)", zIndex: 9999 }}
            className="d-flex flex-column align-items-center justify-content-around position-fixed w-100 h-100">
            <div className="d-flex flex-column align-items-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div>Loading...</div>
            </div>
        </div>)
    }
}



export default ResetPassWord
