import {
  GET_ACCS_AND_TOKEN,
  GET_ACCS_AND_TOKEN_FAILED,
  GET_ACCS_AND_TOKEN_SUCCESSFUL,
  GET_TAB_TOKEN,
  GET_TAB_TOKEN_SUCCESSFUL,
  GET_TAB_TOKEN_FAILED,
  CLEAR_TAB_TOKEN,
  CLEAR_TAB_TOKEN_SUCCESSFUL,
} from "./actionTypes";
  const initialState = {
    error: null, message: null, loading: null, tabToken: null
  }
  
  const tableauList = (state = initialState, action) => {
      console.log("skarp, action", action);
      switch (action.type) {
        case GET_ACCS_AND_TOKEN:
          state = {
            ...state,
            loading: true,
            error: null,
          };
          break;
        case GET_ACCS_AND_TOKEN_SUCCESSFUL:
          if (
            action &&
            action.payload &&
            action.payload.tabToken &&
            action.payload.tabToken.tabToken &&
            action.payload.tabToken.status === 400
          ) {
            state = {
              ...state,
              tabToken: -1,
              loading: false,
              error: true,
            };
          } else {
            state = {
              ...state,
              //   accounts: action.payload.acct.accounts,
              //   organizations: action.payload.acct.organizations,
              //   usersConfig: action.payload.acct.usersConfig,
              tabToken: action.payload.tabToken.tabToken,
              loading: false,
              error: null,
            };
          }
          break;
        case GET_ACCS_AND_TOKEN_FAILED:
          console.log("AYA");
          state = {
            ...state,
            loading: false,
            error: true,
            tabToken: -1,
          };
          break;
        case GET_TAB_TOKEN:
          state = {
            ...state,
            loading: true,
            error: null,
          };
          break;
        case GET_TAB_TOKEN_SUCCESSFUL:
          if (
            action &&
            action.payload &&
            action.payload.tabToken &&
            action.payload.tabToken.tabToken &&
            action.payload.tabToken.status === 400
          ) {
            state = {
              ...state,
              tabToken: -1,
              loading: false,
              error: true,
            };
          } else {
            state = {
              ...state,
              tabToken: action.payload.tabToken.tabToken,
              loading: false,
              error: null,
            };
          }
          break;
        case GET_TAB_TOKEN_FAILED:
          state = {
            ...state,
            loading: false,
            error: true,
            tabToken: -1,
          };
          break;
        case CLEAR_TAB_TOKEN:
          state = {
            ...state,
          };
          break;
        case CLEAR_TAB_TOKEN_SUCCESSFUL:
          state = {
            ...state,
            tabToken: -1,
          };
          break;
        default:
          return state;
      }
      return {...state};
  }
  
  export default tableauList;