import React, { useState, useEffect } from "react";
import {
  Typography,
  ListItemIcon,
  Chip,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Alert,
  IconButton,
  TextField,
  Autocomplete,
  Popper,
} from "@mui/material";
import { useTagDetails, useUserAccountsInfo } from "./components/useTagAllocation";
import { connect } from "react-redux";
import { clearTagMessages, downloadTags, uploadTags } from "../../store/tagAllocation/tagAllocationSlice";
import UploadTagsModal from "./components/UploadTagsModal";
import spinner from "../../assets/images/aws/loadingGif.gif";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from "notistack";
import DownloadIcon from '@mui/icons-material/SystemUpdateAlt';

const tagColumns = [
  { field: 'key', headerName: 'Tag Key', width: 250 },
  { field: 'value', headerName: 'Tag Value', width: 250 }
];

const CustomPopper = (props) => (
  <Popper {...props} style={{ width: 'auto', maxWidth: '80vw' }} placement="bottom-start" />
);

const ApplicationsTagging = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // State for filters
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  
  const [uniqueTagKeys, setUniqueTagKeys] = useState([]);
  const [selectedTagKeys, setSelectedTagKeys] = useState([]);
  const [uniqueTagValues, setUniqueTagValues] = useState([]);
  const [selectedTagValues, setSelectedTagValues] = useState([]);
  
  // Unique values for dropdowns
  // const accounts = [...new Set(data.map(item => item.accountNumber))];
  const regions = [...new Set(data?.map(item => item.region==="" ? "No Region" : item.region))];
  const services = [...new Set(data?.map(item => item.service))];

  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const { orgCode, ciEnabledAccounts, username }= useUserAccountsInfo();
  const { tagDetails, isFetchingTagDetails, fetchTagDetailsError } = useTagDetails(selectedAccount);

  console.log("#####tagDetails : ", tagDetails);
  console.log("#####isFetchingTagDetails : ", isFetchingTagDetails);
  console.log("#####fetchTagDetailsError : ", fetchTagDetailsError);


  // Count the number of present tags in each row
  const countTags = (row) => {
    return Object.keys(row.tags).length;
  };

  // Function to limit selected values display
  const renderSelectedValues = (selected) => {
    if (selected.length === 0) return "";
    return `${selected.length} selected`;
  };
  
  const clearFilterState = () => {
    console.log("### Calling clearFilterState");
    setSelectedRegions([]);
    setSelectedTagKeys([]);
    setSelectedServices([]);
    setSelectedTagValues([]);
  };

  const changeTagDetails = (tagDetails) => {
    console.log("### Calling changeTagDetails with tagDetails=", tagDetails);
    setFilteredData(tagDetails);
    setData(tagDetails);
  
    const tagKeysSet = new Set();
    const valuesSet = new Set();
    
    if (tagDetails && tagDetails.length > 0) {
      // Create unique tag keys
      tagDetails.forEach((item) => {
        Object.keys(item.tags).forEach((tagKey) => tagKeysSet.add(tagKey));
        
        // Populate values based on the keys
        Object.entries(item.tags).forEach(([key, value]) => {
          // Format as "key: value"
          valuesSet.add(`${key}: ${value}`);
        });
      });
  
      // Set unique keys and values
      setUniqueTagKeys(Array.from(tagKeysSet));
      
      // If selected tag keys are present, populate unique values accordingly
      if (selectedTagKeys.length > 0) {
        const filteredValuesSet = new Set();
        tagDetails.forEach((item) => {
          const tags = item.tags;
          selectedTagKeys.forEach((key) => {
            if (tags[key] !== undefined) {
              filteredValuesSet.add(`${key}: ${tags[key]}`);
            }
          });
        });
        setUniqueTagValues(Array.from(filteredValuesSet));
      } else {
        setUniqueTagValues([]); // Clear values if no keys are selected
      }
    } else {
      setUniqueTagKeys([]);
      setUniqueTagValues([]);
    }
  };

  const handleTagKeyChange = (event) => {
    const { value } = event.target;
    setSelectedTagKeys(value); // Directly set the array of selected tag keys

    const filteredValues = new Set();
    data.forEach((item) => {
      value.forEach((key) => {
        if (key in item.tags) {
          filteredValues.add(`${key}: ${item.tags[key]}`);
        }
      });
    });

    setUniqueTagValues(Array.from(filteredValues)); 
  };

  const handleTagValuesChange = (event, newValues) => {
    setSelectedTagValues(newValues);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // Handle Export to CSV
  const handleDownloadTags = () => {
    if (selectedAccount && orgCode) {
      props.downloadTags(orgCode, selectedAccount);
    }
  };

  // Function to handle Tags modal open/close
  const handleOpenTagsModal = (row) => {
    const tagsMap = Object.entries(row.tags).map(([key, value], index) => {
      return {
        id: index + 1,
        key: key,
        value: value
      };
    });
    setSelectedTags(tagsMap);
    console.log("tagsMap = ", tagsMap);
    setOpenTagsModal(true);
  }

  const handleCloseTagsModal = () => {
    setSelectedTags([]);
    setOpenTagsModal(false);
  }

  const createDownload = (tagsDownloadUrl) => {
    if (tagsDownloadUrl) {
      const link = document.createElement('a');
      link.href = tagsDownloadUrl; // Set the URL of the file to download
      link.download = 'tags-file.ext'; // Replace with your desired file name and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // Handle filter logic
  useEffect(() => {
    let filtered = data;

    if (selectedRegions.length > 0) {
      filtered = filtered.filter(item => selectedRegions.includes(item.region==="" ? "No Region": item.region));
    }
    if (selectedServices.length > 0) {
      filtered = filtered.filter(item => selectedServices.includes(item.service));
    }
    if (selectedTagKeys.length > 0) {
      filtered = filtered.filter((item) =>
        selectedTagKeys.every((key) => key in item.tags)
      );
    }
    if (selectedTagValues.length > 0) {
      filtered = filtered.filter((item) =>
        selectedTagValues.some((value) => {
          const [key, tagValue] = value.split(": ");
          return item.tags[key] === tagValue;
        })
      );
    }
    setFilteredData(filtered);
  }, [selectedRegions, selectedServices, selectedTagKeys, selectedTagValues]);

  // load initial selected account
  useEffect(() => {
    console.log("### Calling useEffect for change in ciEnabledAccounts = ", ciEnabledAccounts);
    if  (!selectedAccount && ciEnabledAccounts?.length > 0) {
      const randomSelectedAccount = ciEnabledAccounts?.[0]?.accountNumber;
      console.log("### Using randomSelectedAccount: ", randomSelectedAccount);
      setSelectedAccount(randomSelectedAccount);
    }
  }, [ciEnabledAccounts, selectedAccount]);

  // load tagDetails in UI
  useEffect(() => {
    console.log("### Calling useEffect for change in tagDetails = ", tagDetails);
    changeTagDetails(tagDetails);
    clearFilterState();
  }, [selectedAccount, tagDetails]);

  // Display successful upload message for tags upload from Modal
  const uploadMessage = props.uploadMessage;
  useEffect(() => {
    if (uploadMessage) {
      enqueueSnackbar(uploadMessage, { variant: "success" });
    }
  }, [uploadMessage]);

  //For Storing downloaded file for tags
  const tagsDownloadUrl = props.tagsDownloadUrl;
  useEffect(() => {
    console.log("tagsDownloadUrl : ", tagsDownloadUrl);
    if (tagsDownloadUrl) {
      createDownload(tagsDownloadUrl);
      props.clearTagMessages();
    }
  }, [tagsDownloadUrl]);

  // For displaying download error
  const fetchTagsError = props.fetchTagsError;
  useEffect(() => {
    console.log("fetchTagsError : ", fetchTagsError);
    if (fetchTagsError) {
      enqueueSnackbar(fetchTagsError, { variant: "error" });
      setTimeout(() => {
        console.log("About to call clearTagMessages");
        props.clearTagMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
  }, [fetchTagsError]);

  const columns = [
    {
      field: "arn",
      flex: 2,
      headerName: <>Identifier {isFetchingTagDetails && (<img src={spinner} width="20px" alt="Spinner" />)}</>,
    },
    { field: "service", headerName: "Service",  flex: 1},
    { field: "region", headerName: "Region",  flex: 1},
    {
      field: "project",
      headerName: "Project",
      flex: 1,
      renderCell: (params) => params.row.tags?.Project || "-", 
    },
    {
      field: "application",
      headerName: "Application",
      flex: 1,
      renderCell: (params) => params.row.tags?.Application || "-", 
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      renderCell: (params) => params.row.tags?.Customer || "-", 
    },
    {
      field: "environment",
      headerName: "Environment",
      flex: 1,
      renderCell: (params) => params.row.tags?.Environment || "-", 
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      renderCell: (params) => params.row.tags?.Department || "-", 
    },
  ];

  // Convert your data into rows format
  const rows = filteredData?.map((row, index) => ({
    id: index, // unique id for DataGrid row
    arn: row.arn,
    service: row.service,
    region: row.region,
    tags: row.tags, // we will use this row to calculate the number of tags
  }));

  return (
    <>
      <Card>
        {/* <CardHeader title="Applications Tagging Dashboard" /> */}
        <CardContent>
          <Box>
            {/* Filters */}
            <Box style={{ display: "flex", gap: "20px", flexGrow: 1, flexWrap: "wrap" }}>
              {/* Account Selection */}
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="account-filter-select-label">Account</InputLabel>
                <Select
                  labelId="account-filter-select-label"
                  id="account-select"
                  name="accountFilter"
                  label="Account"
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e.target.value)}
                >
                  {ciEnabledAccounts.map((account) => (
                    <MenuItem
                      key={account.accountNumber}
                      value={account.accountNumber}  
                      sx={{
                        padding: "4px 8px", // Reduce padding inside the dropdown item
                      }}
                    >
                      {account.accountNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Region Filter */}
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="region-filter-select-label">Region</InputLabel>
                <Select
                  name="regionFilter"
                  label="Region"
                  labelId="region-filter-select-label"
                  multiple
                  value={selectedRegions}
                  onChange={(e) => setSelectedRegions(e.target.value)}
                  renderValue={(selected) => renderSelectedValues(selected)}
                >
                  {regions.map((region) => (
                    <MenuItem
                      key={region}
                      value={region}
                      sx={{
                        padding: "4px 8px", // Reduce padding inside the dropdown item
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox checked={selectedRegions.indexOf(region) > -1} />
                      </ListItemIcon>
                      {/* Render Chip for the MenuItem */}
                      <Chip label={region} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Service Filter */}
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="service-filter-select-label">Service</InputLabel>
                <Select
                  name="serviceFilter"
                  label="Service"
                  labelId="service-filter-select-label"
                  multiple
                  value={selectedServices}
                  onChange={(e) => setSelectedServices(e.target.value)}
                  renderValue={(selected) => renderSelectedValues(selected)}
                >
                  {services.map((service) => (
                    <MenuItem
                      key={service}
                      value={service}
                      sx={{
                        padding: "4px 8px", // Reduce padding inside the dropdown item
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox checked={selectedServices.indexOf(service) > -1} />
                      </ListItemIcon>
                      {/* Render Chip for the MenuItem */}
                      <Chip label={service} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Tag Keys Filter */}
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="tag-key-select-label">Tag Keys</InputLabel>
                <Select
                  name="tagFilter"
                  label="Tag Keys"
                  labelId="tag-key-select-label"
                  multiple
                  value={selectedTagKeys}
                  onChange={handleTagKeyChange}
                  // renderValue={(selected) => selected.join(', ')}
                  renderValue={(selected) => renderSelectedValues(selected)}
                >
                  {uniqueTagKeys.map(tagKey => (
                    <MenuItem
                      key={tagKey}
                      value={tagKey}
                      sx={{
                        padding: "4px 8px", 
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox checked={selectedTagKeys.includes(tagKey)} />
                      </ListItemIcon>
                      <Chip label={tagKey} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Tag Values Filter */}
              <FormControl sx={{ minWidth: 200 }} size="small">
                <Autocomplete
                  multiple
                  options={uniqueTagValues}
                  value={selectedTagValues}
                  PopperComponent={CustomPopper}
                  onChange={handleTagValuesChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Tag Values" placeholder="Search..." />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props}>
                      <Checkbox checked={selected} />
                      {option}
                    </MenuItem>
                  )}
                  renderTags={(value, getTagProps) => renderSelectedValues(value)} 
                  size="small"
                  sx={{ minWidth: 200 }}
                />
              </FormControl>
  
              {/* Export to CSV Button */}
              <Box sx={{ marginBottom: "20px", marginLeft: "auto", display: "flex", gap: "1rem" }}>
                <Button
                  variant="contained"
                  onClick={handleModalOpen}
                  sx={{
                    backgroundColor: "#F98125",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#e8721f",
                    },
                  }}
                >
                  Upload Tag CSV
                </Button>
                <Button
                  variant="contained"
                  sx={{backgroundColor: "#193A6F",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#303F9F",
                    }}}
                  onClick={handleDownloadTags}
                  disabled={props.isFetchingTags}
                >
                  <DownloadIcon sx={{ mr: 1 }}/> Download Tag CSV
                </Button>
              </Box>
            </Box>

            {/* Table */}
            <Box sx={{ width: "100%" }}>
              <Paper>
                {props.accountsLoading ? (
                  <div
                    style={{
                        display: "flex",
                        direction: "row",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        justifyItems: "center",
                        height: "200px",
                    }}
                  >
                    <img src={spinner} width="32px" alt="Spinner" />
                  </div>
                ) : ciEnabledAccounts?.length === 0 ? (
                  <Alert severity="error">No account with Cost Insight feature exists!</Alert>
                ) : props.fetchTagDetailsError ? (
                  <Alert severity="error">{props.fetchTagDetailsError}</Alert>
                ) : data?.length === 0 ? (
                  <Alert severity="info">No tags exist for this account!</Alert>
                ) : filteredData?.length === 0 ? (
                  <Alert severity="info">No tags exist for the selected filters!</Alert>
                ) : rows ? (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    loading={isFetchingTagDetails}
                    disableSelectionOnClick
                    disableColumnMenu
                    rowHeight={40}
                    sx={{
                      maxHeight: 'calc(100vh - 350px)',
                      '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold', // Make individual header title bold
                      },
                      "& .MuiDataGrid-cell": {
                        py: 0.5, // Adjusts padding to make it more compact
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#f5f5f5", // Optional, adds AWS-like header background
                      },
                    }}
                  />
                ) : isFetchingTagDetails ? (
                    <div
                      style={{
                          display: "flex",
                          direction: "row",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          justifyItems: "center",
                          height: "200px",
                      }}
                    >
                      <img src={spinner} width="32px" alt="Spinner" />
                    </div>
                ) : null
                }
              </Paper>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {isModalOpen && (
        <UploadTagsModal
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          orgCode={orgCode}
          accountNumber={selectedAccount}
        />
      )}
      

      {/* Modal for displaying the tags */}
      <Modal
        open={openTagsModal}
        onClose={handleCloseTagsModal}
        aria-labelledby="resource-tags-modal-title"
        aria-describedby="resource-tags-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Responsive width
            maxWidth: 600, // Max width
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: '#f3f4f6', // Very light gray background color
              padding: '8px 16px', // Padding around header
              borderBottom: '1px solid #ddd', // Bottom border to separate header
            }}
          >
            {/* Modal Title (Two-line) */}
            <Box>
              <Typography id="resource-tags-modal-title" variant="h6" component="h2">
                Resource Tags
              </Typography>
              <Typography
                id="resource-tags-modal-description"
                variant="body2"
                color="textSecondary"
              >
                Tags that are assigned to the resource
              </Typography>
            </Box>

            {/* Close Icon */}
            <IconButton onClick={handleCloseTagsModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Spacing between header and table */}
          <Box sx={{ padding: '16px' }}> {/* Adds padding between header and table */}
            
            {/* Table to display the tags */}
            <Paper
              style={{
                height: 'auto', // Auto height to eliminate extra space
                width: '100%',
                boxShadow: 'none', // Remove extra shadow to avoid double border look
                border: 'none', // Remove borders inside Paper to avoid double border
                overflow: 'hidden', // Prevent overflow
              }}
            >
              <DataGrid
                rows={selectedTags}
                columns={tagColumns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                disableColumnMenu
                getRowId={(row) => row.id}
                hideFooter // Hides the footer
                rowHeight={35} // Set row height for data rows
                headerHeight={35} // Set header height to match row height
                autoHeight // Makes the table adjust its height based on rows
                sx={{
                  width: '100%', // Ensure DataGrid takes full width
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#f3f4f6',
                    color: '#333',
                    fontWeight: 'bold', // Make header text bold like AWS
                    borderBottom: '2px solid #ddd',
                    fontSize: '14px',
                    height: '35px', // Match header height with data rows
                    lineHeight: '35px', // Align header text vertically
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Make individual header title bold
                  },
                  '& .MuiDataGrid-cell': {
                    borderBottom: '1px solid #eee',
                    fontSize: '14px',
                    color: '#555',
                  },
                  '& .MuiDataGrid-row': {
                    '&:hover': {
                      backgroundColor: '#f7f9fc',
                    },
                    '&:nth-of-type(odd)': {
                      backgroundColor: '#fafafa',
                    },
                  },
                  '& .MuiCheckbox-root': {
                    color: '#0073bb',
                  },
                }}
              />
            </Paper>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    isUploadingTags,
    uploadMessage,
    uploadError,

    isFetchingTags,
    fetchTagsMessage,
    fetchTagsError,
    tagsDownloadUrl,

    fetchTagDetailsError,
  } = state.tags;

  const {
    loading: accountsLoading
  } = state.orgAccount;

  return {
    // For uploading tags
    isUploadingTags,
    uploadMessage,
    uploadError,

    // For downloading tags as file
    isFetchingTags,
    fetchTagsMessage,
    fetchTagsError,
    tagsDownloadUrl,

    // For downloading tags as json data
    fetchTagDetailsError,

    // loading state var while accounts are loading
    accountsLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadTags: (orgCode, accountNumber, file) => {
      dispatch(uploadTags({orgCode, accountNumber, file}));
    },
    downloadTags: (orgCode, accountNumber) => {
      dispatch(downloadTags({orgCode, accountNumber}));
    },
    clearTagMessages: () => {
      dispatch(clearTagMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsTagging);