import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, CircularProgress } from "@mui/material";
import {
  fetchBillingRecords,
  clearBillingState,
} from "../../store/billing/billingSlice";
import BillingDataGrid from "./BillingDataGrid";
import AccountNumberFilter from "./AccountNumberFilter"; // Import Account filter
import BillingDateFilter from "./BillingDateFilter"; // Import Date filter

const BillingPage = () => {
  const dispatch = useDispatch();

  // State for account number filter
  const [selectedAccount, setSelectedAccount] = useState("All");

  // State for date filter
  const [selectedMonth, setSelectedMonth] = useState("All");

  // Billing records and loading/error state
  const {
    isLoading,
    records = [],
    errorMessage,
  } = useSelector((state) => state.billing);

  // Accounts for filtering
  const {
    accounts = [],
    loading,
    reg_acct_error,
  } = useSelector((state) => state.orgAccount);

  // Filter records based on account number and selected month
  const filteredRecords = records.filter((record) => {
    const dateFrom = new Date(record.dateFrom);
    const dateTo = new Date(record.dateTo);

    // If account number is selected, filter by account number
    const accountMatch =
      selectedAccount === "All" || record.accountNumber === selectedAccount;

    // If a month is selected, filter by the date range (dateFrom or dateTo)
    const [filterMonth, filterYear] = selectedMonth.split(" ");
    const filterMonthIndex = new Date(
      `${filterMonth} 1, ${filterYear}`
    ).getMonth(); // Get the numeric month index for comparison
    const filterYearNum = parseInt(filterYear);

    // Find the start and end of the selected month
    const filterStartDate = new Date(filterYearNum, filterMonthIndex, 1);
    const filterEndDate = new Date(filterYearNum, filterMonthIndex + 1, 0); // Last day of the month

    // Check if the billing period overlaps with the selected month
    const dateMatch =
      selectedMonth === "All" ||
      (dateFrom <= filterEndDate && dateTo >= filterStartDate); // The billing period overlaps with the selected month

    return accountMatch && dateMatch;
  });

  useEffect(() => {
    dispatch(fetchBillingRecords());
    return () => {
      dispatch(clearBillingState());
    };
  }, [dispatch]);

  if (isLoading || loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (errorMessage || reg_acct_error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          Error: {errorMessage || reg_acct_error}
        </Typography>
      </Box>
    );
  }

  return (
    <div className="page-content">
      {/* Page Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Space between the left and right sections
          alignItems: "center",
          padding: "16px 0",
        }}
      >
        {/* Billing Records Header on the left */}
        <Typography
          variant="h5"
          gutterBottom
          align="left"
          sx={{ marginBottom: 2 }}
        >
          Billing Records
        </Typography>

        {/* Filters on the right */}
        <Box sx={{ display: "flex", gap: "8px" }}>
          {/* Account Number Filter */}
          <AccountNumberFilter
            accounts={accounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
          {/* Billing Date Filter */}
          <BillingDateFilter
            selectedMonth={selectedMonth}
            handleMonthChange={setSelectedMonth}
          />
        </Box>
      </Box>

      {/* Display filtered billing records */}
      {filteredRecords.length > 0 ? (
        <BillingDataGrid data={filteredRecords} />
      ) : (
        <Typography variant="body1">No records found</Typography>
      )}
    </div>
  );
};

export default BillingPage;
