import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { Stepper, Step, StepLabel, StepContent, Typography, Box, Chip, Alert } from "@mui/material";
import { 
  TextField, 
  Button, 
  Grid, 
  Container, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem,
  FormHelperText,
  Tooltip
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material//Visibility";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CheckCircleOutline, ContentCopy } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core";
import logo from "./../../assets/images/Expanded_Colored_PoweredBy.png";
import { BackgroundPattern } from "../../components/BackgroundPattern";
import Encrypt from "../../components/Encrypt";
import PhoneInput, { isPossiblePhoneNumber  } from "react-phone-number-input";
import { countryList } from "../../utilities/countries";
import UrlDisplayDialog from "../Support/common/UrlDisplayDialog";
import { fetchFreeTrialPublicKey } from "../../helpers/fetchPublicKey";
import { v4 as uuidv4 } from "uuid";

const TRIAL_EMAIL_API_URL = process.env.REACT_APP_TRIAL_EMAIL_API_URL;
const ABSTRACT_API_KEY = process.env.REACT_APP_ABSTRACT_API_KEY;
const EMAIL_VALIDATION_API_URL="https://emailvalidation.abstractapi.com/v1";


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
    // overflow: "auto",
  },
  
  container: {
    position: "relative",
    zIndex: 1,
    justifyContent: "space-evenly",
  },
  logo: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    // marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
}));

const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  password: "",
  confirmPassword: "",

  accountNumber: "",
  accountName: "",
  iamrole: "CloudCatcherTrustRole",
  location: "us-east-1",
  accounttype: "AWS"
};

const zipRegex = /^\d{5,}$/;
const validationSchema = yup.object({
  firstName: yup.string()
    .required("First name is required"),
  lastName: yup.string()
    .required("Last name is required"),
  email: yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  address: yup.string()
    .required("Work address is required"),
  city: yup.string()
    .required("City is required"),
  country: yup.string().test("country", "Country is required", function (value) {
    if (!value || value === "Select Country") {
      return this.createError({ message: "Country is required" });
    }
    return true;
  }),
  zip: yup.string()
    .required("Zip code is required"),
  password: yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      "Password must contain at least one uppercase letter and one special character"),
  confirmPassword: yup.string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  accountNumber: yup.number()
      .required("AWS Account number is required")
      .test(
        'len',
        'AWS Account number must be exactly 12 digits',
        val => val && val.toString().length === 12
      ),
  // accountName: yup.string()
  //     .required("Account name is required"),
  iamrole: yup.string()
      .required("Role is required"),
});

function FreeTrial() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [phonenumber, setPhonenumber] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [phonenumberError, setPhonenumberError] = useState(null);

  // const [isPairingVerified, setIsPairingVerified] = useState(false);
  const [isSendTrialLoading, setIsSendTrialLoading] = useState(false);
  const [sendTrialError, setSendTrialError] = useState(null);
  const [sendTrialMessage, setSendTrialMessage] = useState(null);

  const [cloudFormationLoading, setCloudFormationLoading] = useState(false);
  const [cloudFormationGuideLoading, setCloudFormationGuideLoading] = useState(false);
  const [cloudFormationGuideUrl, setCloudFormationGuideUrl] = useState(null);
  const [cloudFormationGuideModalOpen, setCloudFormationGuideModalOpen] = useState(false);


  const [pairingGuideLoading, setPairingGuideLoading] = useState(false);
  const [pairingGuideUrl, setPairingGuideUrl] = useState(null);
  const [pairingGuideModalOpen, setPairingGuideModalOpen] = useState(false);

  // For email validation API
  const [emailApiValidationLoading, setEmailApiValidationLoading] = useState(false);
  const [emailApiValidationError, setEmailApiValidationError] = useState(null);
  // if the user changes the email in the field, validation should be redone.
  const [emailApiValidationDone, setEmailApiValidationDone] = useState(false);
  const [emailApiValidationStatus, setEmailApiValidationStatus] = useState(false);
  const [activationEmailAddress, setActivationEmailAddress] = useState(null);
  const [externalId, setExternalId] = useState(uuidv4());
  const [copy, setCopy] = useState(false);
  // const externalID = uuidv4();

  const classes = useStyles();

  // For reading token from query string
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  // Get history object to redirect to /login
  const history = useHistory();

  // console.log("active step : " + activeStep);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownConfirmPassword = (event) => {
      event.preventDefault();
  };

  const handlePhoneChange = (value) => {
    setPhonenumber(value);
  }

  const handlePhoneBlur = () => {
    let isInvalidResult;
    if (!phonenumber){
      isInvalidResult = true;
    } else {
      isInvalidResult = !isPossiblePhoneNumber(phonenumber);
    }
    if (isInvalidResult) {
      setPhonenumberError("Please enter a valid phone number");
    } else {
      setPhonenumberError(null);
    }
  }

  const sendTrialEmail = async (userAndAccountData) => {
    console.log("Body :: ", userAndAccountData)

    const options = {
      method: "POST",
      url: TRIAL_EMAIL_API_URL + "/createTrialOrganization",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...userAndAccountData }),
    };
    console.log("Body 1:: ", JSON.stringify({ ...userAndAccountData, phonenumber: phonenumber }))

    try {
      setIsSendTrialLoading(true);
      const response = await fetch(TRIAL_EMAIL_API_URL + "/createTrialOrganization", options);
      console.log("API response: " , response);
      setIsSendTrialLoading(false);
      if (!response.ok) {
        const status = response.status;

        const responseBody = await response.text();
        console.log("response Body :: ", responseBody)

        enqueueSnackbar(`${responseBody}`, { variant: "error" });
        return;
      }
      // Request successful, continue processing the response
      const responseData = await response.text();
      setSendTrialMessage(responseData);
      setActiveStep(2);
    } catch (error) {
      // Handle or log any other errors
      console.error("Error:", error);
      enqueueSnackbar("An error occurred. Please try again later."+JSON.stringify(error), { variant: "error" });
    } finally {
      // Set loading state to false regardless of success or failure
      setIsSendTrialLoading(false);
    }  
  };

  const handleNext = async (formValues) => {
    if(activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if(activeStep === 1) {

      // Submit data
      const publicKeyString = await fetchFreeTrialPublicKey();

      // Encrypt all form values using the fetched public key
      const sendTrialEmailRequest = {
        firstName: Encrypt(formValues.firstName, publicKeyString),
        lastName: Encrypt(formValues.lastName, publicKeyString),
        email: Encrypt(formValues.email, publicKeyString),
        password: Encrypt(formValues.password, publicKeyString),
        selltoken: Encrypt(token, publicKeyString),
        accountNumber: Encrypt(formValues.accountNumber, publicKeyString),
        accountName: Encrypt(formValues.accountName, publicKeyString),
        iamrole: Encrypt(formValues.iamrole, publicKeyString),
        location: Encrypt(formValues.location, publicKeyString),
        accounttype: Encrypt(formValues.accounttype, publicKeyString),
        phoneNumber: Encrypt(phonenumber, publicKeyString),
        workAddress: Encrypt(formValues.address, publicKeyString),
        zipcode: Encrypt(formValues.zip, publicKeyString),
        city: Encrypt(formValues.city, publicKeyString),
        province: Encrypt(formValues.state, publicKeyString),
        country: Encrypt(formValues.country, publicKeyString),
        externalId: Encrypt(externalId, publicKeyString),
      };
      console.log("Submit Trial request: ", sendTrialEmailRequest);
      sendTrialEmail(sendTrialEmailRequest);
      setActivationEmailAddress(formValues.email);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const getCloudFormationUrl = async () => {
    const fileName = encodeURIComponent("configurations/cloudcatcher-trust-role-template.yaml");
    setCloudFormationLoading(true);
    try {
      const response = await fetch(`${TRIAL_EMAIL_API_URL}/getDocument?fileName=${fileName}`);
      setCloudFormationLoading(false);
      if(response.status === 200){
        const templateUrl = await response.json();
        console.log("templateUrl=", templateUrl);
        return templateUrl.url;
      } else {
        const errorResponse = await response.json();
        enqueueSnackbar(`Error: ${errorResponse.message}`, { variant: "error" });
        return null;  
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
      return null;
    }    
  }

  const handleCloudFormation = async (e) => {
    e.stopPropagation();
    const cloudFormationUrl = await getCloudFormationUrl();
    if(cloudFormationUrl!==null) {
      const urlResponse = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${encodeURIComponent(cloudFormationUrl)}`;
      console.log("urlResponse = ", urlResponse);
      window.open(urlResponse);      
    }
  }

  const handleManualPairing = (e) => {
    e.stopPropagation();
    const accountId="";
    const roleName="CloudCatcherTrustRole";
    const url = `https://console.aws.amazon.com/iam/home#/roles$new?step=review&roleType=crossAccount&accountID=${accountId}&policies=arn%3Aaws%3Aiam%3A%3Aaws%3Apolicy%2FReadOnlyAccess&roleName=${roleName}&externalId=${externalId}&trustedEntityType=AWS_ACCOUNT&isThirdParty=true&awsAccount=${accountId}`;
    window.open(url);
  }

  const getManualPairingGuideUrl = async () => {
    const fileName = encodeURIComponent("configurations/Self-Onboarding-Manual-Guide-to-Pair-New-Account.pdf");
    setPairingGuideLoading(true);
    try{
      const response = await fetch(`${TRIAL_EMAIL_API_URL}/getDocument?fileName=${fileName}`);
      setPairingGuideLoading(false);
      if(response.status === 200) {
        const templateUrl = await response.json();
        console.log("templateUrl : ", templateUrl);    
        return templateUrl.url;
      } else {
        const errorResponse = await response.json();
        enqueueSnackbar(`Error: ${errorResponse.message}`, { variant: "error" });
        return null;  
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
      return null;
    }
  }

  const handleManualPairingGuide = async (e) => {
    e.stopPropagation();
    const manualPairingGuideUrl = await getManualPairingGuideUrl();
    if(manualPairingGuideUrl!==null){
      setPairingGuideUrl(manualPairingGuideUrl);
      setPairingGuideModalOpen(true);
    }
  }

  const handleClickExternalId = () => {
    navigator.clipboard.writeText(externalId);
    setCopy(true)
    setTimeout(() => setCopy(false), 3000); 
  }


  //TODO: update fileName and fetch url
  const getCloudFormationGuideUrl = async () => {
    const fileName = encodeURIComponent("configurations/Self-Onboarding-CloudFormation-Guide-to-Pair-New-Account.pdf");
    setCloudFormationGuideLoading(true);
    try{
      const response = await fetch(`${TRIAL_EMAIL_API_URL}/getDocument?fileName=${fileName}`);
      setCloudFormationGuideLoading(false);
      if(response.status === 200) {
        const templateUrl = await response.json();
        console.log("templateUrl : ", templateUrl);    
        return templateUrl.url;
      } else {
        const errorResponse = await response.json();
        enqueueSnackbar(`Error: ${errorResponse.message}`, { variant: "error" });
        return null;  
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
      return null;
    }
  }

  const handleCloudFormationGuide = async (e) => {
    e.stopPropagation();
    const cloudFormationGuideUrl = await getCloudFormationGuideUrl();
    if(cloudFormationGuideUrl!==null){
      setCloudFormationGuideUrl(cloudFormationGuideUrl);
      setCloudFormationGuideModalOpen(true);
    }
  }

  

  const isUserDetailsInvalid = (formData, formErrors) => {
    // console.log("formData = ", formData, "formErrors = ", formErrors);
    return formData.firstName === "" ||
        formData.lastName === "" || 
        formData.password  === "" || 
        formData.confirmPassword  === "" || 
        formData.email  === "" ||
        phonenumber  === "" ||
        formData.address  === "" ||
        formData.city  === "" ||
        formData.state  === "" ||
        formData.country  === "" ||
        formData.zip  === "" ||

        Boolean(formErrors.firstName) ||
        Boolean(formErrors.lastName) || 
        Boolean(formErrors.password) || 
        Boolean(formErrors.confirmPassword) || 
        Boolean(formErrors.email) ||
        Boolean(phonenumberError) || 
        Boolean(formErrors.address) ||
        Boolean(formErrors.city) ||
        Boolean(formErrors.state) ||
        Boolean(formErrors.country) ||
        Boolean(formErrors.zip) ||
        Boolean(emailApiValidationError);
  }

  const isAccountDetailsInvalid = (formData, formErrors) => {
    // console.log("formData = ", formData, "formErrors = ", formErrors);
    return formData.accountNumber === "" ||
        formData.iamrole === "" ||
        Boolean(formErrors.accountNumber) ||
        Boolean(formErrors.accountName) ||
        Boolean(formErrors.iamrole) ;
  }

  const validateEmailUsingApi = async (email) => {
    setEmailApiValidationLoading(true);
    // setEmailApiValidationDone(false);
    setEmailApiValidationError(null);
    const response = await fetch(`${EMAIL_VALIDATION_API_URL}?api_key=${ABSTRACT_API_KEY}&email=${email}`);
    const validationResponse = await response.json();
    setEmailApiValidationLoading(false);
    setEmailApiValidationDone(true);

    let isValidFormat;
    let isFreeEmail;
    let isDisposableEmail;
    let isMxFound;
    let isSmtpValid;
  
    console.log("validationResponse = ", validationResponse);
    if(validationResponse?.is_valid_format?.value!==null &&
      validationResponse?.is_free_email?.value!==null &&
      validationResponse?.is_disposable_email?.value!==null &&
      validationResponse?.is_mx_found?.value!==null &&
      validationResponse?.is_smtp_valid?.value!==null
    ) {
      isValidFormat = validationResponse.is_valid_format.value;
      isFreeEmail = validationResponse.is_free_email.value;
      isDisposableEmail = validationResponse.is_disposable_email.value;
      isMxFound = validationResponse.is_mx_found.value;
      isSmtpValid = validationResponse.is_smtp_valid.value;
      if (!isValidFormat || isFreeEmail || isDisposableEmail || !isMxFound || !isSmtpValid) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const validateEmail = async (email) => {
    if(!emailApiValidationDone){
      try {
        const emailValidationStatus = await validateEmailUsingApi(email);
        setEmailApiValidationStatus(emailValidationStatus);
        if(emailValidationStatus) {
          setEmailApiValidationError(null);
        } else {
          setEmailApiValidationError("Please enter valid business email!");
        }    
      } catch (error) {
        setEmailApiValidationError("Email validation failed. Please try again. If this persists, please contact CloudNova support!");
      }
    }
  }


  return (
    <SnackbarProvider>
      <div className={`${classes.root}`}>
        <Container style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Grid className={classes.form} container spacing={2} sx={{ fontFamily: "Roboto, sans-serif" }}>
            <Grid item xs={12} md={4} style={{marginTop: "10px", display: "flex", flexDirection: "column"}}>
              <Grid item  className={classes.logo} style={{width: "100%"}}>
                <img src={logo} className="logo" 
                  // height="60px" 
                  width="100%"
                  maxWidth="100%" 
                  alt="Logo" 
                />
              </Grid>
              <Grid item style={{backgroundColor: "#F0F0F0", borderRadius: "10px", padding: "10px", marginTop: "10px", flex: "1"}}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="h6">Experience CloudCatcher in action</Typography>
                  </Grid>
                </Grid>
                <Stepper orientation="vertical" activeStep={activeStep} >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        {getStepLabel(index)}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item style={{ marginTop: "20px" }}>
                <Typography variant="body2" align="center">
                    Need help? <a href="mailto:support.cloudcatcher@cloudnovatech.com" style={{textDecoration: 'none'}}>Contact Support</a>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              {activeStep === steps.length ? (
                <Box sx={{ mt: 2, display: "flex", minHeight: "200px", flexDirection: "column", alignItems: "center" }}>
                  <Grid item xs={12} md={8} sx={{marginBottom: "2rem"}}>
                    <CheckCircleOutlineIcon sx={{ fontSize: 40, color: "green" }} />
                  </Grid>
                  <Grid item xs={12} md={8} sx={{marginBottom: "2rem"}}>
                    <Typography variant="body2" align="center">
                      Organization has been created successfully. Please check your email for the activation Link!
                    </Typography>                    
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Button
                      variant="contained"
                      onClick={() => history.push("/login")}
                      fullWidth
                    >
                      Click here to Login
                    </Button>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ mt: 2, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Formik
                    initialValues={initialFormData}
                    validationSchema={validationSchema}
                  >
                    {
                      ({values, errors, isValid, isSubmitting, touched, handleSubmit, handleChange, handleBlur}) => {

                        return (
                          <>
                            { activeStep === 0 && (
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="firstName"
                                    label="First Name *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="lastName"
                                    label="Last Name *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="email"
                                    label="Email *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.email}
                                    onChange={ (e) => { handleChange(e); setEmailApiValidationDone(false); } }
                                    // onChange={handleChange}
                                    onBlur={(e) => { handleBlur(e); validateEmail(values.email); }}
                                    error={touched.email && (Boolean(errors.email) || Boolean(emailApiValidationError)) }
                                    helperText={touched.email && (errors.email || emailApiValidationError)}
                                    InputProps={{
                                      endAdornment: emailApiValidationStatus && (
                                        <InputAdornment position="end">
                                          <IconButton size="small">
                                            <CheckCircleOutline style={{ color: 'green' }} />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <PhoneInput
                                      fullWidth
                                      style={{marginTop: "8.5px"}}
                                      className={!phonenumberError? "phone-input": "phone-input invalid"}
                                      required
                                      name="phonenumber"
                                      placeholder="Phone Number *"
                                      maxLength={16}
                                      value={phonenumber}
                                      onChange={handlePhoneChange}
                                      onBlur={handlePhoneBlur}
                                    />
                                    {phonenumberError 
                                      ? (<div style={{fontSize: "80%", marginTop: "0.25rem", color: "#d32f2f", marginLeft: "14px"}}>{phonenumberError}</div>) 
                                      : null
                                    }
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <TextField
                                    fullWidth
                                    name="address"
                                    label="Work Address *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.address && Boolean(errors.address)}
                                    helperText={touched.address && errors.address}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="city"
                                    label="City *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.city && Boolean(errors.city)}
                                    helperText={touched.city && errors.city}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="state"
                                    label="State/Province *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.state && Boolean(errors.state)}
                                    helperText={touched.state && errors.state}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <FormControl fullWidth error={touched.country && Boolean(errors.country)} style={{marginTop: "8.5px"}}>
                                    <InputLabel id="country-select-label">Country</InputLabel>
                                    <Select
                                      name="country"
                                      label="Country *"                                      
                                      variant="outlined"
                                      margin="dense"
                                      size="small"
                                      labelId="country-select-label"
                                      value={values.country}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // helperText={ touched.country && errors.country }
                                    >
                                      {countryList.map((country) => {
                                        return (
                                          <MenuItem key={country} value={country}>
                                            {country}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    <FormHelperText>{ touched.country && errors.country }</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="zip"
                                    label="Zip code *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.zip}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.zip && Boolean(errors.zip)}
                                    helperText={touched.zip && errors.zip}
                                  />
                                </Grid>                                

                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="password"
                                    label="Create Password *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.password}
                                    type={showPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                          >
                                            {showPassword ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.confirmPassword}
                                    type={showConfirmPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                          >
                                            {showConfirmPassword ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            { activeStep === 1 && (
                              <Grid container spacing={1}>
                                <Grid item xs={12} md={12}>
                                  <Typography fontSize="0.9rem" gutterBottom>
                                    <Chip 
                                      label="2a: Enter your AWS Account Number. Your AWS account name will be your account name on CloudCatcher." 
                                      color="primary"
                                      variant="outlined"
                                    /> 
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="accountNumber"
                                    label="AWS Account Number *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.accountNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.accountNumber && Boolean(errors.accountNumber)}
                                    helperText={touched.accountNumber && errors.accountNumber}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: "80px" }}>
                                  <TextField
                                    fullWidth
                                    name="accountName"
                                    label="AWS Account Name"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.accountName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.accountName && Boolean(errors.accountName)}
                                    helperText={touched.accountName && errors.accountName}
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Typography  fontSize="0.9rem" gutterBottom>
                                    <Tooltip title="Utilize either the Manual Pairing or CloudFormation method to pair your account.">
                                      <Chip
                                        label="2b: Account Pairing"
                                        color="primary"
                                        variant="outlined"
                                        icon={
                                          <IconButton size="small">
                                            <InfoIcon />
                                          </IconButton>
                                        }
                                      />
                                    </Tooltip>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  {/* <Typography>Account Pairing</Typography> */}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    variant="contained"
                                    onClick={handleManualPairing}
                                    fullWidth
                                  >
                                    Launch Manual Pairing
                                  </Button>
                                  <Button
                                    onClick={handleManualPairingGuide}
                                    disabled={pairingGuideLoading}
                                    fullWidth
                                  >
                                    Manual Pairing Guide
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={1} container justifyContent="center" alignItems="center">
                                  <Typography>OR</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    variant="contained"
                                    onClick={handleCloudFormation}
                                    disabled={cloudFormationLoading}
                                    fullWidth
                                  >
                                    CloudFormation Script
                                  </Button>
                                  <Button
                                    onClick={handleCloudFormationGuide}
                                    disabled={cloudFormationGuideLoading}
                                    fullWidth
                                  >
                                    CloudFormation Guide
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <Typography  fontSize="0.9rem" gutterBottom>
                                    {/* <Chip label="2c" color="primary" /> {" "} */}
                                    <Chip label="2c: Enter your IAM Role Name." 
                                      color="primary" 
                                      variant="outlined"
                                    />                                    
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    fullWidth
                                    name="iamrole"
                                    label="IAM Role Name *"
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    value={values.iamrole}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.iamrole && Boolean(errors.iamrole)}
                                    helperText={touched.iamrole && errors.iamrole}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Tooltip title="Enter the name of the new cross-account IAM role that was created during the account pairing steps.">
                                            <InfoIcon color="primary"/>
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                      label="External ID"
                                      name="externalId"
                                      variant="outlined"
                                      margin="dense"
                                      value={externalId}
                                      size="small"
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Tooltip title={copy ? "Copied" : "Click to copy"}>
                                              <IconButton onClick={handleClickExternalId}>
                                                <ContentCopy color={copy ? "primary" : ""} />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Use this External ID when creating the IAM Role">
                                              <InfoIcon color="primary" />
                                            </Tooltip>
                                          </InputAdornment>
                                        ),
                                      }}
                                      fullWidth
                                    />
                                </Grid>
                              </Grid>                        
                            )}
                            <Box sx={{ display: "flex", paddingTop: "1rem", justifyContent: activeStep === 1 ? "space-between" : "flex-end", mt: 2 }}>
                              {activeStep !== 0 && (
                                <Button variant="contained" disabled={activeStep === 0} onClick={handleBack}>
                                  Back
                                </Button>
                              )}
                              <Button 
                                variant="contained"
                                onClick={() => handleNext(values)}
                                disabled={
                                  ( activeStep === 0 && isUserDetailsInvalid(values, errors) ) ||
                                  ( activeStep === 0 && emailApiValidationLoading ) ||                                  
                                  ( activeStep === 1 && isSendTrialLoading) ||
                                  ( activeStep === 1 && isAccountDetailsInvalid(values, errors))
                                  // ( activeStep === 1 && ( !isPairingVerified ))
                                }
                              >
                                {activeStep === steps.length - 1 ? "Submit" : "Next"}
                              </Button>
                            </Box>
                          </>
                        )
                      }
                    }
                  </Formik>
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
        { pairingGuideUrl && (
          <UrlDisplayDialog 
          url={pairingGuideUrl}
          pageTitle="Manual Pairing Guide"
          isOpen={pairingGuideModalOpen}
          setOpen={setPairingGuideModalOpen}
          clearUrl={() => {}}
        />
        )}
        { cloudFormationGuideUrl && (
          <UrlDisplayDialog 
          url={cloudFormationGuideUrl}
          pageTitle="CloudFormation Guide"
          isOpen={cloudFormationGuideModalOpen}
          setOpen={setCloudFormationGuideModalOpen}
          clearUrl={() => {}}
        />
        )}
        <BackgroundPattern />
      </div>
    </SnackbarProvider>
  );
}

const steps = ["User Details", "Account Details"];
function getStepLabel(step) {
  switch (step) {
    case 0:
      return (
        <>
          Please provide your user information.
        </>
      );
    case 1:
      return (
        <>
          Please provide your AWS account information.
        </>
      );
    default:
      return "Unknown step";
  }
}


export default FreeTrial;