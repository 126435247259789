export const REGISTER_ORG = 'register_org';
export const REGISTER_ORG_SUCCESSFUL = 'register_org_successfull';
export const REGISTER_ORG_FAILED = 'register_org_failed';
export const VALIDATE_TOKEN = 'validate_token';
export const VALIDATE_TOKEN_SUCCESSFUL = 'validate_token_successful';
export const VALIDATE_TOKEN_FAILED = 'validate_token_failed';
export const RESEND_ACTIVATE_EMAIL = 'resend_activate_email';
export const RESEND_ACTIVATE_EMAIL_SUCCESSFUL = 'resend_activate_email_successfull';
export const RESEND_ACTIVATE_EMAIL_FAILED = 'resend_activate_email_failed';
export const RESET_PASSWORD = "resetpasswd"
export const RESET_PASSWORD_SUCCESSFUL= 'resetpasswd_successfull';
export const RESET_PASSWORD_FAILED= 'resetpasswd_failed';

export const PUT_MAILING_INFO = 'put_mailing_info';
export const SET_MAILING_INFO = 'sut_mailing_info';
export const PUT_BILLING_INFO = 'put_billing_info';
export const SUT_BILLING_INFO = 'sut_billing_info';

export const CLEAR_ALL_ORGANIZATION_MESSAGES = "clear_all_organization_messages";
export const CLEAR_ALL_ORGANIZATION_MESSAGES_SUCCESSFUL =
  "clear_all_organization_messages_successful";