import { 
	GET_ORGS_NAME_DATA,
 GET_ORGS_NAME_DATA_SUCCESSFUL,
 GET_ORGS_NAME_DATA_FAILED 
} from './actionTypes';

const initialState = {
	error: null, message: null, loading: true
}

const header = (state = initialState, action) => {
	switch (action.type) {
		case GET_ORGS_NAME_DATA:
			state = {
				loading: true,
				error: null
			}
			break;
		case GET_ORGS_NAME_DATA_SUCCESSFUL:
			state = {
				...action.payload,
				loading: false,
				error: null
			}
			break;
		case GET_ORGS_NAME_DATA_FAILED:
			state = {
				loading: false,
				error: action.payload ? action.payload.response.data : null
			}
			break;
		default:
			return state;
	}
	return state;
}

export default header;