import React, { useState } from 'react';

import {
  Button,
  TextField
} from "@material-ui/core";

const VerificationMfaCode = ({ onVerify, loading, loginError }) => {
  const [mfaCode, setMfaCode] = useState('');

  const handleChange = (event) => {
    setMfaCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onVerify(mfaCode); // Call the provided verification function
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="MFA Code"
        variant="outlined"
        name="verificationMfaCode"
        value={mfaCode}
        type="number"
        id="verificationMfaCode"
        placeholder="Enter MFA Code"
        onChange={handleChange}
        required
        // fullWidth
        validate={{
          required: {
            value: true,
            errorMessage: "Please enter verification MFA Code",
          },
        }}
      />
      <br/>
      <br/>
      <br/>
      <Button
        variant="contained"
        color="primary"
        // className={classes.submit}
        type="submit"
        disabled={loading}
      >
        Verify
      </Button>
    </form>
  );
};

export default VerificationMfaCode;
