import {
    GET_ORGS_NAME_DATA,
 GET_ORGS_NAME_DATA_SUCCESSFUL,
 GET_ORGS_NAME_DATA_FAILED 
  } from './actionTypes';
  
  export const getOrgsNameData = (data) => {
    return {
      type:  GET_ORGS_NAME_DATA,
      payload: { data }
    }
  }
  
  export const getOrgsNameDataSuccessful = (data) => {
    return {
      type: GET_ORGS_NAME_DATA_SUCCESSFUL,
      payload: { data }
    }
  }
  
  export const getOrgsNameDataFailed = (data) => {
    return {
      type: GET_ORGS_NAME_DATA_FAILED,
      payload: data
    }
  }
  