import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalBody, Modal, Button, ModalFooter } from "reactstrap";


const DirtyRedirectCheckHoc = ({ children, isParentElement }) => {
    const [isDirtyAlert, setDirtyAlert] = useState(false)
    const [targetLink, setTargetLink] = useState()
    const history = useHistory();

    const handleRedirectLink = (e) => {
      const isDirty = sessionStorage.getItem("isDirty", "true");
      let targetLink;
      targetLink = e.target.link;
      if(!targetLink){
        targetLink = e.target.getAttribute("link");
      }

    //   if (!targetLink) {
    //       targetLink = e.target.parentElement.getAttribute('link');
    //   }

    //   console.log("--== DirtyRedirectCheckHoc <::::> targetLink ", targetLink);
      if (targetLink && isDirty) {
        e.preventDefault();
        e.stopPropagation();
        setDirtyAlert(true);
        setTargetLink(targetLink);
      }
    }

    const continueRedirectLink = (e) => {
        sessionStorage.removeItem('isDirty');
        history.push(targetLink);
        setDirtyAlert(false)
        setTargetLink(null)
        e.preventDefault();
        e.stopPropagation();
    }

    const cancelRedirectLink = (e) => {
        setDirtyAlert(false)
        e.preventDefault();
        e.stopPropagation();
    }
    return (
        <div onClick={handleRedirectLink}>
            {children}

            <Modal isOpen={isDirtyAlert} backdrop="static" centered size="md">
                <ModalBody>
                    <div className="d-flex flex-column align-items-center">
                        <div>It looks like you have been editing something.</div>
                        <div>If you leave before saving, your changes will be lost.</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => continueRedirectLink(e)} size="sm">
                        Leave
                    </Button>
                    <Button color="secondary" onClick={(e) => cancelRedirectLink(e)} size="sm">
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}


export default DirtyRedirectCheckHoc;