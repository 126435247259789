import { createSlice } from "@reduxjs/toolkit";

export const kibanaDashboardsSlice = createSlice({
  name: "kibanaDashboards",
  initialState: {
    isLoading: false,
    dashboards: [],
    errorMessage: null,
  },
  reducers: {
    fetchKibanaDashboards: (state, action) => {
      state.isLoading = true;
      state.dashboards = [];
      state.errorMessage = null;
    },
    fetchKibanaDashboardsSuccess: (state, action) => {
      console.log("🚀 ~ action fetchKibanaDashboardsSuccess:", action)
      state.isLoading = false;
      state.dashboards = Object.entries(action.payload).map(([key, value]) => ({ key, value }));
      state.errorMessage = null;
    },
    fetchKibanaDashboardsFailure: (state, action) => {
      state.isLoading = false;
      state.dashboards = [];
      state.errorMessage = action.payload.message;
    },
    clearKibanaDashboardsState: (state) => {
      state.isLoading = false;
      state.dashboards = [];
      state.errorMessage = null;
    },
  }
});

export const {
  fetchKibanaDashboards,
  fetchKibanaDashboardsSuccess,
  fetchKibanaDashboardsFailure,
  clearKibanaDashboardsState,
} = kibanaDashboardsSlice.actions;

export default kibanaDashboardsSlice.reducer;
