import accessToken from "./accessToken";
import jwtDecode from "jwt-decode";

const getUserRole = () => {
  const token = accessToken();
  if (token) {
    const decoded = jwtDecode(token);
    const userName = decoded["email"];
    const userRole = decoded["cognito:groups"][0];
    return userRole;
  }
  return "NONE";
}

export default getUserRole;