import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { ECS, TaskDefinition } from "./ECSDashboards";

const EcsDashboard = () => {
  const [activeTab, setActiveTab] = useState("ECS");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "ECS":
        return <ECS />;
      case "TaskDefinition":
        return <TaskDefinition />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="ECS" value="ECS" />
        <Tab label="Task Definition" value="TaskDefinition" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default EcsDashboard;
