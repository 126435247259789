import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  UPLOAD_FILE_REQUEST,
  SEND_FILE_COMMIT_REQUEST,
  DOWNLOAD_FILE_REQUEST,
  GET_UPLOAD_FILE_URL_REQUEST,
  GET_IAAC_METRICS_REQUEST,
  GET_IAAC_FILE_HISTORY_REQUEST,
  CLEAR_ALL_IAAC_MESSAGES,
} from "./actionsTypes";

import {
  uploadFileSuccessful,
  uploadFileFailed,
  downloadFileSuccessful,
  downloadFileFailed,
  getUploadFileUrlSuccessful,
  getUploadFileUrlFailed,
  sendFileCommitSuccessful,
  sendFileCommitFailed,
  getIaacMetricsSuccessful,
  getIaacMetricsFailed,
  getIaacFileHistorySuccessful,
  getIaacFileHistoryFailed,
  clearAllIaacMessagesSuccessful,
} from "./actions";

import {
  getIaacMetrics as getIaacMetricsApi,
  getUploadFileUrl as getUploadFileUrlApi,
  postUploadFile as postUploadFileApi,
  postFileCommitDetails as postFileCommitDetailsApi,
  getIaacFileHistory as getIaacFileHistoryApi,
  getDownloadFile as getDownloadFileApi,
} from "../../helpers/auth_aws_helper";

function* uploadFile( { payload }) {
  try {
    const response = yield call(postUploadFileApi, payload.fileData, payload.uploadUrl);
    console.log("~~~ postUploadFile Response: ", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(uploadFileSuccessful(response));
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(uploadFileFailed({message: message}));
    }
  } catch( error ) {
    console.log("~~~ uploadFile, received Error is :: ", JSON.stringify(error));
    if(error.response) {
      yield put(uploadFileFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if (error.request){
      yield put(uploadFileFailed({message: "No response is received"}))
    } else {
      yield put(uploadFileFailed({message: error?.message}));
    }  
  }
}

function* sendFileCommitDetails({
  payload: {
    orgcode,
    filename,
    username,
    author,
    projectName,
    branch,
    version,
    comment,
    uuid
  },
}) {
  try {
    const response = yield call(
      postFileCommitDetailsApi,
      orgcode,
      filename,
      username,
      author,
      projectName,
      branch,
      version,
      comment,
      uuid,
    );
    console.log("~~~ postFileCommitDetails Response: ", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(sendFileCommitSuccessful(response));
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(sendFileCommitFailed({ message: message }));
    }
  } catch (error) {
    console.log(
      "~~~ postFileCommitDetails, received Error is :: ",
      JSON.stringify(error)
    );
    if (error.response) {
      yield put(
        sendFileCommitFailed({message: "Error Code: " + error.response?.status + ", Message: " +error.response?.data,})
      );
    } else if (error.request) {
      yield put(sendFileCommitFailed({ message: "No response is received" }));
    } else {
      yield put(sendFileCommitFailed({ message: error?.message }));
    }
  }
}

function* downloadFile( { payload: { fileId, fileType } }) {
  try {
    const response = yield call(getDownloadFileApi, fileId, fileType);
    console.log("~~~ downloadFile Response: ", response);
    if (response && response?.status === 200) {
      delete response.status;
      console.log("creating url from response");
      const responseArray = Object.values(response);
      console.log("Object.values(response) = ", responseArray);
      const downloadFileUrl = responseArray.toString().replaceAll(',','');
      console.log("downloadFileUrl = ", downloadFileUrl);
      yield put(downloadFileSuccessful(downloadFileUrl));
    } 
    // else if (response){
    //   const downloadFileUrl = ''.join(response.values());
    //   if(downloadFileUrl.startsWith('https')){
    //     yield put(downloadFileSuccessful(downloadFileUrl));
    //   }
    // } 
    else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(downloadFileFailed({message: message}));
    }  
  } catch( error ) {
    console.log("~~~ downloadFile, received Error is :: ", JSON.stringify(error));
    if(error.response) {
      yield put(downloadFileFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if (error.request){
      yield put(downloadFileFailed({message: "No response is received"}))
    } else {
      yield put(downloadFileFailed({message: error?.message}));
    }  
  }
}

function* getUploadFileUrl({ payload: { fileName, orgcode, username, uuid } }) {
  try {
    const response = yield call(getUploadFileUrlApi, fileName, orgcode, username, uuid);
    console.log("~~~ getUploadFileUrl Response: ", JSON.stringify(response));
    if (response && response.status === 200) {
      delete response.status;
      yield put(getUploadFileUrlSuccessful(response));
    } else {
      console.log("getUploadFileUrl, response", JSON.stringify(response));
      let message;
      if (response?.data?.message) {
        message = response?.data?.message;
      } else {
        message = response?.data;
      }
      yield put(getUploadFileUrlFailed({ message: message }));
    }
  } catch (error) {
    console.log(
      "~~~ getUploadFileUrl, received Error is :: ",
      JSON.stringify(error)
    );
    if (error.response) {
      yield put(
        getUploadFileUrlFailed({
          message:
            "Error Code: " +
            error.response?.status +
            ", Message: " +
            error.response?.data,
        })
      );
    } else if (error.request) {
      yield put(getUploadFileUrlFailed({ message: "No response is received" }));
    } else {
      yield put(getUploadFileUrlFailed({ message: error?.message }));
    }
  }
}

function* getIaacMetrics({ payload: { orgcode } }) {
  try {
    const response = yield call(
      getIaacMetricsApi, orgcode
    );
    console.log("~~~ getIaacMetrics Response: ", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(getIaacMetricsSuccessful(response));
    } else {
      let message;
      if (response?.data?.message) {
        message = response?.data?.message;
      } else {
        message = response?.data;
      }
      yield put(getIaacMetricsFailed({ message: message }));
    }
  } catch (error) {
    console.log(
      "~~~ getIaacMetrics, received Error is :: ", error
    );
    if (error.response) {
      yield put(
        getIaacMetricsFailed({
          message:
            "Error Code: " +
            error.response?.status +
            ", Message: " +
            error.response?.data,
        })
      );
    } else if (error.request) {
      yield put(getIaacMetricsFailed({ message: "No response is received" }));
    } else {
      yield put(getIaacMetricsFailed({ message: error?.message }));
    }
  }
}

function* getIaacFileHistory({ payload: { orgcode } }) {
  try {
    const response = yield call(getIaacFileHistoryApi, orgcode);
    console.log("~~~ getIaacFileHistory Response: ", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(getIaacFileHistorySuccessful(Object.values(response)));
    } else {
      let message;
      if (response?.data?.message) {
        message = response?.data?.message;
      } else {
        message = response?.data;
      }
      yield put(getIaacFileHistoryFailed({ message: message }));
    }
  } catch (error) {
    console.log("~~~ getIaacFileHistory, received Error is :: ", error);
    if (error.response) {
      yield put(
        getIaacFileHistoryFailed({
          message:
            "Error Code: " +
            error.response?.status +
            ", Message: " +
            error.response?.data,
        })
      );
    } else if (error.request) {
      yield put(getIaacFileHistoryFailed({ message: "No response is received" }));
    } else {
      yield put(getIaacFileHistoryFailed({ message: error?.message }));
    }
  }
}

function* clearAllIaacMessages() {
  console.log("Saga: clear All Iaac Messages");
  yield put(clearAllIaacMessagesSuccessful());
}

function* watchUploadFileRequest() {
  yield takeEvery(UPLOAD_FILE_REQUEST, uploadFile);
}

function* watchFileCommitRequest() {
  yield takeEvery(SEND_FILE_COMMIT_REQUEST, sendFileCommitDetails);
}


function* watchDownloadFileRequest() {
  yield takeEvery(DOWNLOAD_FILE_REQUEST, downloadFile);
}

function* watchGetUploadUrlRequest() {
  yield takeEvery(GET_UPLOAD_FILE_URL_REQUEST, getUploadFileUrl);
}

function* watchGetIaacMetricsRequest() {
  yield takeEvery(GET_IAAC_METRICS_REQUEST, getIaacMetrics);
}

function* watchGetIaacFileHistoryRequest() {
  yield takeEvery(GET_IAAC_FILE_HISTORY_REQUEST, getIaacFileHistory);
}

function* watchClearAllIaacMessages() {
  yield takeEvery(CLEAR_ALL_IAAC_MESSAGES, clearAllIaacMessages);
}

function* iaacSaga() {
  yield all([fork(watchUploadFileRequest)]);
  yield all([fork(watchFileCommitRequest)]);
  yield all([fork(watchDownloadFileRequest)]);
  yield all([fork(watchGetUploadUrlRequest)]);
  yield all([fork(watchGetIaacMetricsRequest)]);
  yield all([fork(watchGetIaacFileHistoryRequest)]);
  yield all([fork(watchClearAllIaacMessages)]);
}

export default iaacSaga;