import {
  GET_LIST_OF_USERS,
  GET_LIST_OF_USERS_SUCCESSFUL,
  GET_LIST_OF_USERS_FAILED,
  RESET_NEW_USER,
  POST_NEW_USER,
  POST_EDIT_USER,
  GET_REACTIVE_USER,
  SET_REACTIVE_USER,
  DELETE_USER,
  POST_EDIT_USER_SUCCESSFUL,
  POST_EDIT_USER_FAILED,
  POST_NEW_USER_FAILED,
  POST_NEW_USER_SUCCESSFUL,
  CLEAR_ALL_USER_LIST_MESSAGES,
  CLEAR_ALL_USER_LIST_MESSAGES_SUCCESSFUL,
  DELETE_USER_SUCCESSFUL,
  DELETE_USER_FAILED
} from './actionTypes';

export const addNewUser = (user) => {
  return {
    type: POST_NEW_USER,
    payload: { user }
  }
}

export const addNewUserSuccessful = (addUserResponse) => {
  return {
    type: POST_NEW_USER_SUCCESSFUL,
    payload: { addUserResponse },
  };
};

export const addNewUserFailed = (error) => {
  return {
    type: POST_NEW_USER_FAILED,
    payload: { error }
  };
};

export const editUser = (user) => {
  return {
    type: POST_EDIT_USER,
    payload: { user },
  };
}

export const editUserSuccessful = (editUserResponse) => {
  return {
    type: POST_EDIT_USER_SUCCESSFUL,
    payload: { editUserResponse },
  };
};

export const editUserFailed = (error) => {
  return {
    type: POST_EDIT_USER_FAILED,
    payload: { error },
  };
};

export const reActive = (payload) => {
  return {
    type: GET_REACTIVE_USER,
      payload
  }
}

export const deleteUser = (payload) => {
  return {
    type: DELETE_USER,
      payload
  }
}

export const deleteUserSuccessful = (deleteUserResponse) => {
  return {
    type: DELETE_USER_SUCCESSFUL,
    payload: { deleteUserResponse },
  };
};

export const deleteUserFailed = (error) => {
  return {
    type: DELETE_USER_FAILED,
    payload: { error },
  };
};

export const updateReActive = (payload) => {
  return {
    type: SET_REACTIVE_USER,
      payload
  }
}


export const resetNewUser = (payload) => {
  return {
    type: RESET_NEW_USER,
      payload
  }
}

// export const postAddUserResponse = (payload) => {
//   return {
//     type: POST_NEW_USER_RESPONSE,
//       payload
//   }
// }


export const getListOfUsers = (usr) => {
  return {
      type: GET_LIST_OF_USERS,
      payload: { usr }
  }
}

export const getListOfUsersSuccessful = (usr) => {
  return {
      type: GET_LIST_OF_USERS_SUCCESSFUL,
      payload: { usr }
  }
}

export const getListOfUsersFailed = (usr) => {
  return {
      type: GET_LIST_OF_USERS_FAILED,
      payload: { usr }
  }
}

export const clearAllUserListMessages = () => {
  return {
    type: CLEAR_ALL_USER_LIST_MESSAGES,
    payload: {},
  };
};

export const clearAllUserListMessagesSuccessful = () => {
  return {
    type: CLEAR_ALL_USER_LIST_MESSAGES_SUCCESSFUL,
    payload: {},
  };
};