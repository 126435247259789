import {
    PUSH_NOTIFICATION_DATA,
    POP_NOTIFICATION_DATA,
} from './actionTypes';

const initialState = []

const licensing = (state = initialState, action) => {
    switch (action.type) {
        case PUSH_NOTIFICATION_DATA:
            const { message, fullmessage,className, amend } = action.payload;
            const entity = { id: new Date().getTime(), message, fullmessage, className };
            return amend ? [
                ...state,
                entity
            ] : [entity]
        case POP_NOTIFICATION_DATA:
            return state.filter((item) => item.id === action.payload)
        default:
            return state;
    }
}

export default licensing;