export const GET_LIST_OF_USERS = 'get_list_of_users';
export const GET_LIST_OF_USERS_SUCCESSFUL = 'get_list_of_users_successful';
export const GET_LIST_OF_USERS_FAILED = 'get_list_of_users_failed';

export const RESET_NEW_USER = 'RESET_NEW_USER';

export const POST_EDIT_USER = 'POST_EDIT_USER';
export const POST_EDIT_USER_SUCCESSFUL = "post_edit_user_successful";
export const POST_EDIT_USER_FAILED = "post_edit_user_failed";

export const POST_NEW_USER = 'post_new_user';
export const POST_NEW_USER_SUCCESSFUL = "post_new_user_successful";
export const POST_NEW_USER_FAILED = "post_new_user_failed";
// export const POST_NEW_USER_RESPONSE = 'POST_NEW_USER_RESPONSE';

export const CLEAR_ALL_USER_LIST_MESSAGES = "clear_all_user_list_messages";
export const CLEAR_ALL_USER_LIST_MESSAGES_SUCCESSFUL =
  "clear_all_user_list_messages_successful";

export const GET_REACTIVE_USER = 'GET_REACTIVE_USER';
export const SET_REACTIVE_USER = 'SET_REACTIVE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESSFUL = "DELETE_USER_SUCCESSFUL";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
