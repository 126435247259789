import React, { useState, useEffect } from 'react';
import styles from "./style.module.css";

import { Box, LinearProgress, Skeleton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import CastConnectedIcon from '@mui/icons-material/CastConnected';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const NO_STEPS = 4;

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const IaaCScanner = ({
    fileName, handleModalOpen, 
    webSockConnectionStatus, setWebSockConnectionStatus, 
    scanStatus, setScanStatus}) => {
    const [messages, setMessages] = useState([]);
    const IAAC_SCAN_WEBSOCKET_URL = process.env.REACT_APP_IAAC_SCAN_WEBSOCKET_URL;
    const [accessToken, setAccessToken] = useState(null);

    // get accesstoken from local storage
    useEffect(() => {
        let accessTokenFromLocalStorage;
        if (localStorage.getItem("authUser")) {
            const authUser = JSON.parse(localStorage.getItem("authUser"));
            accessTokenFromLocalStorage = authUser.accessToken;
            console.log("accessTokenFromLocalStorage=", accessTokenFromLocalStorage);
        }
        if (!accessToken) {
            if (accessTokenFromLocalStorage) {
                setAccessToken(accessTokenFromLocalStorage);
            }
        }
    }, []);


    useEffect(() => {
        let ws;
        if(fileName && accessToken){
            console.log("fileName = ",fileName);
            // ws = new WebSocket("wss://socketsbay.com/wss/v2/1/demo/"); // Replace with your API Gateway WebSocket URL
            ws = new WebSocket(IAAC_SCAN_WEBSOCKET_URL+"?token="+accessToken); // Replace with your API Gateway WebSocket URL
            console.log("Connecting to "+IAAC_SCAN_WEBSOCKET_URL+"?authorization="+accessToken);
            ws.onopen = () => {
                console.log('Connected to the server');
                setWebSockConnectionStatus(true);
                console.log("~~~~~ fileName = ",fileName);
                ws.send(JSON.stringify({"action":"sendMessage", "fileName":fileName}));
            };
            ws.onmessage = (event) => {
                const messageData = JSON.parse(event.data);
                const newMessage = {
                    text: messageData.Message,
                    status: messageData.Status,
                    isReceived: true,
                    timestamp: new Date().toLocaleTimeString(), // Get current timestamp
                };
                setMessages((prevMessages)=>[...prevMessages, newMessage]); // Update the component's state with the received data

                // Close the modal if scan was completed successfully
                const searchText = "Scanning Complete";
                if (newMessage.text.toLowerCase().includes(searchText.toLowerCase())) {
                    console.log("Scan is complete!");
                    enqueueSnackbar("Scanning Complete!", {variant: "success"});
                    enqueueSnackbar("This modal will get closed in 10 seconds and you would be redirected to main page automatically!", {variant: "success"});
                    setTimeout(()=> {
                        handleModalOpen(false);
                        setScanStatus(true);
                    }, 10000);                    
                }
            };
    
            ws.onclose = () => {
                console.log('WebSocket closed');
                if(webSockConnectionStatus){
                    setWebSockConnectionStatus(null);
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                setWebSockConnectionStatus(false);
                enqueueSnackbar("Close the modal and try again!", {variant: "error"});
                // Perform actions when WebSocket connection cannot be established
                // For example, show an error message or retry the connection.
            };
            return () => {
                ws.close();
                setWebSockConnectionStatus(null);
            };    
        }
    }, [fileName, accessToken]);

    return (
        <div className={styles.websocketScannerContainer}>
            <SnackbarProvider />
            <div className={styles.scanHeader}>
                <h2 className={styles.header}>IaaC Scan Status</h2>
                <div>
                    {/* Display status of websocket connection */}
                    { webSockConnectionStatus!==null? 
                        (   webSockConnectionStatus? 
                            <CastConnectedIcon style={{ color: 'green' }}/>: 
                            <CastConnectedIcon style={{ color: 'red' }}/>
                        ): 
                        (<CastConnectedIcon style={{ color: 'gray' }}/>)
                    }
                </div>
            </div>
            {webSockConnectionStatus===false?
                (<div>Websocket connection could not be established</div>):
                ( webSockConnectionStatus ? 
                    (<>
                        <LinearProgressWithLabel value={messages?(messages.filter(message=>message.status==="Success").length*100/NO_STEPS):0} />    
                
                        <div className={styles.messageListContainer}>
                            <Stepper orientation="vertical" activeStep={messages?.length}>
                                {messages.map((message, index) => (
                                    <Step key={index} >
                                        <StepLabel error={message.status==="Error"}>
                                            <span className={styles['messageTimestamp']}>{message.timestamp}</span>
                                            {message.text}
                                        </StepLabel>
                                    </Step>
                                ))}
                                {(!messages || (messages.length<NO_STEPS && !messages.some(message => message.status==="Error"))) && (
                                    <Step key={1000}>
                                        <StepLabel>
                                            <Skeleton variant="rectangular" width="100%" height={30} />
                                        </StepLabel>
                                    </Step>
                                    )
                                }
                            </Stepper>                    
                        </div>
                    </>): 
                    (null)                             
                )
            }
        </div>
    );
    
}

export default IaaCScanner;