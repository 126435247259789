import React from "react";
import { Box, Typography, IconButton, Divider } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useTheme } from "@mui/material/styles";

const DashboardCard = ({ title, children, icon, actions, chart, ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        padding: "8px 12px",
        // width: "280px",
        height: "320px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...props.sx,
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          mb: 1,
          paddingBottom: "2px",
          borderBottom: `1px solid`,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: "text.primary" }}>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          {actions && (
            actions.map((action, index) => (
              <IconButton
                key={index}
                aria-label={action.label}
                sx={{ color: theme.palette.neutral.main, fontSize: "20px" }}
                onClick={action.onClick}
              >
                {action.icon}
              </IconButton>
            ))
          )}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ alignSelf: 'center', height: '18px' }}
          />
          <IconButton
            aria-label="settings"
            className="drag-handle"
            sx={{ color: theme.palette.neutral.main, fontSize: "20px" }}
          >
            <DragIndicatorIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {/* Chart */}
      {chart && (
        <Box sx={{ flexGrow: 0, textAlign: 'center', mt: 1, mb: 2 }}>
          {chart}
        </Box>
      )}

      {/* Content */}
      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
          overflowY: "auto",
          pr: 1,
          mb: 1,
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: theme.palette.background.default,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.grey[400],
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.grey[500],
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DashboardCard;
