import {
  REGISTER_ORG_ACCOUNT,
  UPDATE_ORG_ACCOUNT,
  UPDATE_ORG_ACCOUNT_SUCCESSFUL,
  REGISTER_ORG_ACCOUNT_SUCCESSFUL,
  REGISTER_ORG_ACCOUNT_FAILED,
  UPDATE_ORG_ACCOUNT_FAILED,
  GET_LIST_OF_ORGS_AND_ACCOUNTS,
  GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL,
  GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED,
  RESET_ORG_ACCOUNT_STATUS,
  CLEAR_ALL_ORG_ACCOUNT_MESSAGES,
  CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL
} from "./actionTypes";

export const registerOrgAccount = (account) => {
    return {
        type: REGISTER_ORG_ACCOUNT,
        payload: { account }
    }
}

export const registerOrgAccountSuccessful = (registerOrgAccountResponse) => {
    return {
      type: REGISTER_ORG_ACCOUNT_SUCCESSFUL,
      payload: { registerOrgAccountResponse },
    };
}

export const registerOrgAccountFailed = (registerOrgAccountError) => {
    return {
        type: REGISTER_ORG_ACCOUNT_FAILED,
        payload: { registerOrgAccountError }
    }
}

export const updateOrgAccount = (account) => {
    return {
        type: UPDATE_ORG_ACCOUNT,
        payload: { account }
    }
}

export const updateOrgAccountSuccessful = (updateOrgAccountResponse) => {
  return {
    type: UPDATE_ORG_ACCOUNT_SUCCESSFUL,
    payload: { updateOrgAccountResponse },
  };
};

export const updateOrgAccountFailed = (updateOrgAccountError) => {
    return {
        type: UPDATE_ORG_ACCOUNT_FAILED,
        payload: { updateOrgAccountError }
    }
}

export const resetOrgAccountStatus = (account) => {
  return {
    // type: REGISTER_ORG_ACCOUNT_SUCCESSFUL,
    type: RESET_ORG_ACCOUNT_STATUS,
    payload: account,
  };
};


export const getListOfOrgsAndAccts = (accounts) => {
    return {
        type: GET_LIST_OF_ORGS_AND_ACCOUNTS,
        payload: { accounts }
    }
}

export const getListOfOrgsAndAcctsSuccessful = (accounts) => {
    return {
        type: GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL,
        payload: { accounts }
    }
}

export const getListOfOrgsAndAcctsFailed = (getListOfOrgsAndAcctsError) => {
    return {
        type: GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED,
        payload: { getListOfOrgsAndAcctsError }
    }
}


export const clearAllOrgAccountMessages = () => {
    return {
        type: CLEAR_ALL_ORG_ACCOUNT_MESSAGES,
        payload: {},
    };
}

export const clearAllOrgAccountMessagesSuccessful = () => {
    return {
        type: CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL,
        payload: {},
    };
}