import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import { Snackbar } from "@mui/material";
import { Alert } from "@material-ui/lab";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

import { connect } from "react-redux";
import { getListOfOrgs, getListOfUsers } from "../../store/actions";
import UpdateOrgAddress from "./UpdateOrgAddress";
import {
  resetPassword,
  resetPasswordSuccessful,
  clearAllOrganizationMessages,
} from "../../store/actions";
import "./settings.styles.css";

import getUserRole from '../../helpers/jwt-token-access/userRole';

const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]{8,}$/;
const passwordErrorMessage = "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";

class OrgDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultMsgModal: false,
      addressType: "",
      userName: null,
      password: "",
      confirmPassword: "",
      oldPassword: "",
      status: null,
      isModalOpen: false,
      showOldPassword: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
    };
  }

  componentDidMount() {
    this.props.getListOfOrgs();
    this.props.getListOfUsers();
    // document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
  }

  componentDidUpdate() {
    if (this.props.users) {
      if (this.state.userName == null) {
        this.setState({
          userName: this.props.users.userName,
        });
      }
    }
    if (this.props.status && this.state.password) {
      console.log("--== componentDidUpdate ", this.props);
      this.setState({
        password: "",
        confirmPassword: "",
        oldPassword: "",
      });
    }
    if (this.props.resetPasswordMessage) {
      if (this.state.isModalOpen) {
        this.setState({ isModalOpen: false });
        setTimeout(() => {
          console.log("About to call clearAllOrganizationMessages");
          this.props.clearAllOrganizationMessages();
        }, 10000); // Hide all messages after 10 seconds
      }
    }
  }

  closeResetSuccesmsg = () => {
    this.props.resetPasswordStatus();
  };

  handleInputChange = (e) => {
    // console.log("e.target.value = " + e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  closeModal = () => {
    // this.setState({ modal: false, addressType: null });
    this.setState({
      modal: false,
      isModalOpen: false,
      addressType: null,
      oldPassword: null,
      password: null,
      confirmPassword: null,
      showOldPassword: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
    });
    this.props.clearAllOrganizationMessages();
    this.props.getListOfOrgs();
  };

  handleClickShowOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword });
  };
  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };
  handleClickShowNewConfirmPassword = () => {
    this.setState({
      showNewConfirmPassword: !this.state.showNewConfirmPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  validatePassword = () => {
    this.form.validateInput("password");
  };

  validateConfirmPassword = () => {
    this.form.validateInput("confirmPassword");
  };

  isInvalid = () => {
    const pwdRegex = passwordRegex;
    const isInvalidItems = [
      {
        field: "oldPassword",
        isInvalid:
          this.state.oldPassword === null ||
          this.state.oldPassword === "" ||
          !(
            this.state.oldPassword.length >= 8
          ) ||
          !pwdRegex.test(this.state.oldPassword)
            ? true
            : false,
      },
      {
        field: "password",
        isInvalid:
          this.state.password === null ||
          this.state.password === "" ||
          !(
            this.state.password.length >= 8 
          ) ||
          !pwdRegex.test(this.state.password)
            ? true
            : false,
      },
      {
        field: "confirmPassword",
        isInvalid:
          this.state.confirmPassword === null ||
          this.state.confirmPassword === "" ||
          !(
            this.state.confirmPassword.length >= 8 
          ) ||
          !pwdRegex.test(this.state.confirmPassword)
            ? true
            : false,
      },
    ];
    const resp = isInvalidItems.filter((item) => item.isInvalid);
    // console.log("response = ", resp)

    return resp.length > 0;
  };

  render() {
    const userRole = getUserRole();
    return (
      <React.Fragment>
        {this.props.organizations &&
          this.props.organizations.map((org) => {
            return (
              <React.Fragment key={org.orgname}>
                <Row className="justify-content-center">
                  <Col xl={10}>
                    <div className="timeline mb-0" dir="ltr">
                      <div className="timeline-item timeline-left">
                        <div className="timeline-block">
                          <div className="time-show-btn mt-0">
                            <Link
                              to="#"
                              className="btn btn-info btn-blue btn-rounded w-lg"
                            >
                              {org.orgname}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md={3}>
                            <Media>
                              <div className="avatar-sm mr-3">
                                <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                  <span
                                    className="iconify font-size-24"
                                    data-icon="icons8:organization"
                                  ></span>
                                </span>
                              </div>
                              <Media
                                body
                                className="align-self-center overflow-hidden"
                              >
                                <h6>Organization Code</h6>
                                <p className="text-muted mb-0">{org.orgcode}</p>
                              </Media>
                            </Media>
                          </Col>
                          <Col md={3}>
                            <Media>
                              <div className="avatar-sm mr-3">
                                <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                  <i className="ri-user-line"></i>
                                </span>
                              </div>
                              <Media
                                body
                                className="align-self-center overflow-hidden"
                              >
                                <h6>Primary Contact</h6>
                                <p className="text-muted mb-0">
                                  {org.orgprimaryname}
                                </p>
                              </Media>
                            </Media>
                          </Col>
                          <Col md={3}>
                            <Media className="mt-4 mt-md-0">
                              <div className="avatar-sm mr-3">
                                <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                  <i className="ri-phone-line"></i>
                                </span>
                              </div>
                              <Media
                                body
                                className="align-self-center overflow-hidden"
                              >
                                <h6>Phone</h6>
                                <p className="text-muted mb-0">
                                  {org.phoneNumber}
                                </p>
                              </Media>
                            </Media>
                          </Col>
                          <Col md={3}>
                            <Media className="mt-4 mt-md-0">
                              <div className="avatar-sm mr-3">
                                <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                  <i className="ri-mail-line"></i>
                                </span>
                              </div>
                              <Media
                                body
                                className="align-self-center overflow-hidden"
                              >
                                <h6>E-mail</h6>
                                <p className="text-muted mb-0">
                                  {org.orgprimaryemail}
                                </p>
                              </Media>
                            </Media>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} sm={6}>
                    <Card className="border rounded shipping-address">
                      <div className="timeline address">
                        <CardBody>
                          {userRole === 'Administrator' ? (
                            <Link
                              to="#"
                              className="float-right ml-1"
                              onClick={() =>
                                this.setState({
                                  modal: true,
                                  addressType: "mailing",
                                })
                              }
                            >
                              Edit
                            </Link>
                          ) : (
                            <Tooltip title="Only Administrator can change address!" arrow>
                              <span className="float-right ml-1" style={{ color: 'lightgray' }}>
                                Edit
                              </span>
                            </Tooltip>
                          )}
                          <h5 className="font-size-14 mb-4">Mailing Address</h5>
                          <h5 className="font-size-14">{org.orgname}</h5>
                          <p className="mb-1">{org.orgaddress}</p>
                          <p className="mb-0">
                            {org.orgcity}, {org.orgstate}, {org.orgzip},{" "}
                            {org.orgcountry}
                          </p>
                        </CardBody>
                      </div>
                    </Card>
                  </Col>
                  <Col lg={4} sm={6}>
                    <Card className="border rounded shipping-address">
                      <div className="timeline address">
                        <CardBody>
                          {userRole === 'Administrator' ? (
                            <Link
                              to="#"
                              className="float-right ml-1"
                              onClick={() =>
                                this.setState({
                                  modal: true,
                                  addressType: "billing",
                                })
                              }
                            >
                              Edit
                            </Link>
                          ) : (
                            <Tooltip title="Only Administrator can change address!" arrow>
                              <span className="float-right ml-1" style={{ color: 'lightgray' }}>
                                Edit
                              </span>
                            </Tooltip>
                          )}
                          <h5 className="font-size-14 mb-4">Billing Address</h5>
                          <h5 className="font-size-14">{org.orgbillingname}</h5>
                          <p className="mb-1">{org.orgbillingaddress}</p>
                          <p className="mb-0">
                            {org.orgbillingcity}, {org.orgbillingstate},{" "}
                            {org.orgbillingzip}, {org.orgbillingcountry}
                          </p>
                        </CardBody>
                      </div>
                    </Card>
                  </Col>
                  <Col lg={4} sm={6}>
                    <Card className="border rounded shipping-address">
                      <div className="timeline address">
                        <CardBody>
                          {this.props.users ? (
                            <>
                              <h5 className="font-size-14 mb-4">
                                User Information
                              </h5>
                              <h5 className="font-size-14">
                                {this.props.users.firstName}{" "}
                                {this.props.users.lastName}
                              </h5>
                              <p className="mb-1">
                                <strong>Username:</strong>{" "}
                                {this.props.users.userName}
                              </p>
                              <p className="mb-0">
                                <strong>Email:</strong> {this.props.users.email}
                              </p>
                              {/* <p className="mb-1"> */}
                              <div className="row">
                                <div className="col-12">
                                  <Button
                                    type="button"
                                    style={{
                                      textAlign: "right",
                                      backgroundColor: "#E3712C",
                                      float: "left",
                                      marginTop: "20px",
                                    }}
                                    size="md"
                                    // onClick={() => this.props.resetPassword()}
                                    onClick={() =>
                                      this.setState({ isModalOpen: true })
                                    }

                                    // onClick={() => this.props.resetPassword({userName: this.props.users.userName })}
                                  >
                                    {" "}
                                    Change Password
                                  </Button>
                                </div>
                              </div>
                              {/* <Label> {this.state.status}</Label> */}

                              <Snackbar
                                open={this.props.resetPasswordMessage !== null}
                                autoHideDuration={5000}
                              >
                                <Alert severity="success">
                                  {this.props.resetPasswordMessage}
                                </Alert>
                              </Snackbar>

                              <>
                                <Modal
                                  isOpen={
                                    // this.props.resetPasswordMessage ? false : true
                                    this.state.isModalOpen
                                  }
                                  centered
                                >
                                  <ModalHeader>
                                    Change Account Password
                                  </ModalHeader>
                                  <ModalBody>
                                    <AvForm
                                      ref={(c) => (this.form = c)}
                                      className="form-horizontal"
                                    >
                                      {this.props.status}
                                      <FormGroup>
                                        <Label htmlFor="oldPasswordField">
                                          Old Password
                                        </Label>
                                        <div style={{ position: "relative" }}>
                                          <IconButton
                                            onClick={
                                              this.handleClickShowOldPassword
                                            }
                                            onMouseDown={
                                              this.handleMouseDownPassword
                                            }
                                            style={{
                                              position: "absolute",
                                              top: "-4px",
                                              left: "0px",
                                              // transform: "translateY(-50%)",
                                              cursor: "pointer",
                                            }}
                                            // fontSize="small"
                                          >
                                            {this.state.showOldPassword ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                          <AvField
                                            style={{ paddingLeft: "40px" }}
                                            // label="Old Password"
                                            variant="outlined"
                                            name="oldPassword"
                                            value={this.state.oldPassword}
                                            type={
                                              this.state.showOldPassword
                                                ? "text"
                                                : "password"
                                            }
                                            id="oldPasswordField"
                                            aria-describedby="Old Password"
                                            placeholder="Enter old password"
                                            // fullwidth
                                            onChange={this.handleInputChange}
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage:
                                                  "Please enter a password",
                                              },
                                              minLength: {
                                                value: 8,
                                                errorMessage:
                                                  "Password must have minimum 8 characters",
                                              },
                                              pattern: {
                                                // value: /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,40}$/,
                                                value: passwordRegex,
                                                errorMessage: passwordErrorMessage,
                                              },
                                            }}
                                          />
                                        </div>
                                      </FormGroup>
                                      <FormGroup>
                                        <Label htmlFor="passwordField">
                                          New Password
                                        </Label>
                                        <div style={{ position: "relative" }}>
                                          <IconButton
                                            onClick={
                                              this.handleClickShowNewPassword
                                            }
                                            onMouseDown={
                                              this.handleMouseDownPassword
                                            }
                                            style={{
                                              position: "absolute",
                                              top: "-4px",
                                              left: "0px",
                                              // transform: "translateY(-50%)",
                                              cursor: "pointer",
                                            }}
                                            // fontSize="small"
                                          >
                                            {this.state.showNewPassword ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                          <AvField
                                            style={{ paddingLeft: "40px" }}
                                            // label="Old Password"
                                            variant="outlined"
                                            name="password"
                                            value={this.state.password}
                                            type={
                                              this.state.showNewPassword
                                                ? "text"
                                                : "password"
                                            }
                                            id="passwordField"
                                            aria-describedby="New Password"
                                            placeholder="Enter New password"
                                            // fullwidth
                                            onChange={(e) => {
                                              this.handleInputChange(e);
                                              this.validateConfirmPassword(e);
                                            }}
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage:
                                                  "Please enter a password",
                                              },
                                              minLength: {
                                                value: 8,
                                                errorMessage:
                                                  "Password must have minimum 8 characters",
                                              },
                                              pattern: {
                                                // value: /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,40}$/,
                                                value: passwordRegex,
                                                errorMessage: passwordErrorMessage,
                                              },
                                            }}
                                          />
                                        </div>
                                      </FormGroup>
                                      <FormGroup>
                                        <Label htmlFor="confirmPasswordField">
                                          Confirm Password
                                        </Label>
                                        <div style={{ position: "relative" }}>
                                          <IconButton
                                            onClick={
                                              this
                                                .handleClickShowNewConfirmPassword
                                            }
                                            onMouseDown={
                                              this.handleMouseDownPassword
                                            }
                                            style={{
                                              position: "absolute",
                                              top: "-4px",
                                              left: "0px",
                                              // transform: "translateY(-50%)",
                                              cursor: "pointer",
                                            }}
                                            // fontSize="small"
                                          >
                                            {this.state
                                              .showNewConfirmPassword ? (
                                              <Visibility />
                                            ) : (
                                              <VisibilityOff />
                                            )}
                                          </IconButton>
                                          <AvField
                                            style={{ paddingLeft: "40px" }}
                                            // label="Old Password"
                                            variant="outlined"
                                            name="confirmPassword"
                                            value={this.state.confirmPassword}
                                            type={
                                              this.state.showNewConfirmPassword
                                                ? "text"
                                                : "password"
                                            }
                                            id="confirmPasswordField"
                                            aria-describedby="New Confirm Password"
                                            placeholder="Enter Confirm password"
                                            // fullwidth
                                            onChange={this.handleInputChange}
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage:
                                                  "Please enter a password",
                                              },
                                              minLength: {
                                                value: 8,
                                                errorMessage:
                                                  "Password must have minimum 8 characters",
                                              },
                                              pattern: {
                                                // value: /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,40}$/,
                                                value: passwordRegex,
                                                errorMessage: passwordErrorMessage,
                                              },
                                              match: {
                                                value: "password", // Use the custom validator
                                                errorMessage:
                                                  "Passwords do not match", // Error message for mismatch
                                              },
                                            }}
                                          />
                                        </div>
                                      </FormGroup>
                                      <p>
                                        Password must contain 8 or more
                                        characters with at least one capital letter, one lower letter, one special character, and one digit.
                                      </p>
                                      {this.props.resetPasswordError && (
                                        <Alert color="error">
                                          {JSON.stringify(this.props.resetPasswordError)}
                                        </Alert>
                                      )}
                                    </AvForm>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      style={{
                                        textAlign: "right",
                                        backgroundColor: "#E3712C",
                                        float: "right",
                                      }}
                                      size="sm"
                                      disabled={
                                        this.state.password !==
                                          this.state.confirmPassword ||
                                        this.props.resetPasswordLoading ||
                                        this.isInvalid()
                                      }
                                      onClick={() =>
                                        this.props.resetPassword({
                                          ...this.state,
                                        })
                                      }
                                      // onClick={() => this.props.resetPassword({ ...this.state, userName: this.props.users.userName })}
                                    >
                                      Reset
                                    </Button>
                                    <Button
                                      style={{
                                        textAlign: "right",
                                        backgroundColor: "#E3712C",
                                        float: "right",
                                      }}
                                      size="sm"
                                      // onClick={this.closeResetSuccesmsg}
                                      onClick={() =>
                                        this.closeModal()
                                      }
                                    >
                                      Close
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                              </>
                              {/* </p> */}
                            </>
                          ) : null}
                        </CardBody>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Modal
                  isOpen={this.state.modal}
                  backdrop="static"
                  centered
                  size="lg"
                >
                  <ModalHeader toggle={this.closeModal}>
                    {`Update ${
                      this.state.addressType === "mailing"
                        ? "Mailing"
                        : "Billing"
                    } Address`}
                  </ModalHeader>
                  <ModalBody>
                    <UpdateOrgAddress
                      modal={this.state.modal}
                      closeModal={this.closeModal}
                      addressType={this.state.addressType}
                    />
                  </ModalBody>
                </Modal>
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { organizations } = state.orgList;
  const { users } = state.userList;
  const {
    resultMsgModal,
    status,
    resetPasswordLoading,
    resetPasswordMessage,
    resetPasswordError,
  } = state.account;
  return {
    organizations,
    users,
    resultMsgModal,
    status,
    resetPasswordLoading,
    resetPasswordMessage,
    resetPasswordError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListOfOrgs: () => {
      dispatch(getListOfOrgs());
    },
    getListOfUsers: () => {
      dispatch(getListOfUsers());
    },
    resetPassword: (data) => {
      dispatch(resetPassword(data));
    },
    resetPasswordStatus: () => {
      dispatch(resetPasswordSuccessful(null));
    },
    clearAllOrganizationMessages: () => {
      dispatch(clearAllOrganizationMessages());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(OrgDetails);
