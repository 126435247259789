import {
    GET__LICENSING_DATA_SUCCESSFUL,
    GET_SCAN_FREQ_METADATA_SUCCESSFUL,
    POST__LICENSING_DATA_SUCCESSFUL,
    HANDLE_API_FAILURES,
} from './actionTypes';

const initialState = {
    error: null, message: null, loading: false
}

const licensing = (state = initialState, action) => {
    switch (action.type) {
        case GET__LICENSING_DATA_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                error: null,
                data: {...action.payload.data}
            }
            break;
        case GET_SCAN_FREQ_METADATA_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                error: null,
                costLookup: action.payload.data
            }
            break;
        case POST__LICENSING_DATA_SUCCESSFUL:
            //console.log('--== POST__LICENSING_DATA_SUCCESSFUL ', action.payload);
            state = {
                ...state,
                status: action.payload.data,
                loading: false,
                error: null
            }
            break;
        case HANDLE_API_FAILURES:
            state = {
                loading: false,
                error: action.payload
            }
            break;
        default:
            return state;
    }
    return {...state};
}

export default licensing;