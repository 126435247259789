import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    GET_AWS_ACC,
    POST_AWS_ACC,
    CLEAR_ALL_AWS_ACC_MESSAGES
} from './actionTypes';
import {
    clearAllAwsAccMessagesSuccessful,
    get_aws_acc_failed,
    get_aws_acc_successful,
    postAwsAccFailed,
    postAwsAccSuccessful,
} from './actions';
import {
    getAwsAccountNumber,
    postAwsAccountNumber,
} from '../../helpers/auth_aws_helper';


function* handleGetAwsAcc() {
    try {
        const response = yield call(getAwsAccountNumber);
        yield put(get_aws_acc_successful(response));
    } catch (error) {
        yield put(get_aws_acc_failed(error));
    }
}

function* handlePostAwsAcc({payload: { awsAccount} }) {
    try {
        console.log("handlePostAwsAcc, awsAccount: ", awsAccount)
        const response = yield call(postAwsAccountNumber, awsAccount);
        console.log('--== handlePostAwsAcc Response ', response);
        if (response && response.status === 200) {
			yield put(postAwsAccSuccessful({ message: response.data}));
		} else {
            let message;
            if (response.data?.message) {
                message = response.data.message;
            } else {
                message = response.data;
            }
            yield put(postAwsAccFailed({ message: message }));
		}
    } catch (error) {
        console.log("~~~~~ handlePostAwsAcc error response : ", error);
		if (error.response) {
            yield put(postAwsAccFailed({message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,}));
        } else if (error.request) {
            yield put(postAwsAccFailed({ message: "No response received" }));
        } else {
            yield put(postAwsAccFailed({ message: error?.message }));
        }
    }
}


function* clearAllAwsAccMessages() {
    console.log("Saga: clear All Aws Acc Messages");
    yield put(clearAllAwsAccMessagesSuccessful());
}

export function* watchGetAwsAcc() {
    yield takeEvery(GET_AWS_ACC, handleGetAwsAcc);
}

export function* watchPostAwsAcc() {
    yield takeEvery(POST_AWS_ACC, handlePostAwsAcc);
}

function* watchClearAllAwsAccMessages() {
    yield takeEvery(CLEAR_ALL_AWS_ACC_MESSAGES, clearAllAwsAccMessages);
}


function* getAwsAccSaga() {
    yield all([fork(watchGetAwsAcc)]);
    yield all([fork(watchPostAwsAcc)]);
    yield all([fork(watchClearAllAwsAccMessages)]);
}

export default getAwsAccSaga;