import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";

class PrivatePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        document.body.classList.add('auth-body-bg');
    }

    componentWillUnmount() {
        document.body.classList.remove('auth-body-bg');
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={5}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo">
                                                            <img src={logodark} height="50" alt="logo" />
                                                        </Link>
                                                    </div>

                                                    <h4 className="badge badge-warning font-size-18">Privacy Policy</h4>
                                                    <p className="text-muted">
                                                        Here is the information about the Privacy Policy
                                                    </p>
                                                </div>
                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Have any questions ?{' '}
                                                        {' '}
                                                        Contact CloudNovaTech{' '}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="authentication-privatepolicy-bg">
                                {/* bg-overlay */}
                                <div className=""></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
export default PrivatePolicy;