import {
  GET_LIST_ALL_RULES,
  GET_LIST_ALL_RULES_SUCCESSFUL,
  GET_LIST_ALL_RULES_FAILED,
  GET_LIST_ACC_RULES,
  GET_LIST_ACC_RULES_SUCCESSFUL,
  GET_LIST_ACC_RULES_FAILED,
  POST_NEW_RULES,
  PUT_EDIT_RULES,
  PUT_EDIT_RULES_FAILED,
  PUT_EDIT_RULES_SUCCESSFUL,
  DELETE_RULES,
  DELETE_RULES_SUCCESSFUL,
  DELETE_RULES_FAILED,
  PUT_TOGGLE_RULES,
  SET_LIST_ACC_RULES_STATE,
} from './actionTypes';

export const addNewRules = (payload) => {
  return {
    type: POST_NEW_RULES,
    payload,
  };
};

export const editRules = (accts, ruleId) => {
  return {
    type: PUT_EDIT_RULES,
    payload: { accts, ruleId },
  };
};

export const setRulezToggleAction = (rulez) => {
  //console.log('--== 1) setRulezToggleAction actions ', rulez);
  return {
    type: PUT_TOGGLE_RULES,
    payload: rulez,
  };
};

export const editRulesSuccessful = (accts, ruleId) => {
  return {
    type: PUT_EDIT_RULES_SUCCESSFUL,
    payload: { accts, ruleId },
  };
};
export const editRulesFailed = (accts, ruleId) => {
  return {
    type: PUT_EDIT_RULES_FAILED,
    payload: { accts, ruleId },
  };
};
export const deleteRules = (accts) => {
  return {
    type: DELETE_RULES,
    payload: { accts },
  };
};

export const deleteRulesSuccessful = (accts) => {
  return {
    type: DELETE_RULES_SUCCESSFUL,
    payload: { accts },
  };
};

export const deleteRulesFailed = (accts) => {
  return {
    type: DELETE_RULES_FAILED,
    payload: { accts },
  };
};

export const getListAllRules = (rules) => {
  return {
    type: GET_LIST_ALL_RULES,
    payload: { rules },
  };
};
export const getListAllRulesSuccessful = (rules) => {
  return {
    type: GET_LIST_ALL_RULES_SUCCESSFUL,
    payload: { rules },
  };
};
export const getListAllRulesFailed = (rules) => {
  return {
    type: GET_LIST_ALL_RULES_FAILED,
    payload: { rules },
  };
};
export const getListAccRules = (rules) => {
  return {
    type: GET_LIST_ACC_RULES,
    payload: { rules },
  };
};
export const getListAccRulesSuccessful = (rules) => {
  return {
    type: GET_LIST_ACC_RULES_SUCCESSFUL,
    payload: { rules },
  };
};
export const getListAccRulesFailed = (rules) => {
  return {
    type: GET_LIST_ACC_RULES_FAILED,
    payload: { rules },
  };
};

export const setListAccRulesState = (status) => {
  //console.log('--== setListAccRulesState <> action ==--', status);
  return {
    type: SET_LIST_ACC_RULES_STATE,
    payload: status,
  };
};
