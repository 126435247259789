import React from "react";
import Box from "@mui/material/Box";
import { Alert, Container, AlertTitle } from "@mui/material";

const ContactAdmin = () => {
  return (
    <div className="page-content" style={{ backgroundColor: "#fff" }}>
      <Container
        style={{
          display: "flex",
          color: "red",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: 500 }}>
          <Alert variant="outlined" severity="info">
            <AlertTitle>Access Restricted</AlertTitle>
            You do not have access to this feature. Please contact your Cloudcatcher Organization Administrator for assistance.
          </Alert>
        </Box>
      </Container>
    </div>
  );
};

export default ContactAdmin;
