export const functionNames = [
  "Bucket",
  "InternetGateway",
  "ElasticIp",
  "Vpn",
  "EgressOnlyInternetGateway",
  "Ebs",
  "Ami",
  "EbsSnapshot",
  "ElasticCloudCompute",
  "CloudFront",
  "Sns",
  "Eks",
  "LoadBalancer",
  "CloudTrail",
  "Route",
  "Efs",
  "Lambda",
  "Rds",
  "RdsSnapshot",
  "CloudWatch",
  "CloudWatchLog",
  "EcsService",
  "EcsTask",
  "EcsInstance",
  "RestApi",
  "ApiDomain",
  "HttpApi",
  "DaxCluster",
  "DynamodbBackup",
  "DynamodbTable",
  "Acm",
  "Waf",
  "Sqs",
  "SecretsManager",
  "Backup",
  "Ecr",
  "LoadBalancer",
  "AutoScaling",
  "GuardDuty",
  "EventBridge",
  "SavingsPlan",
  "Ec2ReservedInstances",
  "DBReservedInstances",
  "SystemsManager",
];

export default functionNames;
