import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable, {MTableToolbar} from 'material-table';
import { Switch, MuiThemeProvider } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { createTheme } from '@material-ui/core/styles';
import tableIcons from './MaterialTableIcons';
import { ModalBody, Modal, Button, ModalFooter } from "reactstrap";
import Save from '@material-ui/icons/Save';
import Select from "react-select";
import loadingGif from "./../../assets/images/aws/loadingGif.gif";


import {
  getListAccRules,
  getListAllRules,
  setRulezToggleAction,
  setListAccRulesState,
} from '../../store/actions';
import SecurityConfigUsers from './_securityConfigUsers';
import getUserRole from '../../helpers/jwt-token-access/userRole';
import { Link } from '@mui/material';

const allowedRoles=[ "PowerUser", "Administrator" ];

let myFilteredAccounts = [];
function SecurityConfig() {
  const direction = 'ltr';
  const theme = createTheme({
    direction: direction,
    palette: {
      type: 'light',
    },
    overrides: {
      MuiTableFooter: {
        root: {
          '&:hover .MuiTableRow-root': {
            backgroundColor: '#fff !important',
            color: 'black',
          },
        }
      },
      MuiTableRow: {
        root: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#e2e6f4 !important',  //hover color for rows background
            color: 'black',                           //hover color for rows text
          },
        },
      },
    },
  });

  const licensing = useSelector((state) => state.licensing);
  const profile = useSelector((state) => state.profile);
  const { rulez, rulezz, state, message, loading } = useSelector((state) => state.ruleList);
  const dispatch = useDispatch();
  const [mask, setMask] = useState(false);
  const [allEnabled, setAllEnabled] = useState(false);
  const [rulezList, setRulezList] = useState([]);
  const [tableData, setTableData] = useState({ isLoading: false, data: [] });
  const [securityConfigUsers, setSecurityConfigUsers] = useState();
  const [saveChangesNotification, setSaveChangesNotification] = useState(false);

  // To display available accounts in Multi Select
  const [multiSelectAccounts, setMultiSelectAccounts] = useState([]);
  // To store accounts selected from Multi Select
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);
  

  const moduleLookup = {
    1: 'NIST',
    2: 'HIPPA',
  };

  const userRole = getUserRole();

  useEffect(() => {
    if (licensing && licensing.data && licensing.data.isLicensingComplete &&
      profile && profile.userRoles && !profile.userRoles.includes("StandardUser")) {
      setMask(false);
    } else {
      setMask(true);
    }
  }, [licensing, licensing.data, profile, profile.userRoles])

  useEffect(() => {
    setRulezList([]);
  }, [rulezz]);

  useEffect(() => {
    if (rulezz && rulezz.length > 0 && rulez && rulez.length > 0) {
      const tblData = [];
      let rowId = 0;
      rulez.forEach((item, index) => {
        const { entity } = item.rulesLabelsDTO;
        const ruleAccountInfo = rulezz.filter(
            accountRule => accountRule.cloudNovaId===item.cloudNovaId &&
            accountRule.accnum !== 'INIT00000001'
          );
        const lastEntity = tblData.find((row) => row.entity === entity)
        const row = { ...item, ...item.rulesLabelsDTO }
        row.enabled = getEnabledStatus(item)

        if (!lastEntity) {
          rowId++;
          tblData.push({ ...item.rulesLabelsDTO, rowId, items: [{ ...row, detailsRowId: index + 1, accountRules:  ruleAccountInfo }] });
        } else {
          lastEntity.items.push({ ...row, detailsRowId: index + 1, accountRules:  ruleAccountInfo });
        }
      })
      setEnabledRules(tblData);
      const uniqueAccounts = Object.values(
        rulezz.reduce((acc, current) => {
          acc[current.acccode] = current;
          return acc;
        }, {})
      );
      const usableAccounts = uniqueAccounts.filter(acct => acct.accnum!=="INIT00000001");
      setMultiSelectAccounts(usableAccounts);
    }
  }, [rulez, rulezz]);

  let updateMessage = state?.acccode;
  useEffect(() => {
    if (updateMessage === "successfully updated security configuration") {
      sessionStorage.removeItem('isDirty');
      dispatch(getListAllRules());
      dispatch(getListAccRules());
    }
  }, [updateMessage]);

  const getEnabledStatus = (rowData) => {
    const accountsList = rulezz.filter(
      (rule) =>
        rule.cloudNovaId === rowData.cloudNovaId &&
        rule.accnum !== 'INIT00000001' && rule.isEnabled
    );
    return accountsList.length > 0;
  }

  const addToChangeList = (row) => {
    const clonedRulezz = [...rulezz];
    clonedRulezz.forEach(
      (rule) => {
        if (rule.cloudNovaId === row.cloudNovaId &&
          rule.accnum !== 'INIT00000001' && rule.accnum===row.accnum) {
          rule.isEnabled = row.isEnabled;
          // Invert already dirty rule to unchanged rule
          if (rule?.isDirty) {
            rule.isDirty = false;
          } else {
            rule.isDirty = true;
          }
        }
      }
    );
    const accountsList = clonedRulezz.filter((item) => item.isDirty);
    setRulezList(accountsList);
    // If there are no changes in rules, update isDirty flag for "Save Changes" button
    if (accountsList?.length > 0) {
      sessionStorage.setItem('isDirty', 'true');
    } else {
      sessionStorage.removeItem('isDirty');
    }
  }

  const onToggleDetailsRow = (rowId, detailsRowId, accountNumber) => {

    const cloneTableData = [...tableData]
    cloneTableData.forEach((entity) => {
      if (entity.rowId === rowId) {
        let enabledDetailsRow = 0;
        entity.items.forEach((detailRow) => {
          if (detailRow.detailsRowId === detailsRowId) {
            const updatedAccountRules = detailRow.accountRules.map(
              accountRule => {
                if (accountRule.accnum === accountNumber) {
                  return { ...accountRule, isEnabled: !accountRule.isEnabled };
                } else {
                  return accountRule;
                }
              }
            );
            detailRow.accountRules = updatedAccountRules;
            addToChangeList(detailRow.accountRules.find(accountRule => accountRule.accnum === accountNumber));
            enabledDetailsRow++;
          } else if (detailRow.enabled) {
            enabledDetailsRow++;
          }
        })
        entity.enabled = enabledDetailsRow > 0;
      }
    });
    setEnabledRules(cloneTableData);
  }

  const setEnabledRules = (tblData) => {
    console.log("tblData",tblData);
    let totalEnabled = 0;
    let totalAccountRules = 0;
    tblData.forEach((entity) => {
      let totalEnabledInEntity = 0;
      let totalAccountRulesInEntity = 0;
      let rulesCountInEntity = 0;
      entity.items.forEach((detailRow) => {
        let totalEnabledInRuleRow = 0;
        let totalAccountRulesInRuleRow = 0;
        detailRow.accountRules.forEach((accountRule) => {
          if (filteredAccounts && filteredAccounts.length === 0){
            totalAccountRules++;
            totalAccountRulesInEntity++;
            totalAccountRulesInRuleRow++;
            if (accountRule.isEnabled) {
              totalEnabled++;
              totalEnabledInEntity++;
              totalEnabledInRuleRow++;
            }
          }
          else if (filteredAccounts.some(filteredAccount => filteredAccount.accnum === accountRule.accnum)) {
            totalAccountRules++;
            totalAccountRulesInEntity++;
            totalAccountRulesInRuleRow++;
            if (accountRule.isEnabled) {
              totalEnabled++;
              totalEnabledInEntity++;
              totalEnabledInRuleRow++;
            }
          }
        });
        if (totalEnabledInRuleRow === totalAccountRulesInRuleRow) {
          rulesCountInEntity++;
        }
      });
      entity.enabled = totalAccountRulesInEntity === totalEnabledInEntity;
      entity.noOfEnabled = `${rulesCountInEntity} of ${entity.items.length}`;
    });
    setAllEnabled(totalEnabled === totalAccountRules);
    setTableData(tblData);
  }

  const onToggleParentRow = (rowId) => {
    const cloneTableData = [...tableData];
    cloneTableData.forEach((row) => {
      if (row.rowId === rowId) {
        row.items.forEach((detailRow) => {
          detailRow.accountRules.forEach((accountRule) => {
            if (filteredAccounts && filteredAccounts.length === 0) {
              accountRule.isEnabled = !row.enabled;
              addToChangeList(accountRule);
            }
            // Check if the accountRule is in filteredAccounts
            else if (filteredAccounts.some(filteredAccount => filteredAccount.accnum === accountRule.accnum)) {
              accountRule.isEnabled = !row.enabled;
              addToChangeList(accountRule);
            }
          });
          detailRow.enabled = !row.enabled;
        });
      }
    });
    setEnabledRules(cloneTableData);
  }

  const onToggleAllRow = (value) => {
    const cloneTableData = [...tableData];
    cloneTableData.forEach((row) => {
      row.items.forEach((detailRow) => {
        detailRow.accountRules.forEach((accountRule) => {
          if (filteredAccounts && filteredAccounts.length === 0) {
            accountRule.isEnabled = !value;
            addToChangeList(accountRule);
          }
          // Check if the accountRule is in filteredAccounts
          else if (filteredAccounts.some(filteredAccount => filteredAccount.accnum === accountRule.accnum)) {
            accountRule.isEnabled = !value;
            addToChangeList(accountRule);
          }
        });
      });
      row.enabled = !value;
    });
    setEnabledRules(cloneTableData);
  }

  const handleMultiSelectChange = (event) => {
    let accts = [];
    if (event != null) {
      event.forEach((acct) => {
        accts.push({ accnum: acct.value, accname: acct.label });
      });
    }
    setFilteredAccounts(accts);
    myFilteredAccounts = [];
    myFilteredAccounts.push(...accts);

    // Recalculate enabled rules based on selected accounts
    const updatedTableData = tableData.map((entity) => {
      // console.log("####################Entity: ", entity);
      let totalEnabledInEntity = 0;
      let totalAccountRulesInEntity = 0;
      let rulesCountInEntity = 0;

      const updatedItems = entity.items.map((detailRow) => {
        let totalEnabledInRuleRow = 0;
        let totalAccountRulesInRuleRow = 0;

        const updatedAccountRules = detailRow.accountRules.map((accountRule) => {
          let isAccountSelected = myFilteredAccounts.length === 0 || myFilteredAccounts.some(filteredAccount => filteredAccount.accnum === accountRule.accnum);
          if (isAccountSelected) {
            totalAccountRulesInRuleRow++;
            totalAccountRulesInEntity++;
            if (accountRule.isEnabled) {
              totalEnabledInRuleRow++;
              totalEnabledInEntity++;
            }
          }
          return accountRule;
        });

        if (totalEnabledInRuleRow === totalAccountRulesInRuleRow) {
          rulesCountInEntity++;
        }

        return {
          ...detailRow,
          accountRules: updatedAccountRules,
        };
      });

      const updatedEntity = {
        ...entity,
        items: updatedItems,
        enabled: totalAccountRulesInEntity === totalEnabledInEntity,
        noOfEnabled: `${rulesCountInEntity} of ${entity.items.length}`,
      };

      return updatedEntity;
    });

    const totalEnabled = updatedTableData.reduce((acc, entity) => {
      return acc + parseInt(entity.noOfEnabled.split(' ')[0], 10); // Adding enabled rules count
    }, 0);

    const totalAccountRules = updatedTableData.reduce((acc, entity) => {
      return acc + entity.items.length; // Total number of items per entity
    }, 0);

    // Update global switch (allEnabled)
    setAllEnabled(totalEnabled === totalAccountRules);
    
    // Update table data with recalculated enabled rules
    setTableData(updatedTableData);
  }

  const handleSave = () => {
    if (rulezList && rulezList.length > 0) {
      dispatch(setRulezToggleAction(rulezList));
      sessionStorage.removeItem('isDirty');
    }
  }

  useEffect(() => {
    sessionStorage.removeItem('isDirty');
    dispatch(getListAllRules());
    dispatch(getListAccRules());

    return () => {
      myFilteredAccounts = [];
    }
  }, []);

  useEffect(() => {
    if (rulezList && rulezList.length > 0) {
      sessionStorage.setItem('isDirty', 'true');
      setSaveChangesNotification(true);
      setTimeout(() => {
        setSaveChangesNotification(false);
      }, 5000)
    }
  }, [rulezList]);

  const handleRuleClick = (cloudNovaId) => {
    const newTabURL = process.env.REACT_APP_URL + `/ruleremediation/${cloudNovaId}`;
    window.open(newTabURL, '_blank');
  };

  return (
    <MuiThemeProvider theme={theme}>
      
      {loading && (
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", height: "80px", alignItems: "center"}}>
            <img src={loadingGif} height={24} alt="Loading Spinner" />
            <p>Loading...</p>
          </div>
        )}
      <div className={mask ? 'mask' : ''}>
        {
          tableData && tableData.length > 0 && (
            <MaterialTable
              key={updateKey}
              icons={tableIcons}
              components={{
                Toolbar: (props) => (<div>
                  <div className="col-sm-12 col-md-6" style={{marginLeft: "auto", paddingTop: "1rem"}}>
                    <Select
                      name="accounts"
                      closeMenuOnSelect={false}
                      onChange={handleMultiSelectChange}
                      isMulti
                      isClearable
                      default
                      placeholder={"Accounts"}
                      value={
                        filteredAccounts.map((acct) => {
                            return { value: acct.accnum, label: acct.accname }
                        })
                      }
                      options={
                        multiSelectAccounts.map((acct) => {
                          return { value: acct.accnum, label: acct.accname ? `${acct.accnum} (${acct.accname})` : `${acct.accnum}` }
                      })}
                    />
                  </div>
                  <div>
                  <MTableToolbar {...props} />
                  </div>
                </div>),
              }}

              actions={[
                {
                  icon: () => (
                    <Switch
                      checked={allEnabled}
                      onChange={() => onToggleAllRow(allEnabled)}
                      disabled={!allowedRoles.includes(userRole)}
                    />
                  ),
                  color: 'primary',
                  isFreeAction: true,
                  size: 'large',
                  tooltip: 'Enable/Disable'
                },
                {
                  icon: () => <Save style={{ color: sessionStorage.getItem('isDirty') ? '#f50057' : '' }}></Save>,
                  color: 'primary',
                  isFreeAction: true,
                  size: 'small',
                  tooltip: 'Save Changes',
                  onClick: handleSave
                },
              ]}
              columns={[
                {
                  title: 'Cloud Entity',
                  field: 'entity',
                  editable: 'never'
                },
                {
                  title: 'Enable/Disable',
                  field: 'items',
                  render: rowData => {
                    return (
                      <Switch
                        checked={rowData.enabled}
                        onChange={(e) => onToggleParentRow(rowData.rowId)}
                        disabled={!allowedRoles.includes(userRole)}
                      />
                    )
                  }
                },
                {
                  title: 'No of Rules Enabled',
                  field: 'noOfEnabled',
                  editable: 'never'
                },
              ]}
              data={tableData}
              title="Security Rules"
              options={{
                paging: false,
                headerStyle: {
                  zIndex: "0",
                  backgroundColor: '#193A6F',
                  color: '#FFFFFF',
                  fontWeight: 'semibold',
                },
                cellStyle: { padding: '0.1em' },
              }}
              detailPanel={[
                {
                  render: rowData => {

                    return (
                      <MaterialTable
                        key={`${rowData.rowId}-${updateKey}`}
                        icons={tableIcons}
                        columns={[
                          {
                            title: 'Rule ID',
                            field: 'cloudNovaId',
                            editable: 'never',
                            render: (rowData) => (
                              <Link
                                onClick={() => handleRuleClick(rowData.cloudNovaId)}
                              >
                                {rowData.cloudNovaId}
                              </Link>
                            ),
                            filtering: false,
                          },
                          {
                            title: 'Rule Name',
                            field: 'ruleName',
                            editable: 'never',
                            filtering: false,
                          },

                          {
                            title: 'Severity',
                            field: 'severity',
                            filtering: false,
                            emptyvalaue: () => <div>"unspecified"</div>,
                            render: (detailsRow) => {
                              const severityEntity = [
                                { severity: ['HIGH'], color: '#F39011' },
                                { severity: ['CRITICAL'], color: '#D82F06' },
                                { severity: ['MODERATE'], color: '#F2D629' },
                                { severity: ['LOW'], color: '#59BE2F' }
                              ].find(
                                (key) =>
                                  key.severity.indexOf(detailsRow.severity.toUpperCase()) > -1
                              )
                              const bgColor = severityEntity ? severityEntity.color : '#193A6F'
                              return (
                                <Chip label={detailsRow.severity} size="small" style={{ width: '6rem', backgroundColor: bgColor, color: '#fff' }} />
                              )
                            },
                          },
                          {
                            title: 'Enable/ Disable',
                            field: 'items',
                            filtering: false,
                            searchable: false,
                            render: detailsRow => {
                              if (myFilteredAccounts && myFilteredAccounts.length > 0) {
                                return detailsRow.accountRules
                                  .filter(accountRule =>
                                    myFilteredAccounts.some(filteredAccount => filteredAccount.accnum === accountRule.accnum)
                                  )
                                  .map(accountRule => (
                                    <div style={{ display: "flex", justifyContent: "space-between" }} key={accountRule.accnum}>
                                      <span>
                                        {accountRule.accnum}
                                        {accountRule.accname && ` (${accountRule.accname})`}
                                      </span>
                                      <Switch
                                        checked={accountRule.isEnabled}
                                        onChange={() => onToggleDetailsRow(rowData.rowId, detailsRow.detailsRowId, accountRule.accnum)}
                                      />
                                    </div>
                                  ));
                              } else {
                                return detailsRow.accountRules.map(accountRule =>
                                  <div style={{ display: "flex", justifyContent: "space-between" }} key={accountRule.accnum}>
                                    <span>
                                      {accountRule.accnum}
                                      {accountRule.accname && ` (${accountRule.accname})`}
                                    </span>
                                    <Switch
                                      checked={accountRule.isEnabled}
                                      onChange={() => onToggleDetailsRow(rowData.rowId, detailsRow.detailsRowId, accountRule.accnum)}
                                    />
                                  </div>
                                )
                              }
                            }
                          },
                        ]}
                        data={rowData.items}
                        // data={filteredData.items}
                        title=""
                        options={{
                          pageSize: rowData.items && rowData.items.length < 5 ? rowData.items.length : 5,
                          actionsColumnIndex: -1,
                          headerStyle: {
                            backgroundColor: '#193A6F',
                            color: '#FFFFFF',
                            fontWeight: 'semibold',
                            padding: '0.2em',
                          },
                          rowStyle: (rowData) => ({
                            backgroundColor:
                              rowData.tableData.id % 2 ? '#FFF' : '#FFF',
                          }),
                          cellStyle: { padding: '0.3em' },
                          filtering: false,
                        }}
                      />

                    )
                  }
                }
              ]

              }

            />
          )
        }
      </div>
      <div style={{ height: 20, paddingTop: '10px', display: 'flex', justifyContent: 'flex-end', fontSize: 12, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: "#74788d" }}>
        Note: Disabled Services will run in the background depending on the Compliance Standards selected for the AWS account
      </div>
      <Modal isOpen={securityConfigUsers} backdrop="static" centered size="lg">
        <ModalBody>
          <SecurityConfigUsers
            rowData={securityConfigUsers}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="sm"
            onClick={() => setSecurityConfigUsers(null)}>Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={state && state.status}>
        <ModalBody>{state && state.status === 200 ? state.acccode : "Unable to Save ..."}</ModalBody>
        <ModalFooter><Button onClick={() => dispatch(setListAccRulesState({}))}>close</Button></ModalFooter>
      </Modal>
    </MuiThemeProvider>
  )
}

export default SecurityConfig;
