import React, { useState } from "react";
import {
  useCreateKibanaDashboardURL,
  useHasActiveUserAccounts,
  useIsEventInsightEnabledForOneUserAccount,
} from "../../../components/Common/HelperComponents/CustomHooks";
import AnimatedLogo from "../../../components/Common/HelperComponents/CloudCatcherAnimatedLogo/CloudCatcherAnimatedLogo";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const EventInsightInforCard = ({ orgCode, dashboardKey }) => {
  const [isIframeReady, setIsIframeReady] = useState(false);
  const { dashboardURL, isLoading, errorMessage } =
    useCreateKibanaDashboardURL(dashboardKey);

  const handleIframeLoad = () => {
    setIsIframeReady(true);
  };

  return (
    <div>
      <div>
        {isLoading ? (
          // Loading State
          <div className="kibana-loading-container" id="kibanaDashboardLoading">
            <AnimatedLogo />
          </div>
        ) : errorMessage ? (
          // Error State
          <div className="kibana-loading-container" id="kibanaDashboardLoading">
            <div>Error: {errorMessage}</div>
          </div>
        ) : (
          // Iframe State (Success)
          <div>
            <iframe
              src={dashboardURL}
              className="iframe-second-row"
              onLoad={handleIframeLoad}
              style={{
                height: "100%",
                width: "100%",
                zIndex: 1,
                display: "block",
              }}
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventInsightInforCard;
