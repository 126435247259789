import { takeEvery, fork, put, all, call, delay } from 'redux-saga/effects';
import {
  CHECK_LOGIN,
  LOGOUT_USER,
  FORCE_LOGOUT,
  CLEAR_ALL_LOGIN_MESSAGES,
  VERIFY_TOTP_REQUEST,
} from "./actionTypes";
import { apiError, loginUserSuccessful, verifyTotpSuccess, verifyTotpFailure, clearAllLoginMessagesSuccess as clearAllLoginMessagesSuccessAction, loginUserSuccessfulWithQrCode, loginUserSuccessfulWithVerification } from './actions';
import { postLogin, postVerifyTotp } from '../../../helpers/auth_aws_helper';
import jwtDecode from "jwt-decode";
import EncryptPassword from '../../../components/EncryptPassword';
import { getLicensingData } from '../../actions';
import { RESET_STORE } from '../../actionTypes';

function* loginUser({ payload: { user, history } }) {
        try {
            const encryptedPassword = yield call(EncryptPassword, user.password);

            const response = yield call(postLogin, {
                username: user.userName, 
                password: encryptedPassword,
                mfaCode: user.mfaCode
                // accessToken: user.token
            });
            console.log("loginUser response: ", response);

            if(response.status === 200){
                let apiResponse = response.data;
                console.log("~~~~~ loginUser saga, Response : ", apiResponse);
                if(typeof apiResponse === "object" && apiResponse !== null) {
                    if ("base64Image" in apiResponse) {
                        yield put(loginUserSuccessfulWithQrCode(apiResponse));
                    } else {
                        const { idToken, refreshToken } = response.data;
                        console.log("--==response", response, idToken);
                        console.log("Refresh Token", refreshToken);
                        const decoded = jwtDecode(idToken);
                        const {exp}= decoded
                        let authUser = response.data;
                        authUser.token = authUser.accessToken;
    
                        console.log('jwt_decode ::: > ', decoded, exp);
                        localStorage.setItem("authUser", JSON.stringify(authUser));
                        localStorage.setItem("activeExp", JSON.stringify(exp));
                        localStorage.removeItem('lastActiveRoute');
                        yield put(loginUserSuccessful(response.data));
                        yield call(retryGetLicensingData);
                        console.log("~~~~~ loginUser saga : ", response.data);
                        // history.push("/dashboard");
                    }                    
                } else if(typeof apiResponse === "string") {
                    yield put(loginUserSuccessfulWithVerification(apiResponse));
                } else {
                    yield put(apiError("Unknown API response: ", apiResponse));
                }
            } else {
                yield put(apiError(response?.data?.message));
            }
        } catch (error) {
            console.log("login error: ", JSON.stringify(error));
            if (error.response){
                yield put(apiError(""+error.response.status+" "+error.response?.data));
            } else if (error.request){
                yield put(apiError(""+error.response.status+" "+JSON.stringify(error.request)));
            } else {
                yield put(apiError(""+error.response.status+" "+JSON.stringify(error.message)));
            }
            yield put(apiError(error.message));
        }
}

function* retryGetLicensingData() {
    const MAX_RETRY_COUNT = 2;
    let retryCount = 0;

    while (retryCount <= MAX_RETRY_COUNT) {
        try {
            console.log(`Retrying-${retryCount} of ${MAX_RETRY_COUNT} getLicensingData`);
            yield put(getLicensingData());
            return; // Exit the function if successful
        } catch (error) {
            // If it's a gateway timeout and retry count is less than maximum, retry after delay
            if (error.status === 504 && retryCount < MAX_RETRY_COUNT) {
                retryCount++; 
                yield delay(3000); 
            } else {
                return;
            }
        }
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.clear();
        sessionStorage.clear();
        yield put({ type: RESET_STORE });
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}
function* forceLogout() {
    try {
        localStorage.clear();
        sessionStorage.clear();
    } catch (error) {
        yield put(apiError(error));
    }
}

function* verifyTotp({payload: {session, code} }) {
    try {
        const response = yield call(postVerifyTotp, {
            session: session,
            code: code
        });
        console.log("verifyTotp response: ", response);

        if(response.status===200){
            yield put(verifyTotpSuccess("TOTP verified successfully!"));
        } else {
            let message;
            if (response.data?.message) {
                    message = response.data.message;
            } else {
                    message = response.data;
            }
            yield put(verifyTotpFailure({message: message}));
        }
    }
    catch (error) {
        console.log("~~~ getIaacLicense, received Error is :: ", JSON.stringify(error));
        if(error.response) {
            yield put(verifyTotpFailure(
                {
                    message: "Error Code: " +error.response?.status+", Message: "+ error.response?.data,
                }
            ));
        } else if (error.request) {
            yield put(verifyTotpFailure({ message: "No response is received" }));
        } else {
            yield put(verifyTotpFailure({ message: error?.message }));
        }
    }
}

export function* clearAllLoginMessages() {
  console.log("Saga: clear all login messages");
  yield put(clearAllLoginMessagesSuccessAction());
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}
export function* watchForceLogout(){
    yield takeEvery(FORCE_LOGOUT, forceLogout)
}

export function* watchVerifyTotp() {
    yield takeEvery(VERIFY_TOTP_REQUEST, verifyTotp);
}

export function* watchClearAllLoginMessages() {
    yield takeEvery(CLEAR_ALL_LOGIN_MESSAGES, clearAllLoginMessages);
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchVerifyTotp),
        fork(watchClearAllLoginMessages)
    ]);
    yield all([fork(watchForceLogout)]);
}

export default loginSaga;