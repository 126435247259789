export const GET_LIST_ALL_RULES = 'get_list_all_rules';
export const GET_LIST_ALL_RULES_SUCCESSFUL = 'get_list_all_rules_successful';
export const GET_LIST_ALL_RULES_FAILED = 'get_list_all_rules_failed';
export const GET_LIST_ACC_RULES = 'get_list_acc_rules';
export const GET_LIST_ACC_RULES_SUCCESSFUL = 'get_list_acc_rules_successful';
export const GET_LIST_ACC_RULES_FAILED = 'get_list_acc_rules_failed';
export const POST_NEW_RULES = 'post_new_rules';
export const PUT_EDIT_RULES = 'put_edit_rules';
export const PUT_EDIT_RULES_FAILED = 'put_edit_rules_failed';
export const PUT_EDIT_RULES_SUCCESSFUL = 'put_edit_rules_successful';
export const DELETE_RULES = 'delete_rules';
export const DELETE_RULES_SUCCESSFUL = 'delete_rules_successful';
export const DELETE_RULES_FAILED = 'delete_rules_failed';
export const PUT_TOGGLE_RULES="PUT_TOGGLE_RULES"
export const SET_LIST_ACC_RULES_STATE="SET_LIST_ACC_RULES_STATE"
