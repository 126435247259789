import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESSFUL,
  UPLOAD_FILE_FAILED,
  SEND_FILE_COMMIT_REQUEST,
  SEND_FILE_COMMIT_SUCCESSFUL,
  SEND_FILE_COMMIT_FAILED,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESSFUL,
  DOWNLOAD_FILE_FAILED,
  GET_UPLOAD_FILE_URL_REQUEST,
  GET_UPLOAD_FILE_URL_SUCCESSFUL,
  GET_UPLOAD_FILE_URL_FAILED,
  GET_IAAC_METRICS_REQUEST,
  GET_IAAC_METRICS_SUCCESSFUL,
  GET_IAAC_METRICS_FAILED,
  GET_IAAC_FILE_HISTORY_REQUEST,
  GET_IAAC_FILE_HISTORY_SUCCESSFUL,
  GET_IAAC_FILE_HISTORY_FAILED,
  CLEAR_ALL_IAAC_MESSAGES_SUCCESSFUL,
} from "./actionsTypes";

const initialState = {
  isFileUploading: false,
  fileUploadMessage: null,
  fileUploadError: null,

  isFileCommitting: false,
  fileCommitMessage: null,
  fileCommitError: null,

  isFileDownloading: false,
  fileDownloadMessage: null,
  fileDownloadError: null,
  fileDownloadUrl: null,

  isUploadFileUrlGetting: false,
  getUploadFileUrlMessage: null,
  getUploadFileUrlError: null,
  uploadUrl: null,

  isIaacMetricsGetting: false,
  getIaacMetricsMessage: null,
  getIaacMetricsError: null,
  metrics: null,

  isIaacFileHistoryGetting: false,
  getIaacFileHistoryMessage: null,
  getIaacFileHistoryError: null,
  fileHistory: null,
};

const iaacReducer = (state=initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      state = {
        ...state,
        isFileUploading: true,
        fileUploadError: null,
        fileUploadMessage: null,
      };
      break;
    case UPLOAD_FILE_SUCCESSFUL:
      state = {
        ...state,
        isFileUploading: false,
        // fileUploadMessage: action.payload.fileUploadResponse.message,
        fileUploadMessage: "File upload successful",
      };
      break;
    case UPLOAD_FILE_FAILED:
      state = {
        ...state,
        isFileUploading: false,
        fileUploadError: action.payload.fileUploadError.message,
      };
      break;
    case SEND_FILE_COMMIT_REQUEST:
      state = {
        ...state,
        isFileCommitting: true,
        fileCommitMessage: null,
        fileCommitError: null,
      };
      break;
    case SEND_FILE_COMMIT_SUCCESSFUL:
      state = {
        ...state,
        isFileCommitting: false,
        // ffileCommitMessage: action.payload.fileCommitResponse.message,
        fileCommitMessage: "File commit successful",
      };
      break;
    case SEND_FILE_COMMIT_FAILED:
      state = {
        ...state,
        isFileCommitting: false,
        fileCommitError: action.payload.fileCommitError.message,
      };
      break;
    case DOWNLOAD_FILE_REQUEST:
      state = {
        ...state,
        isFileDownloading: true,
        fileDownloadMessage: null,
        fileUploadError: null,
        fileDownloadUrl: null,
        fileType: action.payload.fileType,
      };
      break;
    case DOWNLOAD_FILE_SUCCESSFUL:
      state = {
        ...state,
        isFileDownloading: false,
        // fileDownloadMessage: action.payload.fileDownloadMessage.message,
        fileDownloadUrl: action.payload.fileDownloadUrl,
      };
      break;
    case DOWNLOAD_FILE_FAILED:
      state = {
        ...state,
        isFileDownloading: false,
        fileDownloadError: action.payload.downloadFileError.message,
        downloadedFileData: null,
      };
      break;
    case GET_UPLOAD_FILE_URL_REQUEST:
      state = {
        ...state,
        isUploadFileUrlGetting: true,
        getUploadFileUrlMessage: null,
        getUploadFileUrlError: null,
      };
      break;
    case GET_UPLOAD_FILE_URL_SUCCESSFUL:
      state = {
        ...state,
        isUploadFileUrlGetting: false,
        uploadUrl: action.payload.uploadUrl,
        getUploadFileUrlMessage: "File upload URL received successfully",
      };
      break;
    case GET_UPLOAD_FILE_URL_FAILED:
      state = {
        ...state,
        isUploadFileUrlGetting: false,
        getUploadFileUrlError: action.payload.getUploadFileUrlError.message,
        uploadUrl: null,
      };
      break;
    case GET_IAAC_METRICS_REQUEST:
      state = {
        ...state,
        isIaacMetricsGetting: true,
        getIaacMetricsMessage: null,
        getIaacMetricsError: null,
      };
      break;
    case GET_IAAC_METRICS_SUCCESSFUL:
      state = {
        ...state,
        isIaacMetricsGetting: false,
        metrics: action.payload.metrics,
        getIaacMetricsMessage: "Iaac Metrics received successfully",
      };
      break;
    case GET_IAAC_METRICS_FAILED:
      state = {
        ...state,
        isIaacMetricsGetting: false,
        getIaacMetricsError: action.payload.getIaacMetricsError.message,
        metrics: null,
      };
      break;
    case GET_IAAC_FILE_HISTORY_REQUEST:
      state = {
        ...state,
        isIaacFileHistoryGetting: true,
        getIaacFileHistoryMessage: null,
        getIaacFileHistoryError: null,
      };
      break;
    case GET_IAAC_FILE_HISTORY_SUCCESSFUL:
      state = {
        ...state,
        isIaacFileHistoryGetting: false,
        fileHistory: action.payload.fileHistory,
        getIaacFileHistoryMessage: "Iaac file history received successfully",
      };
      break;
    case GET_IAAC_FILE_HISTORY_FAILED:
      state = {
        ...state,
        isIaacFileHistoryGetting: false,
        getIaacFileHistoryError: action.payload.getIaacFileHistoryError.message,
        fileHistory: null,
      };
      break;
    case CLEAR_ALL_IAAC_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        fileUploadMessage: null,
        fileUploadError: null,

        fileCommitMessage: null,
        fileCommitError: null,

        fileDownloadMessage: null,
        fileDownloadError: null,
        downloadedFileData: null,

        getUploadFileUrlMessage: null,
        getUploadFileUrlError: null,
        // uploadUrl: null,

        getIaacMetricsMessage: null,
        getIaacMetricsError: null,
        // metrics: null,

        getIaacFileHistoryMessage: null,
        getIaacFileHistoryError: null,
      };
      break;
    default:
      return state;
  }
  return state;
}

export default iaacReducer;