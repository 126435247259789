import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  functionCountsLoading: false,
  functionCountsTotal: null,
  functionCountsError: null,
  functionCountsProgress: 0,
  fetchFunctionCountsPayload:null,

  bucketPolicyStatusLoading: false,
  bucketPolicyStatusMessage: null,
  bucketPolicyStatusError: null,
  verifyBucketPolicyPayload: null,

  accountStatusLoading: false,
  accountStatusResult: null,
  accountStatusError: null,
  verifyAccountStatusPayload: null,
};

const scanServiceSlice = createSlice({
  name: "scanService",
  initialState,
  reducers: {
    // Fetch Function Counts
    fetchFunctionCountsRequest: (state, action) => {
      state.functionCountsLoading = true;
      state.functionCountsTotal = null;
      state.functionCountsError = null;
      state.functionCountsProgress = 0;
      state.fetchFunctionCountsPayload = action.payload;
    },
    fetchFunctionCountsSuccess: (state, action) => {
      state.functionCountsLoading = false;
      state.functionCountsTotal = action.payload;
      state.fetchFunctionCountsPayload = null;
    },
    fetchFunctionCountsFailure: (state, action) => {
      state.functionCountsLoading = false;
      state.functionCountsTotal = -1;
      state.functionCountsError = action.payload;
      state.fetchFunctionCountsPayload = null;
    },
    updateFunctionCountsProgress: (state, action) => {
      state.functionCountsProgress = action.payload;
    },

    // Verify Bucket Policy Status
    verifyBucketPolicyStatusRequest: (state, action) => {
      state.bucketPolicyStatusLoading = true;
      state.bucketPolicyStatusMessage = null;
      state.bucketPolicyStatusError = null;
      state.verifyBucketPolicyPayload = action.payload;
    },
    verifyBucketPolicyStatusSuccess: (state, action) => {
      state.bucketPolicyStatusLoading = false;
      state.bucketPolicyStatusMessage = action.payload;
    },
    verifyBucketPolicyStatusFailure: (state, action) => {
      state.bucketPolicyStatusLoading = false;
      state.bucketPolicyStatusError = action.payload;
    },

    // Verify Account Status
    verifyAccountStatusRequest: (state, action) => {
      state.accountStatusLoading = true;
      state.accountStatusResult = null;
      state.accountStatusError = null;
      state.verifyAccountStatusPayload = action.payload;
    },
    verifyAccountStatusSuccess: (state, action) => {
      state.accountStatusLoading = false;
      state.accountStatusResult = action.payload;
    },
    verifyAccountStatusFailure: (state, action) => {
      state.accountStatusLoading = false;
      state.accountStatusError = action.payload;
    },
    clearAllScanServiceMessages: (state, action) => {},
    clearAllScanServiceMessagesSuccessful: (state, action) => {
      state.functionCountsTotal = null;
      state.functionCountsProgress = 0;
      state.functionCountsError = null;
      state.fetchFunctionCountsPayload = null;
      state.functionCountsLoading = false;

      state.bucketPolicyStatusMessage = null;
      state.bucketPolicyStatusError = null;
      state.verifyBucketPolicyPayload = null;

      state.accountStatusResult = null;
      state.accountStatusError = null;
      state.verifyAccountStatusPayload = null;
    },
  },
});

export const {
  fetchFunctionCountsRequest,
  fetchFunctionCountsSuccess,
  fetchFunctionCountsFailure,
  updateFunctionCountsProgress,
  verifyBucketPolicyStatusRequest,
  verifyBucketPolicyStatusSuccess,
  verifyBucketPolicyStatusFailure,
  verifyAccountStatusRequest,
  verifyAccountStatusSuccess,
  verifyAccountStatusFailure,
  clearAllScanServiceMessages,
  clearAllScanServiceMessagesSuccessful
} = scanServiceSlice.actions;

export default scanServiceSlice.reducer;
