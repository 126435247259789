import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  API_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  FORCE_LOGOUT,
  CLEAR_ALL_LOGIN_MESSAGES,
  CLEAR_ALL_LOGIN_MESSAGES_SUCCESS,
  VERIFY_TOTP_REQUEST,
  VERIFY_TOTP_SUCCESS,
  VERIFY_TOTP_FAILURE,
  LOGIN_USER_SUCCESSFUL_WITH_QR_CODE,
  LOGIN_USER_SUCCESSFUL_WITH_VERIFICATION,
} from "./actionTypes";

export const checkLogin = (user, history) => {
    return {
        type: CHECK_LOGIN,
        payload: { user, history }
    }
}

export const loginUserSuccessfulWithQrCode = (mfaSetup) => {
    return {
        type: LOGIN_USER_SUCCESSFUL_WITH_QR_CODE,
        payload: mfaSetup
    }
}

export const loginUserSuccessfulWithVerification = (verification) => {
    return {
        type: LOGIN_USER_SUCCESSFUL_WITH_VERIFICATION,
        payload: verification
    }
}

export const loginUserSuccessful = (user) => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: { error }
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}
export const forceLogout = () => {
    return {
        type: FORCE_LOGOUT,
        payload: { }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const verifyTotpRequest = (session, code) => {
    return {
        type: VERIFY_TOTP_REQUEST,
        payload: { session, code }
    }
}

export const verifyTotpSuccess = (verifyTotpSuccessMessage) => {
    return {
        type: VERIFY_TOTP_SUCCESS,
        payload: { verifyTotpSuccessMessage }
    }
}

export const verifyTotpFailure = (verifyTotpError) => {
    return {
        type: VERIFY_TOTP_FAILURE,
        payload: { verifyTotpError }
    }
}

export const clearAllLoginMessages = () => {
  return {
    type: CLEAR_ALL_LOGIN_MESSAGES,
    payload: {},
  };
};

export const clearAllLoginMessagesSuccess = () => {
  return {
    type: CLEAR_ALL_LOGIN_MESSAGES_SUCCESS,
    payload: {},
  };
};
