import React from "react";
import cloudcatcherLogo from "../../../../assets/images/Collapsed_BlueOrange_Curve.png";
import "./AnimatedLogo.css"; // Assuming you're storing the CSS separately

const AnimatedLogo = () => {
  return (
    <img
      src={cloudcatcherLogo}
      alt="Loading..."
      className="loading-logo"
    />
  );
};

export default AnimatedLogo;
