import {
  takeEvery,
  takeLatest,
  fork,
  put,
  all,
  call,
} from "redux-saga/effects";
import {
  FORGET_USER,
  RESET_PASSWORD_FORGOT,
  VALIDATE_RESET_TOKEN,
} from "./actionTypes";
import {
  forgetUserSuccessful,
  resetPasswordFailed,
  resetPasswordSuccessful,
  userForgetPasswordError,
  validateResetTokenFailed,
  validateResetTokenSuccess,
} from "./actions";
import {
  postForgetPwd,
  resetPasswordFrgt,
} from "../../../helpers/auth_aws_helper";

function* forgetUser({ payload: { user } }) {
  console.log("forgetUser :: ", user);
  try {
    const response = yield call(postForgetPwd, { username: user.useremail });
    console.log("forgetUser, response: ", JSON.stringify(response));
    if (!response) {
      yield put(userForgetPasswordError("No response is received"));
      return;
    }
    if (response.status === 200) {
      yield put(forgetUserSuccessful("Email sent!"));
    } else {
      let message;
      if (response?.data?.message) {
        message = response?.data?.message;
      } else {
        message = JSON.stringify(response?.data);
      }
      yield put(userForgetPasswordError(message));
    }
  } catch (error) {
    yield put(userForgetPasswordError(JSON.stringify(error)));
  }
}

function* resetPassword(action) {
  try {
    const response = yield call(resetPasswordFrgt, action.payload);
    console.log("Response in Saga :: ", response);

    // Check if the response is a string
    if (response.status === 200) {
      const successMessage =
        typeof response.data === "string"
          ? response.data
          : "Password changed successfully.";
      yield put(resetPasswordSuccessful({ message: successMessage }));
    } else {
      console.log("Response in else-block Saga :: ", response?.data);
      yield put(resetPasswordFailed({ response: { data: response.data } }));
    }
  } catch (error) {
    console.log("In catch block :: ", error);

    const errorMessage =
      error.response?.data?.message || error.message || "An error occurred";
    yield put(resetPasswordFailed({ response: { data: errorMessage } }));
  }
}

export function* watchUserForget() {
  yield takeEvery(FORGET_USER, forgetUser);
}

export function* watchResetFrgt() {
  yield takeLatest(RESET_PASSWORD_FORGOT, resetPassword);
}
function* forgetSaga() {
  yield all([fork(watchUserForget)]);
  // yield all([fork(watchValidateReset)]);
  yield all([fork(watchResetFrgt)]);
}

export default forgetSaga;
