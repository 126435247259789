import { createSlice } from "@reduxjs/toolkit";

export const featureConfigurationSlice = createSlice({
  name: "featureConfiguration",
  initialState: {
    isPostingFeatureConfiguration: false,
    postFeatureConfigurationMessage: null,
    postFeatureConfigurationError: null,
    featureConfigurationPayload: null,
  },
  reducers: {
    postFeatureConfiguration: (state, action ) => {
      state.isPostingFeatureConfiguration = true;
      state.postFeatureConfigurationMessage = null;
      state.postFeatureConfigurationError = null;
      state.featureConfigurationPayload = action.payload;
    },
    postFeatureConfigurationSuccessful: (state, action ) => {
      state.isPostingFeatureConfiguration = false;
      state.postFeatureConfigurationMessage = action.payload;
      state.postFeatureConfigurationError = null;
      state.featureConfigurationPayload = null;
    },
    postFeatureConfigurationFailure: (state, action ) => {
      state.isPostingFeatureConfiguration = false;
      state.postFeatureConfigurationMessage = null;
      state.postFeatureConfigurationError = action.payload.message;
      state.featureConfigurationPayload = null;
    },
    clearAllFeatureConfigurationMessages: (state, action) => {},
    clearAllFeatureConfigurationMessagesSuccessful: (state, action) => {
      state.postFeatureConfigurationMessage = null;
      state.postFeatureConfigurationError = null;
    },
  }
});

export const {
  postFeatureConfiguration,
  postFeatureConfigurationSuccessful,
  postFeatureConfigurationFailure,
  clearAllFeatureConfigurationMessages,
  clearAllFeatureConfigurationMessagesSuccessful,
} = featureConfigurationSlice.actions;

export default featureConfigurationSlice.reducer;