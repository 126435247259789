import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
  delay,
} from "redux-saga/effects";

import {
  ADD_RESOURCE_GROUP,
  DELETE_RESOURCE_GROUP,
  EDIT_RESOURCE_GROUP,
  GET_RESOURCE_GROUPS,
  CLEAR_ALL_RESOURCE_GROUP_MESSAGES,
} from "./actionTypes";

import {
  addResourceGroupSuccessful,
  addResourceGroupFailure,
  editResourceGroupSuccessful,
  editResourceGroupFailure,
  deleteResourceGroupSuccessful,
  deleteResourceGroupFailure,
  getResourceGroups as getResourceGroupsAction,
  getResourceGroupsSuccessful,
  getResourceGroupsFailure,
  clearAllResourceGroupMessagesSuccessful,
} from "./actions";

import {
  addResourceGroup as addResourceGroupApi,
  editResourceGroup as editResourceGroupApi,
  deleteResourceGroup as deleteResourceGroupApi,
  getResourceGroups as getResourceGroupsApi,
} from "../../helpers/auth_aws_helper";

function* getResourceGroups() {
  try {
    console.log("about to call *getResourceGroups()");
    const response = yield call(getResourceGroupsApi);
    console.log("*getResourceGroupsApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      const resourceGroups = Object.values(response);
      // yield put(getResourceGroupsSuccessful(response));
      yield put(getResourceGroupsSuccessful(resourceGroups));
    } else {
      yield put(getResourceGroupsFailure({ message: response.message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getResourceGroupsFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`
        })
      );
    } else if (error.request) {
      yield put(getResourceGroupsFailure({ message: "No response received" }));
    } else {    
      yield put(getResourceGroupsFailure({ message: error?.message }));
    }
  }
}

function* addResourceGroup({ payload: {resourceGroup} }) {
  try {
    console.log("about to call *addResourceGroup() with ", resourceGroup);
    const response = yield call(addResourceGroupApi, resourceGroup);
    console.log("*addResourceGroupApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      const responseString = Object.values(response)
                .filter((value) => typeof value === "string")
                .join("");
      yield put(addResourceGroupSuccessful(responseString));
      yield delay(2000);
      yield put(getResourceGroupsAction());
    } else {
      yield put(addResourceGroupFailure({ message: response.data }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        addResourceGroupFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(addResourceGroupFailure({ message: "No response received" }));
    } else {
      yield put(addResourceGroupFailure({ message: error?.message }));
    }
  }
}

function* editResourceGroup({ payload: {resourceGroup} }) {
  try {
    console.log("about to call *editResourceGroup() with ", resourceGroup);
    const response = yield call(editResourceGroupApi, resourceGroup);
    console.log("*editResourceGroupApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      const responseString = Object.values(response)
          .filter((value) => typeof value === "string")
          .join("");
      yield put(editResourceGroupSuccessful(responseString));
      yield delay(2000);
      yield put(getResourceGroupsAction());
    } else {
      yield put(editResourceGroupFailure({ message: response.data }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        editResourceGroupFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(editResourceGroupFailure({ message: "No response received" }));
    } else {
      yield put(editResourceGroupFailure({ message: error?.message }));
    }
  }
}

function* deleteResourceGroup({ payload: { resourceGroupName, orgCode } }) {
  try {
    console.log("Saga, deleteResourceGroup, Resource Group Name: ", resourceGroupName, " Org code: ", orgCode);
    const response = yield call(deleteResourceGroupApi, resourceGroupName, orgCode);
    console.log("deleteResourceGroupApi called, received response: ", response);
    if (response && response.status === 200) {
      delete response.status;
      const responseString = Object.values(response)
          .filter((value) => typeof value === "string")
          .join("");
      yield put(deleteResourceGroupSuccessful(responseString));
      yield delay(2000);
      yield put(getResourceGroupsAction());
    } else {
      yield put(deleteResourceGroupFailure({ message: response.data }));
    }
    
  } catch (error) {
    console.log("deleteResourceGroupApi called, received Error: ", error);
    if (error.response) {
      yield put(
        deleteResourceGroupFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(deleteResourceGroupFailure({ message: "No response received" }));
    } else {
      yield put(deleteResourceGroupFailure({ message: error?.message }));
    }
  }
}

export function* clearAllResourceGroupMessages() {
  console.log("Saga: clear All ResourceGroup Messages");
  yield put(clearAllResourceGroupMessagesSuccessful());
}

export function* watchResourceGroup() {
  yield takeLatest(GET_RESOURCE_GROUPS, getResourceGroups);
  yield takeLatest(ADD_RESOURCE_GROUP, addResourceGroup);
  yield takeLatest(EDIT_RESOURCE_GROUP, editResourceGroup);
  yield takeLatest(DELETE_RESOURCE_GROUP, deleteResourceGroup);
  yield takeLatest(
    CLEAR_ALL_RESOURCE_GROUP_MESSAGES,
    clearAllResourceGroupMessages
  );
}

export function* resourceGroupSaga() {
  yield all([fork(watchResourceGroup)]);
}

export default resourceGroupSaga;