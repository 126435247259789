import React, { Component } from "react";

import {
  Grid,
  Typography,
  TextField,
  Button,
  // Link,
  Box,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { forgetUser, clearStore } from "../../store/actions";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";
import { BackgroundPattern } from "../../components/BackgroundPattern";
const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    animation: "$animateBackground 10s infinite",
  },
  "@keyframes animateBackground": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0)",
  },
  container: {
    position: "relative",
    zIndex: 1,
    justifyContent: "space-evenly",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.redirectToResetPage = this.redirectToResetPage.bind(this);
  }

  redirectToResetPage() {
    const { username } = this.state;
    const { history } = this.props;
    const state = { email: username };
    this.props.clearStore();
    history.push("/reset-password", state);
  }

  handleValidSubmit(event, values) {
    this.props.forgetUser({useremail: this.state.username});
  }

  componentDidMount() {
    document.body.classList.add("auth-body-bg");
  }

  componentDidUpdate() {
    if (this.props.message === "Email sent!") {
      this.redirectToResetPage();
    }
  }

  handleError() {
    setTimeout(() => {
      this.props.clearStore();
    }, 5000);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.root}`}>
        <div className={`${classes.background}`}></div>
        <Grid container component="main" className={classes.container}>
          <Grid item xs={12} sm={8} md={5} lg={4}>
            <div className={classes.form}>
              <div className={classes.logo}>
                <Link to="/" className="logo">
                  <img src={logodark} height="60" alt="logo" />
                </Link>
              </div>
              <Typography component="h1" variant="h5" align="center">
                Reset your password to CloudCatcher!
              </Typography>
              <Snackbar
                open={this.props.forgetError !== null}
                autoHideDuration={5000}
                onClose={this.handleError}
              >
                <Alert severity="error">{this.props.forgetError}</Alert>
              </Snackbar>
              <Snackbar
                open={this.props.message !== null}
                autoHideDuration={5000}
                onClose={this.handleError}
              >
                <Alert severity="success">{this.props.message}</Alert>
              </Snackbar>
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  fullWidth
                  id="useremail"
                  label="Email Address"
                  name="useremail"
                  autoComplete="email"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {this.props.loading ? "Loading..." : "Reset"}
                </Button>
              </AvForm>
              <Box mt={2} textAlign="center">
                <Typography variant="body2">
                  Return to{" "}
                  <Link to="/login" color="primary">
                    Login Page
                  </Link>
                </Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
        <BackgroundPattern />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { message, forgetError, loading } = state.forgetPassword;
  return { message, forgetError, loading };
};

export default withStyles(styles)(withRouter(
  connect(mapStateToProps, { forgetUser, clearStore })(ForgetPasswordPage)
  )
);