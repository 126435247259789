import { 
	GET_LIST_OF_USERS,
  GET_LIST_OF_USERS_SUCCESSFUL,
  GET_LIST_OF_USERS_FAILED,
  RESET_NEW_USER,
  POST_NEW_USER_SUCCESSFUL,
  POST_NEW_USER_FAILED,
  SET_REACTIVE_USER,
	POST_NEW_USER,
	POST_EDIT_USER,
	POST_EDIT_USER_SUCCESSFUL,
	POST_EDIT_USER_FAILED,
  CLEAR_ALL_USER_LIST_MESSAGES_SUCCESSFUL,
  DELETE_USER_SUCCESSFUL,
  DELETE_USER_FAILED,
} from './actionTypes';

const initialState = {
  error: null,
  message: null,
  loading: null,
  addNewUserError: null,
  addNewUserMessage: null,
  editUserError: null,
  editUserMessage: null,
  deleteUserError: null,
  deleteUserMessage: null,
};

const userList = (state = initialState, action) => {
	switch (action.type) {
    case GET_LIST_OF_USERS:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case GET_LIST_OF_USERS_SUCCESSFUL:
      state = {
        ...state,
        users: action.payload.usr.userDto,
        loading: false,
        error: null,
      };
      break;
    case GET_LIST_OF_USERS_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload.usr,
      };
      break;
    case RESET_NEW_USER:
      state = {
        ...state,
        loading: false,
        addNewUserResponse: {},
      };
      break;
    case POST_NEW_USER:
      state = {
        ...state,
        addNewUserLoading: true,
        addNewUserError: null,
        addNewUserMessage: null,
      };
      break;
    case POST_NEW_USER_SUCCESSFUL:
      state = {
        ...state,
        addNewUserLoading: false,
        addNewUserMessage: action.payload.addUserResponse.message,
      };
      break;
    case POST_NEW_USER_FAILED:
      state = {
        ...state,
        addNewUserLoading: false,
        addNewUserError: action.payload.error.message,
      };
      break;
    case POST_EDIT_USER:
      state = {
        ...state,
        editUserLoading: true,
        editUserMessage: null,
        editUserError: null,
      };
      break;
    case POST_EDIT_USER_SUCCESSFUL:
      state = {
        ...state,
        editUserLoading: false,
        editUserMessage: action.payload.editUserResponse.message,
        editUserError: null,
      };
      break;
    case POST_EDIT_USER_FAILED:
      state = {
        ...state,
        editUserLoading: false,
        editUserError: action.payload.error.message,
      };
      break;
    case DELETE_USER_SUCCESSFUL:
      state = {
        ...state,
        deleteUserLoading: false,
        deleteUserMessage: action.payload.deleteUserResponse.message,
        deleteUserError: null,
      };
      break;
    case DELETE_USER_FAILED:
      state = {
        ...state,
        deleteUserLoading: false,
        deleteUserError: action.payload.error.message,
      };
      break;
    case SET_REACTIVE_USER:
      state = {
        ...state,
        loading: false,
        reActiveUser: action.payload,
      };
      break;
    case CLEAR_ALL_USER_LIST_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        addNewUserLoading: false,
        addNewUserError: null,
        addNewUserMessage: null,
        editUserLoading: false,
        editUserError: null,
        editUserMessage: null,
        deleteUserLoading: false,
        deleteUserError: null,
        deleteUserMessage: null,
      };
      break;
    default:
      return state;
  }
	return state;
}

export default userList;