import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import { Alert } from "@material-ui/lab";

import UserConfigurationDetails from './../../components/UserConfigurationDetails';
import { addNewUser, deleteUser, editUser, reActive, resetNewUser, getListOfAccts, clearAllUserListMessages } from "../../store/actions"

import spinner from "../../assets/images/aws/loadingGif.gif";
import { ExtractCountryCallingCode, ExtractPhoneNumber } from '../../components/UserConfigurationDetails/ExtractPhoneNumber';


const UsersConfig = () => {
    const dispatch = useDispatch()
    const licensing = useSelector((state) => state.licensing);
    const acctList = useSelector((state) => state.acctList);
    const { accounts, organizations, orgAndAccountsLoading, orgAndAccountsError } = useSelector((state) => {
        console.log('--== state.orgAccount ', state.orgAccount);
        const { accounts, organizations, loading, error } = state.orgAccount
        return {
            accounts, 
            organizations: organizations && organizations.length > 0 ? organizations[0] : {},
            orgAndAccountsLoading: loading,
            orgAndAccountsError: error,
        }
    });
    const userList = useSelector((state) => state.userList);
    const profile = useSelector((state) => state.profile);
    console.log('-- UserConfig ** ', licensing, acctList, userList);
    const {
      addNewUserError,
      addNewUserLoading,
      addNewUserMessage,
      status, reActiveUser,
      editUserError,
      editUserLoading,
      editUserMessage,
      deleteUserError,
      deleteUserLoading,
      deleteUserMessage,
    } = userList;
    const addNewUserResponse = {
      addNewUserError,
      addNewUserLoading,
      addNewUserMessage,
      status,
    };
    const editUserResponse = {
      editUserError,
      editUserLoading,
      editUserMessage,
    };

    const getRoleAccess = useCallback(() => {
        if (licensing && licensing.data.isLicensingComplete && profile && profile.userRoles && profile.userRoles.includes("Administrator")) {
            return 0;
        }

        return -1;
    });
    useEffect(() => {
        
            dispatch(getListOfAccts());
        
    }, [])

    // useEffect(() => {
    //     if ((addNewUserResponse && addNewUserResponse.status === 200) || (reActiveUser && reActiveUser?.status === 200)) {
    //         dispatch(getListOfAccts());
    //     }
    // }, [addNewUserResponse, reActiveUser])

    useEffect(() => {
      if (
        addNewUserMessage ||
        editUserMessage ||
        deleteUserMessage ||
        (reActiveUser && reActiveUser?.status === 200)
      ) {
        dispatch(getListOfAccts());
      }
    }, [addNewUserMessage, editUserMessage, deleteUserMessage, reActiveUser]);

    //For clearing messages after Snackbars are displayed
    useEffect(() => {
        if ( deleteUserError || deleteUserMessage) {
            setTimeout(() => {
                console.log("About to call clearAllUserListMessages");
                dispatch(clearAllUserListMessages());
          }, 10000); // Hide all messages after 10 seconds
        }
    }, [deleteUserMessage, deleteUserError]);

    const handleSubmit = (values) => {
        console.log('--== handleSubmit ', values);
        if (values.isEditFlow) {
            dispatch(editUser(values));
        } else {
            dispatch(addNewUser(values));
        }

    }
    const hanldeResetNewUser = () => {
        dispatch(resetNewUser())
    }

    const onReActive = (payload) => {
        dispatch(reActive(payload));
    }

    const onDelete = (payload) => {
        const acccodes = payload?.listacct?.map(
            account => ({ acccode: account.accountCode })
        ) || [];
        const resourceGroupDTOS = payload?.userGroup?.map(
            userGroup => ({ resourcename: userGroup.resourcename })
        ) || [];
        const userRolesDTOS = payload?.roles || [];
        const deleteApiPayload = { 
            firstName: payload.firstname,
            lastName: payload.lastName,
            userName: payload.username,
            // password: null,
            email: payload.email,
            username: payload.email,
            country: payload.country,
            phoneNumber: ExtractPhoneNumber(payload.phoneNumber),
            countryCode: ExtractCountryCallingCode(payload.phoneNumber),
            orgCode: organizations.orgcode,
            acccodes: acccodes,
            userRolesDTOS: userRolesDTOS,
            resourceGroupDTOS: resourceGroupDTOS, 
            action: payload.action,
        }
        // console.log("delete payload", deleteApiPayload);
        dispatch(deleteUser(deleteApiPayload));
    }

    return (
        <React.Fragment>
            { (licensing.loading || acctList.loading || orgAndAccountsLoading || userList.loading || profile.loading) && 
                    (<React.Fragment>
                        <div
                            style={{
                                display: "flex",
                                direction: "row",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                justifyItems: "center",
                                height: "80vh",
                            }}
                        >
                            <img src={spinner} width="32px" alt="Spinner" />
                        </div>
                    </React.Fragment>)            
            }
            { (licensing.error || acctList.error || orgAndAccountsError || userList.error || profile.error) && 
                (<React.Fragment>
                    <div
                        style={{
                            display: "flex",
                            direction: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            justifyItems: "center",
                            height: "80vh",
                            color: "red"
                        }}
                    >
                        <div>Something went wrong! Refresh the page.</div>
                    </div>
                </React.Fragment>)            
            }
            {
                licensing && licensing.data && accounts && organizations && (
                    <UserConfigurationDetails
                        licensing={licensing.data}
                        accounts={accounts}
                        organizations={organizations}
                        usersConfig={acctList.usersConfig}
                        roleAccess={getRoleAccess()}
                        handleSubmit={handleSubmit}
                        onReActive={onReActive}
                        onDelete={onDelete}
                        addNewUserResponse={addNewUserResponse}
                        editUserResponse={editUserResponse}
                        hanldeResetNewUser={hanldeResetNewUser}
                    />
                )
            }

            <Snackbar
                open={deleteUserError!==null}
                autoHideDuration={5000}
            >
                <Alert severity="error">{deleteUserError}</Alert>
            </Snackbar>
            <Snackbar
                open={deleteUserMessage!==null}
                autoHideDuration={5000}
            >
                <Alert severity="success">{deleteUserMessage}</Alert>
            </Snackbar>        
        </React.Fragment>
    )
}

export default UsersConfig;