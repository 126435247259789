import React from "react";

import step1Icon from "../../../assets/images/pairingGuide/step1.png";
import step2Icon from "../../../assets/images/pairingGuide/step2.png";
import step3Icon from "../../../assets/images/pairingGuide/step3.png";
import step4Icon from "../../../assets/images/pairingGuide/step4.png";

const PairingAccountGuide = () => {
    const pageContentStyle = {
        maxWidth: '1200px', 
        margin: '0 auto',
    };

    const imageStyle = {
        maxWidth: '100%', // Makes image responsive
        height: 'auto', // Maintains aspect ratio
    };

    return(
        <div className="page-content" style={pageContentStyle}>
            {/* Version 1 */}
            <h3>Step-by-Step guide to pair an AWS account with CloudCatcher</h3>
            <ol>
                <li>
                    Copy and paste the link provided during the “Add AWS Account” wizard within  <strong>CloudCatcher</strong> in a new browser. Verify the “Account ID” is the same as the one provided in the link. <br />
                    <img src={step1Icon} alt="step 1" style={imageStyle} />
                </li>
                <br />
                <li>
                    Choose <strong>Next:</strong>
                    <p>In the <strong>Add permissions</strong> section, use the search function to find the "ReadOnlyAccess" policy. Utilize the "Filter by Type" dropdown menu to choose "AWS managed - job function". Ensure that the "ReadOnlyAccess" policy is selected.</p>
                    <img src={step2Icon} alt="step 2" style={imageStyle} />
                </li>
                <br />
                <li>
                    Choose <strong>Next:</strong>
                    <p>Ensure that the role name is set to <strong>"CloudCatcherTrustRole"</strong>. If there is a particular naming convention for roles within your organization, please name the role accordingly. Note down the role name, as it will be required in the "Verify Pairing" phase of the AWS Account Onboarding Process. In the "Description (Optional)" field, enter a description.</p>
                    <img src={step3Icon} alt="step 3" style={imageStyle} />
                </li>
                <br />
                <li>
                    Choose <strong>Next:</strong>
                    <p>Confirm that the "ReadOnlyAccess" policy is selected. For <strong>Tags</strong>(Optional), add relevant tag information by following your company’s Tagging guidelines and then choose <strong>Create role</strong>.</p>
                    <img src={step4Icon} alt="step 4" style={imageStyle} /><br />
                    <p>You have successfully paired your account with CloudCatcher.</p>
                    <p><strong>Note:</strong> Ensure that the role is not accidentally deleted by automated IAM roles cleanup tools or exercise.</p>
                </li>
            </ol>
        </div>
    )
};

export default PairingAccountGuide;
