import {
  SEND_SUPPORT_EMAIL_REQUEST,
  SEND_SUPPORT_EMAIL_SUCCESSFUL,
  SEND_SUPPORT_EMAIL_FAILED,
  CLEAR_ALL_SUPPORT_MESSAGES,
  CLEAR_ALL_SUPPORT_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

export const sendSupportEmailRequest = (emailPayload) => ({
  type: SEND_SUPPORT_EMAIL_REQUEST,
  payload: { emailPayload },
});

export const sendSupportEmailSuccessful = () => ({
  type: SEND_SUPPORT_EMAIL_SUCCESSFUL
});

export const sendSupportEmailFailed = (sendSupportEmailError) => ({
  type: SEND_SUPPORT_EMAIL_FAILED,
  payload: { sendSupportEmailError }
});

export const clearAllSupportMessages = () => ({
  type: CLEAR_ALL_SUPPORT_MESSAGES
});

export const clearAllSupportMessagesSuccessful = () => ({
  type: CLEAR_ALL_SUPPORT_MESSAGES_SUCCESSFUL
});

