import {
	takeEvery,
	fork,
	put,
	all,
	call,
	takeLatest,
} from "redux-saga/effects";
import {
  REGISTER_ORG,
  RESEND_ACTIVATE_EMAIL,
  RESET_PASSWORD,
  VALIDATE_TOKEN,
  PUT_BILLING_INFO,
  PUT_MAILING_INFO,
  CLEAR_ALL_ORGANIZATION_MESSAGES,
} from "./actionTypes";
import {
  registerOrgSuccessful,
  registerOrgFailed,
  resendEmailSuccessful,
  resendEmailFailed,
  resetPasswordSuccessful,
  resetPasswordFailed,
  validateTokenSuccessful,
  validateTokenFailed,
  setBillingInfo,
  setMailingInfo,
  clearAllOrganizationMessagesSuccessful as clearAllOrganizationMessagesSuccessfulAction,
} from "./actions";
import { validateProspectiveToken, resetPwd } from "../../../helpers/auth_aws_helper";
import { postRegister, getActivateEmail, putRegister } from "../../../helpers/auth_aws_helper";
import EncryptPassword from "../../../components/EncryptPassword";
import { fetchAccessToken } from "../../../helpers/jwt-token-access/accessToken";
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function* registerOrg({ payload: { org } }) {
  console.log("##### In registerOrg with ", org);

  try {
    
    const orgPrimaryEmailEncrypted = yield call(EncryptPassword, org.orgEmail);
    const orgBillingEmailEncrypted = yield call(EncryptPassword, org.orgEmail);
    const userNameEncrypted = yield call(EncryptPassword, org.userName);
    const userEmailEncrypted = yield call(EncryptPassword, org.userEmail);
    const userPasswordEncrypted = yield call(EncryptPassword, org.userPassword);

    const response = yield call(postRegister, {
      orgcode: org.orgCode,
      orgname: org.orgName,
      orgmetadata: "fake_data",
      orgdescription: "fake_data",
      orgprimaryname: org.orgPrimaryContact,
      orgprimaryemail: orgPrimaryEmailEncrypted,
      orgaddress: org.orgAddress,
      orgcity: org.orgCity,
      orgstate: org.orgState,
      orgcountry: org.orgCountry,
      orgzip: org.orgZipCode,
      orgbillingname: org.orgBillingName,
      orgbillingemail: orgBillingEmailEncrypted,
      orgbillingaddress: org.orgBillingAddress,
      orgbillingcity: org.orgBillingCity,
      orgbillingstate: org.orgBillingState,
      orgbillingcountry: org.orgBillingCountry,
      orgbillingzip: org.orgBillingZipCode,
      phoneNumber: org.orgPhone,
      selltoken: org.selltoken,
      user: {
        userName: userNameEncrypted,
        firstName: org.userFirstName,
        lastName: org.userLastName,
        email: userEmailEncrypted,
        password: userPasswordEncrypted,
      },
    });

    yield (delay, 5000);
    console.log("RESPONSE :: ", response);

    if (response && response.status === 200) {
      yield put(registerOrgSuccessful(response.data));
    } else {
      console.log("Else block in try, response: ", response);
      if (response.data.message) {
        yield put(
          registerOrgFailed(response.data.message)
        );
      } else {
        yield put(
          registerOrgFailed(response.data)
        );
      }
    }
  } catch(error){
    console.log("~~~ postRegister, received Error is :: ", JSON.stringify(error));
    if(error.response){
      yield put(registerOrgFailed("Error Code: "+error.response?.status+", Message: "+ error.response?.data));
    } else if (error.request) {
      yield put(registerOrgFailed("No response is received"));
    } else {
      yield put(registerOrgFailed( error?.message ));
    }
  }
}

function* handlePutBillingInfo({ type, payload }) {
  try {
    const response = yield call(putRegister, payload);
    yield put(setBillingInfo({ response }));
  } catch (error) {
    yield put(setBillingInfo({ error }));
  }
}

function* handlePutMailingInfo({ type, payload }) {
  try {
    const response = yield call(putRegister, payload);
    yield put(setMailingInfo({ response }));
  } catch (error) {
    yield put(setMailingInfo({ error }));
  }
}

function* resendEmail({ payload: { account } }) {
  // console.log("payload,", account)
  try {
    const response = yield call(getActivateEmail, { email: account });
    yield put(resendEmailSuccessful(response));
  } catch (error) {
    yield put(resendEmailFailed(error));
  }
}

function* validateToken({ payload: { token } }) {
	try {
		const response = yield call(validateProspectiveToken, { token: token });
    console.log("Resp :: ", response)
    if(response && response.status === 200)
    {
      yield put(validateTokenSuccessful(response.data));
    }
    else{
      yield put(validateTokenFailed(response.data));  
    }
	}
	catch (error) {
		yield put(validateTokenFailed(error))
	}
}

export function* clearAllOrganizationMessages() {
  console.log("Saga: clear all messages");
  yield put(clearAllOrganizationMessagesSuccessfulAction());
}

export function* watchOrgRegister() {
	yield takeEvery(REGISTER_ORG, registerOrg)
}

export function* watchResendEmail() {
	yield takeEvery(RESEND_ACTIVATE_EMAIL, resendEmail)
}
export function* watchResetPassword() {
	//Take latest password reset call, avoid multiple press of buttons
	yield takeLatest(RESET_PASSWORD, resetPassword)
}
export function* watchValidateToken() {
	yield takeLatest(VALIDATE_TOKEN, validateToken)
}
export function* watchPutMailingInfo() {
	yield takeLatest(PUT_MAILING_INFO, handlePutMailingInfo)
}
export function* watchPutBillingInfo() {
	yield takeLatest(PUT_BILLING_INFO, handlePutBillingInfo)
}

export function* watchClearAllOrganizationMessages() {
	yield takeLatest(CLEAR_ALL_ORGANIZATION_MESSAGES, clearAllOrganizationMessages)
}

function* organizationSaga() {
	yield all([fork(watchOrgRegister)]);
	yield all([fork(watchResetPassword)]);
	yield all([fork(watchResendEmail)]);
	yield all([fork(watchValidateToken)]);
	yield all([fork(watchPutMailingInfo)]);
	yield all([fork(watchPutBillingInfo)]);
	yield all([fork(watchClearAllOrganizationMessages)]);
}

function* resetPassword({ payload: { user } }) {
	try {

    const token = fetchAccessToken();

    const currentPassword = yield call(EncryptPassword, user.oldPassword);
    const newPassword = yield call(EncryptPassword, user.confirmPassword);

		const response = yield call(resetPwd, {
      currentPassword: currentPassword,
      newPassword: newPassword,
      accessToken: token,
    });
		console.log("--== resetPassword saga response ", response);
		if (response && response.status === 200) {
			delete response.status;
			// Extract the values of the remaining keys
			const values = Object.values(response);
			// Join the values into a single string
			const resultString = values.join('');
			yield put(resetPasswordSuccessful({ message: resultString}));
		} else {
			yield put(resetPasswordFailed({ message: response.data }));
		}
	} catch (error) {
		console.log("--== resetPassword saga error ", error);
		if (error.response) {
      yield put(
        resetPasswordFailed({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(resetPasswordFailed({ message: "No response received" }));
    } else {
      yield put(resetPasswordFailed({ message: error?.message }));
    }
	}
}
export default organizationSaga;