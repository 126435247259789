import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Alert, Tooltip } from "@mui/material";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import OutboundIcon from "@mui/icons-material/Outbound";
import DocumentIcon from '@mui/icons-material/Description';
import { IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import awsRegions from "aws-regions";
import UrlDisplayDialog from "../../../pages/Support/common/UrlDisplayDialog";
import { getReleaseDetailsRequest, clearAllReleaseMessages } from "../../../store/actions";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import ScriptDisplayDialog from "./ScriptDisplayDialog"
import loadingGif from "../../../assets/images/aws/loadingGif.gif";
import { verifyBucketPolicyStatusRequest } from "../../../store/scanService/scanServiceSlice";

const isCostInsightPresent = (selectedIds, objectRows) => {
  return objectRows.some(row => selectedIds.includes(row.id) && row.addons === "Cost Insight");
}

const isEventInsightPresent = (selectedIds, objectRows) => {
  return objectRows.some(row => selectedIds.includes(row.id) && row.addons === "Event Insight");
}

const isCostOptPresent = (selectedIds, objectRows) => {
  return objectRows.some(row => selectedIds.includes(row.id) && row.addons === "Cost Optimization");
}

const evInsManualConfigGuideLink = process.env.REACT_APP_EVENT_INSIGHT_MANUAL_CONFIGURATION_GUIDE;
const coInsManualConfigGuideLink = process.env.REACT_APP_COST_INSIGHT_MANUAL_CONFIGURATION_GUIDE;
const coOptManualConfigGuideLink = process.env.REACT_APP_COST_OPTIMIZATION_MANUAL_CONFIGURATION_GUIDE;

const evInsCliGuideLink = process.env.REACT_APP_EVENT_INSIGHT_CLI_GUIDE;
const evInsCliScriptLink = process.env.REACT_APP_EVENT_INSIGHT_CLI_SCRIPT;

const evInsCloudFormationGuideLink = process.env.REACT_APP_EVENT_INSIGHT_CLOUDFORMATION_GUIDE;
const evInsCloudFormationTemplateLink = process.env.REACT_APP_EVENT_INSIGHT_CLOUDFORMATION_TEMPLATE;


const linksInfo = [
  {
    sectionName: "Event Insight",
    manualLink: evInsManualConfigGuideLink,
    cliGuideLink: evInsCliGuideLink,
    cliScriptLink: evInsCliScriptLink,
    cloudFormationGuideLink: evInsCloudFormationGuideLink,
    cloudFormationTemplateLink: evInsCloudFormationTemplateLink,
  },
  {
    sectionName: "Cost Insight",
    manualLink: coInsManualConfigGuideLink,
    cliGuideLink: null,
    cliScriptLink: null,
    cloudFormationGuideLink: null,
    cloudFormationTemplateLink: null,
  },
  {
    sectionName: "Cost Optimization",
    manualLink: coOptManualConfigGuideLink,
    cliGuideLink: null,
    cliScriptLink: null,
    cloudFormationGuideLink: null,
    cloudFormationTemplateLink: null,
  },  
];


function AddOns({
  complianceRows,
  complianceAddOns,
  setComplianceAddOns,
  licenseData,
  eventAndCostInsightAddOns,
  setEventAndCostInsightAddOns,
  accountNumber,
  setBucketPolicyStatusEventInsight,
  setBucketPolicyEnabledEventInsight,
  setBucketPolicyStatusCostInsight,
  setBucketPolicyEnabledCostInsight,
  setBucketPolicyStatusCostOpt,
  setBucketPolicyEnabledCostOpt,
  readOnlyRole,
  license,
  releaseDetails,
  isReleaseDetailsGetting,
  getReleaseDetailsMessage,
  getReleaseDetailsError,
  getReleaseDetails,
  clearAllReleaseMessages,
  externalId,
  bucketPolicyStatusLoading,
  bucketPolicyStatusMessage,
  bucketPolicyStatusError,
  verifyBucketPolicyStatus
}) {
  const [showArnFieldsEventInsight, setShowArnFieldsEventInsight] = useState(false);
  const [showArnFieldsCostInsight, setShowArnFieldsCostInsight] = useState(false);
  const [showArnFieldsCostOpt, setShowArnFieldsCostOpt] = useState(false);

  const [verifyBucketPolicyEventInsight, setVerifyBucketPolicyEventInsight] = useState(""); // 1 means verified and 0 means not verified
  const [verifyBucketPolicyErrorEventInsight, setVerifyBucketPolicyErrorEventInsight] = useState(false); // true means error in verification
  const [verifyBucketPolicySuccessEventInsight, setVerifyBucketPolicySuccessEventInsight] =
    useState(false); //true means verified and false means not
  const [verifyBucketPolicyLoadingEventInsight, setVerifyBucketPolicyLoadingEventInsight] =
    useState(false); // verify bucket policy loading status

  const [verifyAddons, setVerifyAddons] = useState(null);

  const [verifyBucketPolicyCostInsight, setVerifyBucketPolicyCostInsight] = useState(""); // 1 means verified and 0 means not verified
  const [verifyBucketPolicyErrorCostInsight, setVerifyBucketPolicyErrorCostInsight] = useState(false); // true means error in verification
  const [verifyBucketPolicySuccessCostInsight, setVerifyBucketPolicySuccessCostInsight] =
    useState(false); //true means verified and false means not
  const [verifyBucketPolicyLoadingCostInsight, setVerifyBucketPolicyLoadingCostInsight] =
    useState(false); // verify bucket policy loading status

    const [verifyBucketPolicyCostOpt, setVerifyBucketPolicyCostOpt] = useState(""); // 1 means verified and 0 means not verified
  const [verifyBucketPolicyErrorCostOpt, setVerifyBucketPolicyErrorCostOpt] = useState(false); // true means error in verification
  const [verifyBucketPolicySuccessCostOpt, setVerifyBucketPolicySuccessCostOpt] =
    useState(false); //true means verified and false means not
  const [verifyBucketPolicyLoadingCostOpt, setVerifyBucketPolicyLoadingCostOpt] =
    useState(false); // verify bucket policy loading status

  const [eventInsightRole, setEventInsightRole] = useState("");
  const [eventInsightBucketName, setEventInsightBucketName] = useState("");
  const [eventInsightSelectedRegion, setEventInsightSelectedRegion] = useState("");

  const [costInsightRole, setCostInsightRole] = useState("");
  const [costInsightBucketName, setCostInsightBucketName] = useState("");
  const [costInsightSelectedRegion, setCostInsightSelectedRegion] = useState("");

  const [modalType, setModalType] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const [activePart, setActivePart] = useState("");
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [scriptModalOpen, setScriptModalOpen] = useState(false);

  const [documentUrl, setDocumentUrl] = useState(null);
  const [cliDocumentUrl, setCliDocument] = useState(null);
  
  
  console.log("license is ", license);

  console.log("AWS Regions :: ", awsRegions.list());

  console.log("Rerender props ======= ");
  console.log("bucketPolicyStatusMessage ======= ", bucketPolicyStatusMessage);
  console.log("bucketPolicyStatusError ======= ", bucketPolicyStatusError);
  console.log("bucketPolicyStatusLoading ======= ", bucketPolicyStatusLoading);
  console.log("Rerender props ======= ");

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    // {
    //   field: "price",
    //   headerName: (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       Price&nbsp;
    //       <small style={{ color: "red", fontSize: "smaller" }}>
    //         (Billed Monthly)
    //       </small>
    //     </div>
    //   ),
    //   width: 200,
    //   valueFormatter: (params) => `$${params.value}`,
    // },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
  ];

  // const renderInfoIcon = (rowData) => {
  //   // console.log("rowData = ",rowData);
  //   const value = rowData.value;
  //   if (value === 0 && rowData.row.addons === "Cost Insight") {
  //     return "Free";
  //   } else {
  //     return value === "Contact us"
  //       ? value
  //       : value ===0
  //       ? "Free"
  //       : `$${value}`;
  //   }
  // };

  const eventInsightColumns = [
    // { field: "id", headerName: "ID", width: 50, sortable: false },
    {
      field: "addons",
      headerName: "Addons",
      width: 130,
      sortable: false,
      renderCell: (rowData) => {
        // console.log("rowData.field = ", rowData.field);
        if (rowData.row.addons === "Event Insight") {
          return (
            <>
              Event Insight
              <Tooltip title="Please contact support if you would like to use encryption for CloudTrail logs." placement="top">
                <IconButton size="small" color="primary">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }        
        if (rowData.row.addons === "Cost Insight") {
          return <>Cost Insight</>;
        }
        if (rowData.row.addons === "Cost Optimization") {
          return <>Cost Optimization</>;
        }
      },
    },
    // {
    //   field: "price",
    //   headerName: (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       Price&nbsp;
    //       <small style={{ color: "red", fontSize: "smaller" }}>
    //         (Billed Monthly)
    //       </small>
    //     </div>
    //   ),
    //   width: 150,
    //   sortable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: renderInfoIcon,
    // },
    {
      field: "Configuration",
      headerName: "Manual Configuration",
      width: 170,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (rowData) => {
        // console.log("rowData Manual Config : ", rowData);
        const sectionLinksInfo = linksInfo.find(section => section.sectionName === rowData.row.addons);
        return (
          <div style={{ 
              width: "100%", 
              textAlign: "center" }}
          >
            { (sectionLinksInfo.manualLink) ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("help");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("manual");
                  setHelpModalOpen(true);
                  const config = sectionLinksInfo.manualLink;
                  getReleaseDetails(config);
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {(activeSection === rowData.row.addons && activePart === "manual" && isReleaseDetailsGetting) ? 
                    (<img src={loadingGif} height={20} alt="Loading Spinner" />) : 
                    (<DocumentIcon fontSize="small" color="primary" />)
                }
              </IconButton>
            ) : null}
          </div>
        );
      },
    },
    {
      field: "cli",
      headerName: "CLI",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (rowData) => {
        // console.log("rowData : ", rowData);
        const sectionLinksInfo = linksInfo.find(section => section.sectionName === rowData.row.addons);
        return (
          <div style={{ 
            // backgroundColor: "lightskyblue", 
            width: "100%", 
            textAlign: "center" }}
          >
            {sectionLinksInfo.cliGuideLink && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("help");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("cli-help");
                  setHelpModalOpen(true);
                  getReleaseDetails(sectionLinksInfo.cliGuideLink);
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {(activeSection === rowData.row.addons && activePart === "cli-help" && isReleaseDetailsGetting) ? 
                    (<img src={loadingGif} height={20} alt="Loading Spinner" />) : 
                    (<DocumentIcon fontSize="small" color="primary" />)
                }
              </IconButton>
            )}
            {sectionLinksInfo.cliScriptLink && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("script");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("cli-script");
                  setScriptModalOpen(true);
                  getReleaseDetails(sectionLinksInfo.cliScriptLink);
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {(activeSection === rowData.row.addons && activePart === "cli-script" && isReleaseDetailsGetting) ? 
                    (<img src={loadingGif} height={20} alt="Loading Spinner" />) : 
                    (<OutboundIcon fontSize="small" color="primary"/>)
                }
              </IconButton>
            )}
          </div>
        );
      },
    },
    {
      field: "cloudformation",
      headerName: "Cloudformation",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (rowData) => {
        // console.log("rowData : ", rowData);
        const sectionLinksInfo = linksInfo.find(section => section.sectionName === rowData.row.addons);
        return (
          <div style={{ 
            // backgroundColor: "lightskyblue", 
            width: "100%", 
            textAlign: "center" }}
          >
            {sectionLinksInfo.cloudFormationGuideLink && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("help");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("cf-help");
                  setHelpModalOpen(true);
                  getReleaseDetails(sectionLinksInfo.cloudFormationGuideLink);
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {(activeSection === rowData.row.addons && activePart === "cf-help" && isReleaseDetailsGetting) ? 
                    (<img src={loadingGif} height={20} alt="Loading Spinner" />) : 
                    (<DocumentIcon fontSize="small" color="primary" />)
                }
              </IconButton>
            )}
            {sectionLinksInfo.cloudFormationTemplateLink && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("external");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("cf-link");
                  getReleaseDetails(sectionLinksInfo.cloudFormationTemplateLink);
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {(activeSection === rowData.row.addons && activePart === "cf-link" && isReleaseDetailsGetting) ? 
                    (<img src={loadingGif} height={20} alt="Loading Spinner" />) : 
                    (<OutboundIcon fontSize="small" color="primary"/>)
                }
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];


  let subscriptionPlanData = licenseData.filter(
    (plan) => plan["Name"]?.toLowerCase() === license?.toLowerCase()
  );
  console.log("~~~subscriptionPlanData = ", subscriptionPlanData);

  let additionalAddonsRows = subscriptionPlanData[0]["Add ons"];
  console.log("~~~additionalAddonsRows = ", additionalAddonsRows);

  // First sort addons so that Cost Optimization comes after Cost Insight
  const desiredOrder = ["Event Insight", "Cost Insight", "Cost Optimization"];
  additionalAddonsRows.sort((a, b) => {
    const indexA = desiredOrder.indexOf(a.Name);
    const indexB = desiredOrder.indexOf(b.Name);
    return indexA - indexB;
  });

  // Create index for each row and remove IaaC
  let eventAndCostInsightRows = additionalAddonsRows.map((addon, index) => ({
    id: index + 1,
    addons: addon.Name,
    price: addon.Price,
  }));
  eventAndCostInsightRows = eventAndCostInsightRows.filter(
    (addon) => addon.addons !== "IaaC"
  );

  // console.log("complianceAddOns: ", complianceAddOns);
  // console.log("complianceRows: ", complianceRows);
  const rowSelectionModel = complianceAddOns.map((row) => {
    if (row.id) return row.id;
    else {
      let matchingRow = complianceRows.find(
        (compRow) => compRow.name === row.name
      );
      return matchingRow?.id;
    }
  });

  const eventAndCostRowSelectionModel = eventAndCostInsightAddOns.map((row) => {
    if (row.id) return row.id;
    else {
      let matchingRow = eventAndCostInsightRows.find(
        (eventAndCostRow) => eventAndCostRow.addons === row.name
      );
      return matchingRow?.id;
    }
  });
  console.log("rowSelectionModel: ", rowSelectionModel);
  console.log("eventAndCostRowSelectionModel: ", eventAndCostRowSelectionModel);
  console.log("eventAndCostInsightRows: ", eventAndCostInsightRows);

  const verifyUpdateBucketPolicyStatus = async (addons) => {
    //Verify Bucket Policy for Event Insight
    if(addons === "Event Insight") {
      setVerifyBucketPolicyLoadingEventInsight(true);
      setVerifyBucketPolicyErrorEventInsight(false);
      setVerifyBucketPolicyEventInsight(null);
  
      setVerifyAddons(addons);
      verifyBucketPolicyStatus(
        eventInsightRole,
        accountNumber,
        eventInsightBucketName,
        readOnlyRole,
        eventInsightSelectedRegion,
        "event-insight",
        externalId
      );

    } else if(addons === "Cost Insight") {
      setVerifyBucketPolicyLoadingCostInsight(true);
      setVerifyBucketPolicyErrorCostInsight(false);
      setVerifyBucketPolicyCostInsight(null);

      setVerifyAddons(addons);
      verifyBucketPolicyStatus(
        eventInsightRole,
        accountNumber,
        eventInsightBucketName,
        readOnlyRole,
        eventInsightSelectedRegion,
        "cost-insight",
        externalId
      );
    } else if(addons === "Cost Optimization") {
      setVerifyBucketPolicyLoadingCostOpt(true);
      setVerifyBucketPolicyErrorCostOpt(false);
      setVerifyBucketPolicyCostOpt(null);

      setVerifyAddons(addons);
      verifyBucketPolicyStatus(
        "",
        accountNumber,
        "",
        readOnlyRole,
        "",
        "cost-optimization",
        externalId
      );
    }
  };

  const handleInputChange = (e, addons) => {
    if(addons === "Event Insight"){
      if (e.target.name === "eventInsightRole") {
        setEventInsightRole(e.target.value);
      } else if (e.target.name === "eventInsightBucketName") {
        setEventInsightBucketName(e.target.value);
      } else if (e.target.name === "eventInsightSelectedRegion") {
        setEventInsightSelectedRegion(e.target.value);
      }  
    } else if(addons === "Cost Insight"){
      if (e.target.name === "costInsightRole") {
        setCostInsightRole(e.target.value);
      } else if (e.target.name === "costInsightBucketName") {
        setCostInsightBucketName(e.target.value);
      } else if (e.target.name === "costInsightSelectedRegion") {
        setCostInsightSelectedRegion(e.target.value);
      }  
    } 
  };

  const isArnInvalid = (addons) => {
    if(addons === "Event Insight"){
      const isInvalidItemsEventInsight = [
        {
          field: "accountNumber",
          isInvalid:
            accountNumber.length !== 12 || !/^[0-9]+$/.test(accountNumber)
              ? true
              : false,
        },
        {
          field: "bucketName",
          isInvalid: eventInsightBucketName.length === 0 ? true : false,
        },
        {
          field: "role",
          isInvalid: eventInsightRole.length === 0 ? true : false,
        },
        {
          field: "selectedRegion",
          isInvalid: eventInsightSelectedRegion === "",
        },
      ];
      return isInvalidItemsEventInsight.filter((item) => item.isInvalid).length > 0;
    } else if(addons === "Cost Insight"){
      const isInvalidItemsCostInsight = [
        {
          field: "accountNumber",
          isInvalid:
            accountNumber.length !== 12 || !/^[0-9]+$/.test(accountNumber)
              ? true
              : false,
        },
        {
          field: "bucketName",
          isInvalid: costInsightBucketName.length === 0 ? true : false,
        },
        {
          field: "role",
          isInvalid: costInsightRole.length === 0 ? true : false,
        },
        {
          field: "selectedRegion",
          isInvalid: costInsightSelectedRegion === "",
        },
      ];
      return isInvalidItemsCostInsight.filter((item) => item.isInvalid).length > 0;
    } else if(addons === "Cost Optimization"){
      const isInvalidItemsCostOpt = [
        {
          field: "accountNumber",
          isInvalid:
            accountNumber.length !== 12 || !/^[0-9]+$/.test(accountNumber)
              ? true
              : false,
        },
      ];
      return isInvalidItemsCostOpt.filter((item) => item.isInvalid).length > 0;
    }
  };


  // To display bucket policy fields, if initially Cost Insight is already selected.
  useEffect(() => {
    console.log(
      "useEffect eventAndCostInsightAddOns = ",
      eventAndCostInsightAddOns
    );
    const costInsightAddons = eventAndCostInsightAddOns.some(
      (item) => item.name === "Cost Insight"
    );
    const eventInsightAddons = eventAndCostInsightAddOns.some(
      (item) => item.name === "Event Insight"
    );
    const costOptAddons = eventAndCostInsightAddOns.some(
      (item) => item.name === "Cost Optimization"
    );
    if (costInsightAddons) {
      setShowArnFieldsCostInsight(false);
      setBucketPolicyEnabledCostInsight(false);
    }
    if (eventInsightAddons) {
      setShowArnFieldsEventInsight(false);
      setBucketPolicyEnabledEventInsight(false);
    }
    if (costOptAddons) {
      setShowArnFieldsCostOpt(false);
      setBucketPolicyEnabledCostOpt(false);
    }
  }, []);

  const url = releaseDetails;
  useEffect(() => {
    if (url && modalType && url && modalType=== "external") {
      const urlResponse = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${encodeURIComponent(url)}`;
      // console.log("urlResponse = ", urlResponse);
      window.open(urlResponse);      
    }
  }, [url, modalType]);

  // Handle verifyBucketPolicyStatus API response
  useEffect(() => {
    console.log("verifyAddons", verifyAddons);
    if (bucketPolicyStatusMessage) {
      if (verifyAddons === "Event Insight") {
        if (bucketPolicyStatusMessage === "verified and updated bucket Policy Successfully!") {
          console.log("Bucket Policy for Event Insight Successfully verified!");
          enqueueSnackbar("Bucket Policy for Event Insight Successfully verified!", {variant: "success"});
          setVerifyBucketPolicySuccessEventInsight(true);
          setVerifyBucketPolicyLoadingEventInsight(false);
          setBucketPolicyStatusEventInsight(true);
        } else {
          console.log("Bucket Policy verification failed for Event Insight!");
          enqueueSnackbar("Bucket Policy verification failed for Event Insight!", {variant: "error"});
          setVerifyBucketPolicyLoadingEventInsight(false);
          setVerifyBucketPolicyErrorEventInsight(true);
          setBucketPolicyStatusEventInsight(false);          
        }
      } else if (verifyAddons === "Cost Insight") {
        if (bucketPolicyStatusMessage === "verified and updated bucket Policy Successfully!") {
          console.log("Bucket Policy for Cost Insight Successfully verified!");
          enqueueSnackbar("Bucket Policy for Cost Insight Successfully verified!", {variant: "success"});
          setVerifyBucketPolicySuccessCostInsight(true);
          setVerifyBucketPolicyLoadingCostInsight(false);
          setBucketPolicyStatusCostInsight(true);
        } else {
          console.log("Bucket Policy verification failed for Cost Insight!");
          enqueueSnackbar("Bucket Policy verification failed for Cost Insight!", {variant: "error"});
          setVerifyBucketPolicyLoadingCostInsight(false);
          setVerifyBucketPolicyErrorCostInsight(true);
          setBucketPolicyStatusCostInsight(false);
        }
      } else if (verifyAddons === "Cost Optimization") {
        if (bucketPolicyStatusMessage === "verified!") {
          enqueueSnackbar("Verified!", {variant: "success"});
          setVerifyBucketPolicySuccessCostOpt(true);
          setVerifyBucketPolicyLoadingCostOpt(false);
          setBucketPolicyStatusCostOpt(true);
        } else {
          console.log("verification failed for Cost Optimization!");
          enqueueSnackbar("Verification failed for Cost Optimization! Please Opt-in for Compute Optimizer and Cost Optimization Hub.", {variant: "error"});
          setVerifyBucketPolicyLoadingCostOpt(false);
          setVerifyBucketPolicyErrorCostOpt(true);
          setBucketPolicyStatusCostOpt(false);
        }
      }
    }
    if (bucketPolicyStatusError) {
      if (verifyAddons === "Event Insight") {
        console.log("Bucket Policy verification failed!");
        enqueueSnackbar("Bucket Policy verification failed for Event Insight!", {variant: "error"});
        setVerifyBucketPolicyLoadingEventInsight(false);
        setVerifyBucketPolicyErrorEventInsight(true);
        setBucketPolicyStatusEventInsight(false);
      } else if (verifyAddons === "Cost Insight") {
        console.log("Bucket Policy verification failed!");
        enqueueSnackbar("Bucket Policy verification failed for Cost Insight!", {variant: "error"});
        setVerifyBucketPolicyLoadingCostInsight(false);
        setVerifyBucketPolicyErrorCostInsight(true);
        setBucketPolicyStatusCostInsight(false);        
      } else if (verifyAddons === "Cost Optimization") {
        console.log("verification failed!");
        enqueueSnackbar("Verification failed for Cost Optimization! Please Opt-in for Compute Optimizer and Cost Optimization Hub.", {variant: "error"});
        setVerifyBucketPolicyLoadingCostOpt(false);
        setVerifyBucketPolicyErrorCostOpt(true);
        setBucketPolicyStatusCostOpt(false);
      }
    }
  },[verifyAddons, bucketPolicyStatusMessage, bucketPolicyStatusError])

  return (
    <Box sx={{ width: "100%" }}>
      <SnackbarProvider/>
      <Alert variant="outlined" severity="info" icon={false}>
        Select the Compliance Standards
      </Alert>
      <DataGrid
        rows={complianceRows}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        checkboxSelection
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          // console.log("selected IDs : ", selectedIDs);
          const selectedRows = complianceRows.filter((row) =>
            selectedIDs.has(row.id)
          );

          const newSelectedRows = selectedRows.filter(
            (row) => !complianceAddOns.some((selectedRow) => selectedRow.id === row.id)
          );
          // console.log("selectedRows = ", selectedRows);
          setComplianceAddOns(newSelectedRows);
        }}
        autoHeight
        rowHeight={40}
      />

    <Alert variant="outlined" severity="info" icon={false}>
        Please select any Additional Services and configure them through any of the available options below.
      </Alert>
      <DataGrid
        rows={eventAndCostInsightRows}
        columns={eventInsightColumns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            // showQuickFilter: true,
            // quickFilterProps: { debounceMs: 500 },
          },
        }}
        checkboxSelection
        rowSelectionModel={eventAndCostRowSelectionModel}
        onRowSelectionModelChange={(ids) => {
          console.log("~~~onRowSelectionModelChange ids = ", ids);

          const selectedIDs = new Set(ids);
          const selectedRows = eventAndCostInsightRows.filter((row) =>
            selectedIDs.has(row.id)
          );
          const isCostInsightPresent = selectedRows.some(
            (item) => item.addons === "Cost Insight"
          );
          const isCostOptPresent = selectedRows.some(
            (item) => item.addons === "Cost Optimization"
          );
          const isEventInsightPresent = selectedRows.some(
            (item) => item.addons === "Event Insight"
          );
          const isCostInsightPresentEarlier = eventAndCostInsightAddOns.some(
            (item) => {
              return (
                item.name === "Cost Insight" || item?.addons === "Cost Insight"
              );
            }
          );
          const isCostOptPresentEarlier = eventAndCostInsightAddOns.some(
            (item) => {
              return (
                item.name === "Cost Optimization" || item?.addons === "Cost Optimization"
              );
            }
          );
          const isEventInsightPresentEarlier = eventAndCostInsightAddOns.some(
            (item) => {
              return (
                item.name === "Event Insight" || item?.addons === "Event Insight"
              );
            }
          );

          setEventAndCostInsightAddOns(selectedRows);
          if (!isCostInsightPresentEarlier && isCostInsightPresent) {
            setShowArnFieldsCostInsight(true);
            setBucketPolicyEnabledCostInsight(true);
          }
          if (!isCostInsightPresent) {
            setShowArnFieldsCostInsight(false);
            setBucketPolicyEnabledCostInsight(false);
          }
          if (!isCostOptPresentEarlier && isCostOptPresent) {
            setShowArnFieldsCostOpt(true);
            setBucketPolicyEnabledCostOpt(true);
          }
          if (!isCostOptPresent) {
            setShowArnFieldsCostOpt(false);
            setBucketPolicyEnabledCostOpt(false);
          }
          if (!isEventInsightPresentEarlier && isEventInsightPresent) {
            setShowArnFieldsEventInsight(true);
            setBucketPolicyEnabledEventInsight(true);
          }
          if (!isEventInsightPresent) {
            setShowArnFieldsEventInsight(false);
            setBucketPolicyEnabledEventInsight(false);
          }
        }}
        hideFooterPagination
        autoHeight
        hideFooter
        rowHeight={40}
      />
      <div className="row" style={{ marginTop: "20px" }}>
        {showArnFieldsEventInsight && isEventInsightPresent(eventAndCostRowSelectionModel, eventAndCostInsightRows) && (
          <div className="col-sm-12 col-md-4 col-lg-4">
            <AvForm className="form-horizontal">
              <Row>
                <Col lg="12">Event Insight</Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="eventInsightRole"
                      value={eventInsightRole}
                      placeholder="Role ARN*"
                      type="text"
                      onChange={(e) => handleInputChange(e, "Event Insight")}
                      className={`form-control ${
                        eventInsightRole === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Role is required",
                        },
                        pattern: {
                          value: "^arn:aws:iam::[0-9]{12}:role/",
                          errorMessage:
                            "IAM Role must be in proper format",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="accountNumber"
                      value={accountNumber}
                      placeholder="Account Number*"
                      type="text"
                      disabled={true}
                      className={`form-control ${
                        accountNumber === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Account number is required",
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage:
                            "Account number must include only numbers",
                        },
                        minLength: {
                          value: 12,
                          errorMessage:
                            "Account number must be minimum 12 digits",
                        },
                        maxLength: {
                          value: 12,
                          errorMessage:
                            "Account number cannot be more than 12 digits",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="eventInsightBucketName"
                      value={eventInsightBucketName}
                      placeholder="S3 Bucket ARN*"
                      type="text"
                      onChange={(e) => handleInputChange(e, "Event Insight")}
                      className={`form-control ${
                        eventInsightBucketName === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Bucket name is required",
                        },
                        pattern: {
                          value: "^arn:aws:s3:::[a-z0-9]([a-z0-9.-]{1,61}[a-z0-9])?$",
                          errorMessage:
                            "S3 bucket name must be in proper format",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Input
                      type="select"
                      name="eventInsightSelectedRegion"
                      id="awsRegion"
                      value={eventInsightSelectedRegion}
                      onChange={(e) => handleInputChange(e, "Event Insight")}
                      required
                      placeholder="S3 AWS Region*"
                      className={`form-control ${
                        eventInsightSelectedRegion === "" ? "is-invalid-border" : null
                      }`}
                    >
                      <option value="">Select AWS Region*</option>
                      {awsRegions.list().map((region) => (
                        <option key={region.code} value={region.code}>
                          {region.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <button
                className="btn w-lg btn-primary"
                disabled={
                  isArnInvalid("Event Insight") ||
                  verifyBucketPolicySuccessEventInsight ||
                  verifyBucketPolicyLoadingEventInsight
                }
                onClick={() => verifyUpdateBucketPolicyStatus("Event Insight")}
              >
                {verifyBucketPolicySuccessEventInsight ? (
                  <>Bucket Policy Verified</>
                ) : (
                  <>Verify Bucket Policy</>
                )}
              </button>
              {/* <div>
                {verifyBucketPolicyEventInsight && JSON.stringify(verifyBucketPolicyEventInsight)}
              </div> */}
            </AvForm>
          </div>
        )}
        {showArnFieldsCostInsight && isCostInsightPresent(eventAndCostRowSelectionModel, eventAndCostInsightRows) && (
          <div className="col-sm-12 col-md-4 col-lg-4">
            <AvForm className="form-horizontal">
              <Row>
                <Col lg="12">Cost Insight</Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="costInsightRole"
                      value={costInsightRole}
                      placeholder="Role ARN*"
                      type="text"
                      onChange={(e) => handleInputChange(e, "Cost Insight")}
                      className={`form-control ${
                        costInsightRole === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Role is required",
                        },
                        pattern: {
                          value: "^arn:aws:iam::[0-9]{12}:role/",
                          errorMessage:
                            "IAM Role must be in proper format",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="accountNumber"
                      value={accountNumber}
                      placeholder="Account Number*"
                      type="text"
                      disabled={true}
                      className={`form-control ${
                        accountNumber === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Account number is required",
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage:
                            "Account number must include only numbers",
                        },
                        minLength: {
                          value: 12,
                          errorMessage:
                            "Account number must be minimum 12 digits",
                        },
                        maxLength: {
                          value: 12,
                          errorMessage:
                            "Account number cannot be more than 12 digits",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="costInsightBucketName"
                      value={costInsightBucketName}
                      placeholder="S3 Bucket ARN*"
                      type="text"
                      onChange={(e) => handleInputChange(e, "Cost Insight")}
                      className={`form-control ${
                        costInsightBucketName === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Bucket name is required",
                        },
                        pattern: {
                          value: "^arn:aws:s3:::[a-z0-9]([a-z0-9.-]{1,61}[a-z0-9])?$",
                          errorMessage:
                            "S3 bucket name must be in proper format",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Input
                      type="select"
                      name="costInsightSelectedRegion"
                      id="awsRegion"
                      value={costInsightSelectedRegion}
                      onChange={(e) => handleInputChange(e, "Cost Insight")}
                      required
                      placeholder="S3 AWS Region*"
                      className={`form-control ${
                        costInsightSelectedRegion === "" ? "is-invalid-border" : null
                      }`}
                    >
                      <option value="">Select AWS Region*</option>
                      {awsRegions.list().map((region) => (
                        <option key={region.code} value={region.code}>
                          {region.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <button
                className="btn w-lg btn-primary"
                disabled={
                  isArnInvalid("Cost Insight") ||
                  verifyBucketPolicySuccessCostInsight ||
                  verifyBucketPolicyLoadingCostInsight
                }
                onClick={() => verifyUpdateBucketPolicyStatus("Cost Insight")}
              >
                {verifyBucketPolicySuccessCostInsight ? (
                  <>Bucket Policy Verified</>
                ) : (
                  <>Verify Bucket Policy</>
                )}
              </button>
              {/* <div>
                {verifyBucketPolicyCostInsight && JSON.stringify(verifyBucketPolicyCostInsight)}
              </div> */}
            </AvForm>
          </div>
        )}

        {/*  ARN Fields for Cost Optimization*/}
        {showArnFieldsCostOpt && isCostOptPresent(eventAndCostRowSelectionModel, eventAndCostInsightRows) && (
          <div className="col-sm-12 col-md-4 col-lg-4">
            <AvForm className="form-horizontal">
              <Row>
                <Col lg="12">Cost Optimization</Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <AvField
                      name="accountNumber"
                      value={accountNumber}
                      placeholder="Account Number*"
                      type="text"
                      disabled={true}
                      className={`form-control ${
                        accountNumber === "" ? "is-invalid-border" : null
                      }`}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Account number is required",
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage:
                            "Account number must include only numbers",
                        },
                        minLength: {
                          value: 12,
                          errorMessage:
                            "Account number must be minimum 12 digits",
                        },
                        maxLength: {
                          value: 12,
                          errorMessage:
                            "Account number cannot be more than 12 digits",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Tooltip title="Verify ComputeOptimizer and CostOptimizationHub">
                <button
                  className="btn w-lg btn-primary"
                  disabled={
                    isArnInvalid("Cost Optimization") ||
                    verifyBucketPolicySuccessCostOpt ||
                    verifyBucketPolicyLoadingCostOpt
                  }
                  onClick={() => verifyUpdateBucketPolicyStatus("Cost Optimization")}
                >
                  {verifyBucketPolicySuccessCostOpt ? (
                    <>Verified</>
                  ) : (
                    <>Verify</>
                  )}
                </button>
              </Tooltip>
            </AvForm>
          </div>
        )}
      </div>
      { helpModalOpen && releaseDetails && (
          <UrlDisplayDialog 
            url={releaseDetails}
            pageTitle="Help"
            isOpen={helpModalOpen}
            setOpen={setHelpModalOpen}
            clearUrl={clearAllReleaseMessages}
          />
        )}
        { scriptModalOpen && releaseDetails && (
          <ScriptDisplayDialog
            url={releaseDetails}
            pageTitle="Help"
            isOpen={scriptModalOpen}
            setOpen={setScriptModalOpen}
            clearUrl={clearAllReleaseMessages}
          />
        )}
    </Box>
  );
}

const mapStateToProps = (state) => {
  const {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  } = state.releaseNotes;

  const {
    bucketPolicyStatusLoading,
    bucketPolicyStatusMessage,
    bucketPolicyStatusError
  } = state.scanService;

  return {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
    bucketPolicyStatusLoading,
    bucketPolicyStatusMessage,
    bucketPolicyStatusError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReleaseDetails: (fileName) => {
      dispatch(getReleaseDetailsRequest(fileName));
    },
    clearAllReleaseMessages: () => {
      dispatch(clearAllReleaseMessages());
    },
    verifyBucketPolicyStatus: (role, accountNumber, bucketName, readOnlyRole, selectedRegion, key, externalId) => {
      dispatch(verifyBucketPolicyStatusRequest({ role, accountNumber, bucketName, readOnlyRole, selectedRegion, key, externalId }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOns);