import {
  REGISTER_ORG,
  REGISTER_ORG_SUCCESSFUL,
  REGISTER_ORG_FAILED,
  RESEND_ACTIVATE_EMAIL,
  RESEND_ACTIVATE_EMAIL_SUCCESSFUL,
  RESEND_ACTIVATE_EMAIL_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESSFUL,
  VALIDATE_TOKEN_FAILED,
  SET_MAILING_INFO,
  SUT_BILLING_INFO,
  CLEAR_ALL_ORGANIZATION_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  organizationError: null,
  emailError: null,
  message: null,
  loading: null,
  success: null,
  tokenError: null,
  resetPasswordLoading: false,
  resetPasswordMessage: null,
  resetPasswordError: null,
};

const account = (state = initialState, action) => {
	switch (action.type) {
    // Organization registration
    case REGISTER_ORG:
      state = {
        ...state,
        organization: null,
        loading: true,
        organizationError: null,
        message: null,
      };
      break;

    case REGISTER_ORG_SUCCESSFUL:
      state = {
        ...state,
        organization: action.payload,
        loading: false,
        organizationError: null,
        message: "Organization successfully registered",
      };
      break;

    case REGISTER_ORG_FAILED:
      state = {
        ...state,
        loading: false,
        organizationError: action.payload.error,
      };
      break;

    // Account activation
    case RESEND_ACTIVATE_EMAIL:
      state = {
        ...state,
        email: null,
        loading: true,
        emailError: null,
      };
      break;

    case RESEND_ACTIVATE_EMAIL_SUCCESSFUL:
      state = {
        ...state,
        email: action.payload,
        loading: false,
        emailError: null,
      };
      break;

    case RESEND_ACTIVATE_EMAIL_FAILED:
      state = {
        ...state,
        loading: false,
        emailError: action.payload,
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: null,
        resetPasswordMessage: null,
        user: null,
        modal: false,
      };
      break;
    case RESET_PASSWORD_SUCCESSFUL:
      console.log("RESET_PASSWORD_SUCCESSFUL");
      console.log(action.payload);
      state = {
        ...state,
        resetPasswordLoading: false,
        resetPasswordMessage: action.payload.resetPasswordResponse.message,
        resultMsgModal: action.payload ? true : false,
        user: action.payload,
        status: action.payload ? action.payload.userName : null,
      };
      break;

    case RESET_PASSWORD_FAILED:
      state = {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.payload.error.message,
        resultMsgModal: true,
        status: action.payload ? action.payload.userName : null,
      };
      break;
    case VALIDATE_TOKEN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VALIDATE_TOKEN_FAILED:
      state = {
        ...state,
        success: false,
        loading: false,
        tokenError: action.payload,
      };
      break;
    case VALIDATE_TOKEN_SUCCESSFUL:
      state = {
        ...state,
        success: true,
        loading: false,
        formData: action.payload.formData
      };
      break;
    case SET_MAILING_INFO:
      state = {
        ...state,
        updateOrgAddress: action.payload,
      };
      break;
    case SUT_BILLING_INFO:
      state = {
        ...state,
        updateOrgAddress: action.payload,
      };
      break;
    case CLEAR_ALL_ORGANIZATION_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        resetPasswordLoading: false,
        resetPasswordMessage: null,
        resetPasswordError: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
	return state;
}

export default account;