import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function UrlDisplayDialog({url, pageTitle, isOpen, setOpen, clearUrl}) {
  const handleClose = () => {
    setOpen(false);
    clearUrl();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{width: "50%", position: "absolute", left: "50%", height: "100vh"}}
      >
        <iframe src={url} width="100%" title={pageTitle} height="100%" />        
      </Dialog>
    </React.Fragment>
  );
}
