import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  GET_RELEASE_NOTES_REQUEST,
  GET_RELEASE_DETAILS_REQUEST,
  CLEAR_ALL_RELEASE_MESSAGES,
} from "./actionTypes";

import {
  getReleaseNotesSuccessful,
  getReleaseNotesFailed,
  getReleaseDetailsSuccessful,
  getReleaseDetailsFailed,
  clearAllReleaseMessageSuccessful,
} from "./actions";

import {
  getReleaseNotes as getReleaseNotesApi,
  getReleaseDetails as getReleaseDetailsApi
} from "../../helpers/auth_aws_helper";

function* getReleaseNotes({payload: { key }}){
  try{
    const response =  yield call(getReleaseNotesApi, key);
    console.log("~~~ getReleaseNotes Response: ", response);
    if(response && response.status === 200) {
      delete response.status;
      const array = Object.keys(response).map(key => response[key]);
      yield put(getReleaseNotesSuccessful(array));
    } else {
      let message;
      if(response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getReleaseNotesFailed({ message: message }));
    }
  } catch(error){
    console.log("~~~ getReleaseNotes, received Error is :: ", JSON.stringify(error));
    if(error.response){
      yield put(getReleaseNotesFailed(
        {
          message: "Error Code: "+error.response?.status+", Message: "+ error.response?.data,
        }));
    } else if (error.request) {
      yield put(getReleaseNotesFailed({ message: "No response is received" }));
    } else {
      yield put(getReleaseNotesFailed({ message: error?.message }));
    }
  }
}

function* getReleaseDetails({payload: { fileName }}){
  try{
    const response =  yield call(getReleaseDetailsApi, fileName);
    console.log("~~~ getReleaseDetails Response: ", response);
    if(response && response.status === 200) {
      delete response.status;
      yield put(getReleaseDetailsSuccessful(response.url));
    } else {
      let message;
      if(response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getReleaseDetailsFailed({ message: message }));
    }
  } catch(error){
    console.log("~~~ getReleaseDetails, received Error is :: ", JSON.stringify(error));
    if(error.response){
      yield put(getReleaseDetailsFailed(
        {
          message: "Error Code: "+error.response?.status+", Message: "+ error.response?.data,
        }));
    } else if (error.request) {
      yield put(getReleaseDetailsFailed({ message: "No response is received" }));
    } else {
      yield put(getReleaseDetailsFailed({ message: error?.message }));
    }
  }
}

function* clearAllReleaseMessages() {
  console.log("Saga: clear All Release Messages");
  yield put(clearAllReleaseMessageSuccessful());
}

function* watchGetReleaseNotesRequest() {
  yield takeEvery(GET_RELEASE_NOTES_REQUEST, getReleaseNotes);
}

function* watchGetReleaseDetailsRequest() {
  yield takeEvery(GET_RELEASE_DETAILS_REQUEST, getReleaseDetails);
}

function* watchClearAllReleaseMessages() {
  yield takeEvery(CLEAR_ALL_RELEASE_MESSAGES, clearAllReleaseMessages);
}

function* releaseNotesSaga() {
  yield all([fork(watchGetReleaseNotesRequest)]);
  yield all([fork(watchGetReleaseDetailsRequest)]);
  yield all([fork(watchClearAllReleaseMessages)]);
}

export default releaseNotesSaga;
