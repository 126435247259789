import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_LIST_OF_ACCTS } from './actionTypes';
import { getListOfAcctsSuccessful, getListOfAcctsFailed } from './actions';
import { getOrgsAndAccts } from "../../helpers/auth_aws_helper";

function* getListOfAccts({ payload: { orgs } }) {
	try {
			const response = yield call(getOrgsAndAccts, orgs);
			yield put(getListOfAcctsSuccessful(response));
	} catch (error) {
			yield put(getListOfAcctsFailed(error));
	}
}

export function* watchGetListOfAccts() {
	yield takeEvery(GET_LIST_OF_ACCTS, getListOfAccts)
}

function* getAccountSaga() {
    yield all([fork(watchGetListOfAccts)]);
}

export default getAccountSaga;