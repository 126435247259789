import {
    GET_AWS_ACC,
    GET_AWS_ACC_FAILED,
    GET_AWS_ACC_SUCCESSFUL,
    POST_AWS_ACC,
    POST_AWS_ACC_SUCCESSFUL,
    POST_AWS_ACC_FAILED,
    CLEAR_ALL_AWS_ACC_MESSAGES,
    CLEAR_ALL_AWS_ACC_MESSAGES_SUCCESSFUL
} from './actionTypes';

export const getAwsAcc = (aws) =>{
    return{
        type: GET_AWS_ACC,
        payload: { aws },
    };
};
export const get_aws_acc_failed = (aws) =>{
    return{
        type: GET_AWS_ACC_FAILED,
        payload: { aws },
    };
};
export const get_aws_acc_successful = (aws) =>{
    return{
        type: GET_AWS_ACC_SUCCESSFUL,
        payload: { aws },
    };
};

export const postAwsAcc = (awsAccount) => {
    return{
        type: POST_AWS_ACC,
        payload: { awsAccount },
    };
}

export const postAwsAccSuccessful = (postAwsAccountResponse) => {
    return{
        type: POST_AWS_ACC_SUCCESSFUL,
        payload: { postAwsAccountResponse },
    };
}

export const postAwsAccFailed = (postAwsAccountError) => {
    return{
        type: POST_AWS_ACC_FAILED,
        payload: { postAwsAccountError },
    };
}

export const clearAllAwsAccMessages = () => {
    return {
        type: CLEAR_ALL_AWS_ACC_MESSAGES,
        payload: {},
    };
}

export const clearAllAwsAccMessagesSuccessful = () => {
    return {
        type: CLEAR_ALL_AWS_ACC_MESSAGES_SUCCESSFUL,
        payload: {},
    };
}