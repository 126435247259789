import React from "react";

import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";

if (process.env.REACT_APP_ENVIRONMENT === "Trial") {
  console.log = console.warn = console.error = () => {};
}

const app = (
  <Provider store={store}>
    <BrowserRouter
    // getUserConfirmation={(message, callback) => {
    //     const [confirmOpen, setConfirmOpen] = useState(true);
    //     /* Empty callback to block the default browser prompt */
    //     return UserLeaveConfirmation(
    //         message,
    //         callback,
    //         confirmOpen,
    //         setConfirmOpen
    //     );
    // }}
    >
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
