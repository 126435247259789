import {
    GET_AWS_ACC,
    GET_AWS_ACC_FAILED,
    GET_AWS_ACC_SUCCESSFUL,
    POST_AWS_ACC,
    POST_AWS_ACC_SUCCESSFUL,
    POST_AWS_ACC_FAILED,
    CLEAR_ALL_AWS_ACC_MESSAGES_SUCCESSFUL
} from './actionTypes';
const initialState = {
    //setting default acc number to dev01 - worst case 
    error: null, message: null, loading: null, aws: null
}
const awsAcc = (state = initialState, action) => {
    switch (action.type) {
        case GET_AWS_ACC:
            state = {
                ...state,
                loading: true,
                error: null
            }
            break;
        case GET_AWS_ACC_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            }
            break;
        case GET_AWS_ACC_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                aws: action.payload.aws.accountNumber,
                error:null,
                success: true
            }
            break;
        case POST_AWS_ACC:
            state = {
                ...state,
                awsAccountPosting: true,
                postAwsAccMessage: null,
                postAwsAccError: null,
            }
            break;
        case POST_AWS_ACC_SUCCESSFUL:
            state = {
                ...state,
                awsAccountPosting: false,
                postAwsAccMessage: action.payload.postAwsAccountResponse.message,
            }
            break;
        case POST_AWS_ACC_FAILED:
            state = {
                ...state,
                awsAccountPosting: false,
                postAwsAccError: action.payload.postAwsAccountError.message,
            }
            break;
        case CLEAR_ALL_AWS_ACC_MESSAGES_SUCCESSFUL:
            state = {
                ...state,
                awsAccountPosting: false,
                postAwsAccMessage: null,
                postAwsAccError: null,
            };
            break;
        default:
            return {...state};
    }
    return {...state};
}

export default awsAcc;