import React, { useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import UploadIcon from '@mui/icons-material/Upload';
import GppGoodIcon from '@mui/icons-material/GppGood';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import BugReportIcon from '@mui/icons-material/BugReport';

import styles from "./style.module.css";

const Vulnerability = ({ type, value }) => {
  const vulnerabilityClass = type.toLowerCase()+"Metric";
  

  return (
    <div className={`${styles.vulnerabilityContainer} ${styles[vulnerabilityClass]}`}>
      <div className={styles.circle}>
        <div className={styles.vulMetricType}>{type}</div>
        <div className={styles.vulMetricValue}>{value}</div>
      </div>
    </div>
  );
};

const MetricsDisplay = ({ totalUploaded, totalPassed, totalFailed, totalLimit, criticalCount, highCount, mediumCount, lowCount, infoCount }) => {
  return (
    <div className={styles.metricDisplayContainer}>
      <div className={`${styles.metric} ${styles.uploadedMetric}`}>
        <UploadIcon style={{ fontSize: "40px" }}/>
        <div className={styles.metricText}>
          <div className={styles.metricName}>Templates Uploaded</div>
          <div className={styles.bigNumber}>{totalUploaded} / {totalLimit}</div>
        </div>
      </div>
      <div className={`${styles.metric} ${styles.passedMetric}`}>
        <GppGoodIcon style={{ fontSize: "40px", color:"green" }}/>
        <div className={styles.metricText}>
          <div className={styles.metricName}>Templates Passed</div>
          <div className={styles.bigNumber}>{totalPassed}</div>
        </div>
      </div>
      <div className={`${styles.metric} ${styles.failedMetric}`}>
        <SmsFailedIcon style={{ fontSize: "40px", color:"red" }}/>
        <div className={styles.metricText}>
          <div className={styles.metricName}>Templates Failed</div>
          <div className={styles.bigNumber}>{totalFailed}</div>
        </div>
      </div>

      <div className={styles.vulnerabilities}>
        <BugReportIcon style={{ fontSize: "40px" }}/> Vulnerability
        {(criticalCount!==undefined || criticalCount!==null) && (<Vulnerability type="Critical" value={criticalCount} />)}
        {(highCount!==undefined || highCount!==null) && (<Vulnerability type="High" value={highCount} />)}
        {(mediumCount!==undefined || mediumCount!==null) && (<Vulnerability type="Medium" value={mediumCount}/>)}
        {(lowCount!==undefined || lowCount!==null) && (<Vulnerability type="Low" value={lowCount}/>)}
        {(infoCount!==undefined || infoCount!==null) && (<Vulnerability type="Info" value={infoCount}/>)}       
      </div>

      {/* <div className={`${styles.metric} ${styles.highMetric}`}>
        <BugReportIcon style={{ fontSize: "40px", color:"darkred" }}/>
        <div className={styles.metricText}>
          <div className={styles.metricName}>Vulnerability High</div>
          <div className={styles.bigNumber}>{highCount}</div>
        </div>
      </div>
      <div className={`${styles.metric} ${styles.mediumMetric}`}>
        <BugReportIcon style={{ fontSize: "40px", color:"red" }}/>
        <div className={styles.metricText}>
          <div className={styles.metricName}>Vulnerability Medium</div>
          <div className={styles.bigNumber}>{mediumCount}</div>
        </div>
      </div>
      <div className={`${styles.metric} ${styles.lowMetric}`}>
        <BugReportIcon style={{ fontSize: "40px", color:"orange" }}/>
        <div className={styles.metricText}>
          <div className={styles.metricName}>Vulnerability Low</div>
          <div className={styles.bigNumber}>{lowCount}</div>
        </div>
      </div> */}
    </div>
  );
};

export default MetricsDisplay;
