import { 
    GET_RULE_REMEDIATION, 
    GET_RULE_REMEDIATION_SUCCESSFUL, 
    GET_RULE_REMEDIATION_FAILED, 
    CLEAR_ALL_RULE_REMEDIATION_MESSAGES, CLEAR_ALL_RULE_REMEDIATION_MESSAGES_SUCCESSFUL 
  } from './actionTypes';
  
  export const getRuleRemediation = (resourceName) => {
    return {
      type: GET_RULE_REMEDIATION,
      payload: { resourceName },
    };
  }
  
  export const getRuleRemediationSuccessful = (ruleRemediation) => {
    return {
      type: GET_RULE_REMEDIATION_SUCCESSFUL,
      payload: { ruleRemediation },
    };
  };
  
  export const getRuleRemediationFailed = (error) => {
    return {
      type: GET_RULE_REMEDIATION_FAILED,
      payload: { error },
    };
  };
  
  export const clearAllRuleRemediationMessages = () => {
    return {
      type: CLEAR_ALL_RULE_REMEDIATION_MESSAGES,
      payload: {},
    }
  }
  
  export const clearAllRuleRemediationMessagesSuccessful = () => {
    return {
      type: CLEAR_ALL_RULE_REMEDIATION_MESSAGES_SUCCESSFUL,
      payload: {},
    }
  }