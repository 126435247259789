import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { 
  GET_IAAC_LICENSE_REQUEST ,
  CLEAR_ALL_IAAC_LICENSE_MESSAGES,
} from "./actionTypes"

import { 
  getIaacLicenseSuccessful, 
  getIaacLicenseFailed,
  clearAllIaacLicenseMessagesSuccessful,
} from "./actions"

import { getIaacLicense as getIaacLicenseApi } from "../../helpers/auth_aws_helper";

function* getIaacLicense({ payload: { orgcode } }) {
  try{
    const response = yield call(getIaacLicenseApi, orgcode);
    console.log("~~~ getIaacLicense Response: ", response);
    if(response && response.status === 200) {
      delete response.status;
      yield put(getIaacLicenseSuccessful(response));
    } else{
      let message;
      if(response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getIaacLicenseFailed({ message: message }));
    }
  } catch (error) {
    console.log("~~~ getIaacLicense, received Error is :: ", JSON.stringify(error));
    if(error.response) {
      yield put(getIaacLicenseFailed(
        {
          message: "Error Code: " +error.response?.status+", Message: "+ error.response?.data,
        }
      ));
    } else if (error.request) {
      yield put(getIaacLicenseFailed({ message: "No response is received" }));
    } else {
      yield put(getIaacLicenseFailed({ message: error?.message }));
    }
  }
}

function* clearAllIaacLicenseMessages() {
  console.log("Saga: clear All Iaac License Messages");
  yield put(clearAllIaacLicenseMessagesSuccessful());
}

function* watchGetIaacLicenseRequest() {
  yield takeEvery(GET_IAAC_LICENSE_REQUEST, getIaacLicense);
}

function* watchClearAllIaacLicenseMessages() {
  yield takeEvery(CLEAR_ALL_IAAC_LICENSE_MESSAGES, clearAllIaacLicenseMessages);
}

function* iaacLicenseSaga() {
  yield all([fork(watchGetIaacLicenseRequest)]);
  yield all([fork(watchClearAllIaacLicenseMessages)]);
}

export default iaacLicenseSaga;