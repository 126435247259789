import {
  GET_RELEASE_NOTES_REQUEST,
  GET_RELEASE_NOTES_SUCCESSFUL,
  GET_RELEASE_NOTES_FAILED,
  GET_RELEASE_DETAILS_REQUEST,
  GET_RELEASE_DETAILS_SUCCESSFUL,
  GET_RELEASE_DETAILS_FAILED,
  CLEAR_ALL_RELEASE_MESSAGES_SUCCESSFUL
} from "./actionTypes";

const initialState = {
  isReleaseNotesGetting: false,
  getReleaseNotesMessage: null,
  getReleaseNotesError: null,
  releaseNotes: null,

  isReleaseDetailsGetting: false,
  getReleaseDetailsMessage: null,
  getReleaseDetailsError: null,
  releaseDetails: null,
};

const releaseNotesReducer = (state=initialState, action) => {
  switch (action.type) {
    case GET_RELEASE_NOTES_REQUEST:
      state = {
        ...state,
        isReleaseNotesGetting: true,
        getReleaseNotesMessage: null,
        getReleaseNotesError: null,
        releaseNotes: null,
      };
      break;
    case GET_RELEASE_NOTES_SUCCESSFUL:
      state = {
        ...state,
        isReleaseNotesGetting: false,
        getReleaseNotesMessage: "Release notes received successfully!",
        releaseNotes: action.payload.releaseNotes
      };
      break;
    case GET_RELEASE_NOTES_FAILED:
      state = {
        ...state,
        isReleaseNotesGetting: false,
        getReleaseNotesError: action.payload.getReleaseNotesError.message
      };
      break;              
    case GET_RELEASE_DETAILS_REQUEST:
      state = {
        ...state,
        isReleaseDetailsGetting: true,
        getReleaseDetailsMessage: null,
        getReleaseDetailsError: null,
      };
      break;
    case GET_RELEASE_DETAILS_SUCCESSFUL:
      state = {
        ...state,
        isReleaseDetailsGetting: false,
        getReleaseDetailsMessage: "Release details received successfully!",
        releaseDetails: action.payload.releaseDetails
      };
      break;
    case GET_RELEASE_DETAILS_FAILED:
      state = {
        ...state,
        isReleaseDetailsGetting: false,
        getReleaseDetailsError: action.payload.getReleaseDetailsError.message
      };
      break;
    case CLEAR_ALL_RELEASE_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        getReleaseNotesMessage: null,
        getReleaseNotesError: null,
        getReleaseDetailsMessage: null,
        getReleaseDetailsError: null,
        releaseDetails: null,
      };      
      break;        
    default:
      return state;
  }
  return state;
}

export default releaseNotesReducer;