import {
  GET_ASSET_INSIGHT_METRIC,
  GET_ASSET_INSIGHT_METRIC_SUCCESSFUL,
  GET_ASSET_INSIGHT_METRIC_FAILED,
  GET_COMPLIANCE_METRIC,
  GET_COMPLIANCE_METRIC_SUCCESSFUL,
  GET_COMPLIANCE_METRIC_FAILED,
  GET_COST_INSIGHT_METRIC,
  GET_COST_INSIGHT_METRIC_SUCCESSFUL,
  GET_COST_INSIGHT_METRIC_FAILED,
  GET_SECURITY_POSTURE_METRIC,
  GET_SECURITY_POSTURE_METRIC_SUCCESSFUL,
  GET_SECURITY_POSTURE_METRIC_FAILED,
} from "./actionTypes";

const initialState = {
  assetInsightMetric: null,
  assetInsightMetricError: null,
  assetInsightMetricLoading: null,

  complianceMetric: null,
  complianceMetricError: null,
  complianceMetricLoading: null,

  costInsightMetric: null,
  costInsightMetricError: null,
  costInsightMetricLoading: null,

  securityPostureMetric: null,
  securityPostureMetricError: null,
  securityPostureMetricLoading: null,
};

const metricInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSET_INSIGHT_METRIC:
      state = {
        ...state,
        assetInsightMetricLoading: true,
        assetInsightMetricError: null,
        accCodes: action.payload.accCodes,
        orgCode: action.payload.orgCode,
      };
      break;
    case GET_ASSET_INSIGHT_METRIC_SUCCESSFUL:
      state = {
        ...state,
        assetInsightMetric: action.payload.assetInsightMetric,
        assetInsightMetricLoading: false,
        assetInsightMetricError: null,
      };
      break;
    case GET_ASSET_INSIGHT_METRIC_FAILED:
      state = {
        ...state,
        assetInsightMetricLoading: false,
        assetInsightMetricError: action.payload.error.message,
      };
      break;

    case GET_COMPLIANCE_METRIC:
      state = {
        ...state,
        complianceMetricLoading: true,
        complianceMetricError: null,
        accCodes: action.payload.accCodes,
        orgCode: action.payload.orgCode,
      };
      break;
    case GET_COMPLIANCE_METRIC_SUCCESSFUL:
      state = {
        ...state,
        complianceMetric: action.payload.complianceMetric,
        complianceMetricLoading: false,
        complianceMetricError: null,
      };
      break;
    case GET_COMPLIANCE_METRIC_FAILED:
      state = {
        ...state,
        complianceMetricLoading: false,
        complianceMetricError: action.payload.error.message,
      };
      break;

    case GET_COST_INSIGHT_METRIC:
      state = {
        ...state,
        costInsightMetricLoading: true,
        costInsightMetricError: null,
        accCodes: action.payload.accCodes,
        orgCode: action.payload.orgCode,
      };
      break;
    case GET_COST_INSIGHT_METRIC_SUCCESSFUL:
      state = {
        ...state,
        costInsightMetric: action.payload.costInsightMetric,
        costInsightMetricLoading: false,
        costInsightMetricError: null,
      };
      break;
    case GET_COST_INSIGHT_METRIC_FAILED:
      state = {
        ...state,
        costInsightMetricLoading: false,
        costInsightMetricError: action.payload.error.message,
      };
      break;

    case GET_SECURITY_POSTURE_METRIC:
      state = {
        ...state,
        securityPostureMetricLoading: true,
        securityPostureMetricError: null,
        accCodes: action.payload.accCodes,
        orgCode: action.payload.orgCode,
      };
      break;
    case GET_SECURITY_POSTURE_METRIC_SUCCESSFUL:
      state = {
        ...state,
        securityPostureMetric: action.payload.securityPostureMetric,
        securityPostureMetricLoading: false,
        securityPostureMetricError: null,
      };
      break;
    case GET_SECURITY_POSTURE_METRIC_FAILED:
      state = {
        ...state,
        securityPostureMetricLoading: false,
        securityPostureMetricError: action.payload.error.message,
      };
      break;
      
    default:
      return state;
  }
  return state;
};

export default metricInfo;