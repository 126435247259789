import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { connect } from "react-redux";

import AccountDetailsPolicy from "./../../components/AccountDetailPolicy";

import spinner from "../../assets/images/aws/loadingGif.gif";

import { clearAllAwsAccMessages } from "../../store/actions";

const AccountsConfig = (props) => {
  const [formikInitValues, setFormikInitValues] = useState();
  const licensing = useSelector((state) => state.licensing);
  const acctList = useSelector((state) => state.acctList);
  const profile = useSelector((state) => state.profile);

  const getRoleAccess = useCallback(() => {
    if (
      licensing &&
      licensing.data.isLicensingComplete &&
      profile &&
      profile.userRoles &&
      profile.userRoles.includes("Administrator")
    ) {
      return 0;
    }

    return -1;
  });

  useEffect(() => {
    if (
      licensing &&
      licensing.data &&
      acctList &&
      acctList.accounts &&
      profile &&
      profile.userRoles
    ) {
      var tmpAccs = acctList.accounts.filter(
        (x) => !x.accountNumber.includes("INIT00000001")
      );
      const initValues = {
        licensing: licensing.data,
        accounts: tmpAccs,
        profile: profile,
        roleAccess: getRoleAccess(),
      };
      setFormikInitValues(initValues);
    }
  }, [licensing, acctList, profile]);

  const postAwsAccMessage = props.postAwsAccMessage;
  const postAwsAccError = props.postAwsAccError;
  const clearAllAwsAccMessages = useCallback(() => {
    props.clearAllAwsAccMessages();
  });
  useEffect(() => {
    console.log("useEffect for postAwsAccMessage:", postAwsAccMessage);
    if (postAwsAccMessage) {
      // enqueueSnackbar(postAwsAccMessage, { variant: "success" });
      setTimeout(() => clearAllAwsAccMessages(), 5000);
    }
    if (postAwsAccError) {
      // enqueueSnackbar(postAwsAccError, { variant: "error" });
      setTimeout(() => clearAllAwsAccMessages(), 5000);
    }
  }, [postAwsAccMessage, postAwsAccError, clearAllAwsAccMessages]);

  return (
    <React.Fragment>
      <React.Fragment>
        {(licensing.loading || acctList.loading || profile.loading) && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                direction: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                height: "80vh",
              }}
            >
              <img src={spinner} width="32px" alt="Spinner" />
            </div>
          </React.Fragment>
        )}
        {(licensing.error || acctList.error || profile.error) && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                direction: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                height: "80vh",
                color: "red",
              }}
            >
              <div>Something went wrong! Refresh the page.</div>
            </div>
          </React.Fragment>
        )}
        {formikInitValues && (
          <Formik initialValues={formikInitValues} enableReinitialize>
            {({ values, handleChange }) => (
              <form>
                <AccountDetailsPolicy
                  profile={values.profile}
                  licensing={values.licensing}
                  accounts={values.accounts}
                  roleAccess={values.roleAccess}
                />
              </form>
            )}
          </Formik>
        )}
      </React.Fragment>
      {/* To display success message of UpdateIamRole */}
      <Snackbar open={postAwsAccMessage} autoHideDuration={10000}>
        <Alert severity="success">{postAwsAccMessage}</Alert>
      </Snackbar>

      {/* To display error message of UpdateIamRole */}
      <Snackbar open={postAwsAccError} autoHideDuration={10000}>
        <Alert severity="error">{postAwsAccError}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { awsAccountPosting, postAwsAccMessage, postAwsAccError } =
    state.awsAcc;

  return {
    awsAccountPosting,
    postAwsAccMessage,
    postAwsAccError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAllAwsAccMessages: () => {
      dispatch(clearAllAwsAccMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsConfig);
