import {
    GET_PROFILE_ROLE_INFO,
    GET__PROFILE_ROLE_INFO_SUCCESSFUL,
    GET__PROFILE_ROLE_INFO_FAILED,
} from './actionTypes';

export const getProfileRoleInfo = () => {
    return { type: GET_PROFILE_ROLE_INFO,
        payload: {  } }
}

export const getProfileRoleInfoSuccessful = (data) => {
    return {
        type: GET__PROFILE_ROLE_INFO_SUCCESSFUL,
        payload: { data }
    }
}

export const getProfileRoleInfoFailed = (data) => {
    return {
        type: GET__PROFILE_ROLE_INFO_FAILED,
        payload: data
    }
}
