import { createSlice } from "@reduxjs/toolkit";

export const accountConfigurationSlice = createSlice({
  name: "accountConfiguration",
  initialState: {
    isPostingAccountConfiguration: false,
    postAccountConfigurationMessage: null,
    postAccountConfigurationError: null,
    accountConfigurationPayload: null,
  },
  reducers: {
    postAccountConfiguration: (state, action ) => {
      state.isPostingAccountConfiguration = true;
      state.postAccountConfigurationMessage = null;
      state.postAccountConfigurationError = null;
      state.accountConfigurationPayload = action.payload;
    },
    postAccountConfigurationSuccessful: (state, action ) => {
      state.isPostingAccountConfiguration = false;
      state.postAccountConfigurationMessage = "Account configured successfully";
      state.postAccountConfigurationError = null;
      state.accountConfigurationPayload = null;
    },
    postAccountConfigurationFailure: (state, action ) => {
      state.isPostingAccountConfiguration = false;
      state.postAccountConfigurationMessage = null;
      state.postAccountConfigurationError = action.payload.message;
      state.accountConfigurationPayload = null;
    },
    clearAllAccountConfigurationMessages: (state, action) => {},
    clearAllAccountConfigurationMessagesSuccessful: (state, action) => {
      state.postAccountConfigurationMessage = null;
      state.postAccountConfigurationError = null;
    },
  }
});

export const {
  postAccountConfiguration,
  postAccountConfigurationSuccessful,
  postAccountConfigurationFailure,
  clearAllAccountConfigurationMessages,
  clearAllAccountConfigurationMessagesSuccessful,
} = accountConfigurationSlice.actions;

export default accountConfigurationSlice.reducer;