import React, { useState, useEffect } from 'react';

const QrCode = ({ base64Image }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  useEffect(() => {
    if (base64Image) {
      if(!base64Image.startsWith("data:image")){
        setQrCodeUrl(`data:image/png;base64,${base64Image}`);
      } else {
        setQrCodeUrl(base64Image);
      }
    } else {
      console.error('Base64 image for QR code is missing.');
    }
  }, [base64Image]);

  return (
    <img src={qrCodeUrl} alt="QR Code"/>
  );
};

export default QrCode;
