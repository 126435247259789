import { takeEvery, fork, put, all, call, delay, takeLatest, select } from 'redux-saga/effects';
import { 
    REGISTER_ORG_ACCOUNT, 
    GET_LIST_OF_ORGS_AND_ACCOUNTS,
    UPDATE_ORG_ACCOUNT,
    CLEAR_ALL_ORG_ACCOUNT_MESSAGES,
} from './actionTypes';
import {
    registerOrgAccountSuccessful,
    registerOrgAccountFailed,
    getListOfOrgsAndAcctsSuccessful,
    getListOfOrgsAndAcctsFailed,
    getListOfOrgsAndAccts as getListOfOrgsAndAcctsFun,
    updateOrgAccountSuccessful,
    updateOrgAccountFailed,
    clearAllOrgAccountMessagesSuccessful,
} from "./actions";
import { getOrgsAndAccts, postOrgAccount, updateAccount } from "../../../helpers/auth_aws_helper";

// To avoid multiple simultaneous calls to accounts API
let isFetchingAccounts = false;

function* registerOrgAccount({ payload: { account } }) {
    try {
        const response = yield call(postOrgAccount, {
            orgId: account.orgId,
            accountNumber: account.accountNumber,
            accountName: account.accountName,
            accountCode: account.accountCode,
            urlLink: account.urlLink,
            location: "us-east-1",
            accounttype: account.accountType,
            address: account.address,
            city: account.city,
            state: account.state,
            zip: account.zip,
            country: account.country,
            email: account.email,
            phonenumber:account.phonenumber,
            eula: "blah",
            accountResourceGroups : account.accountResourceGroups,
            //added for license plan and addons
            subscription: account.subscription,
            complianceAddOns: account.complianceAddOns,
            iamrole: account.iamrole,
            externalId: account.externalId,

        });
        console.log('--== registerOrgAccountResponse ', response);
        if (response && response.status === 200) {
            const responseString = Object.values(response.data)
                .filter((value) => typeof value === "string")
                .join("");

            console.log("--== registerOrgAccountSuccessful = ",responseString);

			yield put(registerOrgAccountSuccessful({ message: responseString}));
            // yield put(registerOrgAccountSuccessful(responseString));
			yield put(getListOfOrgsAndAcctsFun());
		} else {
            const responseString = Object.values(response.data.message)
                .filter((value) => typeof value === "string")
                .join("");
			yield put(registerOrgAccountFailed({ message: responseString }));
		}
        // yield put(registerOrgAccountSuccessful(response));
    } catch (error) {
        console.log("~~~~~ registerOrgAccount error response : ", error);
		if (error.response) {
            yield put(registerOrgAccountFailed({message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,}));
        } else if (error.request) {
            yield put(registerOrgAccountFailed({ message: "No response received" }));
        } else {
            yield put(registerOrgAccountFailed({ message: error?.message }));
        }
    }
}

function* updateOrgAccount({ payload: { account } }) {
    try {
        console.log("~~ updateOrgAccount, Saga account:", account);
        const response = yield call(updateAccount, {
            orgId: account.orgId,
            accountNumber: account.accountNumber,
            accountName: account.accountName,
            accountCode: account.accountCode,
            urlLink: account.urlLink,
            location: account.location,
            accounttype: account.accounttype,
            address: account.address,
            city: account.city,
            state: account.state,
            zip: account.zip,
            country: account.country,
            email: account.email,
            phonenumber:account.phonenumber,
            eula: "blah",
            accountResourceGroups : account.accountResourceGroups,
            //added for license plan and addons
            subscription: account.subscription,
            complianceAddOns: account.complianceAddOns, 
            iamrole: account.iamrole,
            externalId: account.externalId,                  
        });
        console.log("--== updateOrgAccountResponse = ", response);
        if (response && response.status === 200) {
            const responseString = Object.values(response)
                .filter((value) => typeof value === "string")
                .join("");

            console.log("--== updateOrgAccountResponse = ",responseString);

			// yield put(updateOrgAccountSuccessful({ message: responseString}));
            yield put(updateOrgAccountSuccessful({ message: responseString}));
			yield put(getListOfOrgsAndAcctsFun());
		} else {
            let message;
            if (response.data?.message) {
                message = response.data.message;
            } else {
                message = response.data;
            }
			yield put(updateOrgAccountFailed({ message: message }));
		}
    } catch (error) {
        console.log("~~~~~ updateOrgAccount error response : ", error);
		if (error.response) {
            yield put(updateOrgAccountFailed({message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,}));
        } else if (error.request) {
            yield put(updateOrgAccountFailed({ message: "No response received" }));
        } else {
            yield put(updateOrgAccountFailed({ message: error?.message }));
        }
        // yield put(registerOrgAccountFailed(error));
    }
}

function* getListOfOrgsAndAccts({ payload: { accounts } }) {
	try {
        yield delay(300);
        if (isFetchingAccounts) {
            console.log('Already fetching. Skipping new request.');
            return;
        }
        console.log('---== getListOfOrgsAndAccts ==--- isFetchingAccounts: ',isFetchingAccounts);
        isFetchingAccounts = true;
        const response = yield call(getOrgsAndAccts, accounts);
        console.log('---== getListOfOrgsAndAccts, account API response: ', response);
        if (response && response?.status === 200) {
            yield put(getListOfOrgsAndAcctsSuccessful(response));
        }
        else {
            let message;
            if (response.message) {
            message = response.message;
            } else {
            message = response;
            }
            yield put(getListOfOrgsAndAcctsFailed({message: message}));
        }
	} catch (error) {
        console.log("~~~ getListOfOrgsAndAcctsFailed, received Error is :: ", JSON.stringify(error));
        if(error.response) {
            yield put(getListOfOrgsAndAcctsFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
        } else if (error.request){
            yield put(getListOfOrgsAndAcctsFailed({message: "No response is received"}))
        } else {
            yield put(getListOfOrgsAndAcctsFailed({message: error?.message}));
        } 
        // yield put(getListOfOrgsAndAcctsFailed(error));
	} finally {
        isFetchingAccounts = false;
    }
}

export function* watchRegisterOrgAccount() {
    yield takeEvery(REGISTER_ORG_ACCOUNT, registerOrgAccount)
}

export function* watchUpdateOrgAccount() {
    yield takeEvery(UPDATE_ORG_ACCOUNT, updateOrgAccount)
}

export function* watchGetListOfOrgsAndAccts() {
	yield takeLatest(GET_LIST_OF_ORGS_AND_ACCOUNTS, getListOfOrgsAndAccts)
}

function* clearAllOrgAccountMessages() {
    console.log("Saga: clear All Org Account Messages");
    yield put(clearAllOrgAccountMessagesSuccessful());
  }

  
function* watchClearAllOrgAccountMessages() {
    yield takeEvery(CLEAR_ALL_ORG_ACCOUNT_MESSAGES, clearAllOrgAccountMessages);
  }

  
function* orgAccountSaga() {
    yield all([fork(watchRegisterOrgAccount)]);
    yield all([fork(watchUpdateOrgAccount)]);
    yield all([fork(watchGetListOfOrgsAndAccts)]);
    yield all([fork(watchClearAllOrgAccountMessages)]);
}

export default orgAccountSaga;