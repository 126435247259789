export const convertDate = (dateInput) => {
    console.log("🚀 ~ convertDate ~ dateInput:", dateInput);
  
    // If input is null, undefined, or 'null', return empty string
    if (!dateInput || dateInput === 'null') {
      return '';
    }
  
    // Handle object format (like subscription_modified_date)
    if (typeof dateInput === 'object' && dateInput.date && dateInput.time) {
      const { year, month, day } = dateInput.date;
      console.log("🚀 ~ convertDate ~ year, month, day:", year, month, day);
      return `${month}/${day}/${year}`;  // Format as MM/DD/YYYY
    }
  
    // Handle string date (like createdDate)
    const dt = new Date(dateInput);
    
    // Check for invalid date
    if (isNaN(dt.getTime())) {
      return '';  // Return empty string for invalid date
    }
  
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;  // Format as MM/DD/YYYY
  };
  