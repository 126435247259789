import React from 'react';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';

const SecurityConfigUsers = ({ rowData }) => {
    return (
        <MaterialTable
            icons={tableIcons}
            title=""
            columns={[
                {
                    title: 'Account Name',
                    field: 'accname',
                    editable: 'never',
                },
                {
                    title: 'Rule Number',
                    field: 'ruleId',
                    editable: 'never',
                    filtering: false,
                    searchable: false,
                },
                {
                    title: 'Account Number',
                    field: 'accnum',
                    editable: 'never',
                },
            ]}
            data={rowData}
            style={{
                backgroundColor: 'white',
            }}
            options={{
                actionsColumnIndex: -1,
                //headerStyle={classes.colHeader},
                headerStyle: {
                    backgroundColor: '#a9a9a9',
                    color: 'secondary',
                    fontWeight: 'bold',
                    padding: '0.4em',
                },
                rowStyle: (rowData) => ({
                    backgroundColor:
                        rowData.tableData.id % 2 ? '#FFF' : '#FFF',
                }),
                cellStyle: { padding: '0.3em' },
                filtering: false,
            }}
        />
    );
}

export default SecurityConfigUsers;