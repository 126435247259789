import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  SEND_SUPPORT_EMAIL_REQUEST,
  CLEAR_ALL_SUPPORT_MESSAGES,
} from "./actionTypes";

import {
  sendSupportEmailSuccessful,
  sendSupportEmailFailed,
  clearAllSupportMessagesSuccessful,
} from "./actions";

import {
  postSupportEmail as postSupportEmailApi,  
} from "../../helpers/auth_aws_helper";

function* sendSupportEmail({payload: emailPayload}) {
  try {
    const response = yield call(postSupportEmailApi, emailPayload);
    console.log("~~~ sendSupportEmail Response: ", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(sendSupportEmailSuccessful());
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(sendSupportEmailFailed({message: message}));
    }
  } catch( error ) {
    console.log("~~~ uploadFile, received Error is :: ", JSON.stringify(error));
    if(error.response) {
      yield put(sendSupportEmailFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if (error.request){
      yield put(sendSupportEmailFailed({message: "No response is received"}))
    } else {
      yield put(sendSupportEmailFailed({message: error?.message}));
    }  
  }
}

function* clearAllSupportMessages() {
  console.log("Saga: clear All Support Messages");
  yield put(clearAllSupportMessagesSuccessful());
}

function* watchSupportEmailRequest() {
  yield takeEvery(SEND_SUPPORT_EMAIL_REQUEST, sendSupportEmail);
}

function* watchClearAllSupportMessages() {
  yield takeEvery(CLEAR_ALL_SUPPORT_MESSAGES, clearAllSupportMessages);
}

function* supportSaga() {
  yield all([fork(watchSupportEmailRequest)]);
  yield all([fork(watchClearAllSupportMessages)]);
}

export default supportSaga;

