import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import { RDS, ReservedInstances } from "./RDSDashboards";

const RDSDashboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab") || "RDS";

  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "RDS":
        return <RDS />;
      case "ReservedInstances":
        return <ReservedInstances />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="RDS" value="RDS" />
        <Tab label="Reserved Instances" value="ReservedInstances" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default RDSDashboard;
