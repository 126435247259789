import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { Tabs, Tab } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import { Row, Col, FormGroup } from "reactstrap";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { v4 as uuidv4 } from "uuid"; // for uuid version 4
import { getListOfAccts, postAwsAcc, getAwsAcc } from "../../store/actions";
import {
  getReleaseDetailsRequest,
  clearAllReleaseMessages,
} from "../../store/actions";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import FormLabel from "@mui/material/FormLabel";
import AdditionalAddons from "./AddonsManagement/AdditionalAddons/AdditionalAddons";

const UpdateRoleInfoComp = (props) => {
  const {
    handleUpdateIamRole,
    newRoleName,
    setNewRoleName,
    newExternalId,
    setNewExternalId,
  } = props;
  const [isCopied, setIsCopied] = React.useState(false);

  const linkTextAreaRef = useRef(null);
  const [showCopyLinkHelp, setShowCopyLinkHelp] = useState(false);

  const handleManualPairingGuide = async (e) => {
    e.stopPropagation();
    props.getReleaseDetails(
      encodeURIComponent(
        "configurations/Self-Onboarding-Manual-Guide-to-Pair-New-Account.pdf"
      )
    );
  };

  const pertformCopyBtnUiChanges = (textAreaEl) => {
    document.getElementById("clickToCopyBtnId").textContent = "Copied";
    setTimeout(() => {
      document.getElementById("clickToCopyBtnId").textContent = "Click to Copy";
      textAreaEl.focus();
      textAreaEl.selectionStart = textAreaEl.selectionEnd = 0;
    }, 3000);
  };

  const handleClicktoCopy = () => {
    // console.log("--== handleClicktoCopy ", linkTextAreaRef);
    if (linkTextAreaRef && linkTextAreaRef.current) {
      const tag = linkTextAreaRef.current;
      tag.select();
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(tag.value);
        pertformCopyBtnUiChanges(tag);
      } else {
        document.execCommand("copy");
        pertformCopyBtnUiChanges(tag);
      }
    }
  };

  let showTextArea = true;

  useEffect(() => {
    const newUuid = uuidv4();
    setNewExternalId(newUuid);
  }, []);

  useEffect(() => {
    if (props.aws === null) {
      props.getAwsAcc();
    }
  }, []);

  const clearAllReleaseMessages = useCallback(() =>
    props.clearAllReleaseMessages()
  );
  const releaseDetails = props.releaseDetails;
  useEffect(() => {
    if (releaseDetails !== null) {
      const newTab = window.open(releaseDetails, "_blank");
      newTab.focus();
      clearAllReleaseMessages();
    }
  }, [clearAllReleaseMessages, releaseDetails]);

  const handleRefresh = () => {
    const newUuid = uuidv4();
    setNewExternalId(newUuid);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(newExternalId);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const isInvalid = () => {
    const isInvalidItems = [
      {
        field: "newRoleName",
        isInvalid:
          newRoleName === "" ||
          newRoleName === null ||
          newRoleName === undefined
            ? true
            : false,
      },
      {
        field: "newExternalId",
        isInvalid:
          newExternalId === "" ||
          newExternalId === null ||
          newExternalId === undefined
            ? true
            : false,
      },
    ];
    const resp = isInvalidItems.filter((item) => item.isInvalid);
    return resp.length > 0;
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            gap: "10px",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            Follow the steps in the Pairing Guide to pair your account with
            CloudCatcher:{" "}
          </div>
          <button
            style={{ border: "solid 1px #F98125" }}
            className="btn"
            disabled={props.isReleaseDetailsGetting}
            onClick={handleManualPairingGuide}
          >
            <strong>Click to view Pairing guide</strong>
          </button>
        </div>
        <hr
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        />
      </div>

      <Row>
        <Col lg="2">
          <FormGroup>
            <FormLabel component="label">IAM Role</FormLabel>
          </FormGroup>
        </Col>
        <Col lg="10">
          <FormGroup>
            <TextField
              fullWidth
              size="small"
              name="newRoleName"
              value={newRoleName}
              placeholder="Role Name"
              type="text"
              onChange={(e) => setNewRoleName(e.target.value)}
              error={!newRoleName}
              helperText={!newRoleName && "IAM Role is required"}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="2">
          <FormGroup>
            <FormLabel component="label">External Id</FormLabel>
          </FormGroup>
        </Col>
        <Col lg="10">
          <FormGroup>
            <TextField
              fullWidth
              size="small"
              name="newExternalId"
              value={newExternalId}
              placeholder="External ID"
              type="text"
              disabled={true}
              error={!newExternalId}
              helperText={!newExternalId && "External Id is required"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopy}>
                      {isCopied ? (
                        <span>Copied</span>
                      ) : (
                        <ContentCopyIcon color="primary" />
                      )}
                    </IconButton>
                    <IconButton onClick={handleRefresh}>
                      <RefreshIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <Row>
            <Col lg="12">
              <button
                id="showLinkBtnId"
                className="btn btn-link"
                onClick={() => {
                  showTextArea = !showTextArea;
                  if (showTextArea) {
                    document.getElementById("showLinkBtnId").textContent =
                      "Hide Link";
                    document.getElementById("consoleBodyId").style.display =
                      "block";
                  } else {
                    document.getElementById("showLinkBtnId").textContent =
                      "Show Link";
                    document.getElementById("consoleBodyId").style.display =
                      "none";
                  }
                }}
              >
                Hide Link
              </button>
            </Col>
            <Col lg="12">
              <button
                id="clickToCopyBtnId"
                className="btn btn-link"
                onClick={handleClicktoCopy}
              >
                Copy Link
              </button>
            </Col>
            <Col lg="12">
              <div>
                <button
                  className="btn btn-link"
                  onClick={() =>
                    window.open(linkTextAreaRef.current.textContent)
                  }
                >
                  Click to Launch
                </button>
                <Tooltip
                  title="Please make sure you are logged in the AWS account you are onboarding, prior to completing this step.
                              Open a new tab in your browser. Copy the link above and paste it in the URL bar of this new tab.
                              Complete setting up a role through AWS IAM role creation console, make sure that the role's name is set as:
                              CloudCatcherTrustRole"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setShowCopyLinkHelp(false)}
                  open={showCopyLinkHelp}
                  placement="top"
                  disableHoverListener
                >
                  <button
                    className="btn btn-sm btn-link text-dark"
                    onClick={() => setShowCopyLinkHelp(!showCopyLinkHelp)}
                  >
                    <i className="mdi mdi-help-circle mr-1"></i>
                  </button>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Col>

        <Col lg="9">
          <div
            className="mb-3"
            id="consoleBodyId"
            style={{
              display: "block",
            }}
          >
            <textarea
              readOnly
              ref={linkTextAreaRef}
              className="form-control"
              rows="4"
              name="urlLink"
              value={`https://console.aws.amazon.com/iam/home#/roles$new?step=review&roleType=crossAccount&accountID=${props.aws}&policies=arn%3Aaws%3Aiam%3A%3Aaws%3Apolicy%2FReadOnlyAccess&roleName=${newRoleName}&externalId=${newExternalId}&trustedEntityType=AWS_ACCOUNT&isThirdParty=true&awsAccount=${props.aws}`}
            />
          </div>
        </Col>
      </Row>

      <hr
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      />
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button
          className="btn w-lg btn-primary"
          disabled={props.awsAccountPosting || isInvalid()}
          onClick={handleUpdateIamRole}
        >
          {props?.selectedAccount?.status === "ACTIVE"
            ? "Update Role and External ID"
            : "Activate"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { awsAccountPosting, postAwsAccMessage, postAwsAccError } =
    state.awsAcc;

  const { aws } = state.awsAcc;

  const {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  } = state.releaseNotes;

  return {
    awsAccountPosting,
    postAwsAccMessage,
    postAwsAccError,

    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,

    aws,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postAwsAcc: (awsAccountUpdateRequest) => {
      dispatch(postAwsAcc(awsAccountUpdateRequest));
    },
    getListOfAccts: () => {
      dispatch(getListOfAccts());
    },
    getReleaseDetails: (fileName) => {
      dispatch(getReleaseDetailsRequest(fileName));
    },
    clearAllReleaseMessages: () => {
      dispatch(clearAllReleaseMessages());
    },
    getAwsAcc: () => {
      dispatch(getAwsAcc());
    },
  };
};

const UpdateRoleInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateRoleInfoComp);

const UpdateIamRole = (props) => {
  console.log("UpdateIamRole, props", props);
  console.log("UpdateIamRole, props.selectedAccount", props.selectedAccount);
  const [tabValue, setTabValue] = useState(0);
  const [updateRoleDone, setUpdateRoleDone] = useState(false);
  const [newRoleName, setNewRoleName] = useState(
    props.selectedAccount?.iamrole || "CloudcatcherAuditTrustRole"
  );
  const [newExternalId, setNewExternalId] = useState(
    props.selectedAccount?.externalId
  );
  const [accountStatus, setAccountStatus] = useState(
    props.selectedAccount?.status
  );

  const handleUpdateIamRole = () => {
    const updateRoleRequest = {
      accountNumber: props.selectedAccount.accountNumber,
      iamRole: newRoleName,
      externalId: newExternalId,
    };
    console.log("updateRoleRequest: ", updateRoleRequest);
    props.postAwsAcc(updateRoleRequest);
  };

  const postAwsAccMessage = props.postAwsAccMessage;
  const getListOfAccts = useCallback(() => {
    props.getListOfAccts();
  }, []);
  const closeModal = useCallback(() => {
    props.closeModal();
  }, []);
  useEffect(() => {
    console.log("UpdateIamRole: useEffect");
    if (postAwsAccMessage) {
      if (accountStatus === "INACTIVE") {
        setUpdateRoleDone(true);
        setTabValue(1);
        setAccountStatus("ACTIVATED");
      } else if (accountStatus === "ACTIVE") {
        closeModal();
      }
      if (accountStatus !== "ACTIVATED") {
        getListOfAccts();
      }
    }
  }, [
    postAwsAccMessage,
    getListOfAccts,
    closeModal,
    newExternalId,
    newRoleName,
    accountStatus,
  ]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SnackbarProvider />
      {/* {props?.selectedAccount?.status === "ACTIVE" ? ( */}
      {accountStatus === "ACTIVE" ? (
        <UpdateRoleInfo
          handleUpdateIamRole={handleUpdateIamRole}
          selectedAccount={props.selectedAccount}
          newRoleName={newRoleName}
          setNewRoleName={setNewRoleName}
          newExternalId={newExternalId}
          setNewExternalId={setNewExternalId}
        />
      ) : (
        <div>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="IAM Role Update" />
            <Tab label="Addons" disabled={!updateRoleDone} />
          </Tabs>
          {tabValue === 0 && (
            <UpdateRoleInfo
              handleUpdateIamRole={handleUpdateIamRole}
              selectedAccount={props.selectedAccount}
              newRoleName={newRoleName}
              setNewRoleName={setNewRoleName}
              newExternalId={newExternalId}
              setNewExternalId={setNewExternalId}
            />
          )}
          {tabValue === 1 && (
            <div style={{ height: 400, width: "100%" }}>
              <AdditionalAddons
                addOns={props.addOns}
                editAccount={props.selectedAccount}
              />
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateIamRole);
