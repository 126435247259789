import JSEncrypt from "jsencrypt";

// Encryption for Free Trial
const Encrypt = (valuesToEncrypt, publicKeyString) => {
  try {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKeyString);

    // Encrypt the value using the public key
    const encryptedValues = encrypt.encrypt(valuesToEncrypt);

    // Print the encrypted values to the console
    console.log("Encrypted Values:", encryptedValues);

    return encryptedValues;
  } catch (error) {
    console.error("Error encrypting values:", error);
    throw error; // Re-throw the error to handle it in the caller function
  }
};

export default Encrypt;
