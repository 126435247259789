import { useOldestActivatedAccount } from "../../Common/HelperComponents/CustomHooks";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./CustomToast.module.css"; // Import the CSS module


const useActivationNotification = (totalHours) => {
  const oldestAccount = useOldestActivatedAccount();

  useEffect(() => {
    if (oldestAccount && oldestAccount.activation_date) {
      const activationDate = ( (new Date(oldestAccount.activation_date).getTime()) - ((new Date(oldestAccount.activation_date)).getTimezoneOffset() * 60000 ));
      const currentTime = new Date();
      const diffInMs = currentTime - activationDate;
      const diffInHours = diffInMs / (1000 * 60 * 60);

      if (diffInHours <= totalHours) {
        // Calculate remaining time
        const remainingHours = Math.floor(totalHours - diffInHours);
        const remainingMinutes = Math.floor(
          ((totalHours - diffInHours) * 60) % 60
        );

        // Check if the toast is already active by ID
        const toastId = "activation-toast";
        if (!toast.isActive(toastId)) {
          toast.info(
            `Account ${
              oldestAccount.accountNumber
            } was activated on ${new Date(activationDate)}. A comprehensive scan is in progress. Your data will be available in approximately ${remainingHours} hours and ${remainingMinutes} minutes.`,
            {
              toastId,
              autoClose: false, // Make the toast sticky
              position: toast.POSITION.TOP_CENTER,
              closeButton: true,
            }
          );
        }
      }
    }
  }, [oldestAccount, totalHours]);
};

export default useActivationNotification;
