import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_LIST_OF_ORGS } from './actionTypes';
import { getListOfOrgsSuccessful, getListOfOrgsFailed } from './actions';
// import { getOrgsAndAccts } from '../../helpers/auth_helper';
import { getOrgsAndAccts } from '../../helpers/auth_aws_helper';

function* getListOfOrgs({ payload: { orgs } }) {
	try {
			const response = yield call(getOrgsAndAccts, orgs);
			yield put(getListOfOrgsSuccessful(response));
	} catch (error) {
			yield put(getListOfOrgsFailed(error));
	}
}

export function* watchGetListOfOrgs() {
	yield takeEvery(GET_LIST_OF_ORGS, getListOfOrgs)
}

function* getOrganizationSaga() {
    yield all([fork(watchGetListOfOrgs)]);
}

export default getOrganizationSaga;