export const UPLOAD_FILE_REQUEST = "upload_file_request";
export const UPLOAD_FILE_SUCCESSFUL = "upload_file_successful";
export const UPLOAD_FILE_FAILED = "upload_file_failed";

export const SEND_FILE_COMMIT_REQUEST = "send_file_commit_request";
export const SEND_FILE_COMMIT_SUCCESSFUL = "send_file_commit_successful";
export const SEND_FILE_COMMIT_FAILED = "send_file_commit_failed";


export const DOWNLOAD_FILE_REQUEST = "download_file_request";
export const DOWNLOAD_FILE_SUCCESSFUL = "download_file_successful";
export const DOWNLOAD_FILE_FAILED = "download_file_failed";

export const GET_UPLOAD_FILE_URL_REQUEST = "get_upload_file_url_request";
export const GET_UPLOAD_FILE_URL_SUCCESSFUL = "get_upload_file_url_successful";
export const GET_UPLOAD_FILE_URL_FAILED = "get_upload_file_url_failed";

export const GET_IAAC_METRICS_REQUEST = "get_iaac_metrics_request";
export const GET_IAAC_METRICS_SUCCESSFUL = "get_iaac_metrics_successful";
export const GET_IAAC_METRICS_FAILED = "get_iaac_metrics_failed";

export const GET_IAAC_FILE_HISTORY_REQUEST = "get_iaac_file_history_request";
export const GET_IAAC_FILE_HISTORY_SUCCESSFUL = "get_iaac_file_history_successful";
export const GET_IAAC_FILE_HISTORY_FAILED = "get_iaac_file_history_failed";

export const CLEAR_ALL_IAAC_MESSAGES = "clear_all_iaac_messages";
export const CLEAR_ALL_IAAC_MESSAGES_SUCCESSFUL = "clear_all_iaac_messages_successful";

