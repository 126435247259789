import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import CostOptimizationPage from "./CostOptimizationPage";
import CostOptimizationDetails from "./CostOptimizationDetails";
import CostOptimizationExtraSavings from "./CostOptimizationExtraSavings";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

// Main Cost Optimization Dashboard
const CostOptimizationDashboard = ({ licenseName, message }) => {
  const [activeTab, setActiveTab] = useState("costoptimization");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "costoptimization":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // CostOptimizationPage
          return <GetKibanaDashboard dashboardKey={"CostOptimizationhub"} />;
        }
      case "costoptimizationdetails":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // CostOptimizationDetails
          return <GetKibanaDashboard dashboardKey={"Hub Details"} />;
        }
      case "extraSavings":
        if (freeLicenses.includes(licenseName)) {
          return <InaccessibleResources message={message} />;
        } else {
          // CostOptimizationExtraSavings
          return <GetKibanaDashboard dashboardKey={"Other Opportunities"} />;
        }
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <GetKibanaDashboard dashboardKey={"CostOptimizationhub"} />;
    </div>
  );
};

export default CostOptimizationDashboard;
