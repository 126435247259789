import React from "react";

import Box from '@mui/material/Box';

import { 
  Alert,
  Container, 
} from "@mui/material";

const TrialExpiredPage = () => {
  console.log(" TrialExpiredPage called");
  return (
    <Container style={{display: "flex", color: "red", flexDirection: "column", height: "80vh", justifyContent: "center", alignItems: "center"}}>
      <Box>
        <Alert variant="outlined" severity="info">
          <h1>Trial Expired</h1>
          <p>Your trial period has expired. Please contact support or update your subscription.</p>    
        </Alert>
      </Box>      
    </Container>
    
  )
}

export default TrialExpiredPage;