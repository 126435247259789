import {
  GET_IAAC_SUBSCRIPTION_REQUEST,
  GET_IAAC_SUBSCRIPTION_SUCCESSFUL,
  GET_IAAC_SUBSCRIPTION_FAILED,
  POST_IAAC_SUBSCRIPTION_REQUEST,
  POST_IAAC_SUBSCRIPTION_SUCCESSFUL,
  POST_IAAC_SUBSCRIPTION_FAILED,
  PUT_IAAC_SUBSCRIPTION_REQUEST,
  PUT_IAAC_SUBSCRIPTION_SUCCESSFUL,
  PUT_IAAC_SUBSCRIPTION_FAILED,
  CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES,
  CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES_SUCCESSFUL
} from "./actionTypes";

export const getIaacSubscriptionRequest = (orgcode) => ({
  type: GET_IAAC_SUBSCRIPTION_REQUEST,
  payload: { orgcode }
});

export const getIaacSubscriptionSuccessful = (subscription) => ({
  type: GET_IAAC_SUBSCRIPTION_SUCCESSFUL,
  payload: { subscription }
});

export const getIaacSubscriptionFailed = (getIaacSubscriptionError) => ({
  type: GET_IAAC_SUBSCRIPTION_FAILED,
  payload: { getIaacSubscriptionError }
});

export const postIaacSubscriptionRequest = (orgcode, subscriptionRequest) => ({
  type: POST_IAAC_SUBSCRIPTION_REQUEST,
  payload: { orgcode, subscriptionRequest }
});

export const postIaacSubscriptionSuccessful = (subscription, postIaacSubscriptionMessage) => ({
  type: POST_IAAC_SUBSCRIPTION_SUCCESSFUL,
  payload: { subscription, postIaacSubscriptionMessage }
});

export const postIaacSubscriptionFailed = (postIaacSubscriptionError) => ({
  type: POST_IAAC_SUBSCRIPTION_FAILED,
  payload: { postIaacSubscriptionError }
});

export const putIaacSubscriptionRequest = (orgcode, subscription) => ({
  type: PUT_IAAC_SUBSCRIPTION_REQUEST,
  payload: { orgcode, subscription }
});

export const putIaacSubscriptionSuccessful = (subscription, putIaacSubscriptionMessage) => ({
  type: PUT_IAAC_SUBSCRIPTION_SUCCESSFUL,
  payload: { subscription, putIaacSubscriptionMessage }
});

export const putIaacSubscriptionFailed = (putIaacSubscriptionError) => ({
  type: PUT_IAAC_SUBSCRIPTION_FAILED,
  payload: { putIaacSubscriptionError }
});

export const clearAllIaacSubscriptionMessages = () => {
  return {
    type: CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES,
    payload: {},
  };
};

export const clearAllIaacSubscriptionMessagesSuccessful = () => {
  return {
    type: CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES_SUCCESSFUL,
    payload: {},
  };
};
