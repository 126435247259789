import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  TextField,
  Button,
  Modal,
  makeStyles,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { Alert as SnackbarAlert } from "@material-ui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { Alert } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  getResourceGroups,
  addResourceGroup,
  editResourceGroup,
  deleteResourceGroup,
  clearAllResourceGroupMessages,
  // getListOfAccts,
  getListOfOrgsAndAccts,
} from "../../store/actions";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import jwtDecode from "jwt-decode";
import spinner from "../../assets/images/aws/loadingGif.gif";
import getUserRole from "../../helpers/jwt-token-access/userRole";

const allowedRoles = ["Administrator"];

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#193a6f",
  },
  activeButton: {
    color: "white", // change this to the desired active color
    marginLeft: "auto", // This will push the link to the right
    textDecoration: "none", // Remove text decoration from the link
  },
  logo: {
    maxWidth: 160,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  primary: {
    backgroundColor: "#193A6F",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#303F9F",
    },
  },
}));

const ResourceGroupsConfig = (props) => {
  const dispatch = useDispatch();
  const { accounts, organizations } = useSelector((state) => {
    console.log("--== state.orgAccount ", state.orgAccount);
    const { accounts, organizations } = state.orgAccount;
    return {
      accounts,
      organizations:
        organizations && organizations.length > 0 ? organizations[0] : {},
    };
  });

  const token = accessToken();
  const decoded = jwtDecode(token);
  const userName = decoded["email"];
  const userRole = getUserRole();
  const columns = [
    { field: "resourcename", headerName: "Resource Group Name", width: 180 },
    {
      field: "description",
      headerName: "Resource Group Description",
      width: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        // console.log("Resource Groups :: ", params.row),
        <div>
          {/* Edit Icon */}
          {/* <IconButton
            aria-label="edit"
            onClick={() => handleEdit(params.row)}
            className={allowedRoles.includes(userRole) ? "text-darkblue" : ""}
            disabled={!allowedRoles.includes(userRole)}
          >
            <EditIcon />
          </IconButton> */}
          {/* Delete Icon */}
          <IconButton
            aria-label="delete"
            onClick={() => openDeleteModal(params.row)}
            className={allowedRoles.includes(userRole) ? "text-danger" : ""}
            disabled={!allowedRoles.includes(userRole)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [resourceGroupName, setResourceGroupName] = useState("");
  const [oldResourceGroupName, setOldResourceGroupName] = useState("");
  const [resourceGroupDescription, setResourceGroupDescription] = useState("");
  const [resourceGroupToDelete, setResourceGroupToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteResourceGroupError, setDeleteResourceGroupError] =
    useState(null);

  const classes = useStyles();

  const clearState = () => {
    setResourceGroupName("");
    setResourceGroupDescription("");
    setOldResourceGroupName("");
    setDeleteResourceGroupError(null);
  };

  const handleAddDialogClose = () => {
    clearState();
    props.clearAllMessages();
    setAddDialogOpen(false);
  };

  // const handleEditDialogClose = () => {
  //   clearState();
  //   props.clearAllMessages();
  //   setEditDialogOpen(false);
  // };

  // const handleEdit = (row) => {
  //   // console.log("Editing resource group with row: ", row);
  //   setResourceGroupName(row.resourcename);
  //   setOldResourceGroupName(row.resourcename);
  //   setResourceGroupDescription(row.description);
  //   setEditDialogOpen(true);
  // };

  const openDeleteModal = (params) => {
    console.log("Params :: ", params);
    console.log("Organization  :: ", organizations.orgcode);
    setResourceGroupToDelete(params.resourcename);
    setShowDeleteModal(true);    
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setResourceGroupToDelete(null);
    setDeleteResourceGroupError(null);
    if (props.deleteResourceGroupMessage || props.deleteResourceGroupError) {
      props.clearAllMessages();
    }
  };

  const handleDeleteConfirm = () => {
    if (resourceGroupToDelete) {
      props.deleteResourceGroup(resourceGroupToDelete, organizations?.orgcode);
    }
  };

  //For Submitting data of Add resource to API
  const handleAddSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting Add Resource Group Form");

    let resourceGroup = {
      resourceGroup: resourceGroupName,
      description: resourceGroupDescription,
      orgCode: organizations?.orgcode,
    };
    console.log("Submitting data ::", resourceGroup);
    props.addResourceGroup(resourceGroup);
  };

  //For Submitting data of Edit resource to API
  // const handleEditSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Submitting Edit Resource Group Form");

  //   let resourceGroup = {
  //     oldResourceGroupName: oldResourceGroupName,
  //     newResourceGroupName: resourceGroupName,
  //     description: resourceGroupDescription,
  //     orgCode: organizations?.orgcode,
  //   };
  //   console.log("Submitting data ::", resourceGroup);
  //   props.editResourceGroup(resourceGroup);
  // };


  // const closeEditModal = useCallback(() => {
  //   handleEditDialogClose();
  // }, []);
  const closeDeleteModalCached = useCallback(() => {
    closeDeleteModal();
  }, []);
  const clearAllMessages = useCallback(() => {
    props.clearAllMessages();
  }, []);

  const getResourceGroups = useCallback(() => {
    props.getResourceGroups();
  }, []);

  const getListOfOrgsAndAccts = useCallback(() => {
    props.getListOfOrgsAndAccts();
  }, []);

  const getMsg = props.getResourceGroupsMessage;
  const getErr = props.getResourceGroupsError;
  const editMsg = props.editResourceGroupMessage;
  const deleteMsg = props.deleteResourceGroupMessage;
  const deleteErr = props.deleteResourceGroupError;
  const addMsg = props.addResourceGroupMessage;

  useEffect(() => {
    // dispatch(getListOfAccts());
    getListOfOrgsAndAccts();
  }, [getListOfOrgsAndAccts]);

  useEffect(() => {
    console.log("~ Calling getResourceGroups()");
    // if (userRole.includes("Administrator")) {
    getResourceGroups();
    // }
  }, [getResourceGroups]);

  // Close Add Resource Group dialog automatically
  useEffect(() => {
    console.log("useEffect for closing add resource group dialog");
    if (addMsg) {
      // Add successful, close the dialog box
      setAddDialogOpen(false);
      clearState();
      getResourceGroups();
      // props.getListOfAccts();
      getListOfOrgsAndAccts();
    }
  }, [addMsg, getResourceGroups, getListOfOrgsAndAccts]);

  // useEffect(() => {
  //   if (editMsg) {
  //     closeEditModal();
  //   }
  // }, [closeEditModal, editMsg]);
  useEffect(() => {
    if (deleteMsg) {
      getListOfOrgsAndAccts();
      closeDeleteModalCached();
    }
    if (deleteErr) {
      setDeleteResourceGroupError(deleteErr);
    }
  }, [closeDeleteModalCached, deleteMsg, deleteErr, getListOfOrgsAndAccts]);
  useEffect(() => {
    if (getMsg || getErr) {
      setTimeout(() => {
        clearAllMessages();
      }, 5000);
    }
  }, [clearAllMessages, getMsg, getErr]);

  return (
    <div>
      <h1>Resource Groups Configuration</h1>
      {
        // userRole.includes("Administrator")
        true ? (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant="contained"
              className={classes.primary}
              style={{ margin: "10px 10px", alignSelf: "flex-start" }}
              onClick={() => setAddDialogOpen(true)}
              disabled={
                !allowedRoles.includes(userRole) || props.isAddingResourceGroup
              }
            >
              Add Resource Group
            </Button>

            {/* Data grid to display users */}
            <div>
              {props.isGettingResourceGroups && (
                <>
                  <div
                    style={{
                      display: "flex",
                      direction: "row",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                      height: "100vh",
                    }}
                  >
                    <img src={spinner} width="32px" alt="Spinner" />
                  </div>
                </>
              )}
              {props.getResourceGroupsMessage && (
                <Snackbar
                  open={props.getResourceGroupsMessage !== null}
                  autoHideDuration={5000}
                >
                  <SnackbarAlert severity="success">
                    {props.getResourceGroupsMessage}
                  </SnackbarAlert>
                </Snackbar>
              )}
              {props.getResourceGroupsError && (
                <Snackbar
                  open={props.getResourceGroupsError !== null}
                  autoHideDuration={5000}
                >
                  <SnackbarAlert severity="error">
                    {props.getResourceGroupsError}
                  </SnackbarAlert>
                </Snackbar>
              )}
              {props.deleteResourceGroupMessage && (
                <Snackbar
                  open={props.deleteResourceGroupMessage !== null}
                  autoHideDuration={5000}
                >
                  <SnackbarAlert severity="success">
                    {props.deleteResourceGroupMessage}
                  </SnackbarAlert>
                </Snackbar>
              )}
              {props.addResourceGroupMessage && (
                <Snackbar
                  open={props.addResourceGroupMessage !== null}
                  autoHideDuration={5000}
                >
                  <SnackbarAlert severity="success">
                    Resource Group Added Successfully!
                  </SnackbarAlert>
                </Snackbar>
              )}
              {props.editResourceGroupMessage && (
                <Snackbar
                  open={props.editResourceGroupMessage !== null}
                  autoHideDuration={5000}
                >
                  <SnackbarAlert severity="success">
                    {props.editResourceGroupMessage}
                  </SnackbarAlert>
                </Snackbar>
              )}
              {props.resourceGroups && (
                <DataGrid
                  columns={columns}
                  rows={props.resourceGroups}
                  getRowId={(row) => row.resourcename}
                  autoHeight
                />
              )}
            </div>

            {/* Modal to add a resource group */}
            <Modal
              className={classes.modal}
              open={addDialogOpen}
              onClose={handleAddDialogClose}
            >
              <div className={classes.paper}>
                <h2 id="simple-modal-title">Add Resource Group</h2>
                {props.isAddingResourceGroup && (
                  <img src={spinner} width="32px" alt="Spinner" />
                )}
                {props.addResourceGroupError && (
                  <Alert className="mt-3 mb-0" color="danger">
                    Error while adding a resource group.
                    {JSON.stringify(props.addResourceGroupError)}
                  </Alert>
                )}
                <br />
                <br />
                <form className={classes.form} onSubmit={handleAddSubmit}>
                  <div>
                    <TextField
                      label="Resource Group Name"
                      size="small"
                      fullWidth
                      value={resourceGroupName}
                      onChange={(e) => {
                        setResourceGroupName(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      label="Resource Group Description"
                      size="small"
                      fullWidth
                      value={resourceGroupDescription}
                      onChange={(e) => {
                        setResourceGroupDescription(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    // onClick={handleAddUser}
                    disabled={props.isAddingResourceGroup}
                  >
                    Add Resource Group
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAddDialogClose()}
                    style={{ margin: "10px 10px", alignSelf: "flex-start" }}
                  >
                    Cancel
                  </Button>
                </form>
              </div>
            </Modal>

            {/* Modal to edit a resource group */}
            {/* <Modal
              className={classes.modal}
              open={editDialogOpen}
              onClose={handleEditDialogClose}
            >
              <div className={classes.paper}>
                <h2 id="simple-modal-title">Edit Resource Group</h2>
                {props.isEditingResourceGroup && (
                  <img src={spinner} width="32px" alt="Spinner" />
                )}
                {props.editResourceGroupError && (
                  <Alert className="mt-3 mb-0" color="danger">
                    Error while editing a resource group.
                    {JSON.stringify(props.editResourceGroupError)}
                  </Alert>
                )}
                <br />
                <br />
                <form className={classes.form} onSubmit={handleEditSubmit}>
                  <div>
                    <TextField
                      label="Resource Group Name"
                      size="small"
                      fullWidth
                      value={resourceGroupName}
                      onChange={(e) => {
                        setResourceGroupName(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      label="Resource Group Description"
                      size="small"
                      fullWidth
                      value={resourceGroupDescription}
                      onChange={(e) => {
                        setResourceGroupDescription(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    // onClick={handleAddUser}
                    disabled={props.isEditingResourceGroup}
                  >
                    Edit Resource Group
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleEditDialogClose()}
                    style={{ margin: "10px 10px", alignSelf: "flex-start" }}
                  >
                    Cancel
                  </Button>
                </form>
              </div>
            </Modal> */}

            {/* Delete Confirmation Modal */}
            <Modal
              className={classes.modal}
              open={showDeleteModal}
              onClose={closeDeleteModal}
            >
              <div className={classes.paper}>
                <h2 id="delete-confirmation-modal">Confirm Delete</h2>
                <p id="confirm-delete-organization">
                  Are you sure you want to delete this Resource Group?
                </p>
                {deleteResourceGroupError && (
                  <Alert className="mt-3 mb-0" color="danger">
                    Error while deleting a resource group.
                    {JSON.stringify(deleteResourceGroupError)}
                  </Alert>
                )}
                <div className={classes.modalButtons}>
                  <Button
                    onClick={handleDeleteConfirm}
                    color="secondary"
                    variant="contained"
                    disabled={props.isDeletingResourceGroup}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={closeDeleteModal}
                    color="primary"
                    variant="contained"
                    style={{ margin: "10px 10px", alignSelf: "flex-end" }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div>
            <h1>Not Authorized</h1>
          </div>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    isGettingResourceGroups,
    getResourceGroupsMessage,
    getResourceGroupsError,

    isAddingResourceGroup,
    addResourceGroupMessage,
    addResourceGroupError,

    isEditingResourceGroup,
    editResourceGroupMessage,
    editResourceGroupError,

    isDeletingResourceGroup,
    deleteResourceGroupError,
    deleteResourceGroupMessage,

    resourceGroups,
  } = state.resourceGroup;
  return {
    isGettingResourceGroups,
    getResourceGroupsMessage,
    getResourceGroupsError,

    isAddingResourceGroup,
    addResourceGroupMessage,
    addResourceGroupError,

    isEditingResourceGroup,
    editResourceGroupMessage,
    editResourceGroupError,

    isDeletingResourceGroup,
    deleteResourceGroupError,
    deleteResourceGroupMessage,

    resourceGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResourceGroups: () => {
      dispatch(getResourceGroups());
    },
    addResourceGroup: (resourceGroup) => {
      dispatch(addResourceGroup(resourceGroup));
    },
    editResourceGroup: (resourceGroup) => {
      dispatch(editResourceGroup(resourceGroup));
    },
    deleteResourceGroup: (resourceGroupName, orgCode) => {
      dispatch(deleteResourceGroup(resourceGroupName, orgCode));
    },
    clearAllMessages: () => {
      dispatch(clearAllResourceGroupMessages());
    },
    // getListOfAccts: () => {
    //   dispatch(getListOfAccts());
    // },
    getListOfOrgsAndAccts: () => {
      dispatch(getListOfOrgsAndAccts());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceGroupsConfig);
