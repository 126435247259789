import React, { useState, useEffect } from "react";
import { Button, Tooltip } from "@mui/material";
import { Snackbar } from "@mui/material";
import { Alert } from "@material-ui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styles from "./style.module.css"; // Import your CSS Module
import BoxLabel from "./BoxLabel";
import CloudFormationIcon from "../../../assets/images/aws/aws-cloudformation.png";
import { connect } from "react-redux";

import loadingGif from "./../../../assets/images/aws/loadingGif.gif";

import Terraform from "./../../../assets/images/iaac/terraform.png";
import Kubernetes from "./../../../assets/images/iaac/kubernetes.png";
import Docker from "./../../../assets/images/iaac/docker.png";
import CloudFormation from "./../../../assets/images/iaac/cloudformation.png";
import Ansible from "./../../../assets/images/iaac/ansible.png";
import OpenAPI from "./../../../assets/images/iaac/openAI.png";
import Helm from "./../../../assets/images/iaac/helm.png";
import gRPC from "./../../../assets/images/iaac/grpc.png";
import AWS from "./../../../assets/images/iaac/aws.png";
import Crossplane from "./../../../assets/images/iaac/crossplane.png";
import Pulumi from "./../../../assets/images/iaac/pulumi.png";
import Serverless from "./../../../assets/images/iaac/serverless.png";
import GDM from "./../../../assets/images/iaac/google-deployment-manager.png";
import ARM from "./../../../assets/images/iaac/azure-resource-manager.png";
import SAM from "./../../../assets/images/iaac/sam.png";
import DockerCompose from "./../../../assets/images/iaac/docker-compose.png";
import knative from "./../../../assets/images/iaac/knative.png";
import AzureBlueprints from "./../../../assets/images/iaac/azure_blurprints.png";
import cicd from "./../../../assets/images/iaac/cicd.png";
import openTofu from "./../../../assets/images/iaac/openTofu.png";
import Bicep from "./../../../assets/images/iaac/bicep.png";

import { useTable, useGlobalFilter, useSortBy } from "react-table";

import FullScreenDialog from "./FullScreenDialog";

import {
  clearAllIaacMessages,
  downloadFileRequest,
} from "../../../store/actions";
import { GlobalFilter } from "./GlobalFilter";

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Findings",
    // accessor: "",
  },
  {
    Header: "Input Filename",
    accessor: "fileName",
  },
  {
    Header: "Username",
    accessor: "userName",
  },
  {
    Header: "Status",
    // accessor: "",
  },
  {
    Header: "Vulnerabilities",
    // accessor: "",
  },
  {
    Header: "Template Type",
    accessor: "scriptType",
  },
  {
    Header: "Project Name",
    accessor: "projectName",
  },
  {
    Header: "Branch",
    accessor: "branch",
  },
  {
    Header: "Version",
    accessor: "version",
  },
  {
    Header: "Comments",
    accessor: "comments",
  },
  {
    Header: "Upload Date (UTC)",
    accessor: "createdDate",
  },
];

const iconMap = {
  cloudformation: CloudFormation,
  terraform: Terraform,
  kubernetes: Kubernetes,
  dockerfile: Docker,
  dockercompose: DockerCompose,
  ansible: Ansible,
  openapi: OpenAPI,
  helm: Helm,
  grpc: gRPC,
  aws: AWS,
  crossplane: Crossplane,
  pulumi: Pulumi,
  serverlessfw: Serverless,
  googledeploymentmanager: GDM,
  azureresourcemanager: ARM,
  sam: SAM,
  knative: knative,
  azureblueprints: AzureBlueprints,
  cicd: cicd,
  opentofu: openTofu,
  bicep: Bicep
};

function FileUploadHistoryTable(props) {
  const [expandedRow, setExpandedRow] = useState(null);
  const [issues, setIssues] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentFileOperation, setCurrentFileOperation] = useState(null);

  const fileHistory = props.fileHistory;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data: fileHistory }, useGlobalFilter, useSortBy);
  const { globalFilter } = state;
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const fileDownloadUrlProps = props.fileDownloadUrl;
  const fileType = props.fileType;
  useEffect(() => {
    const fetchData = async (url) => {
      setIssues(null);
      try {
        const response = await fetch(url);
        
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setIssues(result);
      } catch (error) {
        console.error("Error fetching data:", error);
        setExpandedRow(null);
      }
    };

    if (fileDownloadUrlProps) {
      if (fileType === "input") {
        window.open(fileDownloadUrlProps, "_blank");
      } else if (fileType === "output") {
        fetchData(fileDownloadUrlProps);
      }      
    }
  }, [fileDownloadUrlProps, fileType]);

  //For displaying success/error messages for short duration
  const fileDownloadMessage = props.fileDownloadMessage;
  const fileDownloadError = props.fileDownloadError;
  useEffect(() => {
    if (fileDownloadMessage || fileDownloadError) {
      setExpandedRow(null);
      setTimeout(() => {
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
  }, [fileDownloadMessage, fileDownloadError]);

  const handleResultsClick = (rowIndex, outputBucketUri) => {
    setIssues(null);
    setCurrentRowIndex(rowIndex);
    setCurrentFileOperation("output");
    setExpandedRow(rowIndex === expandedRow ? null : rowIndex);
    props.downloadFile(outputBucketUri, "output");
  };

  const handleFilenameClick = (rowIndex, templateBucketUri) => {
    setCurrentRowIndex(rowIndex);
    setCurrentFileOperation("input");
    props.downloadFile(templateBucketUri, "input");
  };

  return (
    <>
      <div>
        <div>
          <div>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table {...getTableProps()} className={styles.historyTable}>
            <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())} 
                        onMouseEnter={() => setHoveredColumn(column.id)} 
                        onMouseLeave={() => setHoveredColumn(null)}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownwardIcon /> 
                            ) : (
                              <ArrowUpwardIcon />
                            )
                          ) : column.accessor && hoveredColumn === column.id ? (
                            <ArrowUpwardIcon style={{ color: "gray" }} /> 
                          ) : (
                            ""
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              {/* </tr> */}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows &&
                rows?.map((item, index) => {
                  prepareRow(item);
                  return (
                  <React.Fragment key={item.original.id}>
                    <tr {...item.getRowProps()}>
                      <td>{item.original.id}</td>
                      <td>
                        <Button
                          // variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row expansion
                            handleResultsClick(
                              index,
                              item.original.outputBucketUri
                              );
                          }}
                          disabled={expandedRow && props.isFileDownloading}
                        >
                          {expandedRow === index &&
                          currentFileOperation === "output" &&
                          props.isFileDownloading ? (
                            <img
                              src={loadingGif}
                              height={24}
                              alt="Loading Spinner"
                            />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Button>
                      </td>
                      <td>
                        <Tooltip title="Click to download" arrow>
                          <Button
                            style={{
                              position: "relative",
                              textTransform: "unset",
                              textAlign: "left",
                            }}
                            disabled={
                              currentFileOperation === "input" &&
                              currentRowIndex === index &&
                              props.isFileDownloading
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFilenameClick(
                                index,
                                item.original.archiveBucketUri
                                );
                            }}
                          >
                            {currentRowIndex === index &&
                              currentFileOperation === "input" &&
                              props.isFileDownloading && (
                                <img
                                  src={loadingGif}
                                  height={24}
                                  alt="Loading Spinner"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                />
                              )}
                            {item.original.fileName.toLowerCase()}
                          </Button>
                        </Tooltip>
                      </td>
                      <td>{item.original.userName}</td>
                      <td>
                        {item.original.status === true ? (
                          // <Chip label="success" color="success" size="medium" />
                          <div className={styles.success}>PASSED</div>
                        ) : (
                          // <Chip label="failed" color="error" size="medium" />
                          <div className={styles.error}>FAILED</div>
                        )}
                      </td>

                      {/* <td>{item.resultSummary}</td> */}
                      <td>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {(item.original?.critical!==undefined || item.original?.critical!==null) && (
                            <div style={{ width: "60px", margin: "5px" }}>
                              <BoxLabel
                                key="critical"
                                label="critical"
                                count={item.original.critical}
                                color="darkred"
                              />
                            </div>
                          )}
                          {(item.original?.high!==undefined || item.original?.high!==null) && (
                            <div style={{ width: "60px", margin: "5px" }}>
                              <BoxLabel
                                key="high"
                                label="high"
                                count={item.original.high}
                                color="red"
                              />
                            </div>
                          )}                          
                          {(item.original?.medium!==undefined || item.original?.medium!==null) && (
                            <div style={{ width: "60px", margin: "5px" }}>
                              <BoxLabel
                                key="medium"
                                label="medium"
                                count={item.original.medium}
                                color="orange"
                              />
                            </div>
                            )}                          
                          {(item.original?.low!==undefined || item.original?.low!==null) && (
                            <div style={{ width: "60px", margin: "5px" }}>
                              <BoxLabel
                                key="low"
                                label="low"
                                count={item.original.low}
                                color="#FFD7B5"
                              />
                            </div>
                          )}                          
                          {(item.original?.info!==undefined || item.original?.info!==null) && (
                            <div style={{ width: "60px", margin: "5px" }}>
                              <BoxLabel
                                key="info"
                                label="info"
                                count={item.original.info}
                                color="#007BFF"
                              />
                            </div>                          
                          )}                     
                        </div>
                      </td>

                      <td>
                        {item.original.scriptType && (
                          <>
                            <img src={iconMap[item.original.scriptType.toLowerCase()]} height="24px" style={{ marginRight: '8px' }} alt="CloudFormation Icon"></img>
                            {/* {item.original.scriptType} */}
                          </>
                        )}
                      </td>
                      <td>{item.original.projectName}</td>
                      <td>{item.original.branch}</td>
                      <td>{item.original.version}</td>
                      <td>{item.original.comments}</td>
                      <td>{item.original.createdDate}</td>
                    </tr>
                    {expandedRow === index && issues !== null && (
                      <FullScreenDialog 
                        item={item} 
                        issues={issues?.queries} 
                        severityCounters={issues?.severity_counters} 
                        filePaths={issues?.paths}
                        jsObject={issues}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Snackbars for downloading file */}
      <Snackbar open={props.fileDownloadError !== null} autoHideDuration={5000}>
        <Alert severity="error">
          Error while downloading the file. {props.fileDownloadError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={props.fileDownloadMessage !== null}
        autoHideDuration={5000}
      >
        <Alert severity="success">{props.fileDownloadMessage}</Alert>
      </Snackbar>
    </>
  );
}



const mapStateToProps = (state) => {
  const {
    isFileDownloading,
    fileDownloadMessage,
    fileDownloadError,
    fileDownloadUrl,
    fileType,
  } = state.iaac;

  return {
    isFileDownloading,
    fileDownloadMessage,
    fileDownloadError,
    fileDownloadUrl,
    fileType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadFile: (fileId, fileType) => {
      dispatch(downloadFileRequest(fileId, fileType));
    },
    clearAllIaacMessages: () => {
      dispatch(clearAllIaacMessages());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadHistoryTable);