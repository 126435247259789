import React, { useEffect, useState, useRef } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Alert, AlertTitle } from "@mui/material";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { pink } from "@mui/material/colors";
import { connect } from "react-redux";
import { getLicensingData } from "../../../store/actions";

import loadingGif from "../../../assets/images/aws/loadingGif.gif";

const parameters = {
  name: "Choose Your Plan",
  price: "Price",
  numberOfAssets: "Number of Assets",
  scanFrequency: "Scan Frequency",
  securityPosture: "Security Posture",
  securityPostureReport: "Security Posture Report",
  supportResponse: "Support Response",
  costInsight: "Cost Insight",
  eventInsight: "Event Insight",
  enabled: "enabled",
  description: "Description",
};

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];
// const freeLicenses = ["TrialLinkedLicense"];


const transposeData = (data) => {
  // get all keys: id, price, numberOfAssets, securityPosture, securityPostureReport 
  const keys = Object.keys(data[0]);
  // get all plan names: Basic, Standard, ...
  // let planNames = data.map( plan => plan.name.startsWith("CUS") ? plan.description : plan.name);
  let planNames = data.map( plan => plan.name);

  console.log("Plan names: ", planNames);
  const transposedData = [];

  // For each key, create a row with properties as id, Basic, Standard, ... all plans
  for (const key of keys) {
    if (key === "id") {
      continue;
    }
    let row = { id: parameters[key] };
    // For each plan, store value of current property
    planNames.forEach( planName => {
      const matchingPlanForPlanName = data.find(plan => plan.name === planName);
      // console.log(" matchingPlanForPlanName: ", matchingPlanForPlanName);
      if(!matchingPlanForPlanName) {
        row = {...row, [planName]: "Contact Us" };
      } else {
        row = {...row, [planName]: matchingPlanForPlanName[key]};
      }
    })
    transposedData.push(row);
  }
  return transposedData;
};


// export function transposeArray(plans) {
//   console.log("In transposeArray ", plans);
//   const keys = Object.keys(plans[0]);
//   console.log("array keys ", keys);

//   let newArray = [];

//   for (const key of keys) {
//     // if(key==='id' || key==='name'){
//     //   continue;
//     // }
//     if (key === "id") {
//       continue;
//     }
//     console.log("Key = ", key);
//     let row = { id: parameters[key] };
//     let col = 0;
//     // const cols = ["basic", "standard", "premium", "enterprise"];
//     const cols = plans.map(plan => plan.name);
//     for (const plan of plans) {
//       if (key === "name") {
//         row = { ...row, [cols[col]]: plan[key] };
//       } else if (key === "price") {
//         row = { ...row, [cols[col]]: "$ " + plan[key] };
//       } else {
//         row = { ...row, [cols[col]]: plan[key] };
//       }
//       col++;
//     }
//     // row = { ...row, [cols[col]]: "Contact us" };
//     newArray.push(row);
//   }

//   return newArray;
// }

const Licensing = ({ data, license, selectLicense, getLicensingData, licenseData, upgradeSelection }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  console.log("Original Data: ", data);

  const apiRef = useGridApiRef();
  console.log("apiRef: ", apiRef);

  const currentLicense = licenseData?.data?.licenseName;
  console.log("Selected currentLicense is " + currentLicense);

  useEffect(() => {
    if(!currentLicense){
      console.log("~~~~~LicensingInfo: calling useEffect for getLicensingData()");
      getLicensingData();
    }
  }, [currentLicense, getLicensingData]);


  const handleScrollLeft = () => {
    // console.log("scroll left called,apiRef.current.getScrollPosition(): ", apiRef.current.getScrollPosition());
    const currentScrollPosition = apiRef.current.getScrollPosition();
    if(currentScrollPosition.left > 0) {
      apiRef.current.scroll({...currentScrollPosition,  left: currentScrollPosition.left - 50 });
    }
  };

  const handleScrollRight = () => {
    // console.log("scroll right called,apiRef.current.getScrollPosition(): ", apiRef.current.getScrollPosition());
    const currentScrollPosition = apiRef.current.getScrollPosition();
    if(currentScrollPosition.left >= 0) {
      apiRef.current.scroll({ top:0,  left: (currentScrollPosition.left + 50) });
    }
  };

  let rows;
  
  if(upgradeSelection) {
    rows = data.filter(plan => {
      let selected;
      if(currentLicense){
        if(freeLicenses.includes(currentLicense) && plan["Name"] === "Free") {
          selected = false;
        } else if(!freeLicenses.includes(currentLicense) && plan["Name"] !== "Free"){
          selected = true;
        } else {
          selected = true;
        }
      }
      return selected;
    }).map((plan, index) => {
      const name = plan["Name"];
      const metrics = plan;
      // const metrickeys = Object.keys(metrics);
      return {
        id: index + 1,
        price: metrics["Price"],
        numberOfAssets: metrics["Number Of Assets"],
        scanFrequency: metrics["Scan Frequency"],
        securityPosture: metrics["Security Posture"],
        securityPostureReport: metrics["Security Posture Report"],
        supportResponse: metrics["Support Response"],
        enabled: metrics["enabled"],
        description: metrics["Description"],
        name: name,
      };
    });
  } else {
    rows = data.filter(plan => {
      let selected;
      if(currentLicense){
        if(freeLicenses.includes(currentLicense) && plan["Name"] === "Free") {
          selected = true;
        } else if(!freeLicenses.includes(currentLicense) && plan["Name"] !== "Free"){
          selected = true;
        } else {
          selected = false;
        }
      }
      return selected;
    }).map((plan, index) => {
      const name = plan["Name"];
      const metrics = plan;
      // const metrickeys = Object.keys(metrics);
      return {
        id: index + 1,
        price: metrics["Price"],
        numberOfAssets: metrics["Number Of Assets"],
        scanFrequency: metrics["Scan Frequency"],
        securityPosture: metrics["Security Posture"],
        securityPostureReport: metrics["Security Posture Report"],
        supportResponse: metrics["Support Response"],
        enabled: metrics["enabled"],
        description: metrics["Description"],
        name: name,
      };
    });
  }
  

  // console.log("rows before transposeArray: ", rows);
  const planNames = rows.map(plan => plan.name);
  const planHeaders = rows.map(plan => plan.name.startsWith("CUS") ? plan.description : plan.name);
  console.log("planNames: ", planNames);
  console.log("transpose data :", transposeData(rows));
  // rows = transposeArray(rows);
  rows = transposeData(rows);
  console.log("rows after transposeArray: ", rows);
  // Remove Description row
  const filteredRows = rows.filter( row => !(row.id==="Description"));
  rows = filteredRows;
  console.log("rows after removing description: ", rows);

  const checkDisabled = (plan) => {
    let disabledFlag = false;
    let selectedRow = rows.find((row) => row.id === "enabled");
    if (selectedRow) {
      disabledFlag = selectedRow[plan];
    }
    return disabledFlag;
  };

  const selectPlan = (plan) => {
    if (plan === "enterprise") {
      setDialogOpen(true);
    } else {
      selectLicense(plan);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const idColumn = {
    field: "id",
    headerName: "",
    flex: 1,
    width: 180,
    sortable: false,
    renderCell: (rowData) => {
      if (rowData.row.id === "enabled") {
        return null;
      }
      if (rowData.row.id === "Price") {
        return (
          <>
            Price (<small style={{ color: "red" }}>Billed Monthly</small>)
          </>
        );
      }
    },
  };

  const dynamicColumns = planNames.map( (planName, index) => {
    const standardPlanNames = ["Basic", "Standard", "Premium"];
    const planHeader = standardPlanNames.includes(planName) ? planName : planHeaders[index]; 

    return ({
      field: planName,
      headerName: planHeader,
      width: 140,
      sortable: false,
      renderCell: (rowData) => {
        if (rowData.row.id === "enabled") {
          return null;
        }
        if (rowData.row.id === "Choose Your Plan")
          return (
            <Button
              color="warning"
              fullWidth
              variant={license === rowData.field ? "contained" : "outlined"}
              onClick={() => selectPlan(rowData.field)}
              disabled={!checkDisabled(planName)}
            >
              Buy
            </Button>
          );
      },
      disableClickEventBubbling: true,
      align: "center",
      headerAlign: "center",
    })
  });

  const defaultEnterpriseColumn = {
    field: "enterprise",
    headerName: "Enterprise",
    width: 140,
    sortable: false,
    renderCell: (rowData) => {
      if (rowData.row.id === "enabled") {
        return null;
      }
      if (rowData.row.id === "Choose Your Plan"){
        return (
          <Button
            color="warning"
            fullWidth
            variant={license === rowData.field ? "contained" : "outlined"}
            onClick={() => selectPlan(rowData.field)}
            // disabled={!checkDisabled("enterprise")}
          >
            Contact Us
          </Button>
        );
      }        
      else {
        return "Contact Us";
      } 
    },
    disableClickEventBubbling: true,
    align: "center",
    headerAlign: "center",
  };

  console.log("Dynamic columns: ", dynamicColumns);

  let columns;
  if(dynamicColumns.some(column => column.field.startsWith("CUS"))) {
    columns = [ {...idColumn}, ...dynamicColumns ];
  } else if(freeLicenses.includes(currentLicense)){
    columns = [ {...idColumn}, ...dynamicColumns ];
  } else {
    columns = [ {...idColumn}, ...dynamicColumns, {...defaultEnterpriseColumn} ];
  }

  if (currentLicense === "AwsMarketPlaceLicense") {
    const columnsAwsLicenseRemoved = columns.filter(column => column.field.startsWith("AWS") || column.field.startsWith("id"));
    columns = columnsAwsLicenseRemoved;
    const rowsAwsLicenseRemoved = rows.filter(row => row.id !== "Price" && row.id !== "Number of Assets");
    console.log("rowsAwsLicenseRemoved: ", rowsAwsLicenseRemoved);
    rows = rowsAwsLicenseRemoved;
  } else {
    const columnsAwsLicenseRemoved = columns.filter(column => !column.field.startsWith("AWS"));
    columns = columnsAwsLicenseRemoved;
  }
  
  console.log("Final columns: ", columns);
  console.log("Final rows: ", rows);

  if(licenseData?.data?.loading) {
    return (
      <div>
        <img src={loadingGif} height={24} alt="Loading Spinner" />
      </div>
    )
  }

  return (
    <div>
      <Alert severity="info" icon={false}>
        <AlertTitle>
          Based on the Asset Count from our Scan, we have following
          recommendations of Subscription plans. 
        </AlertTitle>
      </Alert>
      <div style={{ 
          // display: 'flex', 
          // justifyContent: 'center', 
          position: 'relative',
          marginBottom: '10px' 
          }
      }>
        <Button onClick={handleScrollLeft} style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            zIndex: 10,
          }}
        >
          <ArrowLeftIcon sx={{ fontSize: 40, color: pink[500] }}/> 
        </Button>
        <div style={{ overflowX: 'auto', flex: '1' }}>
          <DataGrid
            // ref={dataGridRef}
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableColumnReorder
            disableMultipleColumnsSorting
            disableMultipleColumnsFiltering
            hideFooterPagination
            autoHeight
            hideFooter
            rowHeight={40}
          />
        </div>
        <Button onClick={handleScrollRight} style={{
            position: "absolute",
            right : "10px",
            top: "50%",
            zIndex: 10,
          }}
        >
          <ArrowRightIcon sx={{ fontSize: 40, color: pink[500] }}/>
        </Button>
        </div>
        {!license && (
          <Alert severity="error">
            <AlertTitle>Please select a plan!</AlertTitle>
          </Alert>
        )}
        <Modal isOpen={dialogOpen}>
          <ModalBody>
            For this Enterprise plan, you need to contact us. Close this dialog
            box and contact us for further processing.
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeDialog}>Close</Button>
          </ModalFooter>
        </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    licenseData: state.licensing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLicensingData: () => {
      dispatch(getLicensingData());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Licensing);

const LicensingInfoBase = ({ data, license, getLicensingData, licenseData }) => {
  // const [dialogOpen, setDialogOpen] = useState(false);
  console.log("data = ",data);
  console.log("Selected license is " + license);

  const currentLicense = licenseData?.data?.licenseName;
  console.log("Selected currentLicense is " + currentLicense);

  useEffect(() => {
    if(!currentLicense){
      console.log("~~~~~LicensingInfo: calling useEffect for getLicensingData()");
      getLicensingData();
    }
  }, [currentLicense, getLicensingData]);
  

  let rows = data.filter(plan => {
    let selected;
    if(currentLicense){
      if(freeLicenses.includes(currentLicense) && plan["Name"] === "Free") {
        selected = true;
      } else if(!freeLicenses.includes(currentLicense) && plan["Name"] !== "Free"){
        selected = true;
      } else {
        selected = false;
      }
    }
    return selected;
  }).map((plan, index) => {
    const name = plan["Name"];
    const metrics = plan;

    return {
      id: index + 1,
      price: metrics["Price"],
      numberOfAssets: metrics["Number Of Assets"],
      scanFrequency: metrics["Scan Frequency"],
      securityPosture: metrics["Security Posture"],
      securityPostureReport: metrics["Security Posture Report"],
      supportResponse: metrics["Support Response"],
      name: name,
      description: metrics["Description"],
    };
  });

  const planNames = rows.map(plan => plan.name);
  const planHeaders = rows.map(plan => plan.name.startsWith("CUS") ? plan.description : plan.name);
  rows = transposeData(rows);
  const filteredRows = rows.filter( row => !(row.id==="Description"));
  rows = filteredRows;
  console.log("Licensing rows = ",rows);
  rows = rows.filter( row => row.id !== "Choose Your Plan");


  const idColumn = {
    field: "id",
    headerName: "",
    flex: 1,
    minWidth: 180,
    // maxWidth: 180,
    sortable: false,
    renderCell: (rowData) => {
      if (rowData.row.id === "enabled") {
        return null;
      }
      if (rowData.row.id === "Price") {
        return (
          <>
            Price (<small style={{ color: "red" }}>Billed Monthly</small>)
          </>
        );
      }
    },
  };

  const dynamicColumns = planNames.filter(planName => {
    let selected;
    if(currentLicense){
      if(freeLicenses.includes(currentLicense) && planName === "Free") {
        selected = true;
      } else if(!freeLicenses.includes(currentLicense) && planName !== "Free"){
        selected = true;
      } else {
        selected = false;
      }
    }
    return selected;
  }).map( (planName, index) => {
    const standardPlanNames = ["Basic", "Standard", "Premium"];
    const planHeader = standardPlanNames.includes(planName) ? planName : planHeaders[index]; 

    return ({
      field: planName,
      headerName: planHeader,
      width: 140,
      sortable: false,
      renderCell: (rowData) => {
        const isSelected = planName === license;
        const backgroundColor = isSelected ? 'lightskyblue' : 'inherit'; // Set background color conditionally
        const style = { backgroundColor, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"};
        if (rowData.row.id === "enabled") {
          return null;
        }
        if (rowData.row.id === "Choose Your Plan") {
          return null;
        }
        return (
          <div style={style}>{rowData.row[planName]}</div> // Apply background color to cells
        );
      },
      disableClickEventBubbling: true,
      align: "center",
      headerAlign: "center",
    })
  });

  const defaultEnterpriseColumn = {
    field: "enterprise",
    headerName: "Enterprise",
    width: 140,
    sortable: false,
    renderCell: (rowData) => {
      if (rowData.row.id === "enabled") {
        return null;
      }
      if (rowData.row.id === "Choose Your Plan"){
        return null;
      }        
      else {
        return "Contact Us";
      } 
    },
    disableClickEventBubbling: true,
    align: "center",
    headerAlign: "center",
  };

  let columns;
  if(dynamicColumns.some(column => column.field.startsWith("CUS"))) {
    columns = [ {...idColumn}, ...dynamicColumns ];
  } else if(freeLicenses.includes(currentLicense)){
    columns = [ {...idColumn}, ...dynamicColumns ];
  } else {
    columns = [ {...idColumn}, ...dynamicColumns, {...defaultEnterpriseColumn} ];
  }

  if (currentLicense === "AwsMarketPlaceLicense") {
    const columnsAwsLicenseRemoved = columns.filter(column => column.field.startsWith("AWS") || column.field.startsWith("id"));
    columns = columnsAwsLicenseRemoved;
    const rowsAwsLicenseRemoved = rows.filter(row => (row.id !== "Price" && row.id !== "Number of Assets") );
    rows = rowsAwsLicenseRemoved;
  } else {
    const columnsAwsLicenseRemoved = columns.filter(column => !column.field.startsWith("AWS"));
    columns = columnsAwsLicenseRemoved;
  }

  console.log("Final columns: " , columns);
  console.log("Final rows: ", rows);

  if(licenseData?.data?.loading) {
    return (
      <div>
        <img src={loadingGif} height={24} alt="Loading Spinner" />
      </div>
    )
  }

  return (
    <div>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableColumnReorder
        disableMultipleColumnsSorting
        disableMultipleColumnsFiltering
        hideFooterPagination
        autoHeight
        hideFooter
        rowHeight={40}
        initialState={{ pinnedColumns: { left: ['id'] } }}
        // options={{ customHeadRender : null}}
      />
    </div>
  );
};


const LicensingInfo = connect(mapStateToProps, mapDispatchToProps)(LicensingInfoBase);

export { LicensingInfo };
