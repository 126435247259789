import React, { Component } from "react";

import { connect } from "react-redux";
import { Button } from "reactstrap";

import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

import { Alert, Snackbar } from "@mui/material";

// Redux Store
import { toggleRightSidebar, getOrgsNameData, getProfileRoleInfo } from "../../store/actions";

//Import logo Images
import logosmdark from "../../assets/images/logo-main.jpg";
import logodark from "../../assets/images/logo-main.jpg";
import logosmlight from "../../assets/images/Collapsed_WhiteOrange_Curve.png";
import logolight from "../../assets/images/Expanded_WhiteOrange_Curve.png";
import EmailModal from "../../pages/Support/Email/EmailModal";
import SubscribeModal from "../../pages/Subscribe";


const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];
// const freeLicenses = ["TrialLinkedLicense"];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isSocialPf: false,
      emailSupportModal: false,
      subscriptionModal: false,
      configInfoOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.openEmailSupportModal = this.openEmailSupportModal.bind(this);
    this.closeEmailSupportModal = this.closeEmailSupportModal.bind(this);

    this.handleSubscribeClick = this.handleSubscribeClick.bind(this);
    this.openSubscriptionModal = this.openSubscriptionModal.bind(this);
    this.closeSubscriptionModal = this.closeSubscriptionModal.bind(this);
    this.openConfigInfo = this.openConfigInfo.bind(this);
    this.closeConfigInfo = this.closeConfigInfo.bind(this);
  }
  componentDidMount() {
    this.props.getOrgsNameData();
    if(!this.props.userRoles) {
      this.props.getProfileRoleInfo();
      console.log("Calling getProfileRoleInfo() from Header");
    }
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    if(this.props?.toggleMenuCallback !== undefined || this.props?.toggleMenuCallback !== null) { 
      this.props.toggleMenuCallback();
    }
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    if(this.props?.toggleRightSidebar !== undefined || this.props?.toggleRightSidebar !== null``) {
      this.props.toggleRightSidebar();
    }
  }


  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  openEmailSupportModal() {
    this.setState({ emailSupportModal: true});
  }

  closeEmailSupportModal() {
    this.setState({ emailSupportModal: false});
  }

  openSubscriptionModal() {
    this.setState({ subscriptionModal: true});
  }

  closeSubscriptionModal() {
    this.setState({ subscriptionModal: false});
  }

  openConfigInfo() {
    this.setState({ configInfoOpen: true});
  }

  closeConfigInfo() {
    this.setState({ configInfoOpen: false});
  }

  handleSubscribeClick() {
    if(this.props.userRoles.includes("Administrator")){
      this.openSubscriptionModal();
    } else {
      this.openConfigInfo();
    }
  }

  render() {
    const { organization } = this.props;
    const currentLicense = this.props.licenseData?.data?.licenseName;
    // console.log('--== Header *** ', organization);
    return (
      <React.Fragment>
        <header id="page-topbar" style={{ backgroundColor: '#193A6F' }}>
          <div className="navbar-header">
            <div className="d-flex">

              <div className="navbar-brand-box" style={{ backgroundColor: '#193A6F' }}>
                <Link to="#" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosmdark} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logodark} alt="" height="40" />
                  </span>
                </Link>

                <Link to="#" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosmlight} alt="" style={{ height: "2rem" }} />
                  </span>
                  <span className="logo-lg">
                    <img src={logolight} alt="" height="35" style={{ width: "12rem" }} />
                  </span>
                </Link>
              </div>

              <Button size="sm" color="none" type="button" onClick={this.toggleMenu} className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                <i className="ri-menu-2-line align-middle"></i>
              </Button>

            </div>

            <div className="d-flex">


              <div className="dropdown d-none d-lg-inline-block ml-1">
                {/* <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.openSubscriptionModal}>
                  Subscribe
                </Button> */}
                {/* For testing purposes */}
                {freeLicenses.includes(currentLicense) && (
                  <button type="button" className="btn btn-primary" onClick={this.handleSubscribeClick}>
                    Subscribe
                  </button>
                )}

                
                {/* <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.toggleFullscreen}>
                  <i className="ri-fullscreen-line"></i>
                </Button> */}
                <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.openEmailSupportModal}>
                  <i className="ri-mail-send-line"></i>
                </Button>
              </div>
              {
                organization && organization.orgName && (
                  <div className="dropdown d-none d-lg-inline-block ml-1">
                    <Button color="none" type="button" className="header-item noti-icon waves-effect" style={{ color: '#FFFFFF' }}>
                      <span>{organization.orgName}</span>
                    </Button>
                  </div>
                )
              }
              <ProfileMenu organization={organization?.orgName}/>
            </div>
          </div>
        </header>
        <>
              {this.state.emailSupportModal && <EmailModal open={this.state.emailSupportModal} onClose={this.closeEmailSupportModal}/>}
        </>
        <>
              {this.state.subscriptionModal && <SubscribeModal isOpen={this.state.subscriptionModal} closeModal={this.closeSubscriptionModal} />}
              {this.state.configInfoOpen && (
                <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.configInfoOpen}
                onClose={ () => this.closeConfigInfo() }
              >
                <Alert
                  onClose={() => this.closeConfigInfo()}
                  sx={{ width: '100%', bgcolor: "#323232", color:"whitesmoke"}}
                  icon={false} severity="success"
                >
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{alignSelf: "self-end"}}>
                        <>Please contact your Cloudcatcher admin!</>
                    </div>
                  </div>
                </Alert>  
              </Snackbar>
              )}
        </>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { layoutType } = state.layout;
  const { userRoles } = state.profile;
  const { header } = state;
  // console.log('--== header orgAccount ', header);
  return { 
    layoutType, 
    organization: header.data,
    licenseData: state.licensing,
    userRoles
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRightSidebar,
    getOrgsNameData: () => dispatch(getOrgsNameData()),
    getProfileRoleInfo: () => dispatch(getProfileRoleInfo()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Header));
