import React, { useEffect } from "react";
import MetricsDisplay from "./MetricsDisplay";
import styles from './style.module.css'

import {
  clearAllIaacMessages,
  getIaacMetricsRequest,
} from "../../../store/actions";

import { connect } from "react-redux";
import { Skeleton } from "@mui/material";
import { Snackbar } from "@mui/material";
import { Alert } from "@material-ui/lab";


function IaaCHeaderComponent(props) {
  const {orgcode, username, subscription, setTemplatesUploadCount} = props;
  console.log("props: ", props);

  useEffect(() => {
    if (!props.metrics) {
      console.log("Calling metrics API ");
      props.getIaacMetrics(orgcode);
    }
  }, [orgcode]);

    //For displaying success/error messages for short duration
  const iaacMetricsMessage = props.getIaacMetricsMessage;
  const iaacMetricsError = props.getIaacMetricsError;
  useEffect(() => {
    if(iaacMetricsMessage) {
      setTemplatesUploadCount(props.metrics.total);
    }
    if (iaacMetricsMessage || iaacMetricsError) {
      setTimeout(() => {
        console.log("About to call clearAllUserListMessages");
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
  }, [iaacMetricsMessage, iaacMetricsError]);


  return (
    <div>
      <div className={styles.iaacHeaderContainer}>
        {!props.metrics ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={100}
          />
        ) : (
          <MetricsDisplay
            totalUploaded={props.metrics.total}
            totalPassed={props.metrics.passed}
            totalFailed={props.metrics.failed}
            totalLimit={subscription?.limit}
            criticalCount={props.metrics?.critical}
            highCount={props.metrics?.high}
            mediumCount={props.metrics?.medium}
            lowCount={props.metrics?.low}
            infoCount={props.metrics?.info}
          />
        )}
      </div>

      {/* Snackbars for metrics API */}
      <Snackbar
        open={props.getIaacMetricsError !== null}
        autoHideDuration={5000}
      >
        <Alert severity="error">
          Error while getting file history data. {props.getIaacMetricsError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={props.getIaacMetricsMessage !== null}
        autoHideDuration={5000}
      >
        <Alert severity="success">{props.getIaacMetricsMessage}</Alert>
      </Snackbar>
    </div>
  );
}


const mapStateToProps = (state) => {
  const {
    isIaacMetricsGetting,
    getIaacMetricsMessage,
    getIaacMetricsError,
    metrics,
  } = state.iaac;

  return {
    isIaacMetricsGetting,
    getIaacMetricsMessage,
    getIaacMetricsError,
    metrics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIaacMetrics: (orgcode) => {
      dispatch(getIaacMetricsRequest(orgcode));
    },
    clearAllIaacMessages: () => {
      dispatch(clearAllIaacMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IaaCHeaderComponent);
