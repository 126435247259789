const refreshToken = () => {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (obj && obj.refreshToken) {
        return obj.refreshToken;
    } else {
        return "";
    }
};

export default refreshToken;