import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  validateToken,
  validateTokenFailed,
  validateTokenSuccess,
  apiError,
} from "../../store/actions";
import Register from "../Authentication/Register";
import { withRouter, Link } from "react-router-dom";

const ValidateToken = () => {
  // const [org,success,loading] = useState()
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const acc = useSelector((state) => state.account);
  const { loading, success, tokenError, formData } = acc;
  // The following two lines for debugging
  // let { loading, success, tokenError } = acc;
  // success = true;

  console.log("ValidateToken, acc:", acc);

  var showable = false;
  useEffect(() => {
    dispatch(validateToken(token));
  }, []);
  useEffect(() => {
    showable = true;
  }, [success]);

  if (success) {
    // return <Register token={token} />
    return <Register formData={formData} />;
  } else if (tokenError) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h2>Invalid Token</h2>
        <p>
          Please reach out to{" "}
          <a href="mailto:support.cloudcatcher@cloudnovatech.com">
            support.cloudcatcher@cloudnovatech.com
          </a>
          .
        </p>
        <Link to="/login" className="btn btn-primary">
          GO TO LOGIN
        </Link>
      </div>
    );
  } else {
    return (
      <div
        style={{ backgroundColor: "rgba(255,255,255,0.7)", zIndex: 9999 }}
        className="d-flex flex-column align-items-center justify-content-around position-fixed w-100 h-100"
      >
        <div className="d-flex flex-column align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div>Loading...</div>
        </div>
      </div>
    );
  }
};

export default ValidateToken;
