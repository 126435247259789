import {
  SEND_SUPPORT_EMAIL_REQUEST,
  SEND_SUPPORT_EMAIL_SUCCESSFUL,
  SEND_SUPPORT_EMAIL_FAILED,
  CLEAR_ALL_SUPPORT_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  isSupportEmailSending: false,
  sendSupportEmailMessage: null,
  sendSupportEmailError: null,
  emailPayload: null,
};

const supportReducer = (state=initialState, action) => {
  switch(action.type) {
    case SEND_SUPPORT_EMAIL_REQUEST:
      state = {
        ...state,
        isSupportEmailSending: true,
        sendSupportEmailMessage: null,
        sendSupportEmailError: null,
        emailPayload: action.payload.emailPayload,
      };
      break;
    case SEND_SUPPORT_EMAIL_SUCCESSFUL:
      state = {
        ...state,
        isSupportEmailSending: false,
        sendSupportEmailMessage: "Email sent successfully!",
        emailPayload: null,
      };
      break;      
    case SEND_SUPPORT_EMAIL_FAILED:
      state = {
        ...state,
        isSupportEmailSending: false,
        sendSupportEmailError: "Email sending failed!",
        emailPayload: null,
      };
      break;
    case CLEAR_ALL_SUPPORT_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        sendSupportEmailMessage: null,
        sendSupportEmailError: null,
        emailPayload: null,
      };
      break;            
    default:
      return state;
  }
  return state;
}

export default supportReducer;