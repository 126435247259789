import React, { useEffect, useState } from 'react';
import { MDBDataTableV5 } from "mdbreact";
import './default.theme.css';

const UserDataGrid = (props) => {

  // useEffect(() => {
  //   const headers = document.querySelectorAll('.mdb-dataTable thead th');
  //   headers.forEach(header => {
  //     header.classList.remove('sorting_desc');
  //   });
  // }, []);

  const [showSortingArrows, setShowSortingArrows] = useState(false);

  const handleMouseEnter = () => {
    setShowSortingArrows(true);
  };

  const handleMouseLeave = () => {
    setShowSortingArrows(false);
  };


  console.log("UserDataGrid is rendered");
    return  (
        <div id="dataGridTheme">
            <MDBDataTableV5 
              {...props} 
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={showSortingArrows ? '' : 'no-sorting-arrows'}
            >
                {props.children && props.children}
            </MDBDataTableV5>
        </div>
    )
}

// export default UserDataGrid;
export default React.memo(UserDataGrid, (prevProps, nextProps)=>{
  const prevUserEmails = prevProps.data.rows?.map((user) => user.email);
  const nextUserEmails = nextProps.data.rows?.map((user) => user.email);

  console.log("Memo preProps: ", prevProps.data.rows);
  console.log("Memo nextProps: ", nextProps.data.rows);

  // Compare user emails to check if any users were added, deleted, or changed
  if (
    prevUserEmails?.length !== nextUserEmails?.length ||
    !prevUserEmails?.includes((email) => nextUserEmails?.includes(email))
  ) {
    // Return false if user emails are different
    return false;
  }
  // If user emails are same, compare user's last modified date
  for (const prevUser of prevProps.data.rows) {
    const nextUser = nextProps.data.rows.find(
      (user) => user.email === prevUser.email
    );
    
    if (prevUser.lastModifiedDate !== nextUser.lastModifiedDate) {
      return false;
    }
  }

  // If all details are same, return true to avoid unnecessary re-render.
  return true;
});