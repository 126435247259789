import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ScriptDisplayDialog({ url, pageTitle, isOpen, setOpen, clearUrl }) {
  const [content, setContent] = useState('');
  const [buttonLabel, setButtonLabel] = useState("Copy");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        console.log("response: " + response);
        const data = await response.text();
        setContent(data);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [url, isOpen]);

  const handleClose = () => {
    setOpen(false);
    clearUrl();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
    setButtonLabel("Copied");
    setTimeout(() => setButtonLabel("Copy"), 3000); 
    // Optionally, provide user feedback that content has been copied
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ width: "50%", position: "absolute", left: "50%" }}
      >
        <DialogContent dividers>
          <div>
            <div>
              <Button onClick={copyToClipboard} variant="contained" color="primary">
                {buttonLabel}
              </Button>
            </div>
            <pre>
              {content}
            </pre>
          </div>
        </DialogContent>        
      </Dialog>
    </React.Fragment>
  );
}
