import React, { useState } from "react";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";
import { Tab, Tabs } from "@mui/material";

const AssetExplorer = () => {
  const [activeTab, setActiveTab] = useState("AssetInsight");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "AssetExplorer":
          return <GetKibanaDashboard dashboardKey={"Asset Explorer"} />;
      case "AssetInsight":
          return <GetKibanaDashboard dashboardKey={"Resource Explorer_2"} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
      >
        <Tab label="Asset Insight" value="AssetInsight" />
        <Tab
          label="Asset Explorer"
          value="AssetExplorer"
        />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default AssetExplorer;
