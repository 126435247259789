import {
  GET_ACCS_AND_TOKEN,
  GET_ACCS_AND_TOKEN_FAILED,
  GET_ACCS_AND_TOKEN_SUCCESSFUL,
  GET_TAB_TOKEN,
  GET_TAB_TOKEN_SUCCESSFUL,
  GET_TAB_TOKEN_FAILED,
  CLEAR_TAB_TOKEN,
  CLEAR_TAB_TOKEN_SUCCESSFUL
} from './actionTypes';

export const getAcctsTableau = (acct) => {
  return {
      type: GET_ACCS_AND_TOKEN,
      payload: { acct }
  }
}

export const getAcctsTableauSuccessful = (tabToken) => {
  return {
      type: GET_ACCS_AND_TOKEN_SUCCESSFUL,
      payload: { tabToken }
  }
}

export const getAcctsTableauFailed = (err) => {
  return {
      type: GET_ACCS_AND_TOKEN_FAILED,
      payload: { err }
  }
}

export const getTabToken = () => {
  return {
    type: GET_TAB_TOKEN
  }
}

export const getTabTokenSuccessful = (tabToken) => {
  return {
    type: GET_TAB_TOKEN_SUCCESSFUL,
    payload: { tabToken },
  };
};

export const getTabTokenFailed = (err) => {
  return {
    type: GET_TAB_TOKEN_FAILED,
    payload: { err },
  };
};

export const clearTabToken = () => {
return {
  type: CLEAR_TAB_TOKEN,
}
}

export const clearTabTokenSuccessful = () => {
return {
  type: CLEAR_TAB_TOKEN_SUCCESSFUL,
};
};