import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Input,
} from "reactstrap";

import {
  Snackbar,
  Step,
  StepLabel,
  Stepper, 
} from "@mui/material";

import Licensing from "../../components/AccountDetailPolicy/Pricing/Licensing";
import AddOns from "../../components/AccountDetailPolicy/Pricing/AddOns";

import TermsOfUse from "../../components/Common/TermsOfUse";
import { clearAllOrgAccountMessages, getAwsAcc, getLicensingData, getListOfOrgsAndAccts, getPricingInfo, registerOrgAccountSuccessful, updateOrgAccount } from "../../store/actions";
import { connect } from "react-redux";
import loadingGif from "../../assets/images/aws/loadingGif.gif";
import ConfirmationDialog from "./ConfirmationDialog";
import { Alert } from "@material-ui/lab";
import { getAddonsArray } from "../../components/AccountDetailPolicy/UpdateAccount";
import { clearAllScanServiceMessages, fetchFunctionCountsRequest } from "../../store/scanService/scanServiceSlice";
import { functionNames } from "../../store/scanService/functionNames";

const stepStyle = {
  boxShadow: 2,
  backgroundColor: "rgba(0,0,0,0.1)",
  padding: 2,
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      color: "warning.main",
      fontSize: "2rem",
    },
    "& .MuiStepConnector-line": {
      borderColor: "warning.main",
    },
  },
  "& .Mui-completed": {
    "&.MuiStepIcon-root": {
      color: "warning.main",
      fontSize: "2rem",
    },
    "& .MuiStepConnector-line": {
      borderColor: "warning.main",
    },
  },
};

const SubscribeModal = (props) => {
  const {isOpen, closeModal} = props;
  const [stepperStep, setStepperStep] = useState(0);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);
  const [termsAndConditionsAgreement, setTermsAndConditionsAgreement] = useState(false);
  
  const [license, setLicense] = useState("");
  const [pricingApiError, setPricingApiError] = useState("");
  const [pricingApiLoading, setPricingApiLoading] = useState(false);
  const [currentStepError, setCurrentStepError] = useState(false);

  // Asset counts
  const [assetCountFailure, setAssetCountFailure] =useState(false);
  const [assetCountSuccess, setAssetCountSuccess] =useState(false);


  const [complianceAddOns, setComplianceAddOns] = useState([]);
  const [complianceAddOnsRows, setComplianceAddOnsRows] = useState([]);
  const [licenseData, setLicenseData] = useState([]);
  const [eventAndCostInsightAddOns, setEventAndCostInsightAddOns] = useState([]);
  const [verifyBucketPolicyStatusEventInsight, setVerifyBucketPolicyStatusEventInsight] = useState(false);
  const [verifyBucketPolicyStatusCostInsight, setVerifyBucketPolicyStatusCostInsight] = useState(false);
  const [verifyBucketPolicyStatusCostOpt, setVerifyBucketPolicyStatusCostOpt] = useState(false);
  const [isBucketPolicyEnabledEventInsight, setIsBucketPolicyEnabledEventInsight] = useState(false);
  const [isBucketPolicyEnabledCostInsight, setIsBucketPolicyEnabledCostInsight] = useState(false);
  const [isBucketPolicyEnabledCostOpt, setIsBucketPolicyEnabledCostOpt] = useState(false);

  const [updateAccountError, setUpdateAccountError] = useState(false);
  const [updateAccountSuccess, setUpdateAccountSuccess] = useState(false);

  // To control scan function
  const abortControllerRef = useRef(null);


  // Loads accountNumber and organization and accounts
  useEffect(()=>{
    if (props.organizations===null || props.accounts===null){
      props.getListOfOrgsAndAccts();
    }
  },[]);

  // Extract account information and organization information from API response
  let orgCode = props?.organizations[0]?.orgcode;
  let accountForUpdate;
  let orgForUpdate;
  if(props.accounts && props.accounts.length > 0){
    accountForUpdate = props.accounts.find(account => !account.accountNumber.startsWith("INIT"));
  }
  if(props.organizations && props.organizations.length > 0){
    orgForUpdate = props.organizations[0];
  }


  // Keep required information of account and organization
  const orgId = orgForUpdate?.id;
  const accountNumber = accountForUpdate?.accountNumber;
  const accountName = accountForUpdate?.accountName;
  const accountCode  = accountForUpdate?.accountCode;
  const urlLink  = accountForUpdate?.urlLink;
  const location  = accountForUpdate?.location;
  const accounttype  = accountForUpdate?.accounttype;
  const address  = accountForUpdate?.address;
  const city  = accountForUpdate?.city;
  const state  = accountForUpdate?.state;
  const zip  = accountForUpdate?.zip;
  const country  = accountForUpdate?.country;
  const email  = accountForUpdate?.email;
  const phonenumber  = accountForUpdate?.phonenumber;
  const eula  = accountForUpdate?.eula;
  // const eula  = "blah";
  const accountResourceGroups  = accountForUpdate?.accountResourceGroups;
  const iamrole  = accountForUpdate?.iamrole;
  const externalId  = accountForUpdate?.externalId;
  
  const fetchFunctionCounts = useCallback(
    (functionNames, accountNumber, iamRole, externalId, signal) => {
      props.fetchFunctionCounts(functionNames, accountNumber, iamRole, externalId, signal);
    }, []);

  const clearAllScanServiceMessages = useCallback(() => {
    props.clearAllScanServiceMessages();
  },[]);
  

  // console.log("props.aws: ", props.aws);
  const isAssetCountLoading = props.functionCountsLoading;
  useEffect(()=>{
    const fetchTotalCount = (signal) => {
      setAssetCountFailure(false);

      fetchFunctionCounts(
        functionNames,
        accountNumber,
        iamrole,
        externalId,
        signal
      );
    };

    if(accountNumber && stepperStep === 0) {      
      if(accountNumber && iamrole && !assetCountSuccess 
        // && !isAssetCountLoading
      ) {
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
        console.log("Calling fetchTotalCount");
        fetchTotalCount(signal);
      }
    }

    return () => {
      if (abortControllerRef.current) {
        console.log("###### abortControllerRef.current.abort()");
        abortControllerRef.current.abort();
      }
    };
  },[ 
    accountNumber,
    iamrole,
    stepperStep,
    externalId,
    assetCountSuccess,
    isAssetCountLoading,
    fetchFunctionCounts,
    clearAllScanServiceMessages
  ]);

  const assetCountsTotal = props?.functionCountsTotal?.total;
  const functionCountsError = props.functionCountsError;
  useEffect(() => {
    if (assetCountsTotal > 0) {
      if (!assetCountSuccess) {
        setAssetCountSuccess(true);
        setAssetCountFailure(false);
      }
    } else if (assetCountsTotal === -1) {
      if (!assetCountFailure) {
        setAssetCountSuccess(false);
        setAssetCountFailure(true);
      }
    }

    return () => {
      console.log("clearing all scanService messages...");
      clearAllScanServiceMessages();
    }
  }, [assetCountsTotal, assetCountSuccess, assetCountFailure, functionCountsError, clearAllScanServiceMessages]);


  // Calls License Pricing API data
  useEffect(()=>{
    const getPricingApiData = async () => {
      //Getting data of licensing and addons
      // console.log("~~~~~~ getPricingApiData called...");
      setPricingApiError(null);
      setPricingApiLoading(true);
      let subscription = license;      
      props.getLicensePricingInfo(accountNumber, subscription, orgCode);
    };
    if(accountNumber && orgCode && !pricingApiLoading) {
      getPricingApiData();
    }

  }, [accountNumber, license, orgCode, props, pricingApiLoading]);

  // Store License Pricing API data
  const pricingInfo = props.pricingInfo;
  useEffect(()=>{
    const storePricingApiData = () => {
      //Added for processing response of License Info API (AWS Lambda)
      if (pricingInfo && !complianceAddOnsRows.length > 0) {
        const data = pricingInfo;
        let newRows = data["Compliance Add Ons"].map((addOn, index) => {
          return {
            id: index + 1,
            name: addOn.Name,
            price: addOn.Price,
            description: addOn.Description,
            subscription: addOn.Subscription
          };
        });
        setComplianceAddOnsRows(newRows);
        setLicenseData(data["Subscription Model"]);
      }
    };

    if (pricingInfo) {
      storePricingApiData();
      if ( stepperStep === 0 && assetCountSuccess) {
        setStepperStep( stepperStep+1 );
      }
    }
  },[pricingInfo, stepperStep, assetCountSuccess, complianceAddOnsRows]);

  // To enable Alert snackbar display
  const reg_acct_error = props.reg_acct_error;
  useEffect(()=>{
    if(reg_acct_error){
      setUpdateAccountError(true);
      setTimeout(()=>setUpdateAccountError(false), 5000);
    }
  }, [reg_acct_error]);

  // To enable Alert snackbar display
  const updateAccountSuccessMessage = props.message;
  useEffect(()=>{
    if(updateAccountSuccessMessage){
      setUpdateAccountSuccess(true);
      // setTimeout(()=>setUpdateAccountSuccess(false), 5000);
    }
  }, [updateAccountSuccessMessage]);


  const handleClose = () => {
    if (isConfirmationOpen) {
      setIsConfirmationOpen(false);
      props.clearAllOrgAccountMessages();
      closeModal();        
    } else {
      setIsConfirmationOpen(true);
    }
  };

  const setBucketPolicyStatusEventInsight = (status) => {
    setVerifyBucketPolicyStatusEventInsight(status);
  };

  const setBucketPolicyStatusCostInsight = (status) => {
    setVerifyBucketPolicyStatusCostInsight(status);
  };

  const setBucketPolicyStatusCostOpt = (status) => {
    setVerifyBucketPolicyStatusCostOpt(status);
  };

  const setBucketPolicyEnabledEventInsight = (status) => {
    setIsBucketPolicyEnabledEventInsight(status);
  };

  const setBucketPolicyEnabledCostInsight = (status) => {
    setIsBucketPolicyEnabledCostInsight(status);
  };

  const setBucketPolicyEnabledCostOpt = (status) => {
    setIsBucketPolicyEnabledCostOpt(status);
  };

  const selectLicense = (licenseName) => {
    setLicense(licenseName);
  };

  const handleCheckboxChange = () => {
    if (termsAndConditionsAgreement) {
      setTermsAndConditionsAgreement(false);
    } else {
      setTermsAndConditionsAgreement(true);
    }
  };

  const handlePrevious = () => {
    if (stepperStep > 1) {
      setStepperStep(stepperStep-1);
    }
  };

  const isLicenseInvalid = () => {
    return false;
  };

  const isAddOnsInvalid = () => {
    return (isBucketPolicyEnabledEventInsight ? 
        !verifyBucketPolicyStatusEventInsight : 
        false) || 
        (isBucketPolicyEnabledCostInsight ? 
          !verifyBucketPolicyStatusCostInsight : 
          false) ||
        (isBucketPolicyEnabledCostOpt ? 
          !verifyBucketPolicyStatusCostOpt : 
          false);
  };
  const isConfirmationInvalid = () => {
    return !termsAndConditionsAgreement;
  };

  const isCurrentStepInvalid = () => {
    // return false;
    if (stepperStep === 1) {
      return isLicenseInvalid();
    } else if (stepperStep === 2) {
      return isAddOnsInvalid();
    } else if (stepperStep === 3) {
      return isConfirmationInvalid();
    }
  };



  const handleLicenseStep = () => {
    if (license !== "") {
      setCurrentStepError(false);
      return true;
    } else {
      setCurrentStepError(true);
    }
  };

  const handleAddOnsStep = () => {
    if (complianceAddOns.length >= 0) {
      setCurrentStepError(false);
      calculatePrice();
      return true;
    } else {
      setCurrentStepError(true);
    }
  };

  const handleSubmit = () => {
    // Convert resource groups into desired format
    const accountRgs = accountResourceGroups.map(rg => ({ resourcename: rg.resourcename}));

    let subscription;
    subscription = {
      name:
        license.charAt(0).toUpperCase() +
        license.slice(1),
    };

    //Prepare subscription object,
    // If selected license is custom, then all addons are selected by default
    let subscriptionAddOnsAllSelected = getAddonsArray(licenseData);
    // Remove IaaC from custom license
    if(license.startsWith("CUS")){
      subscriptionAddOnsAllSelected = subscriptionAddOnsAllSelected.filter(
        addons => addons.name !== "IaaC"
      ); 
    }

    // Prepare subscriptionAddons in { name, enabled } format from the user selected addons
    let subscriptionAddOns = eventAndCostInsightAddOns.map(
      (addon) => ({ name: addon.addons?addon.addons: addon.name, enabled: true })
    );

    let mergedSubscriptionAddOns; 

    if(subscriptionAddOnsAllSelected.length > 0){
      mergedSubscriptionAddOns = mergedSubscriptionAddOns = subscriptionAddOnsAllSelected.map(
        (sub1) => {
          const sub2 =
            subscriptionAddOns.find((sub2) => sub2.name === sub1.name) || {};
          return { ...sub1, ...sub2 };
        }
      );
    } else {
      mergedSubscriptionAddOns = subscriptionAddOns;
    }
    

    subscriptionAddOns = mergedSubscriptionAddOns;

    subscriptionAddOns = subscriptionAddOns.filter(
      addons => addons.name !== "IaaC"
    ); 

    subscription = { ...subscription, addons: subscriptionAddOns };

    //Prepare compliance addons object, end
    let complianceAddOnsAllSelected = complianceAddOnsRows
        .filter(addOn => addOn.subscription.toLowerCase() === license.toLowerCase())
        .map(
          (addOn) => {
            return { name: addOn.name, enabled: false };
          }
        );
    // console.log("complianceAddOnsAllSelected = ", complianceAddOnsAllSelected);
    let compAddOns = complianceAddOns.map((addon) => ({
      name: addon.name,
      enabled: true,
    }));
    // console.log("complianceAddOns = ", complianceAddOns);
    const mergedComplianceAddOns = complianceAddOnsAllSelected.map((sub1) => {
      const sub2 =
      compAddOns.find((sub2) => sub2.name === sub1.name) || {};
      return { ...sub1, ...sub2 };
    });
    compAddOns = mergedComplianceAddOns;

    let accountUpdateRequest = {
      orgId: orgId,
      //for testing purposes
      accountNumber: accountNumber,
      // accountNumber: "303030",
      // accountNumber: "786477308850",
      accountName: accountName,
      accountCode: accountCode,
      urlLink: urlLink,
      location: location,
      accounttype: accounttype,
      address: address,
      city: city,
      state: state,
      zip: zip,
      country: country,
      email: email,
      phonenumber: phonenumber,
      eula: eula,
      accountResourceGroups: accountRgs,
      subscription,
      complianceAddOns: compAddOns,
      iamrole: iamrole,
      externalId: externalId
    };
    console.log("accountUpdateRequest = ",accountUpdateRequest);
    props.updateOrgAccount(accountUpdateRequest);
  };


  const handleNext = async () => {
    if (stepperStep === 1) {
      if(handleLicenseStep()) {
        setStepperStep(stepperStep+1);
      }
    } else if (stepperStep === 2) {
      if (handleAddOnsStep()) {
        setStepperStep(stepperStep+1);
      }
    } else if (stepperStep === 3) {
      handleSubmit();
    }
  };

  const resetState = () => {
    setStepperStep(0);
    setTotalPrice(null);
    setPriceDetails(null);
    setTermsAndConditionsAgreement(false);
    setLicense("");
    setPricingApiError("");
    setCurrentStepError(false);
    setAssetCountFailure(false);
    setAssetCountSuccess(false);
    setComplianceAddOns([]);
    setComplianceAddOnsRows([]);
    setLicenseData([]);
    setEventAndCostInsightAddOns([]);
    setVerifyBucketPolicyStatusEventInsight(false);
    setVerifyBucketPolicyStatusCostInsight(false);
    setIsBucketPolicyEnabledEventInsight(false);
    setIsBucketPolicyEnabledCostInsight(false);
  }

  const closeOnAcctSuccessMsg = () => {
    resetState();
    closeModal();
    props.clearAllOrgAccountMessages();
    props.getLicensingData();
  };

  const calculatePrice = () => {
    let priceDetails = "";
    let totalPrice = 0;
    const selected = licenseData.filter(
      (row) => row.Name.toLowerCase() === license.toLowerCase()
    );
    totalPrice = selected.length > 0 ? selected[0]["Price"] : 0;
    priceDetails +=
      "Price for " + license + " plan = $" + totalPrice;
    for (const addons of complianceAddOns) {
      priceDetails +=
        ", Price for " + addons.name + " addons = $" + addons.price;
      totalPrice += addons.price;
    }
    for (const addons of eventAndCostInsightAddOns) {
      priceDetails +=
        ", Price for " +
        addons.addons +
        " under " +
        license +
        " = $" +
        addons[license];
      // totalPrice += addons[this.state.license];
      totalPrice += addons.price;
    }
    setTotalPrice(totalPrice);
    setPriceDetails(priceDetails);
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} size="lg" centered>
        <ModalHeader
          toggle={handleClose}
          style={{ backgroundColor: "#F98125", margin: -1 }}
        ></ModalHeader>
        <ModalBody>
        <div>
            <Container fluid={true}>
              <Row>
                <Col sm="12" md="12">
                  <Card>
                    <CardBody>
                      {(stepperStep > 0) && (
                          <Stepper
                          alternativeLabel
                          activeStep={stepperStep-1}
                          sx={stepStyle}
                        >
                          <Step key="Step1" completed={stepperStep > 1}>
                            <StepLabel>License Selection</StepLabel>
                          </Step>
                          <Step key="Step2" completed={stepperStep > 2}>
                            <StepLabel>Addons Selection</StepLabel>
                          </Step>
                          <Step
                            key="Step3"
                            completed={stepperStep > 3}
                            // icon={<VisibilityIcon />}
                          >
                            <StepLabel>Confirmation</StepLabel>
                          </Step>
                        </Stepper>
                      )}

                      { stepperStep === 0 && (
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "300px"}}>
                          <h6>Please wait while we scan your assets.</h6>
                          {isAssetCountLoading && (
                            <img
                              src={loadingGif}
                              height={40}
                              alt="Loading Spinner"
                            />
                          )}
                        </div>
                      )}
                      
                      {(stepperStep === 1 && licenseData.length>0) && (
                        <Licensing
                          data={licenseData}
                          license={license}
                          selectLicense={selectLicense}
                          upgradeSelection={true}
                        />
                        // <>test</>
                      )}

                      { (stepperStep === 2 && licenseData.length > 0) && (
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-12 col-md-12">
                            <AddOns
                              complianceAddOns={complianceAddOns}
                              setComplianceAddOns={setComplianceAddOns}
                              complianceRows={complianceAddOnsRows.filter(addOn => addOn.subscription.toLowerCase() === license.toLowerCase())}
                              licenseData={licenseData}
                              eventAndCostInsightAddOns={
                                eventAndCostInsightAddOns
                              }
                              setEventAndCostInsightAddOns={
                                setEventAndCostInsightAddOns
                              }
                              accountNumber={accountNumber}
                              setBucketPolicyStatusEventInsight={setBucketPolicyStatusEventInsight}
                              setBucketPolicyEnabledEventInsight={setBucketPolicyEnabledEventInsight}
                              setBucketPolicyStatusCostInsight={setBucketPolicyStatusCostInsight}
                              setBucketPolicyEnabledCostInsight={setBucketPolicyEnabledCostInsight}
                              setBucketPolicyStatusCostOpt={setBucketPolicyStatusCostOpt}
                              setBucketPolicyEnabledCostOpt={setBucketPolicyEnabledCostOpt}
                              readOnlyRole={iamrole}
                              license={license}
                              externalId={externalId}
                            />
                          </div>
                        </div>
                      )}

                      {stepperStep === 3 && (
                        <div>
                          <div
                            style={{
                              backgroundColor: "#EAEAEA",
                              padding: "20px",
                              marginTop: "20px",
                              borderRadius: "10px",
                            }}
                          >
                            <div>Kindly verify the details before submitting</div>

                            <div className="row">
                              <div className="col-sm-3 col-md-3">
                                Account Number:
                              </div>
                              <div
                                className="col-sm-9 col-md-9"
                                style={{ color: "blue" }}
                              >
                                {accountNumber}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-3 col-md-3">License:</div>
                              <div
                                className="col-sm-3 col-md-3"
                                style={{ color: "blue" }}
                              >
                                {license.charAt(0).toUpperCase() +
                                  license.slice(1)}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-3 col-md-3">Addons:</div>
                              <div
                                className="col-sm-9 col-md-9"
                                style={{ color: "blue" }}
                              >
                                {complianceAddOns.map(
                                  (row, index) =>
                                    row.name +
                                    (complianceAddOns.length ===
                                    index + 1
                                      ? ""
                                      : ", ")
                                )}
                                {eventAndCostInsightAddOns.map(
                                  (row, index) =>
                                    (index === 0
                                      ? ", " + row.addons
                                      : row.addons) +
                                    (eventAndCostInsightAddOns
                                      .length ===
                                    index + 1
                                      ? ""
                                      : ", ")
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-3 col-md-3">
                                Total Price/Month:
                              </div>
                              <div
                                className="col-sm-9 col-md-9"
                                style={{ color: "blue" }}
                              >
                                $ {totalPrice}
                              </div>
                            </div>
                          </div>

                          <div className="row" style={{ paddingTop: "30px" }}>
                            <div className="col-sm-1 col-md-1 col-lg-1"></div>
                            <div className="col-sm-11 col-md-11 custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                style={{ marginLeft: "20px" }}
                                id="termsAndConditionsCheckbox"
                                name="termsAndConditionsCheckbox"
                                onChange={handleCheckboxChange}
                                checked={termsAndConditionsAgreement}
                              />
                              <TermsOfUse />
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="row"
                        style={{
                          marginTop: "30px",
                          gap: "10px",
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        {stepperStep > 1 ? (
                          <div>
                            <button
                              className="btn w-lg btn-primary"
                              onClick={handlePrevious}
                            >
                              Back
                            </button>
                          </div>
                        ) : null}

                        <div>
                          <button
                            className="btn w-lg btn-primary"
                            disabled={isAssetCountLoading || isCurrentStepInvalid() || props?.loading}
                            onClick={handleNext}
                          >
                            {stepperStep === 3 ? "Submit" : "Next"}
                          </button>
                        </div>
                      </div>

                      <>
                        <Modal isOpen={updateAccountSuccess}>
                          <ModalBody>Your account has been successfully upgraded.</ModalBody>
                          <ModalFooter>
                            <Button onClick={closeOnAcctSuccessMsg}>
                              Close
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </ModalBody>
      </Modal>
      <ConfirmationDialog
        open={isConfirmationOpen}
        message="Do you really want to close this? If you click Yes, you will lose all your progress!"
        onConfirm={() => {
          setIsConfirmationOpen(false);
          if (abortControllerRef.current) {
            abortControllerRef.current.abort(); // Abort the ongoing API call
          }
          closeModal();
        }}
        onCancel={() => setIsConfirmationOpen(false)}
      />
      <Snackbar
          open={updateAccountError}
          autoHideDuration={5000}
      >
        <Alert severity="error">
          Error while updating the account.{" "}
          {props.reg_acct_error}
        </Alert>
      </Snackbar>
    </React.Fragment>
    
  )
}

const mapStateToProps = (state) => {
  const { account, accounts, organizations, reg_acct_error, loading, error, message } = state.orgAccount;
  const {aws} = state.awsAcc;
  const {
    pricingInfo,
    error: pricingInfoApiError,
    loading: pricingInfoApiLoading,
  } = state.pricingInfo;
  const {
    functionCountsLoading,
    functionCountsTotal,
    functionCountsError,
    functionCountsProgress,
  } = state.scanService;

  return {
    account,
    accounts,
    organizations,
    reg_acct_error,
    loading,
    message,
    aws,
    error,
    pricingInfo,
    pricingInfoApiError,
    pricingInfoApiLoading,

    functionCountsLoading,
    functionCountsTotal,
    functionCountsError,
    functionCountsProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListOfOrgsAndAccts: () => {
      dispatch(getListOfOrgsAndAccts());
    },
    updateOrgAccount: (data, changeOrganization) => {
      dispatch(updateOrgAccount(data, changeOrganization));
    },
    resetOrgAccountStatus: () => {
      dispatch(registerOrgAccountSuccessful(null));
    },
    clearAllOrgAccountMessages: () => {
      dispatch(clearAllOrgAccountMessages());
    },
    getAwsAcc: () => {
      dispatch(getAwsAcc());
    },
    getLicensePricingInfo: (accountNumber, subscription, orgCode) => {	
      dispatch(getPricingInfo(accountNumber, subscription, orgCode));	
    },
    getLicensingData: () => {
      dispatch(getLicensingData());
    },
    fetchFunctionCounts: (functionNames, accountNumber, roleName, externalId, signal) => {
      dispatch(fetchFunctionCountsRequest({ functionNames, accountNumber, roleName, externalId, signal }));
    },
    clearAllScanServiceMessages: () => {
      dispatch(clearAllScanServiceMessages());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeModal);
