export const GET_ASSET_INSIGHT_METRIC = "get_asset_insight_metric";
export const GET_ASSET_INSIGHT_METRIC_SUCCESSFUL =
  "get_asset_insight_metric_successful";
export const GET_ASSET_INSIGHT_METRIC_FAILED = "get_asset_insight_metric_failed";

export const GET_COMPLIANCE_METRIC = "get_compliance_metric";
export const GET_COMPLIANCE_METRIC_SUCCESSFUL = "get_compliance_metric_successful";
export const GET_COMPLIANCE_METRIC_FAILED = "get_compliance_metric_failed";

export const GET_COST_INSIGHT_METRIC = "get_cost_insight_metric";
export const GET_COST_INSIGHT_METRIC_SUCCESSFUL =
  "get_cost_insight_metric_successful";
export const GET_COST_INSIGHT_METRIC_FAILED =
  "get_cost_insight_metric_failed";

export const GET_SECURITY_POSTURE_METRIC = "get_security_posture_metric";
export const GET_SECURITY_POSTURE_METRIC_SUCCESSFUL =
  "get_security_posture_metric_successful";
export const GET_SECURITY_POSTURE_METRIC_FAILED = "get_security_posture_metric_failed";
