import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetInsightMetric } from "../../../store/actions"; // Update with actual action
import { resourceIconMap } from "./AssetManagementInfoCard";

export const useAssetInsightData = (accCodes, orgCode) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const assetInsightMetric = useSelector(
    (state) => state.metricInfo.assetInsightMetric
  );
  const assetInsightMetricLoading = useSelector(
    (state) => state.metricInfo.assetInsightMetricLoading
  );
  const assetInsightMetricError = useSelector(
    (state) => state.metricInfo.assetInsightMetricError
  );

  // To track if the API call has already been made
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (accCodes.length > 0 && orgCode && !hasFetchedData.current) {
      console.log("Making API call for asset insight data");
      dispatch(getAssetInsightMetric(accCodes, orgCode)); // Dispatch the action
      hasFetchedData.current = true; // Mark the call as done
    } else {
      console.log("Skipping API call for asset insight data");
    }
  }, [dispatch, accCodes, orgCode]);

  useEffect(() => {
    if (assetInsightMetric) {
      const metricObject = apiDataToAssetInsightMetricObject(assetInsightMetric);
      setData(metricObject);
    }
    setLoading(assetInsightMetricLoading);
    setError(assetInsightMetricError ? "Error in Asset Insight API" : null);
  }, [assetInsightMetric, assetInsightMetricLoading, assetInsightMetricError]);

  return { data, loading, error };
};

const apiDataToAssetInsightMetricObject = (apiData) => {
  const resources = Object.keys(resourceIconMap);

  // Dynamically build metricObject based on available resources
  let metricObject = {};
  resources.forEach(resource => {
    metricObject[resource] = apiData[resource] || 0; // Default to 0 if resource data is missing
  });

  return metricObject;
};