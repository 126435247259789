import { takeLatest, fork, put, all, call } from 'redux-saga/effects';
import { GET_ACCS_AND_TOKEN, GET_TAB_TOKEN, CLEAR_TAB_TOKEN } from './actionTypes';
import { getAcctsTableauFailed, getAcctsTableauSuccessful, getTabTokenSuccessful, getTabTokenFailed,  clearTabTokenSuccessful } from './actions';
// import { getAccountsAndTabToken } from '../../helpers/auth_helper';
import {
  getAccountsAndTabToken,
  getTabToken,
} from "../../helpers/auth_aws_helper";

function* handleGetAcctsAndTabToken() {
    console.log("skarp boutta call")
    const response = yield call(getAccountsAndTabToken);
	try {
            
			console.log("resp", response);
			const tokenString = Object.values(response)
        .filter((value) => typeof value === "string")
        .join("");
			yield put(getAcctsTableauSuccessful({tabToken: tokenString, status: response.status}));
	} catch (error) {
			yield put(getAcctsTableauFailed(error));
	}
}

export function* watchGetAccsAndTabToken() {
	yield takeLatest(GET_ACCS_AND_TOKEN, handleGetAcctsAndTabToken);
}

function* handleGetTabToken() {
  console.log("handleGetTabToken call");
  const response = yield call(getTabToken);
  try {
    console.log("resp", response);
    const tokenString = Object.values(response)
      .filter((value) => typeof value === "string")
      .join("");
    yield put(
      getTabTokenSuccessful({
        tabToken: tokenString,
        status: response.status,
      })
    );
  } catch (error) {
    yield put(getTabTokenFailed(error));
  }
}

function* handleClearTabToken() {
  console.log("handleClearTabToken call");
  yield put(clearTabTokenSuccessful());
}

export function* watchGetTabToken() {
	yield takeLatest(GET_TAB_TOKEN, handleGetTabToken);
}

export function* watchClearTabToken() {
  yield takeLatest(CLEAR_TAB_TOKEN, handleClearTabToken);
}

function* getTableauSaga() {
    yield all([
			fork(watchGetAccsAndTabToken), 
			fork(watchGetTabToken),
      fork(watchClearTabToken)
		]);
}

export default getTableauSaga;