import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

// Format the numbers to include currency and percentage symbols
const formatCurrency = (value) => `$${value.toLocaleString()}`;
const formatPercentage = (value) => `${value}%`;
const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

const BillingDataGrid = ({ data }) => {
  // Define columns with enhanced formatting
  const columns = [
    {
      field: "dateRange",
      headerName: "Billing Period",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.dateFrom} - ${params.row.dateTo}`, // Combine dateFrom and dateTo
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contractAmount",
      headerName: "Total Contract Amount",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatCurrency(params.row.contractAmount), // Format as currency
    },
    {
      field: "additionalAmount",
      headerName: "Compliance Charge",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatCurrency(params.row.additionalAmount), // Format as currency
    },
    {
      field: "thresholdAmount",
      headerName: "Monthly Contract Amount",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatCurrency(params.row.thresholdAmount), // Format as currency
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatDate(params.row.createdDate), // Remove time from the date
    },
    {
      field: "usagePercentage",
      headerName: "Overage Percentage",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatPercentage(params.row.usagePercentage), // Format as percentage
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 180,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => formatCurrency(params.row.totalAmount), // Format as currency
    },
  ];

  // MUI DataGrid expects rows to have an 'id' field
  const rows = data.map((row) => ({
    id: row.billingId, // MUI requires a unique 'id' field for each row
    ...row,
  }));

  return (
    <Box
      sx={{
        height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 250px)" }, // Dynamic height based on screen size
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick // Removes the checkbox selection feature
        hideFooter={true} // Remove footer for pagination
        autoHeight={false} // Keep the grid height fixed and scrollable
        rowHeight={60} // Set row height for better spacing
        pageSize={15} // Limit the number of rows shown
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f8f8f8", // Light grey for header background
            color: "#333", // Darker text for clarity
            fontSize: "14px",
            fontWeight: "bold",
            borderBottom: "2px solid #e0e0e0", // Subtle border for clarity
            textAlign: "center", // Center text in the header
          },
          "& .MuiDataGrid-cell": {
            backgroundColor: "#fff", // White background for rows
            borderBottom: "1px solid #e0e0e0", // Light grey borders between rows
            justifyContent: "center", // Center content horizontally
            textAlign: "center", // Center text in the cell
          },
          "& .MuiDataGrid-root": {
            border: "none", // Remove border around the grid
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f0f0f0", // Hover effect on rows
          },
        }}
      />
    </Box>
  );
};

export default BillingDataGrid;
