import {
  REGISTER_ORG,
  REGISTER_ORG_SUCCESSFUL,
  REGISTER_ORG_FAILED,
  RESEND_ACTIVATE_EMAIL,
  RESEND_ACTIVATE_EMAIL_SUCCESSFUL,
  RESEND_ACTIVATE_EMAIL_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESSFUL,
  VALIDATE_TOKEN_FAILED,
  PUT_MAILING_INFO,
  SET_MAILING_INFO,
  PUT_BILLING_INFO,
  SUT_BILLING_INFO,
  CLEAR_ALL_ORGANIZATION_MESSAGES,
  CLEAR_ALL_ORGANIZATION_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

export const registerOrg = (org) => {
  return {
    type: REGISTER_ORG,
    payload: { org },
  };
};

export const registerOrgSuccessful = (org) => {
  return {
    type: REGISTER_ORG_SUCCESSFUL,
    payload: org,
  };
};

export const registerOrgFailed = (error) => {
  return {
    type: REGISTER_ORG_FAILED,
    payload: { error },
  };
};

export const resendEmail = (account) => {
  console.log("actionem", account);
  return {
    type: RESEND_ACTIVATE_EMAIL,
    payload: { account },
  };
};

export const resendEmailSuccessful = (account) => {
  return {
    type: RESEND_ACTIVATE_EMAIL_SUCCESSFUL,
    payload: account,
  };
};

export const resendEmailFailed = (error) => {
  return {
    type: RESEND_ACTIVATE_EMAIL_FAILED,
    payload: error,
  };
};
//reset password action from component
export const resetPassword = (user) => {
  console.log("reset password action from component");
  return {
    type: RESET_PASSWORD,
    payload: { user },
  };
};
export const resetPasswordSuccessful = (resetPasswordResponse) => {
  return {
    type: RESET_PASSWORD_SUCCESSFUL,
    payload: { resetPasswordResponse },
  };
};

export const resetPasswordFailed = (error) => {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: { error },
  };
};
export const validateToken = (token) => {
  return {
    type: VALIDATE_TOKEN,
    payload: { token },
  };
};

export const validateTokenSuccessful = (formData) => {
  return {
    type: VALIDATE_TOKEN_SUCCESSFUL,
    payload: { formData },
  };
};

export const validateTokenFailed = (error) => {
  return {
    type: VALIDATE_TOKEN_FAILED,
    payload: error,
  };
};

export const putMailingInfo = (params) => {
  console.log("--== action putMailingInfo ", params);
  return {
    type: PUT_MAILING_INFO,
    payload: params,
  };
};

export const setMailingInfo = (params) => {
  return {
    type: SET_MAILING_INFO,
    payload: params,
  };
};

export const putBillingInfo = (params) => {
  return {
    type: PUT_BILLING_INFO,
    payload: params,
  };
};

export const setBillingInfo = (params) => {
  return {
    type: SUT_BILLING_INFO,
    payload: params,
  };
};

export const clearAllOrganizationMessages = () => {
  return {
    type: CLEAR_ALL_ORGANIZATION_MESSAGES,
    payload: {},
  };
};

export const clearAllOrganizationMessagesSuccessful = () => {
  return {
    type: CLEAR_ALL_ORGANIZATION_MESSAGES_SUCCESSFUL,
    payload: {},
  };
};