import {
  GET_LIST_OF_ACCTS,
  GET_LIST_OF_ACCTS_SUCCESSFUL,
  GET_LIST_OF_ACCTS_FAILED 
} from './actionTypes';

export const getListOfAccts = (acct) => {
  return {
      type: GET_LIST_OF_ACCTS,
      payload: { acct }
  }
}

export const getListOfAcctsSuccessful = (acct) => {
  return {
      type: GET_LIST_OF_ACCTS_SUCCESSFUL,
      payload: { acct }
  }
}

export const getListOfAcctsFailed = (acct) => {
  return {
      type: GET_LIST_OF_ACCTS_FAILED,
      payload: { acct }
  }
}
