import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Badge, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SeverityIcon from '@mui/icons-material/RemoveModerator';
import InfoIcon from '@mui/icons-material/GppMaybe';

import IACIssue from "./IACIssue";
import styles from "./style.module.css"; // Import your CSS Module
import { severityColor } from "./IACIssue";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog({item, issues, severityCounters, filePaths, jsObject}) {
  // console.log("severityCounters", severityCounters);
  // console.log("severityColor", severityColor);
  const filePathsString = filePaths.join(', ');
  const [open, setOpen] = React.useState(true);
  const [isCopied, setIsCopied] = React.useState(false);
  const [displayIssues, setDisplayIssues] = React.useState(issues);
  const [selectedSeverities, setSelectedSeverities] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSeverityClick = (severity) => {
    const newSelectedSeverities = [...selectedSeverities]; 
    if (newSelectedSeverities.includes(severity)) {
      const index = newSelectedSeverities.indexOf(severity);
      newSelectedSeverities.splice(index, 1);
    } else {
      newSelectedSeverities.push(severity);
    }
    setSelectedSeverities(newSelectedSeverities);

    const filteredIssues = issues.filter((issue) =>
      newSelectedSeverities.length === 0 || newSelectedSeverities.includes(issue.severity)
    );
    setDisplayIssues(filteredIssues);
  };

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(JSON.stringify(issues));
  // }

  const handleCopy = () => {
    setIsCopied(true);
    const jsonAsString = JSON.stringify(jsObject, null, 2);
    navigator.clipboard.writeText(jsonAsString);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{width: "50%", position: "absolute", left: "50%", height: "100vh"}}
      >
        <AppBar sx={{ position: 'relative', background: "#f98125" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            Vulnerabilities {filePathsString ? `in ${filePathsString}`: ""}
            {/* <Button autoFocus color="inherit" onClick={handleCopy}>
              copy
            </Button> */}
            <Tooltip title="Click to copy all vulnerabilities" arrow>
              <div className={styles.copyIcon} onClick={() => handleCopy()}>
                {isCopied ? (
                  <span>Copied</span>
                ) : (
                  <ContentCopyIcon />
                )}
              </div>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={styles.severityCounters}>          
            {(severityCounters?.CRITICAL!==undefined || 
              severityCounters?.CRITICAL!==null || 
              severityCounters?.CRITICAL!==0
            ) && (
              <div className={styles.severityCounter}>
                <IconButton onClick={() => handleSeverityClick('CRITICAL')}>
                  <Badge 
                    badgeContent={severityCounters.CRITICAL} 
                    sx={{color:severityColor.CRITICAL}}
                  >
                    <SeverityIcon fontSize="large" sx={{color: severityColor.CRITICAL}}/>
                  </Badge>
                </IconButton>
                <div className={`${styles.severityLabel} ${(selectedSeverities.length === 0 || selectedSeverities.includes("CRITICAL") ) ? styles.selectedSeverityLabel : '' }`}>Critical</div>
              </div>              
            )}
            {(severityCounters?.HIGH!==undefined || 
              severityCounters?.HIGH!==null ||
              severityCounters?.HIGH!==0
            ) && (
              <div className={styles.severityCounter}>
                <IconButton onClick={() => handleSeverityClick('HIGH')}>
                  <Badge 
                    badgeContent={severityCounters?.HIGH} 
                    sx={{color:severityColor.HIGH}}
                  >
                    <SeverityIcon fontSize="large" sx={{color: severityColor.HIGH}}/>
                  </Badge>
                </IconButton>                
                <div className={`${styles.severityLabel} ${(selectedSeverities.length === 0 || selectedSeverities.includes("HIGH")) ? styles.selectedSeverityLabel : '' }`}>High</div>
              </div>              
            )}
            {(severityCounters?.MEDIUM!==undefined || 
              severityCounters?.MEDIUM!==null ||
              severityCounters?.MEDIUM!==0
            ) && (
              <div className={styles.severityCounter}>
                <IconButton onClick={() => handleSeverityClick('MEDIUM')}>
                  <Badge 
                    badgeContent={severityCounters?.MEDIUM} 
                    sx={{color:severityColor.MEDIUM}}
                  >
                    <SeverityIcon fontSize="large" sx={{color: severityColor.MEDIUM}}/>
                  </Badge>
                </IconButton>
                <div className={`${styles.severityLabel} ${(selectedSeverities.length === 0 || selectedSeverities.includes("MEDIUM") ) ? styles.selectedSeverityLabel : '' }`}>Medium</div>
              </div>              
            )}
            {(severityCounters?.LOW!==undefined || 
              severityCounters?.LOW!==null ||
              severityCounters?.LOW!==0
            ) && (
              <div className={styles.severityCounter}>
                <IconButton onClick={() => handleSeverityClick('LOW')}>
                  <Badge 
                    badgeContent={severityCounters?.LOW} 
                    sx={{color:severityColor.LOW}}
                  >
                    <SeverityIcon fontSize="large" sx={{color: severityColor.LOW}}/>
                  </Badge>
                </IconButton>
                <div className={`${styles.severityLabel} ${(selectedSeverities.length === 0 || selectedSeverities.includes("LOW") ) ? styles.selectedSeverityLabel : '' }`}>Low</div>
              </div>              
            )}
            {(severityCounters?.INFO!==undefined || 
              severityCounters?.INFO!==null ||
              severityCounters?.INFO!==0
            ) && (
              <div className={styles.severityCounter}>
                <IconButton onClick={() => handleSeverityClick('INFO')}>
                  <Badge 
                    badgeContent={severityCounters?.INFO} 
                    sx={{color:severityColor.INFO}}
                    onClick={() => handleSeverityClick('INFO')}
                  >
                    <InfoIcon fontSize="large" sx={{color: severityColor.INFO}}/>
                  </Badge>
                </IconButton>
                <div className={`${styles.severityLabel} ${(selectedSeverities.length === 0 || selectedSeverities.includes("INFO") ) ? styles.selectedSeverityLabel : '' }`}>Info</div>
              </div>              
            )}
            
          
        
        </div>
        <ExpandedComponent item={item} issues={displayIssues} />
      </Dialog>
    </React.Fragment>
  );
}

function ExpandedComponent({ item, issues }) {
  return (
    <div className={styles.expandedComponent}>
      {issues &&
        issues.map((issue) => (
          <div className={styles.IACIssue}>
            <IACIssue issue={issue} />
          </div>
      ))}
    </div>
  );
}