import { takeLatest, fork, put, all, call, takeEvery, delay } from 'redux-saga/effects';

import {  GET_PRICING_INFO } from "./actionTypes";

import { getPricingInfoSuccessful, getPricingInfoFailed } from "./actions";

import { getPricingInfo } from '../../helpers/auth_aws_helper'

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

//Updated to include accountNumber
function* getPricingInformation({ payload: { accountNumber, subscription, orgCode } }) {
  let retries = 0;
  while(retries < MAX_RETRIES) {
    try {
      console.log("About to call AWS API, getPricingInfo, Retry Number: " + (retries+1));
      //Updated to include accountNumber
      const response = yield call(getPricingInfo, accountNumber, subscription, orgCode);
      if(response.status === 200) {
        yield put(getPricingInfoSuccessful(response));
        return; // Exit the saga if API call is successful, no further retry needed
      } else {
        let message;
        if (response.message) {
          message = response.message;
        } else {
          message = response;
        }
        yield put(getPricingInfoFailed(message));
      }      
    } catch (error) {
      retries++;
      yield put(getPricingInfoFailed(error));
      yield delay(RETRY_DELAY);
    }
  }
  // Handle case when all retries fail
  yield put(getPricingInfoFailed('Maximum number of retries reached'));
}

export function* watchPricingInformation(){
  yield takeLatest(GET_PRICING_INFO, getPricingInformation);
}

function* getPricingInformationSaga() {
  yield all([fork(watchPricingInformation)]);
}

export default getPricingInformationSaga;