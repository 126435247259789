import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_ORGS_NAME_DATA } from './actionTypes';
import { getOrgsNameDataSuccessful, getOrgsNameDataFailed  } from './actions';
import { getOrgsName } from "../../helpers/auth_aws_helper";

function* getOrgsNameData({ payload: { data } }) {
	try {
		const response = yield call(getOrgsName, data);
		if (response && response.status === 200) {
			yield put(getOrgsNameDataSuccessful(response));
		} else {
			yield put(getOrgsNameDataFailed(response));
		}
	} catch (error) {
		yield put(getOrgsNameDataFailed(error));
	}
}

export function* watchHeader() {
	yield takeEvery(GET_ORGS_NAME_DATA, getOrgsNameData)
}

function* getOrgsNameSaga() {
  yield all([fork(watchHeader)]);
}

export default getOrgsNameSaga;