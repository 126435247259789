import {
    fork,
    put,
    all,
    call,
    takeLatest,
    delay,
  } from "redux-saga/effects";
  
  import {
    postAccountConfigurationSuccessful,
    postAccountConfigurationFailure,
    clearAllAccountConfigurationMessagesSuccessful,
  } from "./accountConfigurationSlice";
  
  import {
    postAccountConfiguration as postAccountConfigurationApi,
  } from "../../helpers/auth_aws_helper";
  
  function* postAccountConfiguration( {payload: accountConfigurationPayload} ) {
    try {
      console.log("about to call *postAccountConfiguration() with ", accountConfigurationPayload);
      const response = yield call(postAccountConfigurationApi, accountConfigurationPayload);
      console.log("*postAccountConfigurationApi() response: ", response);
  
      if (response && response.status === 200) {
        yield put(postAccountConfigurationSuccessful(response.data));
      } else {
        yield put(postAccountConfigurationFailure({ message: response.data }));
      }
    } catch (error) {
      if (error.response) {
        yield put(
          postAccountConfigurationFailure({
            message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
          })
        );
      } else if (error.request) {
        yield put(postAccountConfigurationFailure({ message: "No response received" }));
      } else {
        yield put(postAccountConfigurationFailure({ message: error?.message }));
      }
    }
  }
  
  function* clearAllAccountConfigurationMessages() {
    console.log("Saga: clear All AccountConfiguration Messages");
    yield put(clearAllAccountConfigurationMessagesSuccessful());
  }
  
  export function* watchAccountConfiguration() {
    yield takeLatest("accountConfiguration/postAccountConfiguration", postAccountConfiguration);
    yield takeLatest("accountConfiguration/clearAllAccountConfigurationMessages", clearAllAccountConfigurationMessages);
  }
  
  export function* watchAccountConfigurationSaga() {
    yield all([fork(watchAccountConfiguration)]);
  }
  
  export default watchAccountConfigurationSaga;