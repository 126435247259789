import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import VPC from "./VPC";
import ElasticIP from "./ElasticIP";
import SecurityGroups from "./SecurityGroups";
import ElasticNetworkInterface from "./ElasticNetworkInterface";

const VPCDashboard = () => {
  const [activeTab, setActiveTab] = useState("vpc");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "vpc":
        return <VPC />;
      case "elasticIP":
        return <ElasticIP />;
      case "securityGroups":
        return <SecurityGroups />;
      case "elsaticNetworkInterface":
        return <ElasticNetworkInterface />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="VPC" value="vpc" />
        <Tab label="Elastic IP" value="elasticIP" />
        <Tab label="Security Groups" value="securityGroups" />
        <Tab
          label="Elastic Network Interface"
          value="elsaticNetworkInterface"
        />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default VPCDashboard;
