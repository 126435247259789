import React from "react";
import { useEffect, useState } from "react";
import styles from "./../style.module.css";
import { connect } from "react-redux";
import { getReleaseNotesRequest } from '../../../store/releaseNotes/actions';

import loadingGif from "./../../../assets/images/aws/loadingGif.gif";
import UrlsTable from "../common/UrlsTable";

const ReleaseNotesPage = (props) => {
  let releaseNotes = null;
  if(props.releaseNotes){
    releaseNotes = props.releaseNotes.map( 
      (releaseNote, index) => ({id: index+1, fileName: releaseNote})
    );
  }

  console.log("props: " , props);


  useEffect(() => {
    props.getReleaseNotes("release-notes");
  }, [])
  

  return (
    <div className="page-content">
      <div className={styles.header}>
        CloudCatcher Release History
      </div>    
      { (props.isReleaseNotesGetting ) && (
        <div style={{display: "flex", justifyContent: "center", height: "100vh", alignItems: "center"}}>
          <img src={loadingGif} height={40} alt="Loading Spinner" />
        </div>
      )}
      {releaseNotes && releaseNotes.length > 0 && (
        <UrlsTable releaseNotes={releaseNotes} />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { 
    isReleaseNotesGetting,
    getReleaseNotesMessage,
    getReleaseNotesError,
    releaseNotes,
  } = state.releaseNotes;

  return {
    isReleaseNotesGetting,
    getReleaseNotesMessage,
    getReleaseNotesError,
    releaseNotes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReleaseNotes: (key) => {
      dispatch(getReleaseNotesRequest(key));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesPage);