import React, { useState } from 'react';
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

import styles from "./style.module.css";
import { Chip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileIssueResults from './FileIssueResults';
// import SeverityIcon from '@mui/icons-material/GppMaybe';
import SeverityIcon from '@mui/icons-material/RemoveModerator';
// import InfoIcon from '@mui/icons-material/Policy';
import InfoIcon from '@mui/icons-material/GppMaybe';

export const severityColor = {
  CRITICAL: "darkred",
  HIGH: "red",
  MEDIUM: "orange",
  LOW: "#FFD7B5",
  INFO: "#007BFF"
}

const IACIssue = ({issue}) => {
  const [isCopied, setIsCopied] = useState(false);


  const handleCopy = (currentIssue) => {
    setIsCopied(true);

    const issueType = currentIssue?.issueType === "MissingAttribute" ? "Missing Attribute": "Incorrect Value"
    let copyText = `${currentIssue.queryName} ${issueType}
Platform ${currentIssue?.platform}
Category ${currentIssue?.category}
Description
${currentIssue?.descriptionText}
Supplied Value: ${currentIssue?.keyActualValue}
Expected Value: ${currentIssue?.keyExpectedValue}
${currentIssue?.severity} ${currentIssue?.keyExpectedValue}`;

    navigator.clipboard.writeText(copyText);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }

  return (
    <div className={styles.issueMain}>
      {/* <div className={styles.issueHeader}>
        <div>{issue.queryName}</div>
        <Chip
          label={
            issue?.issueType === "MissingAttribute"
              ? "Missing Attribute"
              : "Incorrect Value"
          }
          variant="outlined"
          color="error"
        />
        <div className={styles.copyIcon} onClick={() => handleCopy(issue)}>
          {isCopied ? (
            <span>Copied</span>
          ) : (
            <ContentCopyIcon />
          )}
        </div>
      </div> */}
      <div className={styles.severityLogo}>
        {issue?.severity === "INFO" ? 
            (<InfoIcon fontSize="medium" sx={{color: severityColor[issue?.severity]}}/>) :
            (<SeverityIcon fontSize="medium" sx={{color: severityColor[issue?.severity]}}/>)
        }
        
      </div>
      <div className={styles.issueContainer}>
      <div className={styles.header}>{issue?.query_name}</div>
        <table className={styles.tableStyle}>
          <tbody>
            <tr>
              <td className={styles.cellHeaderStyle}>Platform:</td>
              <td className={styles.cellStyle}>{issue?.platform}</td>
            </tr>
            <tr>
              <td className={styles.cellHeaderStyle}>Category:</td>
              <td className={styles.cellStyle}>{issue?.category}</td>
            </tr>
          </tbody>
        </table>

        <div className={styles.text}>
          {issue?.description}
        </div>
        <div>
          <a href={issue?.query_url} target="_blank">
            View Details
          </a>
        </div>


        <FileIssueResults files={issue?.files} />
      </div>
      {/* <div className={styles.severityContainer}>
        <div className={styles.severityLevel}>{issue.severity}</div>
        <div className={styles.severityDescription}>
          {issue.keyExpectedValue}
        </div>
      </div> */}
    </div>
  );
}

export default IACIssue