import {
  GET_ASSET_INSIGHT_METRIC,
  GET_ASSET_INSIGHT_METRIC_SUCCESSFUL,
  GET_ASSET_INSIGHT_METRIC_FAILED,
  GET_COMPLIANCE_METRIC,
  GET_COMPLIANCE_METRIC_SUCCESSFUL,
  GET_COMPLIANCE_METRIC_FAILED,
  GET_COST_INSIGHT_METRIC,
  GET_COST_INSIGHT_METRIC_SUCCESSFUL,
  GET_COST_INSIGHT_METRIC_FAILED,
  GET_SECURITY_POSTURE_METRIC,
  GET_SECURITY_POSTURE_METRIC_SUCCESSFUL,
  GET_SECURITY_POSTURE_METRIC_FAILED,
} from "./actionTypes";

export const getAssetInsightMetric = (accCodes, orgCode) => {
  return {
    type: GET_ASSET_INSIGHT_METRIC,
    payload: { accCodes, orgCode },
  };
};

export const getAssetInsightMetricSuccessful = (assetInsightMetric) => {
  return {
    type: GET_ASSET_INSIGHT_METRIC_SUCCESSFUL,
    payload: { assetInsightMetric },
  };
};

export const getAssetInsightMetricFailed = (error) => {
  return {
    type: GET_ASSET_INSIGHT_METRIC_FAILED,
    payload: { error },
  };
};

export const getComplianceMetric = (accCodes, orgCode) => {
  return {
    type: GET_COMPLIANCE_METRIC,
    payload: { accCodes, orgCode },
  };
};

export const getComplianceMetricSuccessful = (complianceMetric) => {
  return {
    type: GET_COMPLIANCE_METRIC_SUCCESSFUL,
    payload: { complianceMetric },
  };
};

export const getComplianceMetricFailed = (error) => {
  return {
    type: GET_COMPLIANCE_METRIC_FAILED,
    payload: { error },
  };
};

export const getCostInsightMetric = (accCodes, orgCode) => {
  return {
    type: GET_COST_INSIGHT_METRIC,
    payload: { accCodes, orgCode },
  };
};

export const getCostInsightMetricSuccessful = (costInsightMetric) => {
  return {
    type: GET_COST_INSIGHT_METRIC_SUCCESSFUL,
    payload: { costInsightMetric },
  };
};

export const getCostInsightMetricFailed = (error) => {
  return {
    type: GET_COST_INSIGHT_METRIC_FAILED,
    payload: { error },
  };
};

export const getSecurityPostureMetric = (accCodes, orgCode) => {
  return {
    type: GET_SECURITY_POSTURE_METRIC,
    payload: { accCodes, orgCode },
  };
};

export const getSecurityPostureMetricSuccessful = (securityPostureMetric) => {
  return {
    type: GET_SECURITY_POSTURE_METRIC_SUCCESSFUL,
    payload: { securityPostureMetric },
  };
};

export const getSecurityPostureMetricFailed = (error) => {
  return {
    type: GET_SECURITY_POSTURE_METRIC_FAILED,
    payload: { error },
  };
};

