import React, { useMemo } from "react";
import { Box, Select, MenuItem, Typography } from "@mui/material";

const BillingDateFilter = ({ selectedMonth, handleMonthChange }) => {
  const currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth(); // Corrected to account for 0-indexed months

  // Generate months in descending order with "All" on top
  const months = useMemo(() => {
    const monthsArray = ["All"]; // Start with "All" option
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let year = currentYear; year >= currentYear - 2; year--) {
      for (let month = currentMonth; month >= 0; month--) {
        monthsArray.push(`${monthNames[month]} ${year}`);
      }
      currentMonth = 11; // Reset to December for the previous years
    }

    return monthsArray;
  }, [currentYear, currentMonth]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ff9900", // AWS orange background
        color: "#000", // Black text color
        fontWeight: "bold",
        padding: "4px 8px", // Reduced padding for smaller height
        borderRadius: "4px",
        height: "32px", // Make the component smaller
      }}
    >
      <Typography
        sx={{
          marginRight: 1,
          fontSize: "14px",
          fontWeight: "bold",
          color: "#000",
        }}
      >
        Billing Period:
      </Typography>
      <Select
        value={selectedMonth}
        onChange={(e) => handleMonthChange(e.target.value)}
        sx={{
          backgroundColor: "#ff9900", // AWS orange
          color: "#000", // Black text color
          fontWeight: "bold",
          border: "none",
          fontSize: "14px", // Smaller font size
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "& .MuiSelect-icon": {
            color: "#000", // Black color for the icon
          },
          height: "32px", // Reduced height for the dropdown
          minWidth: "150px", // Adjust the width as needed
          paddingRight: "16px",
        }}
        size="small"
      >
        {months.map((month, index) => (
          <MenuItem key={index} value={month}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default BillingDateFilter;
