import {
  REGISTER_ORG_ACCOUNT,
  UPDATE_ORG_ACCOUNT,
  UPDATE_ORG_ACCOUNT_SUCCESSFUL,
  REGISTER_ORG_ACCOUNT_SUCCESSFUL,
  REGISTER_ORG_ACCOUNT_FAILED,
  UPDATE_ORG_ACCOUNT_FAILED,
  GET_LIST_OF_ORGS_AND_ACCOUNTS,
  GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL,
  GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED,
  RESET_ORG_ACCOUNT_STATUS,
  CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL
} from "./actionTypes";

const initialState = {
  reg_acct_error: null, accountsError: null, message: null, loading: null, error:false
}

const orgAccount = (state = initialState, action) => {
	switch (action.type) {
    case REGISTER_ORG_ACCOUNT:
      state = {
        ...state,
        account: null,
        loading: true,
        reg_acct_error: null,
        error: false,
        message: null,
      };
      break;
    case REGISTER_ORG_ACCOUNT_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload?.registerOrgAccountResponse?.message,
        loading: false,
        reg_acct_error: null,
      };
      break;
    case REGISTER_ORG_ACCOUNT_FAILED:
      // console.log("payload is", action.payload)
      state = {
        ...state,
        loading: false,
        reg_acct_error: action.payload ? action.payload.registerOrgAccountError.message : null,
        error: true,
      };
      break;
    case UPDATE_ORG_ACCOUNT:
      state = {
        ...state,
        // accounts: action.payload.account,
        // account: null,
        loading: true,
        message: null,
        reg_acct_error: null,
      };
      break;
    case UPDATE_ORG_ACCOUNT_SUCCESSFUL:
      state = {
        ...state,
        // ...action.payload.accounts,
        // account: action.payload,
        // accounts: action.payload.account,
        loading: false,
        message: action.payload?.updateOrgAccountResponse?.message,
        reg_acct_error: null,
      };
      break;
      case UPDATE_ORG_ACCOUNT_FAILED:
        // console.log("payload is", action.payload)
        state = {
          ...state,
          loading: false,
          reg_acct_error: action.payload ? action.payload.updateOrgAccountError.message : null,
          // error: true,
        };
        break;      
    case RESET_ORG_ACCOUNT_STATUS:
      state = {
        ...state,
        account: action.payload,
        loading: false,
        reg_acct_error: null,
        message: null,
      };
      break;
    case GET_LIST_OF_ORGS_AND_ACCOUNTS:
      state = {
        ...state,
        accounts: null,
        message: null,
        loading: true,
        accountsError: null,
      };
      break;
    case GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL:
      state = {
        ...state,
        ...action.payload.accounts,
        loading: false,
        accountsError: null,
      };
      break;
    case GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED:
      state = {
        ...state,
        loading: false,
        accountsError: action.payload.getListOfOrgsAndAcctsError.message,
      };
      break;
    case CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        reg_acct_error: null, 
        accountsError: null, 
        message: null, 
        loading: false, 
        error:false
      };
      break;
    default:
      return state;
  }
	return state;
}

export default orgAccount;