import React from "react";
import Box from "@mui/material/Box";
import { Alert, Container } from "@mui/material";

const InaccessibleResources = ({ message }) => {
  console.log("InaccessibleResources called");

  // Define a default message
  const defaultMessage =
    "Looks like you’re on a free trial of CloudCatcher! Please subscribe to access this feature!";

  // Use the provided message or fall back to the default message if it's not provided or empty
  const displayMessage = message || defaultMessage;

  return (
    <Container
      style={{
        display: "flex",
        color: "red",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ maxWidth: 500 }}>
        <Alert variant="outlined" severity="info">
          {displayMessage}
        </Alert>
      </Box>
    </Container>
  );
};

export default InaccessibleResources;
