import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import EmailModal from './EmailModal';

const EmailPage = () => {
  const history = useHistory();
  const location = useLocation();

  const [emailSupportModal, setEmailSupportModal] = useState(false);

  useEffect(() => {
    if (location.pathname === '/supportrequest') {
      setEmailSupportModal(true);
    } else {
      setEmailSupportModal(false);
    }
  }, [location.pathname]);

  const openEmailSupportModal = () => {
    setEmailSupportModal(true);
  }

  const closeEmailSupportModal = () => {
    setEmailSupportModal(false);
    history.goBack(); 
  }

  return (
    <EmailModal open={emailSupportModal} onClose={closeEmailSupportModal} />
  );
}

export default EmailPage;