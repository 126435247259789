import {
  GET_IAAC_LICENSE_REQUEST,
  GET_IAAC_LICENSE_SUCCESSFUL,
  GET_IAAC_LICENSE_FAILED,
  CLEAR_ALL_IAAC_LICENSE_MESSAGES_SUCCESSFUL
} from "./actionTypes";

const initialState = {
  isIaacLicenseGetting: false,
  getIaacLicenseMessage: null,
  getIaacLicenseError: null,
  license: null,
};

const iaacLicenseReducer = (state=initialState, action) => {
  switch(action.type){
    case GET_IAAC_LICENSE_REQUEST:
      state = {
        ...state,
        isIaacLicenseGetting: true,
        getIaacLicenseMessage: null,
        getIaacLicenseError: null,
        license: null,
      };
      break;
    case GET_IAAC_LICENSE_SUCCESSFUL:
      state = {
        ...state,
        isIaacLicenseGetting: false,
        getIaacLicenseMessage: "IaaC License received successfully",
        license: action.payload.license,
      };
      break;
    case GET_IAAC_LICENSE_FAILED:
      state = {
        ...state,
        isIaacLicenseGetting: false,
        getIaacLicenseError: action.payload.getIaacLicenseError
      };
      break;
      case CLEAR_ALL_IAAC_LICENSE_MESSAGES_SUCCESSFUL:
        state = {
          ...state,
          getIaacLicenseMessage: null,
          getIaacLicenseError: null,
        };
        break;      
    default:
      return state;
  }
  return state;
}

export default iaacLicenseReducer;