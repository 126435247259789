import React from 'react';

const Iframe = ({ source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;
    return (
        // basic bootstrap classes. you can change with yours.
        <div className="iframe-container">
            <iframe src={src} className="iframe-second-row"></iframe>
        </div>
    );
};

export default Iframe;