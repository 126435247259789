import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { 
  GET_IAAC_SUBSCRIPTION_REQUEST ,
  POST_IAAC_SUBSCRIPTION_REQUEST,
  PUT_IAAC_SUBSCRIPTION_REQUEST,
  CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES,
} from "./actionTypes";

import { 
  getIaacSubscriptionSuccessful, 
  getIaacSubscriptionFailed ,
  postIaacSubscriptionSuccessful,
  postIaacSubscriptionFailed,
  putIaacSubscriptionSuccessful,
  putIaacSubscriptionFailed,
  clearAllIaacSubscriptionMessagesSuccessful,
} from "./actions"

import { 
  getIaacSubscription as getIaacSubscriptionApi,
  postIaacSubscription as postIaacSubscriptionApi,
  putIaacSubscription as putIaacSubscriptionApi
} from "../../helpers/auth_aws_helper";

function* getIaacSubscription({ payload: { orgcode }}) {
  try{
    const response =  yield call(getIaacSubscriptionApi, orgcode);
    console.log("~~~ getIaacSubscription Response: ", response);
    if(response && response.status === 200) {
      delete response.status;
      yield put(getIaacSubscriptionSuccessful(response));
    } else {
      let message;
      if(response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getIaacSubscriptionFailed({ message: message }));
    }
  } catch(error){
    console.log("~~~ getIaacSubscription, received Error is :: ", JSON.stringify(error));
    if(error.response){
      yield put(getIaacSubscriptionFailed(
        {
          message: "Error Code: "+error.response?.status+", Message: "+ error.response?.data,
        }));
    } else if (error.request) {
      yield put(getIaacSubscriptionFailed({ message: "No response is received" }));
    } else {
      yield put(getIaacSubscriptionFailed({ message: error?.message }));
    }
  }
}

function* postIaacSubscription({ payload: { orgcode, subscriptionRequest } }) {
  try {
    const response = yield call(postIaacSubscriptionApi, orgcode, subscriptionRequest);
    console.log("~~~ postIaacSubscription Response: ", response);
    if(response && response.status === 200) {
      delete response.status;
      // yield put(postIaacSubscriptionSuccessful(response, "License subscription created successfully"));
      yield put(postIaacSubscriptionSuccessful(subscriptionRequest, response?.data));
    } else {
      let message;
      if(response.data.message) {
        message = response.data.message;
      } else {
        message = response.data;
      }
      yield put(postIaacSubscriptionFailed({ message: message }));
    }
  } catch(error) {
    console.log("~~~ postIaacSubscription, received Error is :: ", JSON.stringify(error));
    if(error.response) {
      yield put(postIaacSubscriptionFailed(
        {
          message: "Error Code: "+error.response?.status+", Message: "+ error.response?.data,
        }));
    } else if (error.request) {
      yield put(postIaacSubscriptionFailed({ message: "No response is received" }));
    } else {
      yield put(postIaacSubscriptionFailed({ message: error?.message }));
    }
  }
}

function* putIaacSubscription({ payload: { orgcode, subscription } }) {
  try {
    const response = yield call(putIaacSubscriptionApi, orgcode, subscription);
    console.log("~~~ putIaacSubscription Response: ", response);
    if(response && response.status === 200) {
      delete response.status;
      // yield put(putIaacSubscriptionSuccessful(response, "License subscription updated successfully"));
      // yield put(putIaacSubscriptionSuccessful(subscription, response));
      yield put(putIaacSubscriptionSuccessful(subscription, Object.values(response).join('')));
    } else {
      let message;
      if(response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(putIaacSubscriptionFailed({ message: message }));
    }
  } catch(error) {
    console.log("~~~ putIaacSubscription, received Error is :: ", JSON.stringify(error));
    if(error.response) {
      yield put(putIaacSubscriptionFailed(
        {
          message: "Error Code: "+error.response?.status+", Message: "+ error.response?.data,
        }));
    } else if (error.request) {
      yield put(putIaacSubscriptionFailed({ message: "No response is received" }));
    } else {
      yield put(putIaacSubscriptionFailed({ message: error?.message }));
    }
  }
}

function* clearAllIaacSubscriptionMessages() {
  console.log("Saga: clear All Iaac Subscription Messages");
  yield put(clearAllIaacSubscriptionMessagesSuccessful());
}

function* watchGetIaacSubscriptionRequest() {
  yield takeEvery(GET_IAAC_SUBSCRIPTION_REQUEST, getIaacSubscription);
}

function* watchPostIaacSubscriptionRequest() {
  yield takeEvery(POST_IAAC_SUBSCRIPTION_REQUEST, postIaacSubscription);
}

function* watchPutIaacSubscriptionRequest() {
  yield takeEvery(PUT_IAAC_SUBSCRIPTION_REQUEST, putIaacSubscription);
}

function* watchClearAllIaacSubscriptionMessages() {
  yield takeEvery(CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES, clearAllIaacSubscriptionMessages);
}

function* iaacSubscriptionSaga() {
  yield all([fork(watchGetIaacSubscriptionRequest)]);
  yield all([fork(watchPostIaacSubscriptionRequest)]);
  yield all([fork(watchPutIaacSubscriptionRequest)]);
  yield all([fork(watchClearAllIaacSubscriptionMessages)]);
}

export default iaacSubscriptionSaga;