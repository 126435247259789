import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Iframe from "./Common/iframe";
import {getRuleRemediation} from "./../store/actions";
import { connect } from "react-redux";

const RuleRemediation = (props) => {
  let { resourceName } = useParams();
  console.log("Resource Name ::", resourceName)
  const [resourceData, setResourceData] = useState("");

  useEffect(() => {
    if (resourceName) {
      props.getRuleRemediation(resourceName);
    }
  }, [resourceName]);

  const url = props?.ruleRemediation?.ruleRemediation?.url;
  useEffect(() => {
    if (url) {
      setResourceData(url);
    }
  }, [url]);

  console.log("New data :: ", resourceData);

  return (
    <div className="page-content">
      <Iframe source={resourceData}></Iframe>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    getRuleRemediationMessage,
    getRuleRemediationError,
    getRuleRemediationLoading,
    ruleRemediation,
  } = state.ruleRemediation;

  return {
    getRuleRemediationMessage,
    getRuleRemediationError,
    getRuleRemediationLoading,
    ruleRemediation,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRuleRemediation: (resourceName) => {
      dispatch(getRuleRemediation(resourceName));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RuleRemediation);