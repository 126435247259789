export const GET_LICENSING_DATA = 'get_licensing_data';
export const GET__LICENSING_DATA_SUCCESSFUL = 'get_licensing_data_successful';
export const GET__LICENSING_DATA_FAILED = 'get_licensing_data_failed';

export const POST_LICENSING_DATA = 'post_licensing_data';
export const POST__LICENSING_DATA_SUCCESSFUL = 'post_licensing_data_successful';
export const HANDLE_API_FAILURES = 'handle_api_failures';

export const GET_SCAN_FREQ_METADATA = 'get_scan_freq_metadata';
export const GET_SCAN_FREQ_METADATA_SUCCESSFUL = 'get_scan_freq_metadata_successful';
