import {
  GET_PRICING_INFO,
  GET_PRICING_INFO_SUCCESSFUL,
  GET_PRICING_INFO_FAILED,
} from "./actionTypes";

export const getPricingInfo = (accountNumber, subscription, orgCode) => {
  return {
    type: GET_PRICING_INFO,
    payload: { accountNumber, subscription, orgCode },
  };
}

export const getPricingInfoSuccessful = (pricingInfo) => {
  return { 
    type: GET_PRICING_INFO_SUCCESSFUL,
    payload: { pricingInfo }
  }
}

export const getPricingInfoFailed = (error) => {
  return {
    type: GET_PRICING_INFO_FAILED,
    payload: { error },
  };
};