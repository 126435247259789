import React from "react";
import { Box, Typography } from "@mui/material";

import SNSIcon from "./../../../assets/images/aws/SNS.png";
import RDSIcon from "./../../../assets/images/aws/RDS.png";
import DynamoDBIcon from "./../../../assets/images/aws/DynamoDB.png";
import LambdaIcon from "./../../../assets/images/aws/lambda.png";
import ACMIcon from "./../../../assets/images/aws/ACM.png";
import GuardDutyIcon from "./../../../assets/images/aws/GuardDuty.png";
import IAMIcon from "./../../../assets/images/aws/IAM.png";
import ELBIcon from "./../../../assets/images/aws/ELB.png";
import ECSIcon from "./../../../assets/images/aws/ECS.png";
import EC2Icon from "./../../../assets/images/aws/EC2.png";
import CloudTrailIcon from "./../../../assets/images/aws/CloudTrail.png";
import CloudWatchIcon from "./../../../assets/images/aws/CloudWatch.png";
import APIGatewayIcon from "./../../../assets/images/aws/APIGateway.png";
import CloudFrontIcon from "./../../../assets/images/aws/CloudFront.png";
import VPCIcon from "./../../../assets/images/aws/VPC.png";
import S3Icon from "./../../../assets/images/aws/S3.png";
import Route53Icon from "./../../../assets/images/aws/Route53.png";
import KMSIcon from "./../../../assets/images/aws/KMS.png";
import SQSIcon from "./../../../assets/images/aws/SQS.png";
import ECRIcon from "./../../../assets/images/aws/ECR.png";
import SecretManagerIcon from "./../../../assets/images/aws/SecretManager.png";
import EventBridgeIcon from "./../../../assets/images/aws/EventBridge.png";
import SystemsManagerIcon from "./../../../assets/images/aws/SystemsManager.png";
import CloudFormationIcon from "./../../../assets/images/aws/aws-cloudformation.png";

// Mapping resources to icons
export const resourceIconMap = {
  "Secrets Manager": SecretManagerIcon,
  "Systems Manager": SystemsManagerIcon,
  SQS: SQSIcon,
  EventBridge: EventBridgeIcon,
  GuardDuty: GuardDutyIcon,
  SNS: SNSIcon,
  Lambda: LambdaIcon,
  "API Gateway": APIGatewayIcon,
  CloudFormation: CloudFormationIcon,
  CloudTrail: CloudTrailIcon,
  CloudWatch: CloudWatchIcon,
  CloudFront: CloudFrontIcon,
  IAM: IAMIcon,
  DynamoDB: DynamoDBIcon,
  Route53: Route53Icon,
  ACM: ACMIcon,
  ECR: ECRIcon,
  RDS: RDSIcon,
  ECS: ECSIcon,
  S3: S3Icon,
  ELB: ELBIcon,
  Network: VPCIcon,
  EC2: EC2Icon,
  KMS: KMSIcon,
};

const AssetManagementInfoCard = ({ metricObject }) => {
  // Convert metricObject to an array of objects for rendering and sort by asset name
  const assets = Object.keys(metricObject)
    .map((resourceName) => ({
      icon: (
        <img
          src={resourceIconMap[resourceName]}
          width="24px"
          alt={resourceName}
        />
      ),
      asset: resourceName,
      value: metricObject[resourceName],
    }))
    .sort((a, b) => a.asset.localeCompare(b.asset)); // Sort assets alphabetically

  return (
    <Box sx={{ padding: "16px", backgroundColor: "background.paper", borderRadius: "8px", boxShadow: 3 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr", // Single column layout for each asset row
          gap: "8px",
        }}
      >
        {assets.map((row, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: "grey.100",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box sx={{ marginRight: "8px" }}>{row.icon}</Box>
              <Typography variant="body2">
                {row.asset}
              </Typography>
            </Box>
            <Typography variant="body2" align="right">
              {row.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AssetManagementInfoCard;
