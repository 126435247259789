import React from "react";
import { Box, Typography } from "@mui/material";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";

const SecurityScoreChart = ({ score }) => {
  const data = [
    {
      name: "Security Score",
      uv: score,
      fill: "#0244a6", // Fixed color
    },
  ];

  return (
    <Box
      sx={{
        position: "relative", // Set position relative for chart container
        // width: "240px", // Match chart width
        // height: "178px", // Match chart height
        mt: "1rem"
      }}
    >
      <RadialBarChart
        width={220} // Adjust chart width
        height={178} // Adjust chart height
        cx="50%"
        cy="50%"
        innerRadius="70%"
        outerRadius="100%"
        barSize={15}
        startAngle={180}
        endAngle={0}
        data={data}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]} // Score ranges from 0 to 100
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          background={{ fill: "#F0F0F0" }}
          clockWise
          dataKey="uv"
          cornerRadius={15}
          fill="#0244a6" // Fixed color for the Radial Bar
        />
      </RadialBarChart>

      {/* Percentage in the center of the chart */}
      <Typography
        variant="h6" // Adjusted font size
        sx={{
          color: "#000",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -70%)", // Center the text
          fontSize: "1.25rem", // Adjust font size as needed
          fontWeight: "bold",
        }}
      >
        {score}%
      </Typography>
    </Box>
  );
};

export default SecurityScoreChart;
