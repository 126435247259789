import {
  GET_RELEASE_NOTES_REQUEST,
  GET_RELEASE_NOTES_SUCCESSFUL,
  GET_RELEASE_NOTES_FAILED,
  GET_RELEASE_DETAILS_REQUEST,
  GET_RELEASE_DETAILS_SUCCESSFUL,
  GET_RELEASE_DETAILS_FAILED,
  CLEAR_ALL_RELEASE_MESSAGES,
  CLEAR_ALL_RELEASE_MESSAGES_SUCCESSFUL
} from "./actionTypes";

export const getReleaseNotesRequest = (key) => ({
  type: GET_RELEASE_NOTES_REQUEST,
  payload: { key }
});

export const getReleaseNotesSuccessful = (releaseNotes) => ({
  type: GET_RELEASE_NOTES_SUCCESSFUL,
  payload: { releaseNotes }
});

export const getReleaseNotesFailed = (getReleaseNotesError) => ({
  type: GET_RELEASE_NOTES_FAILED,
  payload: { getReleaseNotesError }
});

export const getReleaseDetailsRequest = (fileName) => ({
  type: GET_RELEASE_DETAILS_REQUEST,
  payload: { fileName }
});

export const getReleaseDetailsSuccessful = (releaseDetails) => ({
  type: GET_RELEASE_DETAILS_SUCCESSFUL,
  payload: { releaseDetails }
});

export const getReleaseDetailsFailed = (getReleaseDetailsError) => ({
  type: GET_RELEASE_DETAILS_FAILED,
  payload: { getReleaseDetailsError }
});

export const clearAllReleaseMessages = () => ({
  type: CLEAR_ALL_RELEASE_MESSAGES
});

export const clearAllReleaseMessageSuccessful = () => ({
  type: CLEAR_ALL_RELEASE_MESSAGES_SUCCESSFUL
});

