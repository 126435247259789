export const GET_IAAC_SUBSCRIPTION_REQUEST = "get_iaac_subscription_request";
export const GET_IAAC_SUBSCRIPTION_SUCCESSFUL = "get_iaac_subscription_successful";
export const GET_IAAC_SUBSCRIPTION_FAILED = "get_iaac_subscription_failed";
export const POST_IAAC_SUBSCRIPTION_REQUEST = "post_iaac_subscription_request";
export const POST_IAAC_SUBSCRIPTION_SUCCESSFUL = "post_iaac_subscription_successful";
export const POST_IAAC_SUBSCRIPTION_FAILED = "post_iaac_subscription_failed";
export const PUT_IAAC_SUBSCRIPTION_REQUEST = "put_iaac_subscription_request";
export const PUT_IAAC_SUBSCRIPTION_SUCCESSFUL = "put_iaac_subscription_successful";
export const PUT_IAAC_SUBSCRIPTION_FAILED = "put_iaac_subscription_failed";
export const CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES = "clear_all_iaac_subscription_messages";
export const CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES_SUCCESSFUL = "clear_all_iaac_subscription_messages_successful";
