import { useEffect, useMemo } from "react";
import { useAccessibleUserAccounts } from "../../../components/Common/HelperComponents/CustomHooks";
import { useOrganizations } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useDispatch, useSelector } from "react-redux";
import { getTagDetails } from "../../../store/tagAllocation/tagAllocationSlice";
import jwt_decode from "jwt-decode";

export const useUserAccountsInfo = () => {
  const accounts = useAccessibleUserAccounts();
  const organizations = useOrganizations();

  // Extract orgCode
  const orgCode = useMemo(() => organizations?.[0]?.orgcode, [organizations]);

  // Extract cost enabled accountNumber(s)
  const ciEnabledAccounts = accounts?.filter(
    account => account?.subscription?.addons?.find(item => item.name === "Cost Insight")?.enabled
  );

  // const accountNumber ="829807204216";
  // const accountNumber ="557710868028";
  // const accountNumber = costInsightEnabledAccounts?.[0]?.accountNumber;


  // console.log("#####costInsightEnabledAccounts: ", costInsightEnabledAccounts);
  // console.log("#####accountNumber: ", accountNumber);

  // Extract username
  let username;
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const authUser = jwt_decode(obj.idToken);
    const email = authUser.email;
    username = email;
  }

  return { orgCode, ciEnabledAccounts, username };
};

export const useTagDetails = (accountNumber) => {
  const dispatch = useDispatch();
  const { orgCode, username } = useUserAccountsInfo(); // Reuse the hook

  // Selecting tag details and loading/error state from the store
  const { tagDetails, isFetchingTagDetails, fetchTagDetailsError } = useSelector(
    (state) => state.tags
  );

  useEffect(() => {
    if (orgCode && accountNumber && username) {
      dispatch(getTagDetails({ orgCode, accountNumber, username }));
    }
  }, [dispatch, orgCode, accountNumber, username]);

  return { tagDetails, isFetchingTagDetails, fetchTagDetailsError };
};