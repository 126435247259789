import { useMemo } from "react";
import jwt_decode from "jwt-decode";
import { useAccounts } from "../../../components/Common/HelperComponents/GetDataFromStore";

export const useAccountSummaryData = () => {
  const accounts = useAccounts(); // Always call useAccounts

  const accessibleAccounts = useMemo(() => {
    let username = null;
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      try {
        const decodedToken = jwt_decode(JSON.parse(authUser).idToken);
        username = decodedToken.email;
      } catch (error) {
        console.error("Error decoding token:", error);
        return []; // Return an empty array if there's an error
      }
    }

    if (accounts && username) {
      const filteredAccounts = accounts.filter(
        (x) =>
          !x.accountNumber.includes("INIT00000001") &&
          !x.accountNumber.includes("TEST") &&
          x.accountNumber.length === 12
      );

      return filteredAccounts.filter((acct) =>
        acct.listusr?.some((user) => user.userName === username)
      );
    }

    return []; // Return an empty array if conditions are not met
  }, [accounts]);

  return accessibleAccounts;
};
