import {
    GET__PROFILE_ROLE_INFO_SUCCESSFUL,
    GET__PROFILE_ROLE_INFO_FAILED,
} from './actionTypes';

const initialState = {
    error: null, message: null, loading: true
}

const profile = (state = initialState, action) => {
    switch (action.type) {
        case GET__PROFILE_ROLE_INFO_SUCCESSFUL:
            const {data} = action.payload;
            state = {
                ...data,
                loading: false,
                error: null
            }
            break;
        case GET__PROFILE_ROLE_INFO_FAILED:
            state = {
                loading: false,
                error: action.payload ? action.payload.response.data : null
            }
            break;
        default:
            return state;
    }
    return state;
}

export default profile;