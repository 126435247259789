import { createSlice } from "@reduxjs/toolkit";

export const awsMarketplaceSlice = createSlice({
  name: "awsMarketplace",
  initialState: {
    isAddingFulfillment: false,
    addFulfillmentMessage: null,
    addFulfillmentError: null,

    fulfillmentPayload: null,
    fulfillmentResponse: null,

    isAwsOnboarding: false,
    awsOnboardingMessage: null,
    awsOnboardingError: null,

    awsOnboardingPayload: null,
  },
  reducers: {
    addFulfillment: (state, action) => {
      state.isAddingFulfillment = true;
      state.addFulfillmentMessage = null;
      state.addFulfillmentError = null;
      state.fulfillmentResponse = null;
      state.fulfillmentPayload = action.payload;
    },
    addFulfillmentSuccessful: (state, action) => {
      console.log("addFulfillmentSuccessful action.payload: ",  action.payload);
      state.isAddingFulfillment = false;
      state.addFulfillmentMessage = "Fulfillment done successfully";
      state.fulfillmentResponse = action.payload;
      state.addFulfillmentError = null;
      state.fulfillmentPayload = null;
    },
    addFulfillmentFailure: (state, action) => {
      state.isAddingFulfillment = false;
      state.addFulfillmentMessage = null;
      state.addFulfillmentError = action.payload.message;
      state.fulfillmentPayload = null;
    },
    awsOnboard: (state, action) => {
      state.isAwsOnboarding = true;
      state.awsOnboardingMessage = null;
      state.awsOnboardingError = null;
      state.awsOnboardingPayload = action.payload;
    },
    awsOnboardSuccessful: (state, action) => {
      console.log("awsOnboardingSuccessful action.payload: ",  action.payload);
      state.isAwsOnboarding = false;
      // state.awsOnboardingMessage = "AwsOnboarding done successfully";
      state.awsOnboardingMessage = action.payload;
      state.awsOnboardingError = null;
      state.awsOnboardingPayload = null;
    },
    awsOnboardFailure: (state, action) => {
      state.isAwsOnboarding = false;
      state.awsOnboardingMessage = null;
      state.awsOnboardingError = action.payload.message;
      state.awsOnboardingPayload = null;
    },
    clearAllAwsMarketplaceMessages: (state, action) => {
    },
    clearAllAwsMarketplaceMessagesSuccessful: (state, action) => {
      state.addFulfillmentMessage = null;
      state.addFulfillmentError = null;
      state.awsOnboardingMessage = null;
      state.awsOnboardingError = null;
    }
  }
});

export const {
  addFulfillment,
  addFulfillmentSuccessful,
  addFulfillmentFailure,
  awsOnboard,
  awsOnboardSuccessful,
  awsOnboardFailure,
  clearAllAwsMarketplaceMessages,
  clearAllAwsMarketplaceMessagesSuccessful,
} = awsMarketplaceSlice.actions;

export default awsMarketplaceSlice.reducer;
