import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_PROFILE_ROLE_INFO } from './actionTypes';
import { getProfileRoleInfoSuccessful, getProfileRoleInfoFailed } from './actions';
import { getProfileRoleInfo } from "../../../helpers/auth_aws_helper";

function* getProfileRoleInfoData() {
    try {
        const response = yield call(getProfileRoleInfo);
        if (response && response.status === 200) {
            yield put(getProfileRoleInfoSuccessful(response));
        } else {
            yield put(getProfileRoleInfoFailed(response));
        }
    } catch (error) {
        yield put(getProfileRoleInfoFailed(error));
    }
}

export function* watchProfileRoleInfo() {
    yield takeEvery(GET_PROFILE_ROLE_INFO, getProfileRoleInfoData)
}

function* getProfileRoleInfoSaga() {
    yield all([fork(watchProfileRoleInfo)]);
}

export default getProfileRoleInfoSaga;