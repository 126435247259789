import { takeLatest, fork, put, all, call } from 'redux-saga/effects';

import {
  GET_ASSET_INSIGHT_METRIC,
  GET_COMPLIANCE_METRIC,
  GET_COST_INSIGHT_METRIC,
  GET_SECURITY_POSTURE_METRIC,
} from "./actionTypes";

import {
  getAssetInsightMetricSuccessful,
  getAssetInsightMetricFailed,
  getComplianceMetricSuccessful,
  getComplianceMetricFailed,
  getCostInsightMetricSuccessful,
  getCostInsightMetricFailed,
  getSecurityPostureMetricSuccessful,
  getSecurityPostureMetricFailed,
} from "./actions";

import {
  getAssetInsightMetric,
  getComplianceMetric,
  getCostInsightMetric,
  getSecurityPostureMetric,
} from "../../helpers/auth_aws_helper";

function* getAssetInsightMetricInformation({ payload: { accCodes, orgCode } }) {
  try {
    console.log("About to call AWS API, getAssetInsightMetric");
    const response = yield call(getAssetInsightMetric, accCodes, orgCode);
    console.log("getAssetInsightMetricInformation, response: " + JSON.stringify(response));
    if( response.status === 200 ){
      delete response.status;
      yield put(getAssetInsightMetricSuccessful(response));
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getAssetInsightMetricFailed({message: message}));
    }
  } catch (error) {
    console.log("getAssetInsightMetricFailed, received Error is :: ", JSON.stringify(error));
    if(error.response){
      yield put(getAssetInsightMetricFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if(error.request){
      yield put(getAssetInsightMetricFailed({message: "No response is received"}))
    } else{
      yield put(getAssetInsightMetricFailed({message: error?.message}));
    }
  }
}

function* getComplianceMetricInformation({ payload: { accCodes, orgCode } }) {
  try {
    console.log("About to call AWS API, getComplianceMetric");
    const response = yield call(getComplianceMetric, accCodes, orgCode);
    if( response.status === 200 ){
      delete response.status;
      yield put(getComplianceMetricSuccessful(response));
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getComplianceMetricFailed({message: message}));
    }
  } catch (error) {
    console.log("getComplianceMetricFailed, received Error is :: ", JSON.stringify(error)
    );
    if(error.response){
      yield put(getComplianceMetricFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if(error.request){
      yield put(getComplianceMetricFailed({message: "No response is received"}))
    } else{
      yield put(getComplianceMetricFailed({message: error?.message}));
    }
  }
}

function* getCostInsightMetricInformation({ payload: { accCodes, orgCode } }) {
  try {
    console.log("About to call AWS API, getCostInsightMetric");
    const response = yield call(getCostInsightMetric, accCodes, orgCode);
    if( response.status === 200 ){
      delete response.status;
      yield put(getCostInsightMetricSuccessful(response));
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getCostInsightMetricFailed({message: message}));
    }
  } catch (error) {
    console.log("getCostInsightMetricFailed, received Error is :: ", JSON.stringify(error)
    );
    if(error.response){
      yield put(getCostInsightMetricFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if(error.request){
      yield put(getCostInsightMetricFailed({message: "No response is received"}))
    } else{
      yield put(getCostInsightMetricFailed({message: error?.message}));
    }
  }
}


function* getSecurityPostureMetricInformation({ payload: { accCodes, orgCode } }) {
  try {
    console.log("About to call AWS API, getSecurityPostureMetric");
    const response = yield call(getSecurityPostureMetric, accCodes, orgCode);
    console.log("Security posture response: ", response);
    if( response.status === 200 ){
      delete response.status;
      yield put(getSecurityPostureMetricSuccessful(response));
    } else {
      let message;
      if (response.message) {
        message = response.message;
      } else {
        message = response;
      }
      yield put(getSecurityPostureMetricFailed({message: message}));
    }
  } catch (error) {
    console.log("getSecurityPostureMetricFailed, received Error is :: ", JSON.stringify(error));
    if(error.response){
      yield put(getSecurityPostureMetricFailed({message: "Error Code: "+error.response?.status+", Message: "+error.response?.data}));
    } else if(error.request){
      yield put(getSecurityPostureMetricFailed({message: "No response is received"}))
    } else{
      yield put(getSecurityPostureMetricFailed({message: error?.message}));
    }
  }
}

export function* watchAssetInsightMetricInformation() {
  yield takeLatest(GET_ASSET_INSIGHT_METRIC, getAssetInsightMetricInformation);
}

export function* watchComplianceMetricInformation() {
  yield takeLatest(GET_COMPLIANCE_METRIC, getComplianceMetricInformation);
}

export function* watchCostInsightMetricInformation() {
  yield takeLatest(GET_COST_INSIGHT_METRIC, getCostInsightMetricInformation);
}

export function* watchSecurityPostureMetricInformation() {
  yield takeLatest(GET_SECURITY_POSTURE_METRIC, getSecurityPostureMetricInformation);
}

function* getMetricInformationSaga() {
  yield all([fork(watchAssetInsightMetricInformation), fork(watchComplianceMetricInformation), fork(watchCostInsightMetricInformation), fork(watchSecurityPostureMetricInformation)]);
}

export default getMetricInformationSaga