import React, {useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useDispatch } from "react-redux";
import { ModalBody, Modal, Button, Row, Col, Table, Tooltip } from "reactstrap";
import AddNewUser from "./_addNewUser";
import styled from "styled-components";
import MyDataGrid from "./MyDataGrid";

import { Alert, Snackbar } from "@mui/material";
import getUserRole from "../../helpers/jwt-token-access/userRole";

const allowedRoles=[ "PowerUser", "Administrator" ];

const Styles = styled.div`
  tbody {
    hover {
      background-color: #fcf8e3;
    }
  }
`;
const UserConfigurationDetailsTab = ({
  addNewUserResponse,
  editUserResponse,
  usersConfig,
  accounts,
  organizations,
  licensing,
  roleAccess,
  onReActive,
  onDelete,
  handleSubmit,
  hanldeResetNewUser,
}) => {
  const [usersList, setUsersList] = useState([]);
  const [disableAddUser, setDisableAddUser] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [manageUser, setManageUser] = useState({
    selectedUser: {},
    showAdduser: false,
  });
  let [modalIsOpen, setModalIsOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };
  const [accountList, setaccountList] = useState([]);
  const { showAdduser, selectedUser } = manageUser;
  const dispatch = useDispatch();

  const handleCancelClick = (val) => {
    setManageUser({ selectedUser: {}, showAdduser: val });
    hanldeResetNewUser();
  };

  const [showUserSuccessStatus, setShowUserSuccessStatus] = useState(null);

  const userRole = getUserRole();

  // useEffect(() => {
  //   if (addNewUserResponse && addNewUserResponse.status === 200) {
  //     if (selectedUser && Object.keys(selectedUser).length > 0) {
  //       setShowUserSuccessStatus("User Successfully Updated..");
  //     } else {
  //       setShowUserSuccessStatus("User Successfully Created..");
  //     }
  //   }
  //   console.log("$$$$$$$$$ useEffect for addNewUser");
  // }, [addNewUserResponse]);

  const addNewUserMessage = addNewUserResponse?.addNewUserMessage;
  const editUserMessage = editUserResponse?.editUserMessage;
  useEffect(() => {
    if (addNewUserMessage || editUserMessage) {
      if (selectedUser && Object.keys(selectedUser).length > 0) {
        setShowUserSuccessStatus(editUserMessage);
      } else {
        setShowUserSuccessStatus(addNewUserMessage);
      }
      setTimeout(() => setShowUserSuccessStatus(null), 10000);
    }
    console.log("$$$$$$$$$ useEffect for addNewUser");
  }, [addNewUserMessage, editUserMessage]);

  const closeShowUserSuccessStatus = () => {
    setShowUserSuccessStatus(null);
    // dispatch(postAddUserResponse({}));
    setManageUser((prevState) => {
      return {
        ...prevState,
        showAdduser: false,
      };
    });
  };

  useEffect(() => {
    const [searchBoxItem, dataTableRef] =
      document.getElementsByClassName("mdb-datatable")[0].children;
    if (
      searchBoxItem.getElementsByClassName("mdb-datatable-filter").length > 0
    ) {
      searchBoxItem.setAttribute(
        "style",
        "justify-content: flex-end !important"
      );
      const [dataTableDiv] = dataTableRef.getElementsByClassName(
        "dataTables_scrollHeadInner"
      );
      // dataTableDiv.setAttribute("style", "padding: 0px !important;");
      const [dataTableTHead] = dataTableRef.getElementsByTagName("thead");
      dataTableTHead.setAttribute(
        "style",
        "color: #fff !important; background-color: #343a40 !important;"
      );
      const reactDynamicTag = document.createElement("div");
      reactDynamicTag.setAttribute("id", "userConfigBtnHolder");
      reactDynamicTag.setAttribute("class", "pt-1");
      searchBoxItem.append(reactDynamicTag);
      ReactDOM.render(
        getAddButton(),
        document.getElementById("userConfigBtnHolder")
      );
    }
    console.log("$$$$$$$$$ useEffect for search box item");
  }, []);

  const getAddButton = () => {
    return (
      <div style={{ marginRight: 10 }}>
        <Button
          size="sm"
          style={{
            textAlign: "right",
            backgroundColor: "#F98125",
            float: "right",
            borderColor: "#fff",
          }}
          disabled={
            disableAddUser ||
            usersConfig?.length >= licensing.numUsers ||
            !allowedRoles.includes(userRole)
          }
          onClick={onAddUserClick}
          className={`btn ml-2 ${roleAccess >= 0 ? " btn-success" : ""}`}
        >
          <span id="AddUsersBtn">
            <i className="mdi mdi-plus mr-2"></i>Add Users
          </span>
        </Button>
        <Tooltip
          placement="right"
          isOpen={tooltipOpen && (roleAccess < 0 || disableAddUser)}
          target="AddUsersBtn"
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          {disableAddUser
            ? "Cannot create new users, increase licensing to enable more users. "
            : "Admin role required!"}
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    ReactDOM.render(
      getAddButton(),
      document.getElementById("userConfigBtnHolder")
    );
    console.log("$$$$$$$$$ useEffect for roleAccess");
  }, [roleAccess]);

  useEffect(() => {
    if (licensing) {
      const numUsers = licensing.numUsers;
      if (usersConfig) {
        console.log("--= license inc ", usersConfig.length, numUsers);
        if (usersConfig.length >= numUsers) {
          setDisableAddUser(true);
        }
      }
    }
    console.log("$$$$$$$$$ useEffect for licensing");
  }, [licensing, usersConfig]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const temp = [];
      accounts.forEach((item) => {
        item.listusr.forEach((entity) => temp.push(entity));
      });
      setUsersList(temp);
    }
    console.log("$$$$$$$$$ useEffect for accounts");
  }, [accounts]);

  const handleEditUser = (user) => {
    console.log("--== handleEditUser ", user);
    setManageUser({ showAdduser: true, selectedUser: user });
  };

  const handleConfirmationClick = () => {
    if (showConfirmation && showConfirmation.action === "delete") {
      onDelete(showConfirmation);
    } else {
      onReActive({ email: showConfirmation.email });
    }
    setShowConfirmation(null);
  };

  const onAddUserClick = () => {
    console.log("onAddUserClick called");
    // dispatch(getListOfAccts());
    setManageUser({ selectedUser: {}, showAdduser: true });
  };

  return (
    <React.Fragment>
      <>
        <Modal
          size="lg"
          backdrop="static"
          centered
          style={{ display: "flex" }}
          isOpen={modalIsOpen}
        >
          <div>
            <div style={{ textAlign: "right" }}>
              <Button
                style={{ marginLeft: "auto" }}
                onClick={setModalIsOpenToFalse}
              >
                X
              </Button>
            </div>
          </div>
          <ModalBody>
            <Table
              className="table-centered datatable dt-responsive nowrap"
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
                width: "100%",
              }}
            >
              <thead
                style={{
                  width: "20px",
                  backgroundColor: "#a9a9a9",
                  textAlign: "left",
                }}
              >
                <tr>
                  <th className="text-center">AccountName</th>
                  <th className="text-center">AccountNumber</th>
                  <th className="text-center">AccountType</th>
                </tr>
              </thead>
              <tbody>
                {accountList.map((acct, index) =>
                  !acct.accountNumber.includes("INIT00000001") ? (
                    <tr key={index}>
                      <td>{acct.accountName}</td>
                      <td>{acct.accountNumber}</td>
                      <td>{acct.accountType}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
      </>
      <div style={{ position: "absolute" }}>
        { showAdduser && (
            <AddNewUser
              accounts={accounts}
              organizations={organizations}
              usersConfig={usersConfig}
              setShowAddUsers={handleCancelClick}
              selectedUser={selectedUser}
              showAdduser={showAdduser}
              handleOnSubmit={handleSubmit}
              addNewUserResponse={addNewUserResponse}
              editUserResponse={editUserResponse}
            />
        )}        
      </div>
      <Modal isOpen={showConfirmation} className="col-sm-8">
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <h4 className="text-secondary">
              {showConfirmation && showConfirmation.action === "delete"
                ? `Are you sure, you want to delete ${showConfirmation.username} `
                : "Confirm resend activation ?"}
            </h4>
            <div className="mt-2 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-secondary mr-2"
                onClick={() => setShowConfirmation(null)}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm btn-primary ml-2"
                onClick={handleConfirmationClick}
              >
                OK
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Row>
        <Col lg={12}>
          {/* <DataGrid
                        searchTop
                        searchBottom={false}
                        hover={true}
                        responsive={true}
                        small
                        maxHeight="400px"
                        entriesOptions={[10, 20, 30]}
                        data={usersTableDef}
                        className='your-custom-styles'
                        fullPagination /> */}

          <MyDataGrid
            usersConfig={usersConfig}
            setaccountList={setaccountList}
            setModalIsOpenToTrue={setModalIsOpenToTrue}
            handleEditUser={handleEditUser}
            roleAccess={roleAccess}
            setShowConfirmation={setShowConfirmation}
          />
        </Col>
      </Row>
      <Snackbar
                open={showUserSuccessStatus!==null}
                autoHideDuration={5000}
            >
                <Alert severity="success">{showUserSuccessStatus}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default React.memo(UserConfigurationDetailsTab);