import React from "react";

const ApiError = ({parameter}) =>{
    return (
        <div
            style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
            color: "red"
            }}
        >
            <div>
                We are facing some problem while getting data for {parameter}; kindly revisit after
                some time.
            </div>
        </div>
    )
}

export default ApiError;