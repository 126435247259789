import {
    fork,
    put,
    all,
    call,
    takeLatest,
    delay,
  } from "redux-saga/effects";
  
  import {
    fetchKibanaDashboardsSuccess,
    fetchKibanaDashboardsFailure,
  } from "./kibanaDashboardsSlice";
  
 import { fetchKibanaDashboardsApi } from "../../helpers/auth_aws_helper";
  
  function* fetchKibanaDashboards({ payload: orgCode }) {
    try {
      console.log("Fetching Kibana Dashboards for orgCode:", orgCode);
      const response = yield call(fetchKibanaDashboardsApi, orgCode);
      console.log("fetchKibanaDashboardsApi response:", response);
        // yield delay(10000);
      if (response && response.status === 200) {
        yield put(fetchKibanaDashboardsSuccess(response));
      } else {
        console.log("Fetching Kibana Dashboards Error:", response);
        yield put(fetchKibanaDashboardsFailure({ message: response?.data?.message || response?.data || "Unexpected error occurred while fetching Kibana dashboards." }));
      }
    } catch (error) {
      if (error.response) {
        yield put(
          fetchKibanaDashboardsFailure({
            message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
          })
        );
      } else if (error.request) {
        yield put(fetchKibanaDashboardsFailure({ message: "No response received" }));
      } else {
        yield put(fetchKibanaDashboardsFailure({ message: error?.message }));
      }
    }
  }
  
  function* watchKibanaDashboards() {
    yield takeLatest("kibanaDashboards/fetchKibanaDashboards", fetchKibanaDashboards);
  }
  
  export function* kibanaDashboardsSaga() {
    yield all([fork(watchKibanaDashboards)]);
  }
  
  export default kibanaDashboardsSaga;
  