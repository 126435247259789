import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSecurityPostureMetric } from "../../../store/actions";

export const useSecurityScoreData = (accCodes, orgCode) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const securityPostureMetric = useSelector(
    (state) => state.metricInfo.securityPostureMetric
  );
  const securityPostureMetricLoading = useSelector(
    (state) => state.metricInfo.securityPostureMetricLoading
  );
  const securityPostureMetricError = useSelector(
    (state) => state.metricInfo.securityPostureMetricError
  );

  const hasFetched = useRef(false); // Add a ref to track if the API call was made

  useEffect(() => {
    if (accCodes && orgCode && !hasFetched.current) {
      dispatch(getSecurityPostureMetric(accCodes, orgCode));
      hasFetched.current = true; // Set the ref to true after the API call is made
    }
  }, [dispatch, accCodes, orgCode]);

  useEffect(() => {
    if (securityPostureMetric) {
      const metricObject = apiDataToSecurityPostureMetricObject(
        securityPostureMetric
      );
      setData(metricObject);
    }
    setLoading(securityPostureMetricLoading);
    setError(
      securityPostureMetricError ? "Error in Security Posture API" : null
    );
  }, [
    securityPostureMetric,
    securityPostureMetricLoading,
    securityPostureMetricError,
  ]);

  return { data, loading, error };
};

const apiDataToSecurityPostureMetricObject = (apiData) => {
  let metricObject = {};

  // Map Security score
  metricObject.securityPostSecPercent = parseFloat(apiData["Security score"]).toFixed(2);

  // Map Severity levels
  metricObject.securityPostCriticalTotal =
    typeof apiData["Severity levels"]["CRITICAL"] === "string"
      ? parseInt(apiData["Severity levels"]["CRITICAL"])
      : apiData["Severity levels"]["CRITICAL"];

  metricObject.securityPostHighTotal =
    typeof apiData["Severity levels"]["HIGH"] === "string"
      ? parseInt(apiData["Severity levels"]["HIGH"])
      : apiData["Severity levels"]["HIGH"];

  metricObject.securityPostModerateTotal =
    typeof apiData["Severity levels"]["MEDIUM"] === "string"
      ? parseInt(apiData["Severity levels"]["MEDIUM"])
      : apiData["Severity levels"]["MEDIUM"];

  metricObject.securityPostLowTotal =
    typeof apiData["Severity levels"]["LOW"] === "string"
      ? parseInt(apiData["Severity levels"]["LOW"])
      : apiData["Severity levels"]["LOW"];

  // Map Total and Failed Resources
  metricObject.resourceTotalCount =
    typeof apiData["Total Resources"] === "string"
      ? parseInt(apiData["Total Resources"])
      : apiData["Total Resources"];

  metricObject.resourceFailedCount =
    typeof apiData["Failed Resources"] === "string"
      ? parseInt(apiData["Failed Resources"])
      : apiData["Failed Resources"];

  return metricObject;
};

