import { createSlice } from "@reduxjs/toolkit";

export const billingSlice = createSlice({
  name: "billing",
  initialState: {
    isLoading: false,
    records: [],
    errorMessage: null,
  },
  reducers: {
    fetchBillingRecords: (state) => {
      state.isLoading = true;
      state.records = [];
      state.errorMessage = null;
    },
    fetchBillingRecordsSuccess: (state, action) => {
      console.log("🚀 ~ action fetchBillingRecordsSuccess:", action);
      state.isLoading = false;
      state.records = action.payload;
      state.errorMessage = null;
    },
    fetchBillingRecordsFailure: (state, action) => {
      state.isLoading = false;
      state.records = [];
      state.errorMessage = action.payload.message;
    },
    clearBillingState: (state) => {
      state.isLoading = false;
      state.records = [];
      state.errorMessage = null;
    },
  },
});

export const {
  fetchBillingRecords,
  fetchBillingRecordsSuccess,
  fetchBillingRecordsFailure,
  clearBillingState,
} = billingSlice.actions;

export default billingSlice.reducer;
