import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import dashboardTheme from "./components/dashboardTheme";
import DashboardPage from "./Dashboard";

const Dashboard = () => {
  return (
    <div className="page-content">
      <ThemeProvider theme={dashboardTheme}>
        <DashboardPage />
      </ThemeProvider>
    </div>
  );
};

export default Dashboard;
