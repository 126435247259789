import PhoneInput, {parsePhoneNumber, isPossiblePhoneNumber, getCountryCallingCode  } from 'react-phone-number-input';
export const ExtractPhoneNumber = (phoneNumber) => {
    if (isPossiblePhoneNumber(phoneNumber)) {
        const parsedNumber = parsePhoneNumber(phoneNumber);
        if (parsedNumber) {
            // Extract the phone number excluding the country code
            const numberWithoutCountryCode = phoneNumber.replace(`+${parsedNumber.countryCallingCode}`, '');
            return numberWithoutCountryCode.trim();
        }
    }
    // Handle invalid or unparseable phone numbers
    return null;
};


export const ExtractCountryCallingCode = (phoneNumber) => {
    if(isPossiblePhoneNumber(phoneNumber) ) {
        const countryCode = parsePhoneNumber(phoneNumber);
        return countryCode.countryCallingCode;
    }
}