import {
    GET_LICENSING_DATA,
    GET__LICENSING_DATA_SUCCESSFUL,
    POST_LICENSING_DATA,
    POST__LICENSING_DATA_SUCCESSFUL,
    GET_SCAN_FREQ_METADATA,
    GET_SCAN_FREQ_METADATA_SUCCESSFUL,
    HANDLE_API_FAILURES,
} from './actionTypes';

export const getLicensingData = () => {
    return { type: GET_LICENSING_DATA,
        payload: {  } }
}

export const getLicensingDataSuccessful = (data) => {
    return {
        type: GET__LICENSING_DATA_SUCCESSFUL,
        payload: { data }
    }
}

export const getScanFreqMetadata = () => {
    return { type: GET_SCAN_FREQ_METADATA,
        payload: {  } }
}

export const getScanFreqMetadataSuccessful = (data) => {
    return {
        type: GET_SCAN_FREQ_METADATA_SUCCESSFUL,
        payload: { data }
    }
}

export const postLicensingData = (params) => {
    //console.log('--== postLicensingData ==--');
    return { type: POST_LICENSING_DATA,
        payload: { data: params  } }
}

export const postLicensingDataSuccessful = (data) => {
    return {
        type: POST__LICENSING_DATA_SUCCESSFUL,
        payload: { data }
    }
}

export const handleApiFailures = (data) => {
    return {
        type: HANDLE_API_FAILURES,
        payload: { data }
    }

}
