import { createTheme } from "@mui/material/styles";

const dashboardTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff", // Plain white background
      alt: "#f0f0f0", // Slightly grey alternative background
    },
    primary: {
      main: "#007bff", // Example blue primary color
    },
    secondary: {
      main: "#6c757d", // Example grey secondary color
    },
    neutral: {  // Added the neutral color palette
      main: "#868e96", // Example neutral color (gray)
      contrastText: "#ffffff",
    },
    text: {
      primary: "#212529", // Dark grey text
      secondary: "#6c757d", // Light grey text
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 14,
    h1: {
      fontSize: "2.125rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  },
});

export default dashboardTheme;
