import React from "react";
import { convertDate } from "../../helpers/util_helper";
import { Link } from "react-router-dom";

import {Tooltip as MuiTooltip} from "@mui/material";
import UserDataGrid from "../DataGrid/UserDataGrid";
import getUserRole from "../../helpers/jwt-token-access/userRole";

const allowedRoles=[ "PowerUser", "Administrator" ];

export const MyDataGrid = ({
  usersConfig,
  setaccountList,
  setModalIsOpenToTrue,
  handleEditUser,
  roleAccess,
  setShowConfirmation,
}) => {
    const userRole = getUserRole();
    const usersTableDef = {
    columns: [
      {
        label: "",
        field: " ",
        sort: "disabled",
      },
      {
        label: "First Name",
        field: "firstname",
        sort: "asc",
      },
      {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
      },
      {
        label: "User Name",
        field: "username",
        sort: "asc",
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
      },
      {
        label: "Phone Number",
        field: "phoneNumber",
        sort: "asc",
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
      },
      {
        label: "Accounts",
        field: "accounts",
        sort: "asc",
      },
      {
        label: "Role",
        field: "roles",
        sort: "asc",
      },
      {
        label: "Created Date",
        field: "createdDate",
        sort: "asc",
      },
      {
        label: "Resource Group",
        field: "resourceGroup",
        sort: "asc",
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
      },
    ],
    rows:
      usersConfig &&
      usersConfig.filter(user => !user.isDeleted && !user.email.includes(process.env.REACT_APP_EMAIL_DOMAIN)).map((user, index) => {
        return {
          firstname: `${user.firstname}`,
          lastName: `${user.lastName}`,
          username: `${user.username}`,
          email: `${user.email}`,
          phoneNumber: `${user.phoneNumber}`,
          country: `${user.country}`,
          accounts: (
            <Link
              to="#"
              className="btn btn-link mr-3 text-darkblue"
              onClick={() => {
                setaccountList(
                  user.listacct.filter(
                    (x) => !x.accountNumber.includes("INIT00000001")
                  )
                );
                setModalIsOpenToTrue();
              }}
            >
              <div className="badge badge-pill badge-light">
                {/* INIT0000 */}
                {
                  user.listacct.filter(
                    (x) => !x.accountNumber.includes("INIT00000001")
                  ).length
                }
              </div>
            </Link>
          ),
          roles: `${user.roles}`,
          createdDate: `${convertDate(user.createdDate)}`,
          resourceGroup: (
            <div>
              {user.userGroup && user.userGroup.length > 0 ? (
                <MuiTooltip
                  title={user.userGroup.map((group) => (
                    <div>{group.resourcename}</div>
                  ))}
                >
                  <div className="badge badge-pill badge-light">
                    {user.userGroup.length}
                  </div>
                </MuiTooltip>
              ) : (
                "Unassigned"
              )}
            </div>
          ),
          status: `${user.status ? "Active" : "InActive"}`,
          action: (
            <div className="d-flex justify-content-between">
              <div>
                <button
                  className="btn btn-link text-darkblue btn-sm"
                  id={"edit" + index}
                  onClick={() => handleEditUser(user)}
                  disabled={
                    !allowedRoles.includes(userRole) || 
                    (userRole ==="PowerUser" ? `${user.roles}`==="Administrator" : false)
                  }
                  title="Modify User"
                >
                  <i className="mdi mdi-pencil font-size-18 text-darkblue"></i>
                </button>
              </div>
              <div>
                <button
                  className={`btn btn-link btn-sm`}
                  id={"delete" + index}
                  onClick={() =>
                    setShowConfirmation({ ...user, action: "delete" })
                  }
                  disabled={
                    !allowedRoles.includes(userRole) ||
                    (userRole ==="PowerUser" ? `${user.roles}`==="Administrator" : false)
                  }
                  title="Delete User"
                >
                  <i
                    className={`mdi mdi-trash-can font-size-18 ${
                      roleAccess < 0 
                        ? "text-secondary"
                        : "text-danger"
                    } `}
                  ></i>
                </button>
              </div>
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-link text-info btn-sm"
                  id={"reactivate" + index}
                  disabled={(user && user.status) || roleAccess < 0}
                  onClick={() =>
                    setShowConfirmation({ ...user, action: "reActive" })
                  }
                  title="Resend Activation Email"
                >
                  <i
                    className={`mdi mdi-refresh font-size-18 ${
                      user && user.status ? "text-secondary" : "text-danger"
                    }`}
                  ></i>
                </button>
              </div>
            </div>
          ),
        };
      }),
  };
  console.log("MyDataGrid is rendered");
  return (
    <div className="datatable">
      <UserDataGrid
        searchTop
        searchBottom={false}
        hover={true}
        responsive={true}
        small
        maxHeight="400px"
        entriesOptions={[10, 20, 30]}
        data={usersTableDef}
        fullPagination
      />
    </div>
  );
};

export default MyDataGrid;