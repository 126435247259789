export const fetchPublicKey = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AWS_API_URL}/cloudnova/getPublicKey`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch public key");
    }
    const publicKey = await response.text();
    return publicKey;
  } catch (error) {
    console.error("Error fetching public key:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const fetchFreeTrialPublicKey = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AWS_API_URL}/cloudnova/getFreeTrialPublicKey`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch public key");
    }
    const publicKey = await response.text();
    return publicKey;
  } catch (error) {
    console.error("Error fetching public key:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};
