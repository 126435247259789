import {
    fork,
    put,
    all,
    call,
    takeLatest,
    delay,
  } from "redux-saga/effects";
  
  import {
    postFeatureConfigurationSuccessful,
    postFeatureConfigurationFailure,
    clearAllFeatureConfigurationMessagesSuccessful,
  } from "./featureConfigurationSlice";
  
  import {
    postFeatureConfiguration as postFeatureConfigurationApi,
  } from "../../helpers/auth_aws_helper";
  
  function* postFeatureConfiguration( {payload: featureConfigurationPayload} ) {
    try {
      console.log("about to call *postFeatureConfiguration() with ", featureConfigurationPayload);
      const response = yield call(postFeatureConfigurationApi, featureConfigurationPayload);
      console.log("*postFeatureConfigurationApi() response: ", response);
  
      if (response && response.status === 200) {
        yield put(postFeatureConfigurationSuccessful(response.data));
      } else {
        yield put(postFeatureConfigurationFailure({ message: response.data }));
      }
    } catch (error) {
      if (error.response) {
        yield put(
          postFeatureConfigurationFailure({
            message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
          })
        );
      } else if (error.request) {
        yield put(postFeatureConfigurationFailure({ message: "No response received" }));
      } else {
        yield put(postFeatureConfigurationFailure({ message: error?.message }));
      }
    }
  }
  
  function* clearAllFeatureConfigurationMessages() {
    console.log("Saga: clear All FeatureConfiguration Messages");
    yield put(clearAllFeatureConfigurationMessagesSuccessful());
  }
  
  export function* watchFeatureConfiguration() {
    yield takeLatest("featureConfiguration/postFeatureConfiguration", postFeatureConfiguration);
    yield takeLatest("featureConfiguration/clearAllFeatureConfigurationMessages", clearAllFeatureConfigurationMessages);
  }
  
  export function* watchFeatureConfigurationSaga() {
    yield all([fork(watchFeatureConfiguration)]);
  }
  
  export default watchFeatureConfigurationSaga;