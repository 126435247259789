import React, { Component } from "react";
import { Container } from "reactstrap";
import OrgDetails from './OrgDetails';
// import AcctDetails from './AcctDetails';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "CloudNova", link: "#" },
        { title: "Profile", link: "#" },
        { title: "Settings", link: "#" }
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <OrgDetails />
            {/* <AcctDetails /> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
