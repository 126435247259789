import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
  GET_LIST_ALL_RULES,
  GET_LIST_ACC_RULES,
  PUT_EDIT_RULES,
  PUT_TOGGLE_RULES,
} from './actionTypes';
import {
  getListAllRulesSuccessful,
  getListAllRulesFailed,
  getListAccRulesSuccessful,
  getListAccRulesFailed,
  editRulesSuccessful,
  editRulesFailed,
  setListAccRulesState,
} from './actions';

import {
  getAccRules,
  getAllRules,
  updateRules,
  putToggleRulezLambda,
} from "../../helpers/auth_aws_helper";

function* handleGetListAllRules() {
  try {
    const response = yield call(getAllRules);
    yield put(getListAllRulesSuccessful(response));
  } catch (error) {
    yield put(getListAllRulesFailed(error));
  }
}
function* handleGetListAccRules() {
  try {
    const response = yield call(getAccRules);
    yield put(getListAccRulesSuccessful(response));
    console.log('REPSONSE ', response);
  } catch (error) {
    yield put(getListAccRulesFailed(error));
  }
}
function* updateMappings({ payload: { accts, ruleId } }) {
  console.log('update', accts);
  try {
    const response = yield call(updateRules, {
      rulesDTOS: [
        {
          cloudNovaId: ruleId,
        },
      ],
      acccodes: accts,
    });
    console.log('da real response', response);
    yield put(editRulesSuccessful(response));
    console.log('success');
  } catch (error) {
    console.log('error', error);
    yield put(editRulesFailed(error));
  }
}

// function* deleteRulesAcct({ payload: { accts } }) {
//   console.log('delete', accts);
//   try {
//     const response = yield call(deleteRules, {
//       acccodes: accts,
//     });
//     console.log('delete accts response', response);
//     yield put(deleteRulesSuccessful(response));
//     console.log('success');
//   } catch (error) {
//     console.log('error', error);
//     yield put(deleteRulesFailed(error));
//   }
// }

function* handleRulezToggleAction({payload}) {
  console.log('--== 2) setRulezToggleAction saga ', payload);
  try {
    const params = []
    payload.forEach((item) => {
      params.push({
        acccode: item.acccode,
        ruleId: item.cloudNovaId,
        isEnabled: item.isEnabled
      })
    })
    console.log('--== 2 handleRulezToggleAction ', params);
    const response = yield call(putToggleRulezLambda, params);
    console.log('delete accts response', response);
    yield put(setListAccRulesState({status: 200, ...response}));
  } catch (error) {
    yield put(setListAccRulesState({status: 500}));
  }
}

export function* watchGetListOfRules() {
  yield takeEvery(GET_LIST_ALL_RULES, handleGetListAllRules);
  yield takeEvery(GET_LIST_ACC_RULES, handleGetListAccRules);
  yield takeEvery(PUT_EDIT_RULES, updateMappings);
  // yield takeEvery(DELETE_RULES, deleteRules);
  yield takeEvery(PUT_TOGGLE_RULES, handleRulezToggleAction);
}

function* getRuleSaga() {
  yield all([fork(watchGetListOfRules)]);
}

export default getRuleSaga;