import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  Alert,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
  clearTagMessages,
  uploadTags,
} from "../../../store/tagAllocation/tagAllocationSlice";

const UploadTagsModal = (props) => {
  const { isOpen, handleClose, orgCode, accountNumber } = props;
  const [csvFile, setCsvFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setCsvFile(file);
    } else {
      setCsvFile(null);
      alert("Please upload a valid CSV file.");
    }
  };

  //For displaying upload success/error messages for a short duration
  const uploadMessage = props.uploadMessage;
  const uploadError = props.uploadError;
  useEffect(() => {
    if (uploadMessage || uploadError) {
      setTimeout(() => {
        console.log("About to call clearTagMessages");
        props.clearTagMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
    if (uploadMessage) {
      handleClose();
    }
  }, [uploadMessage, uploadError]);

  const handleUpload = () => {
    if (!csvFile) {
      alert("Please select a file to upload.");
      return;
    }
    props.uploadTags(orgCode, accountNumber, csvFile);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%', // Responsive width
          maxWidth: 600, // Max width
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        {/* Modal Header */}
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: '#f3f4f6', // Very light gray background color
              padding: '8px 16px', // Padding around header
              borderBottom: '1px solid #ddd', // Bottom border to separate header
            }}
          >
            {/* Modal Title (Two-line) */}
            <Box>
              <Typography id="resource-tags-modal-title" variant="h6" component="h2">
                Upload Tags for account: {accountNumber}
              </Typography>
            </Box>
            {/* Close Icon */}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

        {/* Verbiage Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: "20px",
            padding: "16px"
          }}
        >
          {/* Display error message */}
          {uploadError && (
            <Alert severity="error">Error while uploading tags: {uploadError}</Alert>
          )}
          <Alert severity="info" sx={{ textAlign: "center" }}>
            You are allowed to upload one file per day.
          </Alert>
          <Alert severity="info" sx={{ textAlign: "center" }}>
            The CUR Tag report will be available approximately one hour after
            your file is uploaded.
          </Alert>
          <Alert severity="info" sx={{ textAlign: "center" }}>
            You will receive an email notification if there is an issue with the
            upload or if the file is corrupted.
          </Alert>
        </Box>

        {/* File Upload Section */}
        <Box 
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            // marginBottom: "20px",
            padding: "16px",
            alignItems: "center",
          }}
        >

          <input
            accept=".csv"
            style={{ display: "none" }}
            id="csv-file-input"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="csv-file-input">
            <Button
              variant="contained"
              color="primary"
              component="span"
              fullWidth
            >
              {csvFile ? csvFile.name : "Choose CSV File"}
            </Button>
          </label>

          {/* Submit Button */}
          <Button
            // fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#F98125", // Default orange color
              "&:hover": {
                backgroundColor: "#e8721f", // Slightly darker orange on hover
              },
            }}
            onClick={handleUpload}
            disabled={!csvFile || props.isUploadingTags}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isUploadingTags, uploadMessage, uploadError } = state.tags;
  return {
    isUploadingTags,
    uploadMessage,
    uploadError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadTags: (orgCode, accountNumber, file) => {
      dispatch(uploadTags({ orgCode, accountNumber, file }));
    },
    clearTagMessages: () => {
      dispatch(clearTagMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadTagsModal);