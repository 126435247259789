import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";

const COLORS = [
  "#2196f3", // Blue
  "#4caf50", // Green
  "#ffeb3b", // Yellow
  "#ff5722", // Deep Orange
  "#f44336", // Red
  "#673ab7", // Deep Purple
  "#00bcd4", // Cyan
  "#e81e63", // Pink
  "#3f51b5", // Indigo
];


const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "5px 10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: payload[0].payload.fill,
            borderRadius: "3px",
            marginRight: "6px",
          }}
        ></div>
        <span
          style={{ fontSize: "12px", color: "#000" }}
        >{`${payload[0].name}`}</span>
        <span style={{ marginLeft: "8px", fontSize: "12px", color: "#000" }}>
          {payload[0].value}
        </span>
      </div>
    );
  }
  return null;
};

const DonutChart = ({ data, totalAssets, onCategoryClick }) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ stroke: "none", fill: "none" }}
        />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={60}
          outerRadius={80}
          paddingAngle={5}
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label
            content={({ viewBox }) => {
              if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy - 10}
                      style={{
                        fontSize: "28px",
                        fontWeight: "600",
                        fill: "#000",
                      }}
                    >
                      {totalAssets}
                    </tspan>
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy + 15}
                      style={{ fontSize: "12px", fill: "#888" }}
                    >
                      Total assets
                    </tspan>
                  </text>
                );
              }
            }}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
