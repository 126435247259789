import { Box, Typography, Grid, Card, CardContent, Chip } from "@mui/material";
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const SubscriptionModal = ({ isOpen, toggle, accountInformation }) => {
  const {
    contract_amount,
    scan_frequency,
    security_posture = scan_frequency,
    compliance = scan_frequency,
    support_response = 24, // Default 24 hours for support response
    threshold_amount,
    usage_percentage,
    expiration_date,
    accountNumber,
    subscription_status,
  } = accountInformation || {};

  const formatHours = (hours) => {
    return hours === 1 ? `Every ${hours} hour` : `Every ${hours} hours`;
  };

  const formatDate = (dateObj) => {
    if (dateObj && dateObj.date) {
      const { year, month, day } = dateObj.date;
      return `${month}/${day}/${year}`;
    }
    return "N/A";
  };

  const determineExpirationStatus = () => {
    if (subscription_status === "expired") {
      return {
        label: `Expired on ${formatDate(expiration_date)}`,
        badgeColor: "#f8d7da", // Red for expired
        textColor: "#721c24", // Dark red text for expired
      };
    } else if (subscription_status === "subscribed") {
      return {
        label: `Expires on ${formatDate(expiration_date)}`,
        badgeColor: "#d4edda", // Green for active subscription
        textColor: "#155724", // Dark green text for active subscription
      };
    } else {
      // Check if the expiration_date is past today's date when subscription_status is null
      const isExpired = () => {
        if (expiration_date && expiration_date.date) {
          const { year, month, day } = expiration_date.date;
          const expirationDate = new Date(year, month - 1, day); // JS months are 0-indexed
          const today = new Date();
          return expirationDate < today;
        }
        return false;
      };

      const expired = isExpired();

      return {
        label: expired
          ? `Expired on ${formatDate(expiration_date)}`
          : `Expires on ${formatDate(expiration_date)}`,
        badgeColor: expired ? "#f8d7da" : "#d4edda", // Red if expired, green if not
        textColor: expired ? "#721c24" : "#155724", // Red if expired, green if not
      };
    }
  };

  // Call the function to determine expiration status
  const {
    label: expirationLabel,
    badgeColor,
    textColor,
  } = determineExpirationStatus();

  const renderCard = (label, value) => (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          borderRadius: "12px",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography variant="body2" fontWeight="bold" color="primary">
            {label}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {value || "N/A"}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Modal isOpen={isOpen} size="lg" centered>
      <ModalHeader toggle={toggle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ fontSize: "16px" }}
          >
            Subscription for Account: {accountNumber}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Chip
              label={expirationLabel}
              sx={{
                backgroundColor: badgeColor,
                color: textColor,
                fontSize: "11px",
                fontWeight: "bold",
                height: "24px",
              }}
            />
          </Box>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {renderCard(
              "Contract Amount",
              contract_amount ? `$${contract_amount}` : "N/A"
            )}
            {renderCard(
              "Monthly Contract Amount",
              threshold_amount ? `$${threshold_amount}` : "N/A"
            )}
            {renderCard(
              "Usage/Overflow Percentage",
              usage_percentage ? `${usage_percentage}%` : "N/A"
            )}
            {renderCard(
              "Scan Frequency",
              scan_frequency ? formatHours(scan_frequency) : "N/A"
            )}
            {renderCard(
              "Security Posture",
              security_posture ? formatHours(security_posture) : "N/A"
            )}
            {renderCard(
              "Compliance",
              compliance ? formatHours(compliance) : "N/A"
            )}
            {renderCard("Support Response", formatHours(support_response))}
          </Grid>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default SubscriptionModal;
