import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const ConfirmationDialog = ({ open, message, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <button onClick={onCancel} 
          className="btn btn-primary"
        >
          Cancel
        </button>
        <button 
          onClick={onConfirm} 
          className="btn btn-secondary"
        >
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;