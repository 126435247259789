import React, { Component, useRef } from "react";

import { Alert } from "@material-ui/lab";

import {
  Grid,
  Typography,
  TextField,
  Button,
  // Link,
  Box,
  Snackbar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { connect, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  forgetUser,
  clearStore,
  resetPasswordForgot,
} from "../../store/actions";
// import logodark from "../../assets/images/logo-main.jpg";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { OutlinedInput } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { BackgroundPattern } from "../../components/BackgroundPattern";
import EncryptPassword from "../../components/EncryptPassword";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    animation: "$animateBackground 10s infinite",
  },
  "@keyframes animateBackground": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0)",
  },
  container: {
    position: "relative",
    zIndex: 1,
    justifyContent: "space-evenly",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const PasswordResetPage = (props) => {
  console.log("props: ", props);
  const { classes } = props;
  const location = useLocation();
  const { state } = location;
  let email;
  if (state && state.email) {
    email = state.email;
    // Do something with the passed email value
    // console.log("Received Email:", email);
  }
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const verificationCodeRefs = useRef([]);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordError1, setPasswordError1] = useState(false);
  const [passwordErrorBoth, setPasswordErrorBoth] = useState(false);
  const [detailedErr, setDetailedErr] = useState("");
  const [submit, setSubmit] = useState(false);
  const [verificationCodeEntered, setVerificationCodeEntered] = useState(false);
  const reset = useSelector((state) => {
    return state.forgetPassword;
  });
  var success = false;
  const { loading, resetSuccess, resetError } = reset;
  
  const regex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]{8,}$/;
  const dispatch = useDispatch();

  //reset error in here

  // function handleSubmit() {
  //   //check equal and not empty and whatever other requirements
  // }

  // function handleChangePassword(){
  //     setPassword1(password)
  // }
  function handleClickShowPassword1() {
    setShowPassword1(!showPassword1);
  }
  function handleClickShowPassword2() {
    setShowPassword2(!showPassword2);
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDigitChange = (index, event) => {
    const { value } = event.target;

    if (value.length <= 1) {
      const updatedVerificationCode = [...verificationCode];
      updatedVerificationCode[index] = value;
      setVerificationCode(updatedVerificationCode);

      if (
        value.length === 1 &&
        index < verificationCodeRefs.current.length - 1
      ) {
        verificationCodeRefs.current[index + 1].focus();
      }
    }
  };
  useEffect(() => {
    setSubmit(false);
    if (password1 != "") {
      if (!regex.test(password1)) {
        setPasswordError1(true);
        setDetailedErr(
          "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
        );
      } else if (password1 != password2) {
        setPasswordError1(true);
        setDetailedErr("Passwords don't match!");
      } else {
        setPasswordError1(false);
      }
    }
  }, [password1]);

  useEffect(() => {
    setSubmit(false);
    if (password2 != "") {
      if (password1 != password2) {
        setPasswordError1(true);
        setDetailedErr("Passwords don't match!");
      } else {
        setPasswordError1(false);
        setSubmit(true);
      }
    }
  }, [password2]);

  useEffect(() => {
    // console.log("verificationCode=", verificationCode);
    const isEntered = verificationCode.every((digit) => digit.trim() !== "");
    // console.log("isEntered: " + isEntered);
    setVerificationCodeEntered(isEntered);
  }, [verificationCode]);

  // function handleSubmit() {
  //   dispatch(resetPasswordForgot({ password: password1, token: token.token }));
  // }
  async function handleSubmit() {
    const encryptedPassword = await EncryptPassword(password1);
    const newUser = {
      password: encryptedPassword,
      verificationcode: JSON.stringify(verificationCode).replace(/[^0-9]/g, ''),
      username: email,
    };
    console.log("New User :: ", newUser);
    console.log(
      "Handle Submit :: ",
      password1,
      "verification :: ",
      verificationCode,
      "username :: ",
      email
    );
    dispatch(resetPasswordForgot(newUser));
  }
  useEffect(() => {
    if (resetSuccess) {
      success = true;
      clearStore();
    } else {
      success = false;
    }
  }, [resetSuccess]);

  useEffect(() => {
    setPassword1("");
    setPassword2("");
    setDetailedErr(resetError);
    console.log("reseterr", resetError);
  }, [resetError]);

  if (resetSuccess) {
    return (
      <div className={`${classes.root}`}>
        <div className={`${classes.background}`}></div>
        <Grid container component="main" className={classes.container}>
          <Grid item xs={12} sm={8} md={5} lg={4}>
            <div className={classes.logo}>
              <Link to="/" className="logo">
                <img src={logodark} height="60" alt="logo" />
              </Link>
            </div>

            <Snackbar open={true}>
              <Alert severity="success">Password Changed Successfully!</Alert>
            </Snackbar>

            <Box mt={2} textAlign="center">
              <Typography variant="body2">
                Return to{" "}
                <Link to="/login" color="primary">
                  Login Page
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <BackgroundPattern />
      </div>
    );
  } else {
    return (
      <div className={`${classes.root}`}>
        <div className={`${classes.background}`}></div>
        <Grid container component="main" className={classes.container}>
          <Grid item xs={12} sm={8} md={5} lg={4}>
            <div className={classes.form}>
              <div className={classes.logo}>
                <Link to="/" className="logo">
                  <img src={logodark} height="60" alt="logo" />
                </Link>
              </div>
              <Typography component="p" align="center">
                Reset your password to CloudCatcher!
              </Typography>
              <Typography component="p" align="center">
                Enter verification code that you received on your email account!
              </Typography>

              <AvForm
              // onValidSubmit={this.handleValidSubmit}
              >
                <div className="verification-form">
                  {[0, 1, 2, 3, 4, 5].map((index) => (
                    <TextField
                      key={index}
                      type="number"
                      inputRef={(ref) =>
                        (verificationCodeRefs.current[index] = ref)
                      }
                      // label={`Digit ${index + 1}`}
                      variant="outlined"
                      inputProps={{
                        maxLength: 1,
                        className: "verification-digit-input",
                      }}
                      value={verificationCode[index]}
                      onChange={(event) => handleDigitChange(index, event)}
                    />
                  ))}
                </div>
                <Box mt={4} textAlign="center">
                  <TextField
                    label="Enter password"
                    variant="outlined"
                    type={showPassword1 ? "text" : "password"}
                    onChange={(e) => setPassword1(e.target.value)}
                    value={password1}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box mt={4} textAlign="center">
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type={showPassword2 ? "text" : "password"}
                    onChange={(e) => setPassword2(e.target.value)}
                    value={password2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <div>
                  {passwordError1 ? (
                    <Alert severity="error" className="mt-4">
                      {detailedErr}
                      {/* ,
                              {this.props.handleError()} */}
                    </Alert>
                  ) : null}
                  {resetError ? (
                    <Alert severity="error" className="mt-4">
                      {resetError}
                    </Alert>
                  ) : null}
                </div>
                <Box mt={2} textAlign="center">
                  <p>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => handleSubmit()}
                      disabled={!verificationCodeEntered || !submit}
                    >
                      Reset Password!
                    </Button>
                  </p>
                </Box>
              </AvForm>
              <Box mt={2} textAlign="center">
                <Typography variant="body2">
                  Return to{" "}
                  <Link to="/login" color="primary">
                    Login Page
                  </Link>
                </Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
        <BackgroundPattern />
      </div>
    );
  }
};

export default withStyles(styles)(PasswordResetPage);
