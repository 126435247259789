import React, { useState, useEffect, useRef } from "react";
import { Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Input, FormGroup } from "reactstrap";

import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import jwt_decode from "jwt-decode";

import { Box, Tooltip } from "@material-ui/core";

import { connect } from "react-redux";

import { v4 as uuidv4 } from "uuid"; // for uuid version 4

import loadingGif from "./../../../assets/images/aws/loadingGif.gif";
import Terraform from "./../../../assets/images/iaac/terraform.png";
import Kubernetes from "./../../../assets/images/iaac/kubernetes.png";
import Docker from "./../../../assets/images/iaac/docker.png";
import CloudFormation from "./../../../assets/images/iaac/cloudformation.png";
import Ansible from "./../../../assets/images/iaac/ansible.png";
import OpenAPI from "./../../../assets/images/iaac/openAI.png";
import Helm from "./../../../assets/images/iaac/helm.png";
import gRPC from "./../../../assets/images/iaac/grpc.png";
import AWS from "./../../../assets/images/iaac/aws.png";
import Crossplane from "./../../../assets/images/iaac/crossplane.png";
import Pulumi from "./../../../assets/images/iaac/pulumi.png";
import Serverless from "./../../../assets/images/iaac/serverless.png";
import GDM from "./../../../assets/images/iaac/google-deployment-manager.png";
import ARM from "./../../../assets/images/iaac/azure-resource-manager.png";
import SAM from "./../../../assets/images/iaac/sam.png";
import DockerCompose from "./../../../assets/images/iaac/docker-compose.png";
import knative from "./../../../assets/images/iaac/knative.png";
import AzureBlueprints from "./../../../assets/images/iaac/azure_blurprints.png";
import cicd from "./../../../assets/images/iaac/cicd.png";
import openTofu from "./../../../assets/images/iaac/openTofu.png";
import Bicep from "./../../../assets/images/iaac/bicep.png";

import styles from "./style.module.css";

import { SnackbarProvider, enqueueSnackbar } from "notistack";

import {
  clearAllIaacMessages,
  getUploadFileUrlRequest,
  sendFileCommitRequest,
  uploadFileRequest,
} from "../../../store/actions";
import { TextField } from "@mui/material";
import IaaCScanner from "./IaaCScanner";
import AlertDialog from "./AlertDialog";

const MAX_FILE_SIZE = 1024 * 1024;

const ALLOWED_FILE_EXTENSIONS = [
  '.json', '.yaml', '.yml', '.dockerfile', 
  '.cfg', '.config', '.ini', '.proto', 
  '.bicep', '.tf', '.zip', '.tar.gz', 
  '.tgz', '.tar.bz2', '.tbz2', '.tar.xz', '.txz'
];

const FileUpload = (props) => {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [webSocketFileName, setWebSocketFileName] = useState(null);
  const [webSockConnectionStatus, setWebSockConnectionStatus] = useState(null);
  const [uploadDone, setUploadDone] = useState(false);
  // const { orgcode, username } = props;
  const { orgcode } = props;
  const { scanStatus, setScanStatus } = props;
  const [username, setUsername] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileSizeLimitError, setFileSizeLimitError] = useState(false);
  const [inputFileContent, setInputFileContent] = useState("");
  const [showCommitFields, setShowCommitFields] = useState(false);
  const [showCommitTextFields, setShowCommitTextFields] = useState(true);
  // State for commit fields
  const [author, setAuthor] = useState("NA");
  const [projectName, setProjectName] = useState("");
  const [branch, setBranch] = useState("");
  const [version, setVersion] = useState("");
  const [comment, setComment] = useState("");
  const [uuid, setUuid] = useState(null);
  const [defaultFieldValues, setDefaultFieldValues] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  console.log("uploadUrl = ", props.uploadUrl);

  const clearState = () => {
    setSelectedFileName(null);
    setSelectedFile(null);
    setUploadDone(false);
    setInputFileContent("");
    setShowCommitFields(false);
    setAuthor("NA");
    setProjectName("");
    setBranch("");
    setVersion("");
    setComment("");
    setDefaultFieldValues(false);
    props.clearAllIaacMessages();
    if (fileInputRef) {
      fileInputRef.current.value = "";
    }
  };
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const getWebSocketFileName = (uploadedFileName, uuid) => {
    const lastDotIndex = uploadedFileName.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return `${uploadedFileName}&${orgcode}&${username}&${uuid}`;
    }
    const fileNameWithoutExtension = uploadedFileName.slice(0, lastDotIndex);
    const fileExtension = uploadedFileName.slice(lastDotIndex + 1);
    const tempWebSocketFilename = `${fileNameWithoutExtension}&${orgcode}&${username}&${uuid}.${fileExtension}`;

    return tempWebSocketFilename;
  };

  const handleInputFileChange = (event) => {
    console.log("File selected");
    if (event.target.files.length <= 0) return;
    const uploadedFile = event.target.files[0];

    const lastDotIndex = uploadedFile.name.lastIndexOf(".");
    if (lastDotIndex === -1) {
      // enqueueSnackbar("Please choose a supported file format: "+ALLOWED_FILE_EXTENSIONS.join(", "), { variant: "error" });
      setAlertMessage("Please choose a supported file format: "+ALLOWED_FILE_EXTENSIONS.join(", "));
      setAlertOpen(true);
      return;
    }
    const fileExtension = uploadedFile.name.slice(lastDotIndex);
    if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
      // enqueueSnackbar("Please choose a supported file format: "+ALLOWED_FILE_EXTENSIONS.join(", "), { variant: "error" });
      setAlertMessage("Please choose a supported file format: "+ALLOWED_FILE_EXTENSIONS.join(", "));
      setAlertOpen(true);
      return;
    }
    

    // Check if the file name contains the "&" symbol
    if (uploadedFile.name.includes("&")) {
      // enqueueSnackbar("File name contains '&' symbol. Please choose a file with a different name.", { variant: "error" });
      setAlertMessage("File name contains '&' symbol. Please choose a file with a different name.");
      setAlertOpen(true);
      event.target.value = ""; // Clear the input field
      return; // Exit the function
    }

    // Check if the file size is larger than 1MB
    if (uploadedFile.size > MAX_FILE_SIZE) {
      // enqueueSnackbar("File size exceeds 1MB. Please choose a smaller file.", { variant: "error" });
      setAlertMessage("File size exceeds 1MB. Please choose a smaller file.");
      setAlertOpen(true);
      setFileSizeLimitError(true);
      event.target.value = "";
      return;
    }

    setFileSizeLimitError(false);
    setSelectedFile(uploadedFile);
    setSelectedFileName(uploadedFile.name);
    // const reader = new FileReader();

    // reader.onload = (e) => {
    //   const fileContent = e.target.result;
    //   console.log("fileContent = ", fileContent);
    //   setInputFileContent(fileContent);
    // };

    // // Store the file content
    // reader.readAsText(uploadedFile);

    // // Get file upload URL
    // // const fileName = "testfile.yaml";
    const newUuid = uuidv4();
    setUuid(newUuid);
    console.log("uploadedFile.name = ", uploadedFile.name);
    console.log("uuid = ", uuid);
    const tempWebSocketFilename = getWebSocketFileName(
      uploadedFile.name,
      newUuid
    );
    setWebSocketFileName(tempWebSocketFilename);

    console.log("tempWebSocketFilename: ", tempWebSocketFilename);
    console.log("uploadedFile.name: ", uploadedFile.name);

    props.getUploadFileUrl(uploadedFile.name, orgcode, username, newUuid);
  };

  const handleUpload = async () => {
    console.log("handleUpload called");
    console.log("Making file commit API call");
    // setShowCommitFields(true);
    props.sendFileCommit(
      orgcode,
      selectedFileName,
      username,
      author,
      projectName,
      branch,
      version,
      comment,
      uuid
    );
  };

  const handleNewUploadClick = () => {
    console.log("New upload clicked");
    clearState();
  };

  const handleInputChange = (e) => {
    if (e.target.name === "projectName") {
      setProjectName(e.target.value);
    } else if (e.target.name === "branch") {
      setBranch(e.target.value);
    } else if (e.target.name === "version") {
      setVersion(e.target.value);
    } else if (e.target.name === "comment") {
      setComment(e.target.value);
    } else if (e.target.name === "defaultFieldValues") {
      const checked = e.target.checked;
      setDefaultFieldValues(checked);
      setDefaultValuesCommentFields(checked);
      if (checked) {
      }
    }
  };
  const setDefaultValuesCommentFields = (checked) => {
    if (checked) {
      setAuthor("NA");
      setProjectName("NA");
      setBranch("NA");
      setVersion("NA");
      setComment("NA");
    } else {
      setAuthor("NA");
      setProjectName("");
      setBranch("");
      setVersion("");
      setComment("");
    }
  };

  const isCommentFieldsInvalid = () => {
    const isInvalidItems = [
      {
        field: "author",
        isInvalid: author.length === 0 ? true : false,
      },
      {
        field: "projectName",
        isInvalid: projectName.length === 0 ? true : false,
      },
      {
        field: "branch",
        isInvalid: branch.length === 0 ? true : false,
      },
      {
        field: "version",
        isInvalid: version.length === 0 ? true : false,
      },
      {
        field: "comment",
        isInvalid: comment.length === 0 ? true : false,
      },
    ];

    return isInvalidItems.filter((item) => item.isInvalid).length > 0;
  };

  // get username from local storage
  useEffect(() => {
    let usernameFromLocalStorage;
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const authUser = jwt_decode(obj.idToken);
      usernameFromLocalStorage = authUser.email;
      console.log("usernameFromLocalStorage=", usernameFromLocalStorage);
    }
    if (!username) {
      if (usernameFromLocalStorage) {
        setUsername(usernameFromLocalStorage);
      }
    }
  }, []);

  const uploadUrl = props.uploadUrl;
  useEffect(() => {
    if (uploadUrl) {
      console.log("Received uploadUrl = ", uploadUrl);
      setShowCommitFields(true);
    }
  }, [uploadUrl]);

  const fileCommitMessageProps = props.fileCommitMessage;
  useEffect(() => {
    if (fileCommitMessageProps) {
      console.log("Received fileCommitMessageProps = ", fileCommitMessageProps);
      console.log("inputFileContent=", inputFileContent);
      // if (inputFileContent) {
      //   console.log("Making file upload API call");
      //   props.uploadFile(inputFileContent, props.uploadUrl?.uploadUrl);
      // }
      props.uploadFile(selectedFile, props.uploadUrl?.uploadUrl);
    }
  }, [fileCommitMessageProps]);

  //For displaying getting upload URL success/error messages for short duration
  const uploadFileUrlMessage = props.getUploadFileUrlMessage;
  const uploadFileUrlError = props.getUploadFileUrlError;
  useEffect(() => {
    if (uploadFileUrlMessage || uploadFileUrlError) {
      setTimeout(() => {
        console.log("About to call clearAllUserListMessages");
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
    if (uploadFileUrlMessage) {
      enqueueSnackbar(uploadFileUrlMessage, { variant: "success" });
    }
    if (uploadFileUrlError) {
      enqueueSnackbar(uploadFileUrlError, { variant: "error" });
    }
  }, [uploadFileUrlMessage, uploadFileUrlError]);

  //For displaying file upload and commit success/error messages for short duration
  const fileUploadMessageProps = props.fileUploadMessage;
  const fileUploadErrorProps = props.fileUploadError;
  useEffect(() => {
    if (fileUploadMessageProps || fileUploadErrorProps) {
      setTimeout(() => {
        console.log("About to call clearAllUserListMessages");
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
      if (fileUploadMessageProps)
        enqueueSnackbar(fileUploadMessageProps, { variant: "success" });
        setUploadDone(true);
      if (fileUploadErrorProps)
        enqueueSnackbar(fileUploadErrorProps, { variant: "error" });
    }
  }, [fileUploadMessageProps, fileUploadErrorProps]);

  const fileCommitMessage = props.fileCommitMessage;
  const fileCommitError = props.fileCommitError;
  useEffect(() => {
    if (fileCommitMessage || fileCommitError) {
      setTimeout(() => {
        console.log("About to call clearAllUserListMessages");
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
      if (fileCommitMessage)
        enqueueSnackbar(fileCommitMessage, { variant: "success" });
      if (fileCommitError)
        enqueueSnackbar(fileCommitError, { variant: "error" });
    }
  }, [fileCommitMessage, fileCommitError]);

  // To hide commit components when this component is used via modal
  useEffect(() => {
    setShowCommitFields(false);
  }, []);

  console.log("props = ", props);

  return (
    <div className={styles.fileUploadContainer}>
      <SnackbarProvider />
      <div className={styles.fileUploadCard}>
        <div>
          {/* <div>File Upload</div> */}
          <div className={styles.addIcon} title="Create new file upload">
            <AddIcon onClick={handleNewUploadClick} />
          </div>
        </div>

        <div className={styles.fileUploadSteps}>
          <CloudUploadIcon style={{ color: "#F98125", fontSize: "60px" }} />
          {!fileSizeLimitError ? (
            <p>Maximum upload file size limit is 1 MB!</p>
          ) : (
            <p className={styles.errorInfo}>
              Please select a file with size less than 1MB!
            </p>
          )}

          {/* <div> */}
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleInputFileChange}
            // accept=".json, .yaml, .yml, .tf, .proto, cfg, .conf, .ini, .bicep, .zip, .tar, .tar.gz, .rar, *.*"
            accept={ALLOWED_FILE_EXTENSIONS.join(', ')}
          />
          <Button
            onClick={handleFileInputClick}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F98125",
              borderColor: "#fff",
            }}
            disabled={
              props.isUploadFileUrlGetting ||
              props.isFileUploading ||
              props.isFileCommitting
            }
            // fullWidth
          >
            {props.isUploadFileUrlGetting && (
              <img src={loadingGif} height={20} alt="Loading Spinner" />
            )}{" "}
            {selectedFileName ? (
              <label htmlFor="fileInput" style={{ marginBottom: "0rem" }}>
                {selectedFileName}
              </label>
            ) : (
              "Select File"
            )}
          </Button>
          {/* </div> */}
          {showCommitFields && (
            <AvForm className="form-horizontal">
              <Row>
                <Col lg="12" className={styles.commentFieldsHeader}>
                  <FormGroup>
                    <AvField
                      name="defaultFieldValues"
                      value={defaultFieldValues}
                      type="checkbox"
                      onChange={handleInputChange}
                      label="Use default values for below comment fields"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className={showCommitTextFields ? styles.show : styles.hide}>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <Box>
                        <TextField
                          name="projectName"
                          margin="dense"
                          variant="outlined"
                          label="Project Name"
                          value={projectName}
                          placeholder="Project Name*"
                          type="text"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          required
                          error={projectName.length === 0 ? true : false}
                          helperText={
                            projectName.length === 0
                              ? "Project Name is required"
                              : ""
                          }
                          disabled={defaultFieldValues}
                        />
                      </Box>
                    </FormGroup>
                  </Col>
                  {/* </Row>
              <Row> */}
                  <Col lg="4">
                    <FormGroup>
                      <TextField
                        name="branch"
                        margin="dense"
                        variant="outlined"
                        label="Branch"
                        value={branch}
                        placeholder="Branch*"
                        type="text"
                        size="small"
                        fullWidth
                        onChange={handleInputChange}
                        required
                        error={branch.length === 0 ? true : false}
                        helperText={
                          branch.length === 0 ? "Branch Name is required" : ""
                        }
                        disabled={defaultFieldValues}
                      />
                    </FormGroup>
                  </Col>
                  {/* </Row>
              <Row> */}
                  <Col lg="4">
                    <FormGroup>
                      <TextField
                        name="version"
                        margin="dense"
                        variant="outlined"
                        label="Version"
                        value={version}
                        placeholder="Version*"
                        type="text"
                        size="small"
                        fullWidth
                        onChange={handleInputChange}
                        required
                        error={version.length === 0 ? true : false}
                        helperText={
                          version.length === 0 ? "Version is required" : ""
                        }
                        disabled={defaultFieldValues}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <TextField
                        name="comment"
                        margin="dense"
                        variant="outlined"
                        label="Comment"
                        value={comment}
                        size="small"
                        fullWidth
                        placeholder="Comment*"
                        type="text"
                        onChange={handleInputChange}
                        required
                        error={comment.length === 0 ? true : false}
                        helperText={
                          comment.length === 0 ? "Comment is required" : ""
                        }
                        disabled={defaultFieldValues}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </AvForm>
          )}

          <Button
            onClick={handleUpload}
            // disabled={!props.uploadUrl}
            disabled={
              isCommentFieldsInvalid() ||
              props.isFileUploading ||
              props.isFileCommitting ||
              uploadDone ||
              fileSizeLimitError
            }
            style={{
              textAlign: "center",
              backgroundColor: "#F98125",
              width: "50%",
              // float: "right",
              // borderColor: "#fff",
              // width: "200px"
            }}
          >
            {props.isFileUploading && (
              <img src={loadingGif} height={20} alt="Loading Spinner" />
            )}
            Upload
          </Button>
        </div>

        {!selectedFileName && (
          <div className={styles.note}>
            <p>
              Note: For projects containing multiple template files, please
              compress them to one of the below formats and upload the file:
            </p>
            <ul>
              <li>zip</li>
              <li>tar.gz</li>
              <li>tgz</li>
              <li>tar.bz2</li>
              <li>tbz2</li>
              <li>tar.xz</li>
              <li>txz</li>
            </ul>
          </div>
        )}
      </div>
      <div className={styles.iaacScannerCard}>
        {uploadUrl && selectedFileName ? (
          <IaaCScanner
            fileName={webSocketFileName}
            handleModalOpen={props.handleModalOpen}
            webSockConnectionStatus={webSockConnectionStatus}
            setWebSockConnectionStatus={setWebSockConnectionStatus}
            scanStatus={scanStatus}
            setScanStatus={setScanStatus}
          />
        ) : (
          <SupportedPlatforms />
        )}
      </div>
      <AlertDialog open={alertOpen} setOpen={setAlertOpen} message={alertMessage}/>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    isUploadFileUrlGetting,
    getUploadFileUrlMessage,
    getUploadFileUrlError,
    uploadUrl,

    isFileUploading,
    fileUploadMessage,
    fileUploadError,

    isFileCommitting,
    fileCommitMessage,
    fileCommitError,
  } = state.iaac;

  return {
    isUploadFileUrlGetting,
    getUploadFileUrlMessage,
    getUploadFileUrlError,
    uploadUrl,

    isFileUploading,
    fileUploadMessage,
    fileUploadError,

    isFileCommitting,
    fileCommitMessage,
    fileCommitError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUploadFileUrl: (fileName, orgcode, username, uuid) => {
      dispatch(getUploadFileUrlRequest(fileName, orgcode, username, uuid));
    },
    uploadFile: (fileData, uploadUrl) => {
      dispatch(uploadFileRequest(fileData, uploadUrl));
    },
    sendFileCommit: (
      orgcode,
      filename,
      username,
      author,
      projectName,
      branch,
      version,
      comment,
      uuid
    ) => {
      dispatch(
        sendFileCommitRequest(
          orgcode,
          filename,
          username,
          author,
          projectName,
          branch,
          version,
          comment,
          uuid
        )
      );
    },
    clearAllIaacMessages: () => {
      dispatch(clearAllIaacMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);

const SupportedPlatforms = () => {
  return (
    <div className={styles.supportedPlatformsContainer}>
      <h3 className={styles.header}>Supported Platforms</h3>
      <div className={styles.logosGrid}>
        <Tooltip
          title="CloudCatcher supports scanning Terraform's HCL files with .tf extension and input variables using terraform.tfvars or files with .auto.tfvars extension that are in the same directory of .tf files."
          arrow
        >
          <img src={Terraform} alt="Terraform" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Kubernetes manifests with .yaml extension."
          arrow
        >
          <img src={Kubernetes} alt="Kubernetes" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Docker files with any name (but with no extension) and files with .dockerfile extension."
          arrow
        >
          <img src={Docker} alt="Docker" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher currently supports scanning AWS CloudFormation templates."
          arrow
        >
          <img
            src={CloudFormation}
            alt="AWS CloudFormation"
            className={styles.logo}
          />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Ansible files with .yaml extension."
          arrow
        >
          <img src={Ansible} alt="Ansible" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Swagger 2.0 and OpenAPI 3.0 specs with .json and .yaml extension."
          arrow
        >
          <img src={OpenAPI} alt="OpenAPI" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Helm by rendering charts and running Kubernetes queries against the rendered manifest."
          arrow
        >
          <img src={Helm} alt="Helm" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning gRPC files with .proto extension."
          arrow
        >
          <img src={gRPC} alt="gRPC" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning CloudFormation templates with .json or .yaml extension."
          arrow
        >
          <img src={AWS} alt="Cloud Development Kit" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Crossplane manifests with .yaml extension."
          arrow
        >
          <img src={Crossplane} alt="Crossplane" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Pulumi manifests with .yaml extension."
          arrow
        >
          <img src={Pulumi} alt="Pulumi" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Serverless manifests with .yml extension."
          arrow
        >
          <img src={Serverless} alt="Serverless" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Google Deployment Manager files with .yaml extension."
          arrow
        >
          <img
            src={GDM}
            alt="Google Development Manager"
            className={styles.logo}
          />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Azure Resource Manager (ARM) templates with .json extension."
          arrow
        >
          <img src={ARM} alt="Azure Resource Manager" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports AWS Serverless Application Model (AWS SAM) files with .yaml extension."
          arrow
        >
          <img src={SAM} alt="SAM" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning DockerCompose files with .yaml extension."
          arrow
        >
          <img
            src={DockerCompose}
            alt="Docker Compose"
            className={styles.logo}
          />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Knative manifests with .yaml extension."
          arrow
        >
          <img src={knative} alt="knative" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Azure Blueprints files, including Azure Blueprints Policy Assignment Artifacts, Azure Blueprints Role Assignment Artifacts, and Azure Blueprints Template Artifacts with .json extension."
          arrow
        >
          <img
            src={AzureBlueprints}
            alt="Azure Blueprints"
            className={styles.logo}
          />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Github Workflows CI/CD files with .yaml or .yml extension."
          arrow
        >
          <img src={cicd} alt="CI CD" className={styles.logo} />
        </Tooltip>
        <Tooltip title="CloudCatcher supports scanning OpenTofu files." arrow>
          <img src={openTofu} alt="OpenTofu" className={styles.logo} />
        </Tooltip>
        <Tooltip
          title="CloudCatcher supports scanning Bicep files with .bicep extension."
          arrow
        >
          <img src={Bicep} alt="Bicep" className={styles.logo} />
        </Tooltip>
      </div>
    </div>
  );
};

export  { SupportedPlatforms };