import { fork, put, all, call, takeLatest, delay } from "redux-saga/effects";

import {
  // addFulfillment as addFulfillmentAction,
  addFulfillmentSuccessful,
  addFulfillmentFailure,
  awsOnboardSuccessful,
  awsOnboardFailure,
  clearAllAwsMarketplaceMessagesSuccessful,
} from "./awsMarketplaceSlice";

import {
  addFulfillment as addFulfillmentApi,
  awsOnboard as awsOnboardApi,
} from "../../helpers/auth_aws_helper";

function* addFulfillment({ payload: fulfillmentPayload }) {
  try {
    console.log("about to call *addFulfillment() with ", fulfillmentPayload);
    const response = yield call(addFulfillmentApi, fulfillmentPayload);
    console.log("*addFulfillmentApi() response: ", response);

    if (response && response.status === 200) {
      // delete response.status;
      // const responseString = Object.values(response)
      //           .filter((value) => typeof value === "string")
      //           .join("");
      yield put(addFulfillmentSuccessful(response.data));
    } else {
      yield put(addFulfillmentFailure({ message: response.data }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        addFulfillmentFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(addFulfillmentFailure({ message: "No response received" }));
    } else {
      yield put(addFulfillmentFailure({ message: error?.message }));
    }
  }
}

function* awsOnboard({ payload: awsOnboardingPayload }) {
  try {
    console.log("about to call *awsOnboard() with ", awsOnboardingPayload);
    const response = yield call(awsOnboardApi, awsOnboardingPayload);
    console.log("*awsOnboardApi() response: ", response);

    if (response && response.status === 200) {
      // delete response.status;
      // const responseString = Object.values(response)
      //           .filter((value) => typeof value === "string")
      //           .join("");
      yield put(awsOnboardSuccessful(response.data));
    } else {
      yield put(awsOnboardFailure({ message: response.data }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        awsOnboardFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(awsOnboardFailure({ message: "No response received" }));
    } else {
      yield put(awsOnboardFailure({ message: error?.message }));
    }
  }
}

function* clearAllAwsMarketplaceMessages() {
  console.log("Saga: clear All AwsMarketplace Messages");
  yield put(clearAllAwsMarketplaceMessagesSuccessful());
}

export function* watchAwsMarketplace() {
  yield takeLatest("awsMarketplace/addFulfillment", addFulfillment);
  yield takeLatest("awsMarketplace/awsOnboard", awsOnboard);
  yield takeLatest(
    "awsMarketplace/clearAllAwsMarketplaceMessages",
    clearAllAwsMarketplaceMessages
  );
}

export function* awsMarketplaceSaga() {
  yield all([fork(watchAwsMarketplace)]);
}

export default awsMarketplaceSaga;
