import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Table, Input, Label, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { getListOfAccts } from "./../../store/actions";


class ViewAccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOpen: false,
      userModal: false,
      userList: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.accounts !== undefined) {
      return {
        userList: nextProps.userList ? nextProps.userList:[],
      };
    }
  }

  onNewUserCreated = (newUser) => {
    this.setState({ userList: [...this.state.userList, newUser] });
  };

  componentDidMount() {
    this.props.getListOfAccts();
  }

  closeUserModal = () => this.setState({ userModal: false });

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal_static}
          backdrop="static"
          centered
          size="lg"
        >
          <ModalHeader toggle={this.props.closeModal}>
            List of Registered User for Account Number: {this.props?.accountNumber}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <div
                  className="table-responsive mt-3"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <Table
                    className="table-centered datatable dt-responsive nowrap"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead  style={{ width: "20px", backgroundColor:'#a9a9a9' }}>
                      <tr>
                        <th style={{ width: "20px"}}>
                        </th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.userList
                        .filter(user => !user.email.includes(process.env.REACT_APP_EMAIL_DOMAIN))
                        .map((user,index) => (
                          <tr key={index}>
                            <td>
                            </td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.userName}</td>
                            <td>{user.email}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <ModalFooter>
              <Button
                type="button"
                color="light"
                onClick={this.props.closeModal}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { accounts } = state.acctList;
  return { accounts };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListOfAccts: () => {
      dispatch(getListOfAccts());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(ViewAccountList);
