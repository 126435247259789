import {
	GET_LIST_ALL_RULES,
	GET_LIST_ALL_RULES_SUCCESSFUL,
	GET_LIST_ALL_RULES_FAILED,
	GET_LIST_ACC_RULES,
	GET_LIST_ACC_RULES_SUCCESSFUL,
	GET_LIST_ACC_RULES_FAILED,
	PUT_EDIT_RULES,
	PUT_EDIT_RULES_FAILED,
	PUT_EDIT_RULES_SUCCESSFUL,
	SET_LIST_ACC_RULES_STATE,
} from './actionTypes';

const initialState = {
  error: null, message: null, loading: null, rulez: [], rulezz:[], accts:null
}
const ruleList = (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_ALL_RULES:
			state = {
				...state,
				loading: true,
				error: null
			}
			break;
		case GET_LIST_ALL_RULES_SUCCESSFUL:
			state = {
				...state,
				 //rules: action.payload.rulesDTOS,
				rulez: action.payload.rules.rulesDTOS,
				loading: false,
				error: null
			}
			break;
		case GET_LIST_ALL_RULES_FAILED:
			state = {
				...state,
				loading: false,
				error: action.payload.rulesDTOS
			}
			break;
		case GET_LIST_ACC_RULES:
			state = {
				...state,
				loading: true,
				error: null,
			}
			break;
		case GET_LIST_ACC_RULES_SUCCESSFUL:
			state = {
				...state,
				//rules: action.payload.rulesDTOS,
				rulezz: action.payload.rules.ruleAccResponseDTOS,
				loading: false,
				error: null
			}
			break;
		case GET_LIST_ACC_RULES_FAILED:
			state = {
				...state,
				loading: false,
				error: action.payload.rulesAccResponseDTOS
			}
			break;
		case PUT_EDIT_RULES:
			//console.log("put edit");
			state = {
				...state,
				// accts:action.payload,
				loading: true,
				error: null
			}
			break;
		case PUT_EDIT_RULES_SUCCESSFUL:
			state = {
				...state,
				//maybe also try state change here
				accts: action.payload,
				loading: false,
				error: null
			}
			break;
		case PUT_EDIT_RULES_FAILED:
			//console.log("put edit fail");
			state = {
				...state,
				loading: false,
				error: action.payload.rulesAccResponseDTOS
			}
			break;
		case SET_LIST_ACC_RULES_STATE:
			//console.log('--== Update API Status ==--', action.payload);
			state = {
				...state,
				state: action.payload
			}
			break;
		default:
			return {...state};
	}
	return {...state};
}

export default ruleList;