import React from 'react'

export const BackgroundPattern = () => {
  return (
    <div>
      <svg
        id="wave"
        style={{ transform: "rotate(180deg)", transition: "0.3s" }}
        viewBox="0 0 1440 490"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="background-pattern1"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
            <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 0px)", opacity: 1 }}
          fill="url(#sw-gradient-0)"
          d="M0,441L60,416.5C120,392,240,343,360,302.2C480,261,600,229,720,196C840,163,960,131,1080,163.3C1200,196,1320,294,1440,334.8C1560,376,1680,359,1800,326.7C1920,294,2040,245,2160,245C2280,245,2400,294,2520,269.5C2640,245,2760,147,2880,98C3000,49,3120,49,3240,89.8C3360,131,3480,212,3600,253.2C3720,294,3840,294,3960,318.5C4080,343,4200,392,4320,343C4440,294,4560,147,4680,81.7C4800,16,4920,33,5040,32.7C5160,33,5280,16,5400,32.7C5520,49,5640,98,5760,138.8C5880,180,6000,212,6120,220.5C6240,229,6360,212,6480,179.7C6600,147,6720,98,6840,65.3C6960,33,7080,16,7200,32.7C7320,49,7440,98,7560,122.5C7680,147,7800,147,7920,155.2C8040,163,8160,180,8280,187.8C8400,196,8520,196,8580,196L8640,196L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
        ></path>
        {/* <defs>
        <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
          <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style={{ transform: "translate(0, 50px)", opacity: 0.9 }}
        fill="url(#sw-gradient-1)"
        d="M0,196L60,163.3C120,131,240,65,360,40.8C480,16,600,33,720,49C840,65,960,82,1080,73.5C1200,65,1320,33,1440,49C1560,65,1680,131,1800,147C1920,163,2040,131,2160,147C2280,163,2400,229,2520,277.7C2640,327,2760,359,2880,326.7C3000,294,3120,196,3240,196C3360,196,3480,294,3600,294C3720,294,3840,196,3960,204.2C4080,212,4200,327,4320,334.8C4440,343,4560,245,4680,204.2C4800,163,4920,180,5040,212.3C5160,245,5280,294,5400,326.7C5520,359,5640,376,5760,383.8C5880,392,6000,392,6120,343C6240,294,6360,196,6480,130.7C6600,65,6720,33,6840,81.7C6960,131,7080,261,7200,285.8C7320,310,7440,229,7560,228.7C7680,229,7800,310,7920,351.2C8040,392,8160,392,8280,351.2C8400,310,8520,229,8580,187.8L8640,147L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
      ></path> */}
        {/* <defs>
          <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
            <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 100px)", opacity: 0.8 }}
          fill="url(#sw-gradient-2)"
          d="M0,49L60,98C120,147,240,245,360,253.2C480,261,600,180,720,138.8C840,98,960,98,1080,155.2C1200,212,1320,327,1440,318.5C1560,310,1680,180,1800,163.3C1920,147,2040,245,2160,236.8C2280,229,2400,114,2520,65.3C2640,16,2760,33,2880,81.7C3000,131,3120,212,3240,277.7C3360,343,3480,392,3600,408.3C3720,425,3840,408,3960,343C4080,278,4200,163,4320,171.5C4440,180,4560,310,4680,351.2C4800,392,4920,343,5040,302.2C5160,261,5280,229,5400,220.5C5520,212,5640,229,5760,269.5C5880,310,6000,376,6120,400.2C6240,425,6360,408,6480,383.8C6600,359,6720,327,6840,285.8C6960,245,7080,196,7200,179.7C7320,163,7440,180,7560,212.3C7680,245,7800,294,7920,334.8C8040,376,8160,408,8280,383.8C8400,359,8520,278,8580,236.8L8640,196L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
        ></path> */}
      </svg>
      <svg
        id="wave"
        style={{ transform: "rotate(180deg)", transition: "0.3s" }}
        viewBox="0 0 1440 490"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="background-pattern2"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
            <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 0px)", opacity: 1 }}
          fill="url(#sw-gradient-0)"
          d="M0,441L60,416.5C120,392,240,343,360,302.2C480,261,600,229,720,196C840,163,960,131,1080,163.3C1200,196,1320,294,1440,334.8C1560,376,1680,359,1800,326.7C1920,294,2040,245,2160,245C2280,245,2400,294,2520,269.5C2640,245,2760,147,2880,98C3000,49,3120,49,3240,89.8C3360,131,3480,212,3600,253.2C3720,294,3840,294,3960,318.5C4080,343,4200,392,4320,343C4440,294,4560,147,4680,81.7C4800,16,4920,33,5040,32.7C5160,33,5280,16,5400,32.7C5520,49,5640,98,5760,138.8C5880,180,6000,212,6120,220.5C6240,229,6360,212,6480,179.7C6600,147,6720,98,6840,65.3C6960,33,7080,16,7200,32.7C7320,49,7440,98,7560,122.5C7680,147,7800,147,7920,155.2C8040,163,8160,180,8280,187.8C8400,196,8520,196,8580,196L8640,196L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
        ></path>
        {/* <defs>
        <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
          <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        style={{ transform: "translate(0, 50px)", opacity: 0.9 }}
        fill="url(#sw-gradient-1)"
        d="M0,196L60,163.3C120,131,240,65,360,40.8C480,16,600,33,720,49C840,65,960,82,1080,73.5C1200,65,1320,33,1440,49C1560,65,1680,131,1800,147C1920,163,2040,131,2160,147C2280,163,2400,229,2520,277.7C2640,327,2760,359,2880,326.7C3000,294,3120,196,3240,196C3360,196,3480,294,3600,294C3720,294,3840,196,3960,204.2C4080,212,4200,327,4320,334.8C4440,343,4560,245,4680,204.2C4800,163,4920,180,5040,212.3C5160,245,5280,294,5400,326.7C5520,359,5640,376,5760,383.8C5880,392,6000,392,6120,343C6240,294,6360,196,6480,130.7C6600,65,6720,33,6840,81.7C6960,131,7080,261,7200,285.8C7320,310,7440,229,7560,228.7C7680,229,7800,310,7920,351.2C8040,392,8160,392,8280,351.2C8400,310,8520,229,8580,187.8L8640,147L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
      ></path> */}
        {/* <defs>
          <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(243, 106, 62, 1)" offset="0%"></stop>
            <stop stop-color="rgba(255, 179, 11, 1)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          style={{ transform: "translate(0, 100px)", opacity: 0.8 }}
          fill="url(#sw-gradient-2)"
          d="M0,49L60,98C120,147,240,245,360,253.2C480,261,600,180,720,138.8C840,98,960,98,1080,155.2C1200,212,1320,327,1440,318.5C1560,310,1680,180,1800,163.3C1920,147,2040,245,2160,236.8C2280,229,2400,114,2520,65.3C2640,16,2760,33,2880,81.7C3000,131,3120,212,3240,277.7C3360,343,3480,392,3600,408.3C3720,425,3840,408,3960,343C4080,278,4200,163,4320,171.5C4440,180,4560,310,4680,351.2C4800,392,4920,343,5040,302.2C5160,261,5280,229,5400,220.5C5520,212,5640,229,5760,269.5C5880,310,6000,376,6120,400.2C6240,425,6360,408,6480,383.8C6600,359,6720,327,6840,285.8C6960,245,7080,196,7200,179.7C7320,163,7440,180,7560,212.3C7680,245,7800,294,7920,334.8C8040,376,8160,408,8280,383.8C8400,359,8520,278,8580,236.8L8640,196L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
        ></path> */}
      </svg>
    </div>
  );
}