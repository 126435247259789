import {
  GET_LIST_OF_ORGS,
  GET_LIST_OF_ORGS_SUCCESSFUL,
  GET_LIST_OF_ORGS_FAILED 
} from './actionTypes';

export const getListOfOrgs = (org) => {
  return {
      type: GET_LIST_OF_ORGS,
      payload: { org }
  }
}

export const getListOfOrgsSuccessful = (org) => {
  return {
      type: GET_LIST_OF_ORGS_SUCCESSFUL,
      payload: { org }
  }
}

export const getListOfOrgsFailed = (org) => {
  return {
      type: GET_LIST_OF_ORGS_FAILED,
      payload: { org }
  }
}
