import {
  GET_IAAC_SUBSCRIPTION_REQUEST,
  GET_IAAC_SUBSCRIPTION_SUCCESSFUL,
  GET_IAAC_SUBSCRIPTION_FAILED,
  POST_IAAC_SUBSCRIPTION_REQUEST,
  POST_IAAC_SUBSCRIPTION_SUCCESSFUL,
  POST_IAAC_SUBSCRIPTION_FAILED,
  PUT_IAAC_SUBSCRIPTION_REQUEST,
  PUT_IAAC_SUBSCRIPTION_SUCCESSFUL,
  PUT_IAAC_SUBSCRIPTION_FAILED,
  CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES_SUCCESSFUL
} from "./actionTypes";

const initialState = {
  isIaacSubscriptionGetting: false,
  getIaacSubscriptionMessage: null,
  getIaacSubscriptionError: null,
  subscription: null,
  isIaacSubscriptionPosting: false,
  postIaacSubscriptionMessage: null,
  postIaacSubscriptionError: null,
  isIaacSubscriptionPutting: false,
  putIaacSubscriptionMessage: null,
  putIaacSubscriptionError: null,
};

const iaacSubscriptionReducer = (state=initialState, action) => { 
  switch(action.type){
    case GET_IAAC_SUBSCRIPTION_REQUEST:
      state = {
        ...state,
        isIaacSubscriptionGetting: true,
        getIaacSubscriptionMessage: null,
        getIaacSubscriptionError: null,
        subscription: null,
      };
      break;
    case GET_IAAC_SUBSCRIPTION_SUCCESSFUL:
      state = {
        ...state,
        isIaacSubscriptionGetting: false,
        getIaacSubscriptionMessage: "IaaC Subscription received successfully",
        subscription: action.payload.subscription,
      };
      break;
    case GET_IAAC_SUBSCRIPTION_FAILED:
      state = {
        ...state,
        isIaacSubscriptionGetting: false,
        getIaacSubscriptionError: action.payload.getIaacSubscriptionError.message,
      };
      break;
    case POST_IAAC_SUBSCRIPTION_REQUEST:
      state = {
        ...state,
        isIaacSubscriptionPosting: true,
        postIaacSubscriptionMessage: null,
        postIaacSubscriptionError: null,
        subscriptionRequest: action.payload.subscriptionRequest,
        orgcode: action.payload.orgcode,
      };
      break;
    case POST_IAAC_SUBSCRIPTION_SUCCESSFUL:
      state = {
        ...state,
        isIaacSubscriptionPosting: false,
        postIaacSubscriptionMessage: action.payload.postIaacSubscriptionMessage,
        subscription: action.payload.subscription,
      };
      break;
    case POST_IAAC_SUBSCRIPTION_FAILED:
      state = {
        ...state,
        isIaacSubscriptionPosting: false,
        postIaacSubscriptionError: action.payload.postIaacSubscriptionError.message,
        subscription: null,
      };
      break;
    case PUT_IAAC_SUBSCRIPTION_REQUEST:
      state = {
        ...state,
        isIaacSubscriptionPutting: true,
        putIaacSubscriptionMessage: null,
        putIaacSubscriptionError: null,
        subscription: action.payload.subscription,
      };
      break;
    case PUT_IAAC_SUBSCRIPTION_SUCCESSFUL:
      state = {
        ...state,
        isIaacSubscriptionPutting: false,
        putIaacSubscriptionMessage: action.payload.putIaacSubscriptionMessage,
        subscription: action.payload.subscription,
      };
      break;
    case PUT_IAAC_SUBSCRIPTION_FAILED:
      state = {
        ...state,
        isIaacSubscriptionPutting: false,
        putIaacSubscriptionError: action.payload.putIaacSubscriptionError.message,
        subscription: null,
      };
      break;
    case CLEAR_ALL_IAAC_SUBSCRIPTION_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        getIaacSubscriptionMessage: null,
        getIaacSubscriptionError: null,
        postIaacSubscriptionMessage: null,
        postIaacSubscriptionError: null,
        putIaacSubscriptionMessage: null,
        putIaacSubscriptionError: null,
      };
      break;
    default:
      return state;
  }
  return state;
}

export default iaacSubscriptionReducer;