import { fork, put, all, call, takeLatest } from "redux-saga/effects";
import {
  downloadTagsSuccessful,
  downloadTagsFailure,
  getTagDetailsSuccessful,
  getTagDetailsFailure,
  uploadTagsSuccessful,
  uploadTagsFailure,
  clearTagMessagesSuccessful,
} from "./tagAllocationSlice";

import {
  fetchTagsApi,
  fetchTagDetailsApi,
  uploadTagsApi,
} from "../../helpers/auth_aws_helper";

function* downloadTags(action) {
  try {
    const response = yield call(
      fetchTagsApi,
      action.payload.orgCode,
      action.payload.accountNumber
    );

    console.log("downloadTagsApi response:", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(downloadTagsSuccessful(Object.values(response).join('')));
    } else {
      yield put(downloadTagsFailure({ 
        message: response?.data?.message || 
        response?.data ||
        "Unexpected error occurred while fetching tags."
      }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        downloadTagsFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        downloadTagsFailure({ message: "No response received" })
      );
    } else {
      yield put(downloadTagsFailure({ message: error.message }));
    }
  }
}

function* getTagDetails(action) {
  console.log("🚀 ~ function*getTagDetails ~ action:", action)
  try {
    // Call the API function and pass the action payload (which contains orgCode, accountNumber, and username)
    const response = yield call(
      fetchTagDetailsApi,
      action.payload.orgCode,
      action.payload.accountNumber,
      // action.payload.username
    );

    console.log("getTagDetailsApi response:", response);
    if (response && response.status === 200) {
      delete response.status;
      yield put(getTagDetailsSuccessful(Object.values(response)));
    } else {
      yield put(
        getTagDetailsFailure({
          message:
            response?.data?.message ||
            response?.data ||
            "Unexpected error occurred while fetching tag details.",
        })
      );
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getTagDetailsFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        getTagDetailsFailure({ message: "No response received" })
      );
    } else {
      yield put(getTagDetailsFailure({ message: error.message }));
    }
  }
}

function* uploadTags(action) {
  console.log("uploadTags saga, action:", action);
  try {
    const orgCode = action.payload.orgCode;
    const accountNumber = action.payload.accountNumber;
    const selectedFile = action.payload.file;
    const fileName = selectedFile.name;
    // Create FormData
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('orgCode', orgCode);
    formData.append('accountNumber', accountNumber);

    console.log("uploadTags saga, action: formData = ", formData);

    const response = yield call(uploadTagsApi, 
      orgCode,
      accountNumber,
      fileName,
      formData
    );
    console.log("uploadTagsApi response:", response);

    if (response && response.status === 200) {
      yield put(uploadTagsSuccessful(response.data));
    } else {
      yield put(uploadTagsFailure({ 
        message: response?.data?.message || 
        response?.data ||
        "Unexpected error occurred while uploading tags."
      }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        uploadTagsFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        uploadTagsFailure({ message: "No response received" })
      );
    } else {
      yield put(uploadTagsFailure({ message: error.message }));
    }
  }
}

function* clearTagMessages() {
  yield put(clearTagMessagesSuccessful());
}

export function* watchTags() {
  yield takeLatest("tags/downloadTags", downloadTags);
  yield takeLatest("tags/getTagDetails", getTagDetails);
  yield takeLatest("tags/uploadTags", uploadTags);
  yield takeLatest("tags/clearTagMessages", clearTagMessages);
}

export function* watchTagsSaga() {
  yield all([fork(watchTags)]);
}

export default watchTagsSaga;