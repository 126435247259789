import { fork, put, all, call, takeLatest } from "redux-saga/effects";
import {
  fetchBillingRecordsSuccess,
  fetchBillingRecordsFailure,
} from "./billingSlice";
import { fetchBillingRecordsApi } from "../../helpers/auth_aws_helper";

function* fetchBillingRecords() {
  try {
    console.log("Fetching Billing Records");
    const response = yield call(fetchBillingRecordsApi);
    console.log("fetchBillingRecordsApi response:", response);
    if (response && response.status === 200) {
      const records = Object.values(response).filter(record => typeof record === 'object');
      yield put(fetchBillingRecordsSuccess(records));
    } else {
      console.log("Fetching Billing Records Error:", response);
      yield put(
        fetchBillingRecordsFailure({
          message:
            response?.data?.message ||
            "Unexpected error occurred while fetching billing records.",
        })
      );
    }
  } catch (error) {
    if (error.response) {
      yield put(
        fetchBillingRecordsFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        fetchBillingRecordsFailure({ message: "No response received" })
      );
    } else {
      yield put(fetchBillingRecordsFailure({ message: error.message }));
    }
  }
}

function* watchBillingRecords() {
  console.log("Saga is listening for fetchBillingRecords");
  yield takeLatest("billing/fetchBillingRecords", fetchBillingRecords);
}

export function* billingSaga() {
  yield all([fork(watchBillingRecords)]);
}

export default billingSaga;
