import JSEncrypt from "jsencrypt";
import { fetchPublicKey } from "../helpers/fetchPublicKey";

const encryptPassword = async (passwordToEncrypt) => {
  try {
    const publicKeyString = await fetchPublicKey();

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKeyString);

    // Encrypt the password using the public key
    const encrypted = encrypt.encrypt(passwordToEncrypt);
    console.log("Encrypted Password:", encrypted);

    return encrypted; // Return the encrypted password
  } catch (error) {
    console.error("Error encrypting password:", error);
    throw error; // Re-throw the error to handle it in the caller function
  }
};

export default encryptPassword;
