import React from "react";
import { TextField } from "@mui/material";


export const GlobalFilter = ({filter, setFilter}) => {
    return (
        <span>
            <TextField
                name="filter"
                margin="dense"
                variant="outlined"
                label="Search"
                value={filter || ''}
                placeholder="Search"
                type="text"
                size="small"
                fullWidth
                onChange={ e=> setFilter(e.target.value)}
            />
        </span>
    )
}