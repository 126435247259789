import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import refreshToken from "./jwt-token-access/refreshToken"


 // const API_URL = "https://alb01.dev01.cntdev.link/backend";
const API_URL = process.env.REACT_APP_API_URL;
 //const API_URL = "http://localhost:8080";
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
      
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}
const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
})
axiosApi.interceptors.request.use(
    config => {
        const token = accessToken();
        const refToken = refreshToken();
        config.headers["Authorization"] = token ? `Bearer ${token}` : '';
        config.data = {...config.data};
        return config;
    },
    error => Promise.reject(error)
)


export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => ({...response.data, status: response.status})).catch(err => err);
}

export async function getBodyOnly(url, config = {}) {
    return await axiosApi
        .get(url, { ...config })
        .then(response => ({...response.data})).catch(err => err);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export const setupInterceptors = history => {
    axiosApi.interceptors.response.use(res => {

    return res
  }, err => {
    try {

      const { status } = err.response;
      const originalRequest = err.config;
      const refToken = refreshToken();

      if (status === 401 && !originalRequest._retry && refToken && refToken !== "" ) {


        if(isRefreshing){
          return new Promise(function(resolve,reject){
            failedQueue.push({resolve, reject})
          }).then(token => {originalRequest.headers['Authorization'] = 'Bearer' + token;
          return axiosApi(originalRequest);
        }).catch(err =>{
          return Promise.reject(err)
        })
        }
          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function (resolve, reject) {
              axiosApi.post('/userauth/refreshtoken', {
              "refreshToken": refToken
            }).then(res => {

              localStorage.setItem("authUser", JSON.stringify(res.data));
              processQueue(null, res.data.token)
              resolve(axiosApi(originalRequest));

            })
            .catch((err) => {
              // history.push('/sessionExpired');
              // console.log("suh", err)
              processQueue(err, null);
              reject(err)
              history.push('/sessionExpired')
              // return Promise.reject(err);
            }).finally(() => {isRefreshing = false})
          })
          
      } 

      return Promise.reject(err)
    } catch (e) {
      return Promise.reject(err)
    }
  })
}

export default axiosApi
