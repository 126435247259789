import { takeEvery, fork, put, all, call, delay } from 'redux-saga/effects';
import { GET_LIST_OF_USERS, POST_EDIT_USER, POST_NEW_USER, 
	GET_REACTIVE_USER, DELETE_USER,
	CLEAR_ALL_USER_LIST_MESSAGES} from './actionTypes';
import {
	getListOfUsers as getListOfUsersAction,
  getListOfUsersSuccessful,
  getListOfUsersFailed,
  updateReActive,
  addNewUserSuccessful,
  addNewUserFailed,
  editUserSuccessful,
  editUserFailed,
	deleteUserSuccessful,
	deleteUserFailed,
  clearAllUserListMessagesSuccessful as clearAllUserListMessagesSuccessfulAction,
} from "./actions";
import { getOrgsAndAccts, reActiveGet } from '../../helpers/auth_aws_helper';
import {
  editUserPatch,
  deleteUserService,
} from "../../helpers/auth_aws_helper";

import {
  addNewUserPost,
} from "../../helpers/auth_aws_helper";

function* getListOfUsers({ payload: { orgs } }) {
	try {
		const response = yield call(getOrgsAndAccts, orgs);
		yield put(getListOfUsersSuccessful(response));
	} catch (error) {
		yield put(getListOfUsersFailed(error));
	}
}

function* getReActive({ payload }) {
	try {
		const response = yield call(reActiveGet, payload);
		yield put(updateReActive({status: 200}));
	} catch (error) {
		yield put(getListOfUsersFailed(error));
	}
}

function* deleteUserSaga({ payload }) {
  try {

    payload.isDeleted = true;
    payload.userName = payload.username;
    payload.firstName = payload.firstname;
    // delete payload.action;
    delete payload.username;
    delete payload.firstname;
    const response = yield call(deleteUserService, payload);
		if (response && response.status === 200) {
			const { status, ...messageData } = response;
			const messageArray = Object.values(messageData);
			const message = messageArray.join("");
			yield put(deleteUserSuccessful(({ message: message})));
			yield delay(2000);
			yield put(getListOfUsersAction());
		} else {
			let message;
			if(response.data?.message){
				message = response.data.message;
			} else {
				message = response.data;
			}
			yield put(deleteUserFailed({ message: message}));
		}    
  } catch (error) {
		if (error.response) {
      yield put(
        deleteUserFailed({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(deleteUserFailed({ message: "No response received" }));
    } else {
      yield put(deleteUserFailed({ message: error?.message }));
    }
  }
}

function* addNewUserSaga({ payload: {user} }) {
	try {
		const params = { ...user };
		const userRolesDTOS = []
		user.userRolesDTOS.map((item) => {
			userRolesDTOS.push({
				roleName: item
			})
		});
		const resourceGroupDTOS = []
		user.resourceGroupDTOS.map((item) => {
			resourceGroupDTOS.push({
				// resourcename: item
				item
			})
		});
		const acccodes = [];
		user.acccodes.map((item) => {
			acccodes.push({ acccode: item });
		});

		
		params.acccodes = acccodes;
		params.isActivated = true;
		params.userRolesDTOS = userRolesDTOS;
		// params.resourceGroupDTOS = resourceGroupDTOS;
		params.resourceGroupDTOS = user.resourceGroupDTOS;

		const response = yield call(addNewUserPost, params);
		if (response && response.status === 200) {
			yield put(addNewUserSuccessful({ message: response.data}));

			// Added for auto refresh users after adding a new user
			yield delay(2000);
			yield put(getListOfUsersAction());

		} else {
			let message;
			if(response.data?.message){
				message = response.data.message;
			} else {
				message = response.data;
			}
			yield put(addNewUserFailed({ message: message }));
		}
	} catch ( error ) {
		if (error.response) {
      yield put(
        addNewUserFailed({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(addNewUserFailed({ message: "No response received" }));
    } else {
      yield put(addNewUserFailed({ message: error?.message }));
    }
	}
}

function* editUserSaga({ payload: { user } }) {
	try {
		const params = { ...user };
		params.password = null;
		params.isActivated = true;
		const acccodes = [];
		user.acccodes.map((item) => {
			acccodes.push({ acccode: item });
		});
		params.acccodes = acccodes;

		const response = yield call(editUserPatch, params);
		if (response && response.status === 200) {
			yield put(editUserSuccessful(({ message: "User updated successfully!"})));
			yield delay(2000);
			yield put(getListOfUsersAction());
		} else {
			let message;
			if(response.data?.message){
				message = response.data.message;
			} else {
				message = response.data;
			}
			yield put(editUserFailed({ message: message }));
		}
	} catch ( error ) {
		if (error.response) {
      yield put(
        editUserFailed({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(editUserFailed({ message: "No response received" }));
    } else {
      yield put(editUserFailed({ message: error?.message }));
    }
	}
}

export function* clearAllUserListMessages() {
  yield put(clearAllUserListMessagesSuccessfulAction());
}

export function* watchGetListOfUsers() {
	yield takeEvery(GET_LIST_OF_USERS, getListOfUsers)
	yield takeEvery(POST_NEW_USER, addNewUserSaga)
	yield takeEvery(POST_EDIT_USER, editUserSaga)
	yield takeEvery(GET_REACTIVE_USER, getReActive)
	yield takeEvery(DELETE_USER, deleteUserSaga)
	yield takeEvery(CLEAR_ALL_USER_LIST_MESSAGES, clearAllUserListMessages)
}

function* getUserSaga() {
	yield all([fork(watchGetListOfUsers)]);
}

export default getUserSaga;