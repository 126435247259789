import {
  ADD_RESOURCE_GROUP,
  ADD_RESOURCE_GROUP_SUCCESSFUL,
  ADD_RESOURCE_GROUP_FAILURE,
  DELETE_RESOURCE_GROUP,
  DELETE_RESOURCE_GROUP_SUCCESSFUL,
  DELETE_RESOURCE_GROUP_FAILURE,
  EDIT_RESOURCE_GROUP,
  EDIT_RESOURCE_GROUP_SUCCESSFUL,
  EDIT_RESOURCE_GROUP_FAILURE,
  GET_RESOURCE_GROUPS,
  GET_RESOURCE_GROUPS_SUCCESSFUL,
  GET_RESOURCE_GROUPS_FAILURE,
  CLEAR_ALL_RESOURCE_GROUP_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  isAddingResourceGroup: false,
  addResourceGroupMessage: null,
  addResourceGroupError: null,

  deleteResourceGroupError: null,
  isDeletingResourceGroup: false,
  deleteResourceGroupMessage: null,

  isGettingResourceGroups: false,
  getResourceGroupsMessage: null,
  getResourceGroupsError: null,
};

const resourceGroup = (state = initialState, action) => {
  console.log("Reducer, Action Type :: ", action.type);
  switch (action.type) {
    case ADD_RESOURCE_GROUP:
      state = {
        ...state,
        isAddingResourceGroup: true,
        addResourceGroupMessage: null,
        addResourceGroupError: null,
      };
      break;
    case ADD_RESOURCE_GROUP_SUCCESSFUL:
      state = {
        ...state,
        isAddingResourceGroup: false,
        addResourceGroupMessage: action.payload.resourceGroup,
        addResourceGroupError: null,
      };
      break;
    case ADD_RESOURCE_GROUP_FAILURE:
      state = {
        ...state,
        isAddingResourceGroup: false,
        addResourceGroupMessage: null,
        addResourceGroupError: action.payload.error.message,
      };
      break;
      case EDIT_RESOURCE_GROUP:
        state = {
          ...state,
          isEditingResourceGroup: true,
          editResourceGroupMessage: null,
          editResourceGroupError: null,
        };
        break;
      case EDIT_RESOURCE_GROUP_SUCCESSFUL:
        state = {
          ...state,
          isEditingResourceGroup: false,
          editResourceGroupMessage: action.payload.resourceGroup,
          editResourceGroupError: null,
        };
        break;
      case EDIT_RESOURCE_GROUP_FAILURE:
        state = {
          ...state,
          isEditingResourceGroup: false,
          editResourceGroupMessage: null,
          editResourceGroupError: action.payload.error.message,
        };
        break;      
    case DELETE_RESOURCE_GROUP:
      console.log(
        "reducer -> DELETE_RESOURCE_GROUP called with resource group name: ",
        action.payload.resourceGroupName,
        " and organization code: ",
        action.payload.orgCode
      );
      state = {
        ...state,
        isDeletingResourceGroup: true,
        deleteResourceGroupError: null,
        resourceGroupName: action.payload.resourceGroupName,
        orgCode: action.payload.orgCode,
        deleteResourceGroupMessage: null,
      };
      break;
    case DELETE_RESOURCE_GROUP_SUCCESSFUL:
      console.log("reducer -> DELETE_RESOURCE_GROUP_SUCCESS called");
      state = {
        ...state,
        isDeletingResourceGroup: false,
        deleteResourceGroupError: null,
        deleteResourceGroupMessage: action.payload.resourceGroup
      };
      break;
    case DELETE_RESOURCE_GROUP_FAILURE:
      console.log(
        "reducer -> DELETE_RESOURCE_GROUP_FAILURE called with error: ",
        action.payload.error
      );
      state = {
        ...state,
        isDeletingResourceGroup: false,
        deleteResourceGroupError: action.payload.error.message,
        resourceGroupName: null,
        orgCode: null,
      };
      break;

    case GET_RESOURCE_GROUPS:
      state = {
        ...state,
        isGettingResourceGroups: true,
        getResourceGroupsMessage: null,
        getResourceGroupsError: null,
      };
      break;
    case GET_RESOURCE_GROUPS_SUCCESSFUL:
      state = {
        ...state,
        isGettingResourceGroups: false,
        getResourceGroupsMessage: "Resource Groups Retrieved Successfully!",
        getResourceGroupsError: null,
        resourceGroups: action.payload.resourceGroups,
      };
      break;
    case GET_RESOURCE_GROUPS_FAILURE:
      state = {
        ...state,
        isGettingResourceGroups: false,
        getResourceGroupsMessage: null,
        getResourceGroupsError: action.payload.error,
      };
      break;
    case CLEAR_ALL_RESOURCE_GROUP_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        addResourceGroupMessage: null,
        editResourceGroupMessage: null,
        deleteResourceGroupMessage: null,
        getResourceGroupsMessage: null,

        addResourceGroupError: null,
        editResourceGroupError: null,
        deleteResourceGroupError: null,
        getResourceGroupsError: null,
      };
      break;

    default:
      return state;
  }
  return state;
};

export default resourceGroup;