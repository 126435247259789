import { 
	GET_LIST_OF_ORGS,
  GET_LIST_OF_ORGS_SUCCESSFUL,
  GET_LIST_OF_ORGS_FAILED
} from './actionTypes';

const initialState = {
  error: null, message: null, loading: null
}

const orgList = (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_OF_ORGS:
			state = {
				...state,
				loading: true,
				error: null
			}
			break;
		case GET_LIST_OF_ORGS_SUCCESSFUL:
			state = {
				...state,
				organizations: action.payload.org.organizations,
				loading: false,
				error: null
			}
			break;
		case GET_LIST_OF_ORGS_FAILED:
			state = {
				...state,
				loading: false,
				error: action.payload.org
			}
			break;
		default:
			return state;
	}
	return state;
}

export default orgList;