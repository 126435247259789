import { createSlice } from "@reduxjs/toolkit";

export const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    isFetchingTags: false,
    fetchTagsMessage: null,
    fetchTagsError: null,
    tagsDownloadUrl: null,
    
    isFetchingTagDetails: false,
    fetchTagDetailsMessage: null,
    fetchTagDetailsError: null,
    tagDetails: null,
    
    isUploadingTags: false,
    uploadMessage: null,
    uploadError: null,
  },
  reducers: {
    downloadTags: (state) => {
      state.isFetchingTags = true;
      state.fetchTagsMessage = null;
      state.fetchTagsError = null;
      state.tagsDownloadUrl = null;
    },
    downloadTagsSuccessful: (state, action) => {
      state.isFetchingTags = false;
      state.tagsDownloadUrl = action.payload;
      state.fetchTagsMessage = "Tags retrieved successfully!"
      state.fetchTagsError = null;
    },
    downloadTagsFailure: (state, action) => {
      state.isFetchingTags = false;
      state.fetchTagsError = action.payload.message;
    },
    getTagDetails: (state) => {
      state.isFetchingTagDetails = true;
      state.fetchTagDetailsMessage = null;
      state.fetchTagDetailsError = null;
    },
    getTagDetailsSuccessful: (state, action) => {
      state.isFetchingTagDetails = false;
      state.fetchTagDetailsMessage = "Tag details retrieved successfully!"
      state.tagDetails = action.payload;
      state.fetchTagDetailsError = null;
    },
    getTagDetailsFailure: (state, action) => {
      state.isFetchingTagDetails = false;
      state.fetchTagDetailsError = action.payload.message;
    },
    uploadTags: (state) => {
      state.isUploadingTags = true;
      state.uploadMessage = null;
      state.uploadError = null;
    },
    uploadTagsSuccessful: (state, action) => {
      state.isUploadingTags = false;
      state.uploadMessage = action.payload;
    },
    uploadTagsFailure: (state, action) => {
      state.isUploadingTags = false;
      state.uploadError = action.payload.message;
    },
    clearTagMessages: (state, action) => {},
    clearTagMessagesSuccessful: (state) => {
      state.fetchTagsMessage = null;
      state.fetchTagDetailsMessage = null;
      state.uploadMessage = null;

      state.fetchTagsError = null;
      state.fetchTagDetailsError = null;
      state.uploadError = null;
    },
  },
});

export const {
  downloadTags,
  downloadTagsSuccessful,
  downloadTagsFailure,
  getTagDetails,
  getTagDetailsSuccessful,
  getTagDetailsFailure,
  uploadTags,
  uploadTagsSuccessful,
  uploadTagsFailure,
  clearTagMessages,
  clearTagMessagesSuccessful
} = tagsSlice.actions;

export default tagsSlice.reducer;