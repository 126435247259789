import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  API_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  FORCE_LOGOUT,
  CLEAR_ALL_LOGIN_MESSAGES_SUCCESS,
  VERIFY_TOTP_REQUEST,
  VERIFY_TOTP_SUCCESS,
  VERIFY_TOTP_FAILURE,
  LOGIN_USER_SUCCESSFUL_WITH_QR_CODE,
  LOGIN_USER_SUCCESSFUL_WITH_VERIFICATION,
} from "./actionTypes";

const initialState = {
    loginError: null, message: null, loading: false,
    verifyTotpLoading: false,
    verifyTotpSuccess: null,
    verifyTotpError: null,
}

const login = (state = initialState, action) => {
    switch (action.type) {
      case CHECK_LOGIN:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_USER_SUCCESSFUL_WITH_QR_CODE:
        state = {
          ...state,
          loading: false,
          mfaSetup: action.payload,
          stage: "MFASETUP"
        };
        break;
      case LOGIN_USER_SUCCESSFUL_WITH_VERIFICATION:
        state = {
          ...state,
          loading: false,
          verification: action.payload,
          stage: "VERIFICATION"
        };
        break;
      case LOGIN_USER_SUCCESSFUL:
        state = {
          ...state,
          loading: false,
          user: action.payload,
          stage: "AUTHENTICATED"
        };
        break;          
      case LOGOUT_USER:
        state = { ...state, user: null };
        break;
      case FORCE_LOGOUT:
        state = { ...state };
        break;
      case LOGOUT_USER_SUCCESS:
        state = { ...state };
        break;

      case API_ERROR:
        state = {
          ...state,
          loading: false,
          loginError: action.payload.error ? action.payload.error : null,
        };
        break;
      case VERIFY_TOTP_REQUEST:
        state = {
          ...state,
          verifyTotpLoading: true,
          verifyTotpSuccess: null,
          verifyTotpError: null,
        };
        break;
        case VERIFY_TOTP_SUCCESS:
          state = {
            ...state,
            verifyTotpLoading: false,
            verifyTotpSuccess: action.payload.verifyTotpSuccessMessage,
          };
          break;
          case VERIFY_TOTP_FAILURE:
            state = {
              ...state,
              verifyTotpLoading: false,
              verifyTotpError: action.payload.verifyTotpError.message,
            };
            break;
      case CLEAR_ALL_LOGIN_MESSAGES_SUCCESS:
        state = {
          ...state,
          loginError: null,
          verifyTotpError: null,
          verifyTotpSuccess: null,
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
}

export default login;