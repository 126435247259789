import {
  GET_PRICING_INFO,
  GET_PRICING_INFO_SUCCESSFUL,
  GET_PRICING_INFO_FAILED,
} from "./actionTypes";

const initialState = {
  error: null,
  pricingInfo: null,
  loading: null,
};

const pricingInfo = (state=initialState, action) => {
  switch (action.type) {
    case GET_PRICING_INFO:
      state = {
        ...state,
        loading: true,
        //Added to include accountNumber
        accountNumber: action.payload.accountNumber,
        subscription: action.payload.subscription,
        error: null
      }
      break;
    case GET_PRICING_INFO_SUCCESSFUL:
      state = {
        ...state, 
        pricingInfo: action.payload.pricingInfo,
        loading: false,
        error: null
      }
      break;
    case GET_PRICING_INFO_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    default:
      return state;
  }
  return state;
}

export default pricingInfo;