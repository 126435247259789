import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch, Route, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import {
  getLicensingData,
  getListOfAccts,
  pushNotification,
  getProfileRoleInfo,
  getListOfOrgsAndAccts,
  getListAccRules,
  getListAllRules,
} from '../../store/actions';
import AccountsConfig from './_accountsConfig';
import UsersConfig from './_usersConfig';
import SecurityConfig from './_securityConfig';
import ResourceGroupsConfig from "./_resourceGroupsConfig";
import InaccessibleResources from '../FreeTrial/InaccessibleResources';

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const Configuration = ({ licenseName }) => {

    console.log("Configuration page, licenseName: " , licenseName);
  const defaultRoutes = [
    {
      displayName: "Cloud Accounts",
      component: AccountsConfig,
      navLink: "/configuration/accounts",
    },
    {
      displayName: "Users",
      component: UsersConfig,
      navLink: "/configuration/users",
    },
    {
      displayName: "Security",
      component: freeLicenses.includes(licenseName)? InaccessibleResources : SecurityConfig,
      navLink: "/configuration/security",
    },
    {
      displayName: "Resource Groups",
      component: freeLicenses.includes(licenseName)? InaccessibleResources : ResourceGroupsConfig,
      navLink: "/configuration/resourcegroups",
    },
  ];
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [mask, setMask] = useState(false);
  const [configRoutes, setConfigRoutes] = useState();
  const [contactForAsistance, setContactForAsistance] = useState(false);
  const licensing = useSelector((state) => state.licensing);
  const notifications = useSelector((state) => state.notify);
  const acctList = useSelector((state) => state.acctList);
  const { data, costLookup, status } = licensing;

    useEffect(() => {
        dispatch(getLicensingData());
        dispatch(getListOfAccts());

        dispatch(getProfileRoleInfo());
        dispatch(getListOfOrgsAndAccts());
    }, []);
    useEffect(() => {
        if (licensing && licensing.data && licensing.data.numAccounts && acctList && acctList.accounts) {
            const numAccounts = licensing.data.numAccounts;
            const accountNotification = {message: `${acctList.accounts.length -1}/${numAccounts}`, 
            fullmessage:`Accounts ${acctList.accounts.length -1} of ${numAccounts} Used`,
            amend: false};
            if (acctList.accounts.length-1 < numAccounts) {
                accountNotification.className="badge badge-outline bg-white border border-secondary"
            } else if (acctList.accounts.length >= numAccounts) {
                accountNotification.className="badge-mine"
            }
            dispatch(pushNotification(accountNotification));

            const numUsers = licensing.data.numUsers;
            const temp = [];
            acctList.accounts.forEach((item) => {
                item.listusr.forEach((entity) => temp.push(entity))
            });
            const usersNotification = {message: `${acctList.usersConfig.length}/${numUsers}`, 
            fullmessage:`Users ${acctList.usersConfig.length} of ${numUsers} Used`,
            amend: true};
            if (acctList.usersConfig.length < numUsers) {
                usersNotification.className="badge badge-outline bg-white border border-secondary"
            } else if (acctList.usersConfig.length >= numUsers) {
                usersNotification.className = "badge-mine"
            }
            dispatch(pushNotification(usersNotification));
        }
    }, [licensing, acctList])

    useEffect(() => {
        const updatedRoutes = [];
        defaultRoutes.forEach((item) => {
            updatedRoutes.push({
                displayName: item.displayName,
                navLink: item.navLink,
                isActive: item.navLink === location.pathname
            })
        });
        setConfigRoutes(updatedRoutes);
    }, [location])

    useEffect(() => {
        
        if (data && !data.isLicensingComplete && data.licenseName === "NONE") {
            console.log("data is here ", data)
            setMask(true)
            setContactForAsistance(true)
        } else {
            setMask(false);
        }
    }, [data])

    const handleRedirectLink = (e) => {
        const isDirty = sessionStorage.getItem('isDirty', 'true');
        const targetLink = e.target.getAttribute('link');
        console.log('--== targetLink ', targetLink);
        if (targetLink && !isDirty) {
            e.preventDefault();
            e.stopPropagation();
            history.push(targetLink)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{backgroundColor: '#fff'}}>
                <Container fluid>
                    <Card className={mask ? "mask" : ""}>
                        <CardBody>
                            <Row>
                                <Col xs={8}>
                                    {
                                        configRoutes && (
                                            <ul className="nav nav-pills">
                                                {
                                                    configRoutes.map((item) => {
                                                        return (
                                                            <li className="nav-item">
                                                                <button
                                                                    onClick={handleRedirectLink}
                                                                    link={item.navLink}
                                                                    className={`btn btn-sm nav-link ${item.isActive ? 'active' : ''}`}
                                                                >
                                                                    {item.displayName}
                                                                </button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div className="mt-1"></div>
                                    <Switch>
                                        {
                                            defaultRoutes && defaultRoutes.map((item) => {
                                                return (
                                                    <Route path={item.navLink} component={item.component} />
                                                )
                                            })
                                        }
                                    </Switch>
                                </Col>
                            </Row>
                            <Modal isOpen={contactForAsistance}>
                                <ModalBody>
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="agreeTerms">
                                            Licensing must be completed before any changes can be made.
                                        </label>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn btn-secondary" onClick={() => setContactForAsistance(false)}>OK</button>
                                </ModalFooter>
                            </Modal>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default Configuration;