import React from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";

const AccountNumberFilter = ({
  accounts,
  selectedAccount,
  setSelectedAccount,
}) => {
  // Filter valid accounts
  const filteredAccounts = accounts.filter(
    (x) =>
      !x.accountNumber.includes("INIT00000001") &&
      !x.accountNumber.includes("TEST") &&
      x.accountNumber.length === 12
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ff9900",
        color: "#000", // Black color text
        fontWeight: "bold",
        padding: "4px 8px", // Reduced padding for smaller height
        borderRadius: "4px",
        height: "32px", // Make the component smaller
      }}
    >
      <Typography sx={{ marginRight: 1, fontSize: "14px", fontWeight: "bold", color: "#000" }}>
        Accounts:
      </Typography>
      <Select
        value={selectedAccount}
        onChange={(e) => setSelectedAccount(e.target.value)}
        sx={{
          backgroundColor: "#ff9900",
          color: "#000", // Black color text for the dropdown
          fontWeight: "bold",
          border: "none",
          fontSize: "14px", // Smaller font size
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "& .MuiSelect-icon": {
            color: "#000", // Black color for the icon
          },
          height: "32px", // Reduced height for the dropdown
          minWidth: "120px", // Adjust the width as needed
          paddingRight: "16px",
        }}
      >
        <MenuItem value="All">All</MenuItem>
        {filteredAccounts.map((account) => (
          <MenuItem key={account.accountNumber} value={account.accountNumber}>
            {account.accountNumber}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default AccountNumberFilter;
