import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import WebAcl from "./WebACL";
import IPSets from "./IPSets";
import RegexPatterns from "./RegexPatterns";
import RuleGroups from "./RuleGroups";

const WAFDashboard = () => {
  const [activeTab, setActiveTab] = useState("WebAcl");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "WebAcl":
        return <WebAcl />;
      case "IPSets":
        return <IPSets />;
      case "RegexPatterns":
        return <RegexPatterns />;
      case "RuleGroups":
        return <RuleGroups />;

      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Web ACL" value="WebAcl" />
        <Tab label="IP Sets" value="IPSets" />
        <Tab label="Regex Patterns" value="RegexPatterns" />
        <Tab label="Rule Groups" value="RuleGroups" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default WAFDashboard;
