import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_LICENSING_DATA, GET_SCAN_FREQ_METADATA, POST_LICENSING_DATA } from './actionTypes';
import { getLicensingDataSuccessful, getScanFreqMetadataSuccessful, postLicensingDataSuccessful, handleApiFailures } from './actions';
// import { getScanFreq, postLicensing } from '../../helpers/auth_helper';
import { getLicensing } from '../../helpers/auth_aws_helper';

function* getLicensingData() {
    try {
        const response = yield call(getLicensing);
        if (response && response.status === 200) {
            yield put(getLicensingDataSuccessful(response));
        } else {
            yield put(handleApiFailures(response));
        }
    } catch (error) {
        yield put(handleApiFailures(error));
    }
}

// function* getScanFreqMetadata() {
//     try {
//         const response = yield call(getScanFreq);
//         if (response && response.status === 200) {
//             yield put(getScanFreqMetadataSuccessful(response));
//         } else {
//             yield put(handleApiFailures(response));
//         }
//     } catch (error) {
//         yield put(handleApiFailures(error));
//     }
// }

// function* postLicensingData({payload}) {
//     try {
//         const response = yield call(postLicensing, payload.data);
//         yield put(postLicensingDataSuccessful({code: 200, message: response}));
//     } catch (error) {
//         yield put(handleApiFailures(error));
//     }
// }

export function* watchLicensing() {
    console.log('--== watchLicensing ==--');
    yield takeEvery(GET_LICENSING_DATA, getLicensingData)
    // yield takeEvery(GET_SCAN_FREQ_METADATA, getScanFreqMetadata)
    // yield takeEvery(POST_LICENSING_DATA, postLicensingData)
}

function* getLicensingSaga() {
    console.log('--== getLicensingSaga ==--');
    yield all([fork(watchLicensing)]);
}

export default getLicensingSaga;