import React from 'react';
import { MDBDataTableV5, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import './default.theme.css';

export default (props) => {
    return  (
        <div id="dataGridTheme">
            <MDBDataTableV5 {...props}>
                {props.children && props.children}
            </MDBDataTableV5>
        </div>
    )
}