import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { forceLogout } from "../../store/actions";
import { Row, Col, Button, Container } from "reactstrap";
import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";

class SessionExpired extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.forceLogout(this.props.history);
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={5}>
                            <div className="logout">
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100" >
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <form action="/login">
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo">
                                                            <img src={logodark} height="60" alt="logo" />
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-5">
                                                        Your Session Has Expired!
                                                    </h4>
                                                    <p className="text-muted">
                                                        Please sign in again to continue to CloudCatcher.
                                                    </p>
                                                </div>
                                                         <div className="mt-4 text-center">
                                                            <Button
                                                                color="primary"
                                                                className="w-md waves-effect waves-light"
                                                                type="submit"
                                                            >
                                                                Sign in
                                                            </Button>
                                                        </div>
                                                
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="authentication-bg">
                                {/* bg-overlay */}
                                <div className=""></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
        }

export default withRouter(connect(null, { forceLogout})(SessionExpired));