import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import InjectAxiosInterceptors from "./helpers/InjectAxiosInterceptors";
// Import Routes
import { adminRoutesOnly, authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// Import actions
import { getLicensingData } from "./store/actions";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import TrialExpiredLayout from "./components/VerticalLayout/TrialExpiredLayout";

// Allowed components on TrialExpiredLayout
import Settings from "./pages/Settings";

// Import scss
import "./datatables.scss";
import "./theme.scss";

import "./App.css";
import TrialExpiredPage from "./pages/FreeTrial/TrialExpiredPage";
import loadingGif from "./assets/images/aws/loadingGif.gif";
import { SnackbarProvider } from "notistack";

// const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
// const freeLicenses = [freeLicenseName];
// const GRACE_DAYS = 0;

// const landingPageMessages = {
//   costInsight: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access to Cost Insight Module. Cost Insight dashboard visualizes AWS cloud spend, provides detailed information on spending, spend by account, region, service and resource, projects, and customers.`,
//   eventInsight: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access to the Event Insight Module. The Event Insight dashboard provides near real-time events of your cloud environment such as login insight, network changes, and IAM accessibilities, etc. Using Mitre Attack framework to respond swiftly to security threats and operational challenges.`,
//   securityPosture: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access to Cloud Security Posture Management (CSPM) Module. The Security Posture dashboard captures misconfigurations and vulnerabilities of services and resources and provides step-by-step remediation to address security vulnerabilities.`,
//   compliance: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access to the Compliance Module. The Compliance dashboard offers continuous compliance monitoring. CloudCatcher automates validation of your cloud compliance posture, identifies misconfigurations between your current state and the required regulatory standard. It provides you with the step-by-step remediation to address compliance violations.`,
//   costOptimization: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access to the Cost Optimization Module. This helps customers analyze and reduce expenses to maximize cloud ROI by identifying underutilized and idle resources, saving plans, enabling better capacity planning and cost visibility.`,
//   iac: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access to the Infrastructure as a Code (IaaC) Module. IaaC Module scans known configuration templates such as CloudFormation, Terraform, and Kubernetes for identifying vulnerabilities before deploying services and resources to the cloud.`,
//   dashboard: `Looks like you’re on a free trial of CloudCatcher! Please subscribe to get full access`
// }

// const getKey = (routePath) => {
//   let key = null;
//   switch(routePath){
//     case "/costinsight":
//       key = "costInsight";
//       break;
//     case "/costoptimization":
//       key = "costOptimization";
//       break;
//     case "/eventinsight":
//       key = "eventInsight";
//       break;
//     case "/security":
//       key = "securityPosture";
//       break;
//     case "/compliance":
//       key = "compliance";
//       break;
//     case "/iacmanagement/dashboard":
//     case "/iacmanagement/*":
//     case "/iacmanagement/":
//     case "/iacmanagement":
//       key = "iac";
//       break;
//     case "/dashboard":
//         key = "dashboard";
//         break;
//     default:
//       key = null;
//   }
//   return key;
// }

class App extends Component {
  constructor(props) {
    super(props);
    this.getLayout = this.getLayout.bind(this);
  }

  // Should handle calling getLicensingData()
  componentDidMount() {
    if (!this.props.licenseData?.data) {
      if (localStorage.getItem("authUser")) {
        this.props.getLicensingData();
      }
    }
  }

  // Calculate days since expiration time
  // calculateDaysSinceExpiry(expiryDate) {
  //   const expirationDate = expiryDate;
  //   const expirationTime = new Date(expirationDate).getTime() - (new Date(expirationDate).getTimezoneOffset() * 60 * 1000);
  //   const currentTime = new Date().getTime();
  //   const oneHourInMillis = 60 * 60 * 1000;

  //   const daysLeft = Math.ceil( (expirationTime - currentTime) / (oneHourInMillis*24*1) );
  //   return daysLeft;
  // }

  // checks trial expiration
  // isTrialExpired() {
  //   const { licenseData } = this.props;
  //   if (licenseData && licenseData.data && licenseData.data.licenseName === freeLicenseName && licenseData.data.expiration_date) {
  //     const daysSinceExpiry = this.calculateDaysSinceExpiry(licenseData.data.expiration_date);
  //     const isTrialExpiredFlag = daysSinceExpiry <=(-1* GRACE_DAYS);
  //     return isTrialExpiredFlag
  //   }
  //   return false;
  // }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    // console.log("~~~~~ App Component is rendered, authUser: ", this.props.authUser);
    // let trialExpired = null;
    // if(this.props.licenseData && this.props.licenseData?.data) {
    //   trialExpired = this.isTrialExpired();
    // }
    const isLicenseLoading = this.props.licenseData?.loading;

    if (isLicenseLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <img src={loadingGif} height={40} alt="Loading Spinner" />
          <p>Please wait a while!</p>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Router>
          <InjectAxiosInterceptors></InjectAxiosInterceptors>
          <SnackbarProvider />

          <Switch>
            {publicRoutes.map((route, idx) => {
              return (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  allowedRoles={[
                    "Administrator",
                    "StandardUser",
                    "",
                    "PowerUser",
                  ]}
                />
              );
            })}
            {adminRoutesOnly.map((route, idx) => {
              console.log("🚀 ~ App ~ {adminRoutesOnly.map ~ route:", route);
              return (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  allowedRoles={["Administrator"]}
                />
              );
            })}
            {authProtectedRoutes.map((route, idx) => {
              return (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  allowedRoles={["StandardUser", "Administrator", "PowerUser"]}
                />
              );
            })}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    licenseData: state.licensing,
    // authUser: state.login,
  };
};

const mapDispatchToProps = {
  getLicensingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
