import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { Container, Paper, Slide } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { SnackbarProvider, enqueueSnackbar } from "notistack";

import logodark from "../../assets/images/Expanded_Colored_PoweredBy.png";

import { Grid, TextField, Button, Box, Typography } from "@material-ui/core";
import { BackgroundPattern } from "../../components/BackgroundPattern";

import {
  addFulfillment,
  clearAllAwsMarketplaceMessages,
} from "../../store/awsMarketplace/awsMarketplaceSlice";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
    overflow: "hidden",
  },
  formContainer: {
    display: "flex",
    textAlign: "center",
    gap: "1rem",
  },
  email: {
    flex: 1,
  },
  error: {
    color: "red",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0)",
  },
  container: {
    position: "relative",
    zIndex: 1,
    justifyContent: "space-evenly",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  submit: {
    height: "56px",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  subheading: {
    fontSize: "1rem",
    marginBottom: theme.spacing(4),
  },
});

const SignupPage = (props) => {
  const { classes } = props;
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const history = useHistory();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const invalidEmailErrorMessage = (email) => {
    if (email.length === 0) {
      return "Email is required";
    } else if (!isValidEmail(email)) {
      return "Email is not valid";
    } else {
      return "";
    }
  };

  const handleSubmit = (e) => {
    const addFulfillmentPayload = {
      email,
      token,
    };
    console.log("Calling addFulfillment with payload: ", addFulfillmentPayload);
    props.addFulfillment(addFulfillmentPayload);
  };

  const handleSignupResponse = useCallback((fulfillmentResponse) => {
    // const additionalInfo = {
    //   fulfillmentResponse: fulfillmentResponse
    // }
    history.push({
      pathname: "/aws-marketplace/onboard",
      search: `?token=${token}`,
      // state: additionalInfo
    });

    // if (signupResponse.code === 1001) {
    //   history.push({
    //     pathname: "/aws-marketplace/onboard",
    //     search: `?token=${token}`,
    //     state: additionalInfo });
    // } else if (signupResponse.code === 1002) {

    // }
  });

  const clearAllMessages = useCallback(() => {
    props.clearAllMessages();
  });
  const addFulfillmentMessage = props.addFulfillmentMessage;
  const addFulfillmentError = props.addFulfillmentError;
  const fulfillmentResponse = props.fulfillmentResponse;
  useEffect(() => {
    // console.log("SignupPage: useEffect");

    // For testing to bypass actual response
    // handleSignupResponse({code: 1001});
    // For testing to bypass actual response

    console.log("fulfillmentResponse: ", fulfillmentResponse);

    if (addFulfillmentMessage) {
      enqueueSnackbar(addFulfillmentMessage, { variant: "success" });
    }
    if (addFulfillmentError) {
      enqueueSnackbar(addFulfillmentError, { variant: "error" });
    }
    if (addFulfillmentMessage || addFulfillmentError) {
      setTimeout(() => clearAllMessages(), 5000);
    }
    if (fulfillmentResponse) {
      handleSignupResponse(fulfillmentResponse);
    }
  }, [
    addFulfillmentMessage,
    addFulfillmentError,
    fulfillmentResponse,
    handleSignupResponse,
    clearAllMessages,
  ]);

  return (
    <div className={`${classes.root}`}>
      <SnackbarProvider />
      <div className={`${classes.background}`}></div>
      <Container>
        <Grid container component="main" className={classes.container}>
          <Grid item xs={12} sm={8} md={6} lg={5}>
            <div className={classes.form}>
              <div className={classes.logo}>
                <Link to="/" className="logo">
                  <img src={logodark} height="60" alt="logo" />
                </Link>
              </div>
              <Box mt={4} textAlign="center">
                <Typography className={classes.heading}>
                  Signup for CloudCatcher
                </Typography>
                <Typography className={classes.subheading}>
                  Enter your email to get started.
                </Typography>
              </Box>

              {(token === null || token === undefined || token === "") && (
                <Box mt={4} textAlign="center">
                  <Typography className={classes.error}>
                    You need a token for using this feature.
                  </Typography>
                  <Typography className={classes.error}>
                    It appears that URL does not contain a token.
                  </Typography>
                </Box>
              )}

              <div className={classes.formContainer}>
                <TextField
                  label="Email"
                  variant="outlined"
                  name="Email"
                  value={email}
                  type="text"
                  id="email"
                  placeholder="Enter Email"
                  className={classes.email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setTouched(true)}
                  required
                  error={
                    touched && (email.length === 0 || !isValidEmail(email))
                  }
                  helperText={touched ? invalidEmailErrorMessage(email) : ""}
                  disabled={!token || token === ""}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  type="submit"
                  disabled={
                    !token ||
                    token === "" ||
                    !isValidEmail(email) ||
                    props.isAddingFulfillment
                  }
                  onClick={handleSubmit}
                >
                  <ArrowForwardIcon />
                </Button>
              </div>

              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ marginTop: "1rem" }}
              >
                For any other questions, please reach out to us at{" "}
                <a href="mailto:support.cloudcatcher@cloudnovatech.com">
                  support.cloudcatcher@cloudnovatech.com
                </a>
                .
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <BackgroundPattern />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    isAddingFulfillment,
    addFulfillmentMessage,
    addFulfillmentError,
    fulfillmentResponse,
  } = state.awsMarketplace;
  return {
    isAddingFulfillment,
    addFulfillmentMessage,
    addFulfillmentError,
    fulfillmentResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFulfillment: (addFulfillmentPayload) => {
      dispatch(addFulfillment(addFulfillmentPayload));
    },
    clearAllMessages: () => {
      dispatch(clearAllAwsMarketplaceMessages());
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SignupPage)
);
